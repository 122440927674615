import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Card, Col, Row, Space, Tag, Tooltip, Typography } from 'antd'
import { LockOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { useIntl } from 'react-intl'
import { format } from 'date-fns'

import IntlMessages from '../../util/IntlMessages'
import UpdateWorkweekForm from '../../components/update-workweek-form'
import UserUpdateForm from '../../components/user-update-form'
import WorkWeek from '../work-week'
import UserLeaveQuotas from '@vacationtracker/shared/components/user-leave-quotas'
import { useAppSelector } from '../../store/hooks'
import { selectAuthUserSlice } from '../../store/auth-user-slice'

import { IUserProfileTab } from './types'
import { LocaleEnum } from '@vacationtracker/shared/types/i18n'
import { getUserWorkingHoursPerDay } from '@vacationtracker/shared/functions/work-week'
import { getOnboardingLink } from '../../util/get-onboarding-link'

const { Paragraph, Text } = Typography

const UserProfileTab = ({
  user,
  amIAdmin,
  workingDays,
  quotas,
  teams,
  locations,
  isUserEndDateEnabled,
  hourlyLeaveAccounting,
  showUpdateUserForm,
  updateWorkingDays,
  updateUserProfile,
  onQuotaUpdate,
  onEdit,
  setActiveTab,
  setShowUpdateUsersForm,
}: IUserProfileTab): React.ReactElement => {
  const { formatMessage } = useIntl()
  const { authUser } = useAppSelector(selectAuthUserSlice)

  const [isEditWorkWeekFormVisible, showEditWorkWeekForm] = useState(false)
  const [visibleUserUpdateForm, setVisibleUserUpdateForm] = useState(false)

  useEffect(() => {
    if (typeof showUpdateUserForm === 'boolean') {
      setVisibleUserUpdateForm(showUpdateUserForm)
    }
  }, [showUpdateUserForm])

  const handleWorkingDaysUpdate = async (days: number[]): Promise<void> => {
    if (amIAdmin && updateWorkingDays) {
      await updateWorkingDays(days)
      showEditWorkWeekForm(false)
    }
  }

  const handleUserProfileUpdate = async (data) => {
    if (amIAdmin && updateUserProfile) {
      await updateUserProfile(data)
      setVisibleUserUpdateForm(false)
      setShowUpdateUsersForm && setShowUpdateUsersForm(false)
    }
  }

  return (
    <div className="user-profile-page">
      <Card
        title={<IntlMessages id="components.userProfileTab.userProfile" />}
        style={{ marginBottom: 30 }}
        styles={{
          body: {
            padding: 0,
          },
        }}
        extra={
          amIAdmin &&
          <Button type="link" size="small" onClick={() => setVisibleUserUpdateForm(true)}>
            <IntlMessages id="app.edit" />
          </Button>
        }
      >
        <div style={{ padding: 25 }}>
          <Row justify="start" gutter={[0, 15]}>
            <Col span={8}>
              <Space direction="vertical" size={[1, -15]} wrap>
                <Text strong><IntlMessages id="components.userProfileTab.name" /></Text>
                <Text data-testid="user-name">
                  {user.name} {
                    (user.isNameLocked && user.platform !== 'email') ?
                      <Tooltip className="info-tooltip" title={<IntlMessages id="components.userProfileTab.nameEdited" />}><LockOutlined /></Tooltip> :
                      ''
                  } {user.role.toLowerCase() !== 'admin' && user.id === authUser.id && user.platform === 'email' && <Button onClick={onEdit}>
                    <IntlMessages id="app.edit" />
                  </Button>
                  }
                </Text>
              </Space>
            </Col>
            <Col span={8}>
              <Space direction="vertical" size={[1, -15]} wrap>
                <Text strong><IntlMessages id="components.userProfileTab.team" /></Text>
                {amIAdmin ?
                  <Link to={`/app/settings/departments/${user.team.id}`}>{user.team.name}</Link> :
                  <Text>{user.team.name}</Text>
                }
              </Space>
            </Col>
            <Col span={8}>
              <Space direction="vertical" size={[1, -15]} wrap>
                <Text strong><IntlMessages id="components.userProfileTab.location" /></Text>
                {amIAdmin ?
                  <Link to={`/app/settings/locations/${user.location.id}/general`}>{user.location.name}</Link> :
                  <Text>{user.location.name}</Text>
                }
              </Space>
            </Col>
            <Col span={8}>
              <Space direction="vertical" size={[1, -15]} wrap>
                <Text strong><IntlMessages id="app.email" /></Text>
                <Text>{user.email && user.email.startsWith('deleted+') ? <IntlMessages id="app.emailDeleted" /> : user.email}</Text>
              </Space>
            </Col>
            <Col span={8}>
              <Space direction="vertical" size={[1, -15]} wrap>
                <Text strong><IntlMessages id="components.userProfileTab.employeeSince" /></Text>
                <Text>{user.startDate.split('T')[0]}</Text>
              </Space>
            </Col>
            <Col span={8}>
              <Space direction="vertical" size={[1, -15]} wrap>
                <Text strong><IntlMessages id="components.userProfileTab.role" /></Text>
                <Text>
                  {user.isAdmin ? <IntlMessages id="app.role.administrator" /> : <IntlMessages id="app.user" />}
                  {user.role && user.role.toLowerCase() === 'approver' ? <> (<IntlMessages id="app.role.approver" />)</> : ''}
                  &nbsp;(<a href={getOnboardingLink(user.platform as string, user.role)} target="_blank" rel="noreferrer"><IntlMessages id="components.heatmap.clickForMore" /></a>)
                </Text>
              </Space>
            </Col>
            <Col span={8}>
              <Space direction="vertical" size={[1, -15]} wrap>
                <Text strong><IntlMessages id="components.userProfileTab.status" /></Text>
                <Text>
                  {formatMessage({
                    id: user.status === 'ACTIVE' ? 'app.active' : user.status === 'DELETED' ? 'users.deletedUsers' : 'users.inactiveUsers',
                  }).toUpperCase()}
                </Text>
              </Space>
            </Col>
            {user.endDate &&
              <Col span={8}>
                <Space direction="vertical" size={[1, -15]} wrap>
                  <Text strong><IntlMessages id="components.userProfileTab.endDate" /></Text>
                  <Text>{user.endDate.split('T')[0]}</Text>
                </Space>
              </Col>
            }
            <Col span={8}>
              <Space direction="vertical" size={[1, -15]} wrap>
                <Space direction="horizontal">
                  <Text strong><IntlMessages id="user.employeeId" /></Text>
                  <Tooltip className="info-tooltip" title={<IntlMessages id="components.userProfileTab.employeeIdTooltip" />} ><InfoCircleOutlined /></Tooltip>
                </Space>
                <Text>{user.employeeId ? user.employeeId : <IntlMessages id="components.userProfileTab.employeeIdNotSet" />}</Text>
              </Space>
            </Col>
            <Col span={8}>
              <Space direction="vertical" size={[1, -15]} wrap>
                <Text strong><IntlMessages id="app.role.approvers" />:</Text>
                <Paragraph>
                  {user.approvers.join(', ')}
                </Paragraph>
              </Space>
            </Col>
          </Row>
        </div>
      </Card>

      <Card
        title={[
          <span key="title">{formatMessage({ id: 'components.userProfileTab.workingDays' })} </span>,
          user.workWeekType === 'USER' &&
          <Tooltip key="info" title={user.workWeekInfo ?
            <IntlMessages id="components.userProfileTab.customWorkWeekTooltip" values={{
              userName: user.name,
              timestamp: format(new Date(user.workWeekInfo[0]), 'MMMM d, yyyy'),
              adminName: user.workWeekInfo[1],
            }} /> :
            <IntlMessages id="components.userProfileTab.customWorkWeekEditedBefore" values={{ userName: user.name }} />}>
            <Tag color="purple"><IntlMessages id="components.userProfileTab.customWorkWeekLabel" /></Tag>
          </Tooltip>,
        ]}
        style={{ marginBottom: 30 }}
        styles={{
          body: { padding: 0 },
        }}
        extra={
          amIAdmin &&
          <Button type="link" size="small" onClick={() => showEditWorkWeekForm(true)}>
            <IntlMessages id="app.edit" />
          </Button>
        }
      >
        <div style={{ padding: 25 }}>
          <WorkWeek days={workingDays.days} firstDayOfWeek={workingDays.firstDayOfWeek} separator=", " />
        </div>
        <div style={{ padding: '0 25px 25px 25px' }}>
          <IntlMessages id="app.workday" /> ({<IntlMessages id="app.setOn" />} {user?.location?.name} {<IntlMessages id="app.location" />}):
          &nbsp;{getUserWorkingHoursPerDay(user.workHours)} <IntlMessages id="app.hours" />
        </div>
      </Card>
      <UserLeaveQuotas
        quotas={quotas}
        userId={user.id}
        amIAdmin={amIAdmin}
        IntlMessages={IntlMessages}
        onQuotaUpdate={onQuotaUpdate}
        location={user.location}
        userName={user.name}
        hourlyLeaveAccounting={hourlyLeaveAccounting}
        setActiveTab={setActiveTab}
        locale={user.locale || LocaleEnum.en}
        userStartDate={user.startDate}
        userStatus={user.status}
      />

      {amIAdmin && isEditWorkWeekFormVisible &&
        <UpdateWorkweekForm
          workWeek={workingDays.days}
          firstDayOfTheWeek={workingDays.firstDayOfWeek}
          handleSave={(days) => {
            handleWorkingDaysUpdate(days)
          }}
          handleCancel={() => showEditWorkWeekForm(false)}
          visible={isEditWorkWeekFormVisible}
        />
      }
      {amIAdmin && teams && visibleUserUpdateForm &&
        <UserUpdateForm
          initialRolloverChanged={Boolean(quotas[0].leaveTypes.find(lt => lt.rolloverFromPreviousYear !== 0))}
          visible={visibleUserUpdateForm}
          user={user}
          teams={teams}
          locations={locations}
          handleCancel={() => {
            setVisibleUserUpdateForm(false)
            setShowUpdateUsersForm && setShowUpdateUsersForm(false)
          }}
          onSave={(data) => {
            handleUserProfileUpdate(data)
          }}
          enableUserEndDate={isUserEndDateEnabled}
        />
      }
    </div>
  )
}

export default UserProfileTab
