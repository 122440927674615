import { AddonsParent, IAddonsData } from '@vacationtracker/shared/types/automations'
import { SubscriptionPlanEnum } from '@vacationtracker/shared/types/company'
import { FeatureFlagEnum } from '../types/feature-flags'

export const getAddonsEvents = (formatMessage?: Function): IAddonsData[] => {
  // every new addon, integration or automation should be added here
  const addonsEvents = [
    {
      type: 'BLACKOUT_PERIOD',
      parent: AddonsParent.automations,
      parentLabel: formatMessage ? formatMessage({ id: 'app.automations' }) : 'AUTOMATIONS',
      roles: ['Admin', 'Approver'],
      url: 'blackout-period',
      description: formatMessage ? formatMessage({ id: 'choiceAutomations.blackoutPeriodInfo' }): '',
      helpDesklink: 'https://vacationtracker.crisp.help/en/article/how-to-set-a-blackout-period-block-time-1dwxmnh/',
    },
    {
      type: 'SENIORITY_ENTITLEMENT',
      parent: AddonsParent.automations,
      parentLabel: formatMessage ? formatMessage({ id: 'app.automations' }) : 'AUTOMATIONS',
      roles: ['Admin'],
      url: 'seniority-entitlement',
      description: formatMessage ? formatMessage({ id: 'choiceAutomations.seniorityEntitlementShortInfo' }): '',
      helpDesklink: 'https://vacationtracker.crisp.help/en/article/how-do-i-set-up-seniority-based-entitlement-sbe-automation-1mmz4s1/',
    },
    {
      type: 'ENTITLEMENT_BY_ROLE',
      parent: AddonsParent.automations,
      parentLabel: formatMessage ? formatMessage({ id: 'app.automations' }) : 'AUTOMATIONS',
      roles: ['Admin'],
      url: 'entitlement-by-role',
      description: formatMessage ? formatMessage({ id: 'choiceAutomations.enitlementByRoleShortInfo' }): '',
      helpDesklink: 'https://vacationtracker.crisp.help/en/article/how-do-i-set-entitlement-by-role-1l2x4mp/',
    },
    {
      type: 'VISIBILITY',
      parent: AddonsParent.addons,
      parentLabel: formatMessage ? formatMessage({ id: 'app.addons' }) : 'ADDONS',
      roles: ['Admin'],
      url: 'visibility',
      description: formatMessage ? formatMessage({ id: 'choiceAutomations.visibilityInfo' }): '',
      helpDesklink: 'https://vacationtracker.crisp.help/en/article/how-do-i-limit-users-visibility-1xbdrm6/?bust=1723123359283',
    },
    {
      type: 'PROBATION_PERIOD',
      parent: AddonsParent.automations,
      parentLabel: formatMessage ? formatMessage({ id: 'app.automations' }) : 'AUTOMATIONS',
      roles: ['Admin', 'Approver'],
      url: 'probation-period',
      includedIn: [SubscriptionPlanEnum.complete],
      description: formatMessage ? formatMessage({ id: 'choiceAutomations.employmentLeaveRestrictionInfo' }): '',
    },
    {
      type: 'OPEN_API',
      parent: AddonsParent.addons,
      parentLabel: formatMessage ? formatMessage({ id: 'app.addons' }) : 'ADDONS',
      roles: ['Admin'],
      url: 'open-api',
      description: formatMessage ? formatMessage({ id: 'choiceAutomations.openApiInfo' }): '',
    },
    {
      type: 'BAMBOOHR_INTEGRATION',
      parent: AddonsParent.integrations,
      parentLabel: formatMessage ? formatMessage({ id: 'app.integrations' }) : 'INTEGRATIONS',
      roles: ['Admin'],
      url: 'coming-soon',
      includedIn: [SubscriptionPlanEnum.core, SubscriptionPlanEnum.complete],
      description: formatMessage ? formatMessage({ id: 'choiceAutomations.bambooHrIntegration' }) : '',
    },
    {
      type: 'MAXIMUM_USERS_AWAY',
      parent: AddonsParent.automations,
      parentLabel: formatMessage ? formatMessage({ id: 'app.automations' }) : 'AUTOMATIONS',
      roles: ['Admin', 'Approver'],
      url: 'maximum-users-away',
      description: formatMessage ? formatMessage({ id: 'choiceAutomations.teamLeaveBalancingInfo' }): '',
    },
    {
      type: 'ADP_INTEGRATION',
      parent: AddonsParent.integrations,
      parentLabel: formatMessage ? formatMessage({ id: 'app.integrations' }) : 'INTEGRATIONS',
      roles: ['Admin'],
      url: 'coming-soon',
      includedIn: [SubscriptionPlanEnum.core, SubscriptionPlanEnum.complete],
      description: formatMessage ? formatMessage({ id: 'choiceAutomations.adpIntegration' }) : '',
    },
    {
      type: 'LEAVE_DURATION_LIMIT',
      parent: AddonsParent.automations,
      parentLabel: formatMessage ? formatMessage({ id: 'app.automations' }) : 'AUTOMATIONS',
      roles: ['Admin', 'Approver'],
      url: 'leave-duration-limit',
      description: formatMessage ? formatMessage({ id: 'choiceAutomations.leaveDurationLimitInfo' }): '',
    },
  ]

  return addonsEvents
}