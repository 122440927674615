import React, { useContext, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { App, Breadcrumb, Form, Button, Alert, Tooltip, Row, Col, Divider } from 'antd'
import { LoadingOutlined, ExclamationCircleOutlined, WarningOutlined } from '@ant-design/icons'
import { useIntl } from 'react-intl'
import { useManualQuery } from 'graphql-hooks'

import Api from '@vacationtracker/shared/services/api'
import { getLocationById, getTotalLocationsAndUsers } from '../../../../graphql/custom-queries'
import * as logger from '../../../../services/logger'

import { useAppSelector } from '../../../../store/hooks'
import { selectFeatureFlagsSlice } from '../../../../store/feature-flags-slice'
import { selectAuthCompanySlice } from '../../../../store/auth-company-slice'
import { notificationStore } from '../../../../context/notificationsContext/store'
import { getTimeZoneOrDefaultToUtc } from '@vacationtracker/shared/functions/timezone'
import { NUMBER_OF_LOCATIONS_LIMIT } from '@vacationtracker/shared/data/app-parameters'

import IntlMessages from '../../../../util/IntlMessages'
import CircularProgress from '../../../../components/circular-progress'
import LocationForm from '../../../../components/location-form'

import { IGetLocationData, IGetLocationById, IGetTotalLocationsAndUsers, IGetUserListShort } from '../../../../types/locations'
import { SubscriptionPlan, SubscriptionPlanEnum } from '@vacationtracker/shared/types/company'
import { FeatureFlagEnum } from '@vacationtracker/shared/types/feature-flags'
import { WorkingHours } from '@vacationtracker/shared/types/work-week'
import { isArray } from 'lodash'
import { FrontendUrls } from '../../../../types/urls'
import { isWorkDayFeatureAllowed } from '@vacationtracker/shared/functions/work-week'

interface ILocationFormPage {
  match: {
    params: {
      id: string
    }
  }
}

const LocationFormPage = ({ match }: ILocationFormPage): React.ReactElement => {
  const { message, modal, notification } = App.useApp()
  const [form] = Form.useForm()
  const { formatMessage } = useIntl()
  const history = useHistory()
  const { actionNotifications, setActionNotifications } = useContext(notificationStore)

  const [isLoading, setIsLoading] = useState(true)
  const [isLocationLoading, setLocationIsLoading] = useState(true)
  const [createLoader, setCreateLoader] = useState(false)
  const [location, setLocation] = useState<IGetLocationById>({
    id: '',
    name: '',
    workWeek: [1, 2, 3, 4, 5],
    timestamp: '',
    resetQuotas: 'FISCAL_YEAR',
    firstDayOfWeek: 1,
    yearStartDay: 1,
    yearStartMonth: 1,
    timezone: getTimeZoneOrDefaultToUtc(),
    isDefault: false,
    users: [],
  })
  const [users, setUsers] = useState<IGetUserListShort[]>([])
  const [currentWorkWeek, setCurrentWorkWeek] = useState<number[]>([])
  const [currentHours, setCurrentHours] = useState<WorkingHours>()
  const [totalLocation, setTotalLocations] = useState<number>(1)
  const { authCompany } = useAppSelector(selectAuthCompanySlice)
  const { featureFlags } = useAppSelector(selectFeatureFlagsSlice)

  const [ getLocationByIdQuery ] = useManualQuery<IGetLocationData, { id: string }>(getLocationById)
  const [ getTotalLocationsAndUsersQuery ] = useManualQuery<IGetTotalLocationsAndUsers>(getTotalLocationsAndUsers)

  useEffect(() => {        
    if (match.params.id) {
      fetchLocation(match.params.id)
    } 
    fetchTotalLocationsAndUsers()
  }, [match.params.id])

  const fetchLocation = async (locationId) => {
    try {
      const response = await getLocationByIdQuery({ variables: { id:locationId }})
      if (!response.data || response.error) throw response.error
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      response.data.getLocation.users = (response.data.getLocation.users as any).map(({ id }) => id)
      if (response.data.getLocation.rolloverExpiryMonth === 0) {
        response.data.getLocation.rolloverExpiryMonth = 6
      }

      if (response.data.getLocation?.timezone) {
        setLocation(response.data.getLocation)
      } else {
        setLocation({
          ...response.data.getLocation,
          timezone: getTimeZoneOrDefaultToUtc(),
        })
      }

      setCurrentWorkWeek(response.data.getLocation.workWeek)
      setCurrentHours(response.data.getLocation.workHours)
      setLocationIsLoading(false)
    } catch (err) { logger.error('error fetching location', err) }
  }

  const fetchTotalLocationsAndUsers = async () => {
    try {
      const response = await getTotalLocationsAndUsersQuery()
      if (!response.data || response.error) throw response.error

      setTotalLocations(response.data.getLocationList.length)
      setUsers(response.data.getUsersFiltered.users)
      setIsLoading(false)
    } catch (err) { logger.error('error fetching total locations', err) }
  }

  const handleSubmit = async () => {    
    const values = await form.validateFields()
    try {
      setCreateLoader(true)
      const cancelUserSettings = values.cancelUserSettings
      delete values.cancelUserSettings
      const woorkWeek = values.workWeek
      // TODO workday - when we add UI for custom work hours per day per location, change this:
      const workHours = isArray(values.workHours) ? values.workHours[0][1] : values.workHours
      delete values.workWeek
      delete values.workHours

      if (!match.params.id) {
        values.days = woorkWeek
        // TODO workday - when we add UI for custom work hours per day per location, change this:
        values.workHours = workHours ? woorkWeek.map(day => [day, workHours]) : null
      }
      if (match.params.id) {
        delete values.rolloverExpiryMonth
        delete values.rolloverExpiryDay
        delete values.rolloverExpiryAfterDays
      }
      
      const response = await Api.post('/core/event', {
        eventType: match.params.id ? 'LOCATION_UPDATED' : 'LOCATION_CREATED',
        eventGroup: 'LOCATION',
        locationId: match.params.id,
        ...values,
      })

      if (match.params.id && (JSON.stringify(woorkWeek.sort()) !== JSON.stringify(currentWorkWeek) || JSON.stringify(workHours) !== JSON.stringify(currentHours))){
        await Api.post('/core/event', {
          eventType: 'LOCATION_WORK_WEEK_UPDATED',
          eventGroup: 'LOCATION',
          locationId: match.params.id,
          days: woorkWeek.sort(),
          // TODO workday - when we add UI for custom work hours per day per location, change this:
          workHours: workHours ? woorkWeek.map(day => [day, workHours]) : null,
          cancelUserSettings,
        })
      }

      notification.open({
        key: response.correlationId,
        message: match.params.id ?
          formatMessage({ id: 'location.updateInProgress'}, { locationName: response.name }) :
          formatMessage({ id: 'location.createInProgress' }, { locationName: response.name }),
        icon: (<LoadingOutlined />),
        duration: 0,
      })
      setActionNotifications([
        ...actionNotifications,
        response.correlationId,
      ])

      setCreateLoader(false)
      history.push('/app/settings/locations')

      if (!match.params.id) {
        form.resetFields()
      }
    } catch (error) {
      logger.warning(error)
      setCreateLoader(false)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      message.error(error)
      const errorDescription = error.response?.data?.message ? error.response?.data.message : error.message ? error.message : JSON.stringify(error)
      notification.error({
        message: formatMessage({ id: 'error.generic' }),
        description: errorDescription,
        duration: 0,
      })
    }
  }

  const deleteLocation = async (locationId: string, name: string) => {
    try {
      const response = await Api.post('/core/event', {
        eventType: 'LOCATION_DELETED',
        eventGroup: 'LOCATION',
        locationId,
      })

      notification.open({
        key: response.correlationId,
        message: formatMessage({ id: 'location.deleteInProgress' }, { locationName: name }),
        icon: <LoadingOutlined />,
        duration: 0,
      })
      setActionNotifications([
        ...actionNotifications,
        response.correlationId,
      ])
    } catch (error) {
      logger.warning(error)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      message.error(error)
    }
  }

  const backToLocations = () => {
    history.push('/app/settings/locations')
  }

  const showConfirmDelete = () => {
    modal.confirm({
      title: formatMessage({ id: 'locations.deleteLocationTitle' }, { name: location.name }),
      icon: <ExclamationCircleOutlined />,
      content: <>
        {formatMessage({ id: 'locations.deleteLocationConfirm'}, { name: location.name })}<br/><br/>
        <WarningOutlined /> {formatMessage({ id: 'locations.deleteLocationConfirmInitialRollover'})}
      </>,
      okText: formatMessage({ id: 'locations.deleteLocation' }),
      okType: 'danger',
      maskClosable: true,
      async onOk() {
        await deleteLocation(location.id, location.name)
        history.push('/app/settings/locations')
      },
    })
  }

  const shouldDisableCreatingNewLocation = () => {
    if (featureFlags.includes(FeatureFlagEnum.noLimitLocation)) {
      return false
    }
    return authCompany?.plan === SubscriptionPlanEnum.core && totalLocation >= NUMBER_OF_LOCATIONS_LIMIT
  }

  return (
    <div className='main-content form-background'>
      {((match.params.id && isLocationLoading) || isLoading) ?
        <CircularProgress /> :
        <>
          <div className="main-content-header">
            <div className="main-content-header-title">
              {location.id ? <span><IntlMessages id="location.editLocation" /></span> : <span><IntlMessages id="locationForm.createLocation" /></span> }
            </div>
            <div className="main-content-header-breadcrumb">
              <Breadcrumb
                items={[
                  {
                    title: <Link to={FrontendUrls.dashboard}><IntlMessages id="sidebar.dashboard" /></Link>,
                  },
                  {
                    title: <IntlMessages id='sidebar.settings' />,
                  },
                  {
                    title: <Link to='/app/settings/locations'><IntlMessages id="app.locations" /></Link>,
                  },
                  {
                    title: location.id ? <IntlMessages id='location.editLocation' /> : <IntlMessages id='locationForm.createLocation' />,
                  },
                ]}
              />
            </div>
          </div>
          <div className="main-content-body">
            {!location.id && shouldDisableCreatingNewLocation() &&
              <Alert style={{ marginBottom: 20 }} 
                message={
                  <IntlMessages 
                    id="locationForm.locationLimitReached"
                    values={{ limitNumber: NUMBER_OF_LOCATIONS_LIMIT, billingPage: (...chunks) => (<Link to="/app/settings/billing">{chunks}</Link>) }}
                  />
                }
                type="warning" 
              />
            }
            <Form
              form={form}
              name="locationForm"
              initialValues={location}
              className="location-from"
              layout="vertical"
              requiredMark="optional"
              labelWrap
              onFinish={handleSubmit}
              disabled={!location.id && shouldDisableCreatingNewLocation()}
              scrollToFirstError
              size="large"
            >
              
              <LocationForm 
                location={location}
                totalLocation={totalLocation}
                users={users}
                form={form}
                plan={authCompany?.plan as SubscriptionPlan}
                workdayEnabled={isWorkDayFeatureAllowed(featureFlags, authCompany?.plan)}
              />
              <Divider />
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col span={24}>
                  <Form.Item style={{ textAlign: 'right' }}>
                    {
                      location.id ? (
                        <Tooltip title={ location.isDefault ? <IntlMessages id="locations.deleteLocation.disabled" /> : null}>
                          <Button type="default" danger style={{ marginRight: 10 }} onClick={showConfirmDelete} disabled={location.isDefault}>
                            <IntlMessages id="locations.deleteLocation" />
                          </Button>
                        </Tooltip>
                      ) : null
                    }
                    <Button type="default" style={{ marginRight: 10 }} onClick={backToLocations}>
                      <IntlMessages id="app.cancel" />
                    </Button>
                    <Button
                      htmlType="submit"
                      type="primary"
                      loading={createLoader}
                    >
                      {location.id ? <IntlMessages id="locationForm.updateLocation" /> : <IntlMessages id="locationForm.createLocation" />}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <Alert style={{ whiteSpace: 'pre-line' }} message={<IntlMessages id="locationForm.assignLeaveTypesInfo" />} type="info" />
          </div>
        </>
      }
    </div>
  )
}

export default LocationFormPage
