import { useState, useEffect } from 'react'
import { useManualQuery } from 'graphql-hooks'
import { getCalendarData } from '../../graphql/custom-queries'
import type { ICalendarLeavesData, ICalendarEvent, ICalendarLeaves } from '../../types/calendar'
import dayjs from 'dayjs'
import * as Sentry from '@sentry/react'
import { App } from 'antd'

interface QueryVariables {
  dateStart: string
  dateEnd: string
  status: string
  limit: number
  nextToken: string
}

interface IUsePaginatedCalendarDataParams {
  startDate: string | null
  endDate: string | null
  limit?: number
  status?: string
  leavesDataWrapper: (leaves: ICalendarLeaves[]) => ICalendarEvent[]
  abortController: AbortController | null
}

/**
 * A custom hook that fetches paginated calendar data, handles abort signals,
 * error logging, and returns data plus convenience booleans.
 */
export function usePaginatedCalendarData({
  startDate,
  endDate,
  limit = 700,
  status = 'APPROVED',
  leavesDataWrapper,
  abortController,
}: IUsePaginatedCalendarDataParams) {
  const { notification } = App.useApp()
  const [calendarData, setCalendarData] = useState<ICalendarEvent[]>([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<Error | null>(null)

  const [getCalendarDataQuery] = useManualQuery<ICalendarLeavesData, QueryVariables>(getCalendarData)

  useEffect(() => {
    if (!startDate || !endDate) {
      return
    }
    let isMounted = true; // track mount status to avoid state updates after unmount

    (async () => {
      try {
        setLoading(true)
        setError(null)
        setCalendarData([])

        let accumulatedLeaves: ICalendarEvent[] = []
        let nextTokenValue: string | undefined = 'NONE'

        while (nextTokenValue) {
          const response = await getCalendarDataQuery({
            variables: {
              dateStart: dayjs(startDate).utcOffset(0).subtract(1, 'month').format('YYYY-MM-DD'),
              dateEnd: dayjs(endDate).utcOffset(0).add(1, 'month').format('YYYY-MM-DD'),
              status,
              limit,
              nextToken: nextTokenValue,
            },
            fetchOptionsOverrides: {
              signal: abortController?.signal,
            },
          })

          if (response.error) {
            throw response.error
          }

          if (!response.data?.getLeaveRequestByDate) {
            throw new Error(`Missing data: ${JSON.stringify(response)}`)
          }

          const { leaveRequests, nextToken } = response.data.getLeaveRequestByDate
          // Merge new leaves
          accumulatedLeaves = [...accumulatedLeaves, ...leavesDataWrapper(leaveRequests)]
          nextTokenValue = nextToken
        }

        // If component is still mounted, update state
        if (isMounted) {
          setCalendarData(accumulatedLeaves)
        }
      } catch (err: any) {
        if (isMounted) {
          // Handle AbortError or other errors
          if (err.fetchError?.name === 'AbortError') {
            // If needed, do nothing or set an “aborted” flag
          } else {
            Sentry.captureException(err)
            notification.error({
              key: 'calendar-loading-error',
              message: 'Error loading calendar data',
              description: 'Please try again or contact support.',
              duration: 0,
            })
            setError(err)
          }
        }
      } finally {
        if (isMounted) {
          setLoading(false)
        }
      }
    })()

    return () => {
      isMounted = false
    }
  }, [startDate, endDate, limit, status, getCalendarDataQuery])

  return { calendarData, loading, error }
}
