import { ProductName } from '../data/stripe/prices'
import { AddonsStatus, IntegrationsType, AutomationsType, AddonsType, VisibilityLevel } from './addons'
import { PaymentProcessor } from './billing'
import { SubscriptionPlan } from './company'
import { ICoreEvent } from './core-event'
import { Dayjs } from 'dayjs'
import { QuotaUnits } from './leave-policy'
import { FeatureFlagEnum } from './feature-flags'

export type SeniorityEntitlementCalculateFrom = 'USER_EMPLOYMENT_DATE' | 'TODAY' | 'CURRENT_YEAR'
export type AwardSeniorityEntitlementDaysOn = 'USER_WORK_ANNIVERSARY' | 'BEGINNING_OF_THE_FISCAL_YEAR'

export type IAutomationsInterface =
  IAutomationBlackOutPeriod |
  IAutomationSeniorityEntitlement |
  IAutomationEntitlementByRole |
  IAutomationProbationPeriod |
  IAutomationOpenApi |
  IAutomationMaximumUsersAway |
  IAutomationLeaveDurationLimit

export interface IAutomationViewPage {
  formatMessage: Function
  amIAdmin: boolean
  deleteAutomation: (id: string, name: string, automationType: string) => void
  authUserId: string
  dateFormat: string
  addonsPrice?: number
  handleSubscribe: (automationType: string, isActive: boolean) => void
  status?: AddonsStatus
  userCount: number
  addonDetails?: IAddonsData
  currentPlan?: SubscriptionPlan
  allowViewMode?: boolean
  handleBuySubscriptionModal?: (parnet: string, automationType: string) => void
}

export interface IAddonsUpdatedEvent extends ICoreEvent {
  eventType: 'ADDONS_UPDATED'
  eventGroup: 'BILLING'
  type: AutomationsType | AddonsType | IntegrationsType
  isActive: boolean
}

export interface IAutomationBlackOutForm {
  automationId: string
  name: string
  leaveTypeIds: string[]
  startDate: string
  endDate: string
  recurring: boolean
  isActive: boolean
  reason: string
  period: [Dayjs, Dayjs]
  applyToAllUsers: boolean
  locations?: string[]
  teams?: string[]
  labels?: string[]
  owner?: {
    id: ''
    name: ''
  }
}


export interface IAutomationMaximumUsersAwayForm {
  automationId: string
  name: string
  maxUsersAway: IMaxUsersAway
  startDate: string
  endDate: string
  leaveTypeIds: string[]
  recurring: boolean
  autoRejectResponse: string
  period: [Dayjs, Dayjs]
  locations: string[]
  teams: string[]
  labels: string[]
  applyToAllUsers: boolean
}

export interface IAutomationLeaveDurationLimitForm {
  automationId: string
  name: string
  period?: [Dayjs, Dayjs]
  minimumDuration?: number
  maximumDuration?: number
  units: QuotaUnits
  recurring: boolean
  autoRejectResponse: string
  leaveTypeIds: string[]
  locationIds: string[]
  teamIds: string[]
  labelIds: string[]
}

export interface IAutomationSeniorityEntitlementForm {
  automationId: string
  name: string
  leaveTypeId: string
  calculateFrom?: SeniorityEntitlementCalculateFrom
  awardSeniorityEntitlementDaysOn?: AwardSeniorityEntitlementDaysOn
  isActive: boolean
  prorate: boolean
  periods: {
    years: number
    amount: number
    units: QuotaUnits
  }[]
  applyToAllUsers: boolean
  locations?: string[]
  teams?: string[]
  labels?: string[]
  owner?: {
    id: ''
    name: ''
  }
}

export interface IAutomationEntitlementByRoleForm {
  automationId: string
  name: string
  leaveTypeId: string
  startDate: string
  isActive: boolean
  labelId: string
  amount: number
  units: QuotaUnits
  owner?: {
    id: ''
    name: ''
  }
}

export interface IAutomationBlackOutCreateEvent extends ICoreEvent {
  eventType: 'BLACKOUT_PERIOD_CREATED' | 'BLACKOUT_PERIOD_UPDATED'
  eventGroup: 'AUTOMATION'
  name: string
  automationType: string
  automationId: string
  startDate: string
  endDate: string
  recurring: boolean
  isActive: boolean
  reason: string
  leaveTypeIds: string[]
  locations?: string[]
  teams?: string[]
  labels?: string[]
}
export interface IAutomationMaximumUsersAwayCreateEvent extends ICoreEvent {
  eventType: 'MAXIMUM_USERS_AWAY_CREATED' | 'MAXIMUM_USERS_AWAY_UPDATED'
  eventGroup: 'AUTOMATION'
  name: string
  automationType: string
  automationId: string
  startDate: string
  endDate: string
  recurring: boolean
  isActive: boolean
  reason: string
  maxUsersAway: IMaxUsersAway
  autoRejectResponse: string
  leaveTypeIds: string[]
  locations?: string[]
  teams?: string[]
  labels?: string[]
}

export interface IAutomationProbationPeriodCreateEvent extends ICoreEvent {
  eventType: 'PROBATION_PERIOD_CREATED' | 'PROBATION_PERIOD_UPDATED'
  eventGroup: 'AUTOMATION'
  name: string
  automationType: string
  automationId: string
  lengthDays: number
  isActive: boolean
  reason: string
  leaveTypeIds: string[]
  locations?: string[]
  teams?: string[]
  labels?: string[]
}

export interface IAutomationDeleteEvent extends ICoreEvent {
  eventType: 'BLACKOUT_PERIOD_DELETED' | 'SENIORITY_ENTITLEMENT_DELETED' | 'ENTITLEMENT_BY_ROLE_DELETED' |
  'PROBATION_PERIOD_DELETED' | 'MAXIMUM_USERS_AWAY_DELETED' | 'LEAVE_DURATION_LIMIT_DELETED'
  eventGroup: 'AUTOMATION'
  automationId: string
}

export interface IAutomationSeniorityEntitlementCreateEvent extends ICoreEvent {
  eventType: 'SENIORITY_ENTITLEMENT_CREATED' | 'SENIORITY_ENTITLEMENT_UPDATED'
  eventGroup: 'AUTOMATION'
  name: string
  automationType: string
  automationId: string
  startDate?: string
  calculateFrom?: SeniorityEntitlementCalculateFrom
  awardSeniorityEntitlementDaysOn?: AwardSeniorityEntitlementDaysOn
  periods: {
    years: number
    amount: number
    units: QuotaUnits
  }[]
  isActive: boolean
  leaveTypeId: string
  prorate?: boolean
  locations?: string[]
  teams?: string[]
  labels?: string[]
}

export interface ISeniorityEntitlementByLeaveTypeKey extends IAutomationSeniorityEntitlementCreateEvent {
  allowSeniorityEntitlement: boolean
}

export interface IAutomationSeniorityEntitlementDeleteEvent extends ICoreEvent {
  eventType: 'SENIORITY_ENTITLEMENT_DELETED'
  eventGroup: 'AUTOMATION'
  automationId: string
}

export interface IAutomationEntitlementByLabelCreateEvent extends ICoreEvent {
  eventType: 'ENTITLEMENT_BY_ROLE_CREATED' | 'ENTITLEMENT_BY_ROLE_UPDATED'
  eventGroup: 'AUTOMATION'
  name: string
  automationType: string
  automationId: string
  startDate: string
  amount: number
  units: QuotaUnits
  isActive: boolean
  leaveTypeId: string
  labelId: string
}

export interface IAutomationBlackOutPeriod {
  companyId: string
  id: string
  automationType: string
  name: string
  leaveTypeIds: string[]
  startDate: string
  endDate: string
  recurring: boolean
  isActive: boolean
  reason: string
  owner: string
  createdAt: string
  locations?: string[]
  teams?: string[]
  labels?: string[]
}

export interface IAutomationSeniorityEntitlement {
  companyId: string
  id: string
  automationType: string
  name: string
  leaveTypeId: string
  calculateFrom: SeniorityEntitlementCalculateFrom
  awardSeniorityEntitlementDaysOn?: AwardSeniorityEntitlementDaysOn
  isActive: boolean
  prorate: boolean
  periods?: {
    years: number
    amount: number
    units: QuotaUnits
  }[]
  owner: string
  createdAt: string
  locations: string[]
  teams: string[]
  labels: string[]
}

export interface IAutomationEntitlementByRole {
  companyId: string
  id: string
  automationType: string
  name: string
  leaveTypeId: string
  startDate: string
  isActive: boolean
  amount: number
  units: QuotaUnits
  labelId: string
  owner: string
  createdAt: string
}

export interface IAddonsBillingUpdatedEvent {
  type: 'ADDONS-BILLING-UPDATED'
  meta: {
    paymentProcessor: PaymentProcessor
  }
  payload: IAddonsBillingUpdatedPayload
}

export interface IAddonsBillingUpdatedPayload extends IAddonsUpdatedEvent {
  product: ProductName
}

export enum AddonsActiveTabEnum {
  discoveryMoreAddons = 'DISCOVERY_MORE_ADDONS',
  myAddons = 'MY_ADDONS'
}

export enum AddonsParent {
  automations = 'automations',
  integrations = 'integrations',
  addons = 'addons'
}

export interface IAddonsData {
  type: string
  parent: AddonsParent
  parentLabel: string
  roles: string[]
  url: string
  icon?: React.ReactNode
  description: string
  helpDesklink?: string
  includedIn?: string[]
  allowViewMode?: boolean
  featureFlag?: FeatureFlagEnum
}

export interface IUpdateAutomation {
  name?: string
  automationType?: string
  calculateFrom?: SeniorityEntitlementCalculateFrom
  awardSeniorityEntitlementDaysOn?: AwardSeniorityEntitlementDaysOn
  period?: [string, string]
  periods?: {
    years: number
    amount: number
    units: QuotaUnits
  }[]
  startDate?: string
  endDate?: string
  recurring?: boolean
  isActive?: boolean
  reason?: string
  leaveTypeId?: string
  locations?: string[]
  leaveTypeIds?: string[]
  teams?: string[]
  labels?: string[]
  createdAt?: string
  owner?: string
  prorate?: boolean
  labelId?: string
  days?: number
  amount?: number
  units?: QuotaUnits
  visibilityLevel?: VisibilityLevel[]
  lengthDays?: number
  apiKeyValue?: string
  apiKeyId?: string
  maxUsersAway?:IMaxUsersAway
  autoRejectResponse?: string
  minimumDuration?: number
  maximumDuration?: number
  locationIds?: string[]
  teamIds?: string[]
  labelIds?: string[]
}

export interface IAutomationProbationPeriodForm {
  automationId: string
  name: string
  leaveTypeIds: string[]
  isActive: boolean
  reason: string
  lengthDays: number
  applyToAllUsers: boolean
  locations?: string[]
  teams?: string[]
  labels?: string[]
  owner?: {
    id: ''
    name: ''
  }
}

export interface IAutomationProbationPeriod {
  companyId: string
  id: string
  automationType: string
  name: string
  leaveTypeIds: string[]
  lengthDays: number
  isActive: boolean
  reason: string
  owner: string
  createdAt: string
  locations?: string[]
  teams?: string[]
  labels?: string[]
}

export interface IAutomationOpenApi {
  companyId: string
  id: string
  automationType: string
  name: string
  apiKeyId: string
  apiKeyValue: string
  owner: string
  createdAt: string
}

export interface IMaxUsersAway {
  type: MaxUsersAwayTypeEnum
  value: number
}

export enum MaxUsersAwayTypeEnum {
  percentage = 'percentage',
  absolute = 'absolute'
}

export interface IAutomationMaximumUsersAway {
  companyId: string
  id: string
  automationType: string
  name: string
  maxUsersAway: IMaxUsersAway
  startDate: string
  endDate: string
  recurring: boolean
  isActive: boolean
  autoRejectResponse: string
  owner: string
  createdAt: string
  leaveTypeIds?: string[]
  locations?: string[]
  teams?: string[]
  labels?: string[]
}

export interface IAutomationLeaveDurationLimitCreateEvent extends ICoreEvent {
  eventType: 'LEAVE_DURATION_LIMIT_CREATED' | 'LEAVE_DURATION_LIMIT_UPDATED'
  eventGroup: 'AUTOMATION'
  automationType: string
  automationId: string
  isActive: boolean
  name: string
  period: [string, string]
  minimumDuration: number
  maximumDuration: number
  units: QuotaUnits
  recurring: boolean
  autoRejectResponse: string
  leaveTypeIds: string[]
  locationIds?: string[]
  teamIds?: string[]
  labelIds?: string[]
}

export interface IAutomationLeaveDurationLimit {
  companyId: string
  id: string
  automationType: string
  name: string
  isActive: boolean
  owner: string
  createdAt: string
  period: [string, string]
  minimumDuration: number
  maximumDuration: number
  units: QuotaUnits
  recurring: boolean
  autoRejectResponse: string
  leaveTypeIds: string[]
  locationIds?: string[]
  teamIds?: string[]
  labelIds?: string[]
}

