import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { App, Button, Form, Input, Select, DatePicker, Radio, InputNumber, Row, Col, Divider } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import dayjs, { Dayjs } from 'dayjs'
import { useIntl } from 'react-intl'

import Api from '@vacationtracker/shared/services/api'
import { track } from '../../services/analytics/analytics'
import { notificationStore } from '../../context/notificationsContext/store'
import logger from '@vacationtracker/shared/functions/logger'
import { useManualQuery } from 'graphql-hooks'
import { getAutomationsById } from '../../graphql/custom-queries'
import { calculateMaximumUsersAway } from '@vacationtracker/shared/functions/calculate-maximum-users-away'
import { MAXIMUM_USERS_AWAY_DEFAULT_PERCENTAGE } from '@vacationtracker/shared/constants/maximum-users-away'
import IntlMessages from '../../util/IntlMessages'
import FilterAdvanced from '@vacationtracker/shared/components/filter-advanced'
import YesNoSwitch from '../yes-no-switch'
import CircularProgress from '../circular-progress'
import MaximumUsersAwaySummary from '../maximum-users-away-summary-info'

import { FrontendUrls } from '../../types/urls'
import { IFilter } from '@vacationtracker/shared/types/filter'
import {
  IAutomationMaximumUsersAwayCreateEvent,
  IAutomationMaximumUsersAwayForm,
  IMaxUsersAway,
  MaxUsersAwayTypeEnum
} from '@vacationtracker/shared/types/automations'
import { AddonsStatus, AddonsStatusEnum } from '@vacationtracker/shared/types/addons'
import { ITeamShort } from '@vacationtracker/shared/types/team'
import { IGetTeamsShort } from '../../types/teams'
import { IGetLocationsShort } from '../../types/locations'
import { IGetLabelsShort } from '../../types/labels'
import { IGetAutomationsByIdData, ILeaveTypeListShort, IMaximumUsersAwayAutomation } from '../../types/custom-queries'
import { ISelected } from '@vacationtracker/shared/components/filter-advanced/types'
import { RangePickerProps } from 'antd/es/date-picker'

const { RangePicker } = DatePicker
const { Option } = Select

interface IMaximumUsersAwayForm {
  automationId?: string
  teams: IGetTeamsShort[] | ITeamShort[]
  locations: IGetLocationsShort[]
  leaveTypes: ILeaveTypeListShort[]
  labels: IGetLabelsShort[]
  filter: IFilter
  handleFilter: (filter: IFilter) => void
  shouldEnableLabels: boolean
  backToAddons: () => void
  amIAdmin: boolean
  totalUsers: number
  status?: AddonsStatus
}

const createInitialFilterValues = (automationData: { locations: any[]; teams: any[]; labels?: any[] }): ISelected[] => {
  const data: ISelected[] = []

  if (automationData) {
    if (automationData.locations.length > 0) {
      data.push({ type: 'Locations', values: automationData.locations })
    }
    if (automationData.teams.length > 0) {
      data.push({ type: 'Departments', values: automationData.teams })
    }
    if (automationData?.labels && automationData.labels.length > 0) {
      data.push({ type: 'Labels', values: automationData.labels})
    }
  }

  return data.length === 0 ? [{}] as ISelected[] : data
}

const MaxmimumUsersAwayForm = ({
  automationId,
  teams,
  locations,
  leaveTypes,
  labels,
  filter,
  handleFilter,
  shouldEnableLabels,
  backToAddons,
  amIAdmin,
  status,
  totalUsers,
}: IMaximumUsersAwayForm) => {
  const history = useHistory()
  const { formatMessage } = useIntl()
  const [form] = Form.useForm()
  const { notification } = App.useApp()
  const { actionNotifications, setActionNotifications } = useContext(notificationStore)
  const [getAutomationsByIdQuery] = useManualQuery<IGetAutomationsByIdData, { id: string }>(getAutomationsById)

  const [isLoading, setIsLoading] = useState(true)
  const [btnLoading, setBtnLoading] = useState(false)
  const [recurring, setRecurring] = useState(false)
  const [applyToAllUsers, setApplyToAllUsers] = useState<boolean>(false)
  // Add default form values
  const [addon, setAddon] = useState<IAutomationMaximumUsersAwayForm>({
    automationId: '',
    name: '',
    maxUsersAway: {
      type: MaxUsersAwayTypeEnum.percentage,
      value: 50, // Default to 50%
    },
    startDate: dayjs().format('YYYY-MM-DD'),
    endDate: dayjs().add(1, 'month').format('YYYY-MM-DD'),
    leaveTypeIds: leaveTypes.map(leaveType => leaveType.id), // Default to all leave types
    recurring: false,
    autoRejectResponse: formatMessage({
      id: 'automationsForm.maxmimumUsersAway.autoRejectResponsePlaceholder',
    }),
    period: [dayjs(), dayjs().add(1, 'month')] as [Dayjs, Dayjs],
    locations: [],
    teams: [],
    labels: [],
    applyToAllUsers: false,
  })
  const [initialFilterValues, setInitialFilterValues] = useState([{}])
  const [maxUsersAllowed, setMaxUsersAllowed] = useState<number>(0)
  const [summaryValues, setSummaryValues] = useState({
    leaveTypeIds: leaveTypes.map(lt => lt.id),
    recurring: false,
    period: [dayjs(), dayjs().add(1, 'month')] as [Dayjs, Dayjs],
    maxUsersAway: {
      type: MaxUsersAwayTypeEnum.percentage,
      value: 50,
    },
    applyToAllUsers: false,
  })

  useEffect(() => {
    const currentValues = form.getFieldValue('maxUsersAway') as IMaxUsersAway
    const { maxUsersAllowed } = calculateMaximumUsersAway(totalUsers, currentValues)
    setMaxUsersAllowed(maxUsersAllowed)
  }, [automationId, addon?.maxUsersAway, totalUsers, form.getFieldValue('maxUsersAway')])

  useEffect(() => {
    if (automationId) {
      fetchAutomations(automationId)
    } else {
      setIsLoading(false)
    }
  }, [automationId])

  const fetchAutomations = async (id: string) => {
    try {
      const response = await getAutomationsByIdQuery({ variables: { id } })
      const automation = response.data?.getAutomation as IMaximumUsersAwayAutomation
      let applyToAllUsers = true

      handleFilter({
        labelIds: automation?.labels || [],
        locationIds: automation?.locations || [],
        teamIds: automation?.teams || [],
      })

      if (automation?.locations?.length > 0 || automation?.teams?.length > 0 || automation?.labels?.length > 0) {
        applyToAllUsers = false
        form.setFieldsValue({ applyToAllUsers })
        setApplyToAllUsers(applyToAllUsers)
        setInitialFilterValues(createInitialFilterValues(automation))
      }

      const updatedAddon = {
        automationId: automation.id,
        name: automation.name,
        leaveTypeIds: automation.leaveTypeIds,
        startDate: automation.startDate,
        endDate: automation.endDate,
        recurring: automation.recurring,
        maxUsersAway: automation.maxUsersAway,
        autoRejectResponse: automation.autoRejectResponse,
        period: [dayjs(automation.startDate), dayjs(automation.endDate)] as [Dayjs, Dayjs],
        locations: automation.locations,
        teams: automation.teams,
        labels: automation.labels,
        applyToAllUsers,
      }
      setAddon(updatedAddon)
      form.setFieldsValue(updatedAddon)
      setSummaryValues({
        leaveTypeIds: automation.leaveTypeIds,
        recurring: automation.recurring,
        period: [dayjs(automation.startDate), dayjs(automation.endDate)] as [Dayjs, Dayjs],
        maxUsersAway: automation.maxUsersAway,
        applyToAllUsers,
      })
      setIsLoading(false)
    } catch (error) {
      console.error('Error fetching automation:', error)
    }
  }

  const onFinish = () => {
    if (status === AddonsStatusEnum.CANCELED) return

    setIsLoading(true)
    form
      .validateFields([
        'name',
        'period',
        ['maxUsersAway', 'type'],
        ['maxUsersAway', 'value'],
        'recurring',
        'autoRejectResponse',
        'leaveTypeIds',
      ])
      .then(async (values) => {
        setBtnLoading(true)
        let response
        try {
          const data = {
            name: values.name,
            maxUsersAway: {
              type: values['maxUsersAway']['type'],
              value: values['maxUsersAway']['value'],
            },
            startDate: values.period[0].format('YYYY-MM-DD'),
            endDate: values.period[1].format('YYYY-MM-DD'),
            leaveTypeIds: values.leaveTypeIds,
            locations: filter.locationIds,
            teams: filter.teamIds,
            labels: filter.labelIds,
            autoRejectResponse: values.autoRejectResponse,
            recurring: values.recurring,
          }

          track(automationId ? 'MAXIMUM_USERS_AWAY_UPDATED' : 'MAXIMUM_USERS_AWAY_CREATED', {
            maxUsersAwayType: values['maxUsersAway']['type'],
            maxUsersAwayValue: values['maxUsersAway']['value'],
          })

          if (!automationId) {
            response = await Api.post<Partial<IAutomationMaximumUsersAwayCreateEvent>>('/core/event', {
              eventType: 'MAXIMUM_USERS_AWAY_CREATED',
              eventGroup: 'AUTOMATION',
              ...data,
            })
          } else {
            response = await Api.post<Partial<IAutomationMaximumUsersAwayCreateEvent>>('/core/event', {
              eventType: 'MAXIMUM_USERS_AWAY_UPDATED',
              eventGroup: 'AUTOMATION',
              automationId,
              ...data,
              isActive: true,
            })
          }

          notification.open({
            key: response.correlationId,
            message: formatMessage({ id: automationId ? 'automations.maximumUsersAway.updateInProgress' : 'automations.maximumUsersAway.createInProgress' }),
            icon: (<LoadingOutlined />),
            duration: 0,
          })

          setActionNotifications([ ...actionNotifications, response.correlationId ])

          setTimeout(() => {
            history.push(`${FrontendUrls.addons}/maximum-users-away/view`)
          }, 700)
        } catch (error) {
          setBtnLoading(false)
          if (error.response?.data?.error) {
            notification.error({
              message: formatMessage({ id: 'error.automations.automationSubmitError' }),
              description: formatMessage({ id: error.response.data.error }),
              duration: 0,
            })
          } else {
            let description
            let message = formatMessage({ id: 'error.automations.automationSubmitError' })
            if (response?.correlationId) {
              description = formatMessage({ id: 'error.automations.automationSubmitError' })
            } else if (error.message === 'Request failed with status code 400') {
              message = formatMessage({ id: 'error.automations.insufficientPrivileges.title' })
              description = formatMessage({ id: 'error.automations.insufficientPrivileges.description' })
            } else {
              description = error.message
            }

            notification.error({
              message,
              description,
              duration: 0,
            })
          }
        }
      })
      .catch(info => {
        logger.warning('Validate Failed:', info)
        setBtnLoading(false)
      })
  }

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    // Can not select days before today and today
    return current && current < dayjs().subtract(1, 'day')
  }

  const onFormValuesChange = (changedValues: any) => {
    // Update summary values whenever relevant form fields change
    const formValues = form.getFieldsValue(['leaveTypeIds', 'recurring', 'period', 'maxUsersAway', 'applyToAllUsers'])
    setSummaryValues(formValues)

    if (changedValues.maxUsersAway?.type) {
      // Reset to defaults when type changes
      let newValue
      if (automationId && addon?.maxUsersAway?.value && addon?.maxUsersAway?.type === changedValues.maxUsersAway.type) {
        newValue = addon.maxUsersAway.value
      } else {
        newValue = changedValues.maxUsersAway.type === MaxUsersAwayTypeEnum.percentage ? 50 : 1
      }

      form.setFieldsValue({
        maxUsersAway: {
          type: changedValues.maxUsersAway.type,
          value: newValue,
        },
      })

      const values = {
        type: changedValues.maxUsersAway.type,
        value: newValue,
      }
      const { maxUsersAllowed } = calculateMaximumUsersAway(totalUsers, values)
      setMaxUsersAllowed(maxUsersAllowed)
    } else if (changedValues.maxUsersAway) {
      const values = form.getFieldsValue(['maxUsersAway'])
      if (values.maxUsersAway?.type && values.maxUsersAway?.value) {
        const { maxUsersAllowed } = calculateMaximumUsersAway(totalUsers, values.maxUsersAway)
        setMaxUsersAllowed(maxUsersAllowed)
      }
    }
  }

  const changeRecurring = (e) => {
    setRecurring(e.target.checked)
    form.setFieldsValue({ recurring: e.target.checked })
  }

  const handleApplyToAllUsersChange = (value: boolean) => {
    setApplyToAllUsers(value)
    form.setFieldsValue({ applyToAllUsers: value })

    if (value) {
      // Reset filters when "Apply to all" is selected
      handleFilter({
        labelIds: [],
        locationIds: [],
        teamIds: [],
      })
      setInitialFilterValues([{}])
    } else {
      if (automationId) {
        // If editing, restore saved filters
        handleFilter({
          labelIds: addon.labels || [],
          locationIds: addon.locations || [],
          teamIds: addon.teams || [],
        })
        setInitialFilterValues(createInitialFilterValues(addon))
      } else {
        // If creating new, reset filters
        handleFilter({
          labelIds: [],
          locationIds: [],
          teamIds: [],
        })
        setInitialFilterValues([{}])
      }
    }
  }

  const validateMaxUsersAway = (type: string, value: number, totalUsers: number) => {
    if (type === MaxUsersAwayTypeEnum.percentage && value > MAXIMUM_USERS_AWAY_DEFAULT_PERCENTAGE) {
      return Promise.reject(
        <IntlMessages
          id="automationsForm.maxmimumUsersAway.maxUsersAwayValuePercentageError"
          values={{ entered: value }}
        />
      )
    }
    if (type === MaxUsersAwayTypeEnum.absolute && value > totalUsers) {
      return Promise.reject(
        <IntlMessages
          id="automationsForm.maxmimumUsersAway.maxUsersAwayValueAbsoluteError"
          values={{ entered: value, max: totalUsers }}
        />
      )
    }
    return Promise.resolve()
  }

  return (
    <>
      {(isLoading && automationId) ?
        <CircularProgress /> :
        <>
          <Form
            form={form}
            layout="vertical"
            name="automationsForm"
            disabled={status === AddonsStatusEnum.CANCELED}
            onFinish={onFinish}
            className="automation-form"
            initialValues={addon}
            scrollToFirstError
            requiredMark="optional"
            labelWrap
            onValuesChange={onFormValuesChange}
          >
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col xxl={8} xl={8} lg={6} md={6} sm={24} xs={24}>
                <h2><IntlMessages id="automations.MAXIMUM_USERS_AWAY" /></h2>
                <p><IntlMessages id="automations.maximumUsersAway.definePolicyDescription" /></p>
                <p><IntlMessages id="automations.maximumUsersAway.maxUsersDescription" /></p>
                <p><IntlMessages id="automations.maximumUsersAway.autoRejectDescription" /></p>
              </Col>
              <Col xxl={16} xl={16} lg={18} md={18} sm={24} xs={24}>
                <Form.Item
                  name="name"
                  label={<IntlMessages id="app.name" />}
                  rules={[
                    {
                      required: true,
                      message: <IntlMessages id="automationsForm.maxmimumUsersAway.nameRequired" />,
                    },
                  ]}
                >
                  <Input
                    placeholder={formatMessage({ id: 'automationsForm.policy.name' })}
                    disabled={status === AddonsStatusEnum.CANCELED}
                  />
                </Form.Item>

                <Form.Item
                  name="period"
                  label={<IntlMessages id="automationsForm.period" />}
                  rules={[
                    {
                      required: true,
                      message: <IntlMessages id="automationsForm.periodRequired" />,
                    },
                  ]}
                >
                  <RangePicker
                    format="YYYY-MM-DD"
                    disabledDate={disabledDate}
                    disabled={status === AddonsStatusEnum.CANCELED}
                  />
                </Form.Item>

                <YesNoSwitch
                  name="recurring"
                  defaultValue={recurring}
                  label={<IntlMessages id="automationsForm.recurring" />}
                  required
                  onChange={changeRecurring}
                  disabled={status === AddonsStatusEnum.CANCELED}
                  tooltip={<IntlMessages id="automationsForm.recurringTooltip" />}
                />

                <Form.Item
                  name={['maxUsersAway', 'type']}
                  label={<IntlMessages id="automations.maxUsersAwayType" />}
                  rules={[
                    {
                      required: true,
                      message: <IntlMessages id="automationsForm.maxUsersAwayTypeRequired" />,
                    },
                  ]}
                  tooltip={<IntlMessages id="automationsForm.maxmimumUsersAway.maxUsersAwayTypeTooltip" />}
                >
                  <Radio.Group disabled={status === AddonsStatusEnum.CANCELED}>
                    <Radio.Button value={MaxUsersAwayTypeEnum.percentage}>
                      <IntlMessages id="automations.maxUsersAwayTypePercentage" />
                    </Radio.Button>
                    <Radio.Button value={MaxUsersAwayTypeEnum.absolute}>
                      <IntlMessages id="automations.maxUsersAwayTypeAbsolute" />
                    </Radio.Button>
                  </Radio.Group>
                </Form.Item>

                <Form.Item
                  name={['maxUsersAway', 'value']}
                  rules={[
                    {
                      required: true,
                      message: <IntlMessages id="automationsForm.maxUsersAwayValueRequired" />,
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        const type = getFieldValue(['maxUsersAway', 'type'])
                        return validateMaxUsersAway(type, value, totalUsers)
                      },
                    }),
                  ]}
                  dependencies={[['maxUsersAway', 'type']]}
                >
                  <InputNumber
                    min={1}
                    max={form.getFieldValue('maxUsersAwayType') === MaxUsersAwayTypeEnum.percentage ? MAXIMUM_USERS_AWAY_DEFAULT_PERCENTAGE : undefined}
                    disabled={status === AddonsStatusEnum.CANCELED}
                    onChange={(value) => {
                      const type = form.getFieldValue(['maxUsersAway', 'type'])
                      const maxVal = type === MaxUsersAwayTypeEnum.percentage ? 100 : totalUsers
                      if (Number(value) > maxVal) {
                        form.validateFields([['maxUsersAway', 'value']])
                      }
                    }}
                  />
                </Form.Item>

                <Form.Item
                  name="autoRejectResponse"
                  label={<IntlMessages id="automations.autoRejectResponse" />}
                  rules={[
                    {
                      required: true,
                      message: <IntlMessages id="automationsForm.autoRejectResponseRequired" />,
                    },
                  ]}
                >
                  <Input.TextArea
                    defaultValue={formatMessage({ id: 'automationsForm.maxmimumUsersAway.autoRejectResponsePlaceholder' })}
                    disabled={status === AddonsStatusEnum.CANCELED}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Divider />
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col xxl={8} xl={8} lg={6} md={6} sm={24} xs={24}>
                <h2><IntlMessages id="app.applyTo" /></h2>
                <p><IntlMessages id="automations.filterDescription" /></p>
                <p><IntlMessages id="automations.leaveTypesDescription" /></p>
              </Col>
              <Col xxl={8} xl={8} lg={6} md={6} sm={24} xs={24}>
                <YesNoSwitch
                  tooltip={<IntlMessages id="automationsForm.applyToAllUsersTooltip" />}
                  defaultValue={applyToAllUsers}
                  onChange={handleApplyToAllUsersChange}
                  label={<IntlMessages id="automationsForm.applyToAllUsers" />}
                  disabled={status === AddonsStatusEnum.CANCELED}
                />
                {!applyToAllUsers && amIAdmin &&
                  <FilterAdvanced
                    isForm={true}
                    data={{
                      Locations: locations,
                      Departments: teams,
                      Labels: labels,
                    }}
                    onChangeFilter={handleFilter}
                    showLabels={shouldEnableLabels}
                    toolTipInfo="automationsForm.advancedFilter.tooltipInfo"
                    initialValues={initialFilterValues}
                    filterByWidth={125}
                    page={automationId ? undefined : 'automationsForm'}
                  />
                }

                {!amIAdmin &&
                  <Form.Item
                    label={<IntlMessages id="calendar.filterByTeam" />}
                    rules={[{ required: true, message: <IntlMessages id="form.inputRequired" /> }]}
                    name="teams"
                  >
                    <Select
                      mode="multiple"
                      allowClear
                      style={{ width: '100%' }}
                      placeholder={<IntlMessages id="app.pleaseSelect" />}
                      onChange={(values) => {
                        handleFilter({
                          ...filter,
                          teamIds: values,
                        })
                      }}
                    >
                      {teams.map((team) => (
                        <Option value={team.id} key={team.id}>
                          {team.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                }

                <Form.Item
                  name="leaveTypeIds"
                  label={<IntlMessages id="user.leaveTypes" />}
                >
                  <Select
                    mode="multiple"
                    disabled={status === AddonsStatusEnum.CANCELED}
                  >
                    {leaveTypes.map(leaveType => (
                      <Select.Option key={leaveType.id} value={leaveType.id}>
                        {leaveType.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span={24}>
                <Form.Item style={{ textAlign: 'right' }}>
                  <Button onClick={backToAddons}>
                    <IntlMessages id="app.cancel" />
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={btnLoading}
                    disabled={status === AddonsStatusEnum.CANCELED}
                  >
                    <IntlMessages id="app.save" />
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Row>
            <Col xxl={8} xl={8} lg={6} md={6} sm={24} xs={24}>
            </Col>
            <Col xs={24} sm={24} md={16} lg={16} xl={12}>
              <MaximumUsersAwaySummary
                filters={filter}
                noOfUsers={maxUsersAllowed}
                leaveTypes={leaveTypes
                  .filter(lt => summaryValues.leaveTypeIds?.includes(lt.id))
                  .map(lt => lt.name)
                }
                recurring={summaryValues.recurring}
                fromDate={summaryValues.period?.[0]?.format('YYYY-MM-DD')}
                toDate={summaryValues.period?.[1]?.format('YYYY-MM-DD')}
                maxUsersAway={summaryValues.maxUsersAway}
              />
            </Col>
          </Row>
          {/* <IntlMessages TODO: When we have a helpdesk page for maximum users away, add it here
            id="automations.stepByStepHelpDesk"
            values={{
              automationName: () => <IntlMessages id="automations.MAXIMUM_USERS_AWAY" />,
              link: (...chunks) =>
                <a
                  href="https://vacationtracker.crisp.help/en/article/how-to-set-a-blackout-period-block-time-1dwxmnh/"
                  target="_blank"
                  id="helpdesk"
                  rel="noopener noreferrer"
                >
                  {chunks}
                </a>,
            }}
          /> */}
        </>
      }
    </>
  )
}

export default MaxmimumUsersAwayForm