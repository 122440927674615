{
  "accountSettings.connectYourPersonalCalendar": "Conecte seu calendário pessoal",
  "accountSettings.connectYourPersonalCalendar.description": "Conecte seu calendário pessoal às folgas automaticamente. Ao solicitar uma folga, você pode definir se deseja que a folga solicitada apareça como disponível ou ocupado em seu calendário. Visite nossa <helpdesk>Central de Ajuda</helpdesk> para mais informações.",
  "actionNotifications.companyAnnouncement.success": "Anúncio enviado",
  "actionNotifications.leave.editedRequestDenied": "Edição da solicitação de folga negada.",
  "actionNotifications.leave.editedRequestDeniedDescription": "Sua solicitação de folga editada foi negada, no entanto, sua solicitação original permanece ativa.",
  "actionNotifications.leave.requestApproved": "Solicitação de folga aprovada",
  "actionNotifications.leave.requestDenied": "Solicitação de folga foi negada.",
  "actionNotifications.leaveType.created": "O tipo de folga {leaveTypeName} foi criado",
  "actionNotifications.leaveType.deleted": "O tipo de folga foi excluído",
  "actionNotifications.leaveType.updated": "O tipo de folga {leaveTypeName} foi atualizado",
  "actionNotifications.location.createdTitle": "A localização {locationName} foi criada",
  "actionNotifications.location.deletedTitle": "A localização foi excluída",
  "actionNotifications.location.updatedTitle": "A localização {locationName} foi atualizada",
  "actionNotifications.location.view": "Ver localização",
  "actionNotifications.notification.created": "Notificação criada",
  "actionNotifications.notification.deleted": "Notificação excluída",
  "actionNotifications.notification.updated": "Notificação atualizada",
  "actionNotifications.outOfOfficeSetting.success": "Sua configuração de ausência foi atualizada",
  "actionNotifications.scheduledReport.createdTitle": "O relatório agendado {scheduledReportName} foi criado",
  "actionNotifications.scheduledReport.deletedTitle": "O relatório agendado foi excluído",
  "actionNotifications.scheduledReport.updatedTitle": "O relatório agendado {scheduledReportName} foi atualizado",
  "actionNotifications.scheduledReport.viewScheduledReport": "Ver relatório agendado",
  "actionNotifications.team.createdTitle": "O departamento {teamName} foi criado",
  "actionNotifications.team.deletedTitle": "O departamento foi excluído",
  "actionNotifications.team.updatedTitle": "O departamento {teamName} foi atualizado",
  "actionNotifications.team.view": "Ver departamento",
  "addLeave.addLeave": "Adicionar Folga",
  "addLeave.handleSubmitErrorTitle": "Erro ao Enviar Folga",
  "addLeave.inProgress": "Adicionando folga",
  "addLeave.noApproverUsers": "Se você não faz parte de um departamento, não poderá adicionar folgas para si mesmo. Para solicitar folga, mesmo que você não faça parte de um departamento para o qual tenha direitos de aprovação, abra o formulário \"Solicitar folga\" e envie uma solicitação de folga.",
  "addLeave.title": "Folga adicionada",
  "addons.openApi.apiKeyName": "Nome da Chave API",
  "addons.openApi.apiKeyNamePlaceholder": "Nome da chave API",
  "addons.openApi.apiKeySecret": "Segredo da Chave API",
  "addons.openApi.apiKeysInfo": "Gere e gerencie suas chaves API.",
  "addons.openApi.apiKeysTitle": "Chaves API",
  "addons.openApi.buyAppiAddon": "Comprar complemento API",
  "addons.openApi.buyOpenApiInfoPrice": "O complemento API é pago e custa ${price}/mês/usuário ativo. A compra aumentará sua fatura mensal em ${totalPaid} para {userCount, plural, =1 {seu} other {seus}} {userCount} {userCount, plural, =1 {usuário ativo} other {usuários ativos}} (daremos uma estimativa precisa quando você clicar no botão \"Comprar\").",
  "addons.openApi.buyOpenApiInfoPrice2line": "Após a compra do complemento, você ou qualquer outro administrador da sua organização poderá gerar e gerenciar sua chave API.",
  "addons.openApi.buyOpenApiTitle": "Compre o complemento API para gerar chaves API",
  "addons.openApi.createInProgress": "Criação de Chave API em andamento",
  "addons.openApi.createInProgressCompleted": "Criação de Chave API concluída",
  "addons.openApi.createNewApiKey": "Criar Nova Chave API",
  "addons.openApi.currentMonthUsage": "Uso do Mês Atual",
  "addons.openApi.documentation": "Documentação da API",
  "addons.openApi.documentationInfo": "A documentação da API descreve como nossa API funciona e quais métodos estão disponíveis atualmente.",
  "addons.openApi.dontHaveApiKeys": "Você não tem chaves API, clique no botão abaixo para criar uma",
  "addons.openApi.dpenDocumentation": "Abrir Documentação",
  "addons.openApi.feedback": "Se você tiver feedback ou precisar de dados que nossa API não expõe, <link>clique aqui para nos informar</link>.",
  "addons.openApi.limitRequestPerMonth": "Você pode enviar até 30000 solicitações por mês",
  "addons.openApi.limitRequestPerSecound": "As solicitações são limitadas a 10 por segundo com um modo de rajada de 50 solicitações/segundo",
  "addons.openApi.notificationDeleteConfirm": "Por favor, confirme que deseja excluir a Chave API <strong>{name}</strong>.",
  "addons.openApi.notificationDeleteTitle": "Excluir Chave API",
  "addons.openApi.openApiDeleted": "Chave API excluída",
  "addons.openApi.quotasAndRateLimitsInfo": "Aqui estão nossas cotas e limites de taxa da API por chave API:",
  "addons.openApi.quotasAndRateLimitsTitle": "Cotas e limites de taxa",
  "addons.openApiInfo": "Nosso complemento API permite que você conecte o Vacation Tracker a outros aplicativos programaticamente. Atualmente, nossa API permite acesso somente leitura aos seus usuários, suas licenças, localizações, departamentos, tipos de licença e etiquetas.",
  "addonsPaymentStatus.activateForFree": "Ativar gratuitamente",
  "addonsPaymentStatus.activeUntilBillingPeriodEnds": "Ativo até o final do período de faturamento",
  "addonsPaymentStatus.canceled": "Cancelado",
  "addonsPaymentStatus.grantPeriod": "Período de concessão",
  "addonsPaymentStatus.paymentActive": "Pagamento ativo",
  "ai.assistant.completedConversationTooltip": "Esta conversa está concluída. Você não pode enviar novas mensagens, mas pode ver a conversa existente.",
  "ai.assistant.defaultMessage": "Como posso ajudar você?",
  "ai.assistant.disclaimer": "O Assistente de IA está em versão Beta. Ele pode cometer erros. Em caso de erros ou respostas confusas, por favor <support>entre em contato com nosso suporte</support>.",
  "ai.assistant.inputLockedTooltip": "Esta é uma versão inicial do nosso Assistente de IA. Atualmente, ele só ajuda com a integração. No entanto, em breve você poderá iniciar novas conversas e falar com o Assistente de IA do Vacation Tracker. Obrigado pela sua paciência!",
  "ai.assistant.lastMessageTimestampTooltip": "A última mensagem foi enviada em: {date}",
  "ai.assisted.onboarding.companyLogUserNameDecorator": "(através do Assistente de IA)",
  "ai.assisted.onboarding.finalAssistantMessage": "Seu processo de integração está completo. O Vacation Tracker agora configurará sua conta da empresa com esta configuração. Lembre-se, você sempre pode fazer alterações em sua configuração mais tarde, se necessário.",
  "ai.assisted.onboarding.initialAssistantMessage": "Enquanto você estava se cadastrando, já configuramos algumas coisas para você:\n\nLocalizações ({locations}), \nDepartamentos ({departments}), e \nTipos de Folga ({leaveTypes}).\n\nTodos os seus funcionários trabalham nesta localização?",
  "ai.assisted.onboarding.initialUserMessage": "Olá! Eu sou seu Assistente de IA. Estou aqui para ajudar você a começar com sua nova conta da empresa.\n\nEsta configuração de IA é opcional, é claro. Você pode pular e configurar o sistema por conta própria. (Provavelmente você quer começar na seção \"Configurações\" do nosso menu). No entanto, vou guiá-lo através de algumas partes essenciais do Vacation Tracker e configurar tudo para você em alguns minutos. Não armazenamos nenhum de seus dados privados (mas, por favor, não compartilhe informações sensíveis comigo).",
  "ai.assisted.onboarding.noTopics": "Ei, parece que você acabou de encontrar um ovo de Páscoa! 🐣 Estamos trabalhando em algo novo que deve economizar muito tempo para você, mas ainda não está pronto. Então, por favor, vamos manter isso como nosso pequeno segredo. Obrigado!",
  "ai.assisted.onboarding.openSetupNotification": "Veja o que foi feito até agora",
  "ai.assisted.onboarding.openSetupNotificationFetchingSetup": "Obrigado por fornecer todas as informações necessárias. Agora estou começando a criar sua configuração do Vacation Tracker. Você começará a ver atualizações em tempo real a qualquer momento...",
  "ai.assisted.onboarding.openSetupNotificationLeavePolicyAssigned": "Tipo de folga {leaveTypeName} atribuído a {locationName}",
  "ai.assisted.onboarding.openSetupNotificationLeavePolicyAssigning": "Atribuindo tipo de folga {leaveTypeName} a {locationName}",
  "ai.assisted.onboarding.openSetupNotificationLeavePolicySuccessAction": "{entityName} {entity} foi {action} com sucesso",
  "ai.assisted.onboarding.openSetupNotificationNoData": "Não é possível exibir os dados de configuração",
  "ai.assisted.onboarding.openSetupNotificationNoDataSubtitle": "Não posso mostrar os dados de configuração agora. Isso pode acontecer devido a uma breve interrupção na rede ou porque o sistema ainda não terminou de processar os dados. Por favor, recarregue esta página ou verifique as configurações na seção Configurações do menu.",
  "ai.assisted.onboarding.openSetupNotificationTitle": "Status da Configuração de Integração",
  "ai.assisted.onboarding.sendMessageError": "Parece que a resposta do Assistente de IA está demorando muito. Por favor, recarregue.",
  "ai.assisted.onboarding.summary.intro": "Tudo está configurado agora. Você pode abrir o painel e começar a usar o aplicativo.",
  "ai.assisted.onboarding.summary.introSummary": "Aqui está o resumo da sua configuração:",
  "ai.assisted.onboarding.summary.leavePoliciesInfo": "Políticas de licença são configurações de tipos de licença específicas para uma localização. Para ver as políticas de licença criadas, vá para a página de localizações, selecione a localização e abra a aba de políticas de licença.",
  "ai.assisted.onboarding.summary.view.departments": "Ver Departamentos",
  "ai.assisted.onboarding.summary.view.leaveTypes": "Ver Tipos de Licença",
  "ai.assisted.onboarding.summary.view.locations": "Ver Localizações",
  "app.12h": "12h",
  "app.24h": "24h",
  "app.aboutMe": "Sobre mim:",
  "app.accountPreferences": "Ver Preferências",
  "app.accountSettings": "Configurações da Conta",
  "app.accruals": "Acumulações",
  "app.actions": "Ações",
  "app.activate": "Ativar",
  "app.active": "Ativo",
  "app.add": "Adicionar",
  "app.addAvatar": "Adicionar Avatar",
  "app.addLeave": "Adicionar Licença",
  "app.addNewEmail": "Adicionar Destinatário",
  "app.addons": "Complementos",
  "app.addToGoogleCalendar": "Google Agenda",
  "app.addToMicrosoft365Calendar": "Microsoft 365 Agenda",
  "app.AIAssistant": "Assistente de IA",
  "app.all": "Todos",
  "app.allDay": "Dia Todo",
  "app.allowHalfDays": "Permitir Meio Dia",
  "app.allowHourlyIncrements": "Permitir Incrementos por Hora",
  "app.and": "e",
  "app.announcementsHaveBeenPaused": "Seus anúncios foram pausados por 2 meses.",
  "app.applyTo": "Aplicar a",
  "app.approvalReason": "Motivo da aprovação",
  "app.approvalRequired": "Aprovação Necessária",
  "app.approve": "Aprovar",
  "app.approved": "Aprovado",
  "app.approvedByFristLevel": "Você é o segundo nível de aprovação para esta solicitação. Ela já foi aprovada por {approverName}.",
  "app.approveLeaveText": "Por favor, confirme que deseja aprovar a solicitação de licença.",
  "app.approveLeaveTitle": "Aprovar Solicitação de Licença",
  "app.april": "Abril",
  "app.areYouSure": "Tem certeza?",
  "app.askMeLater": "Pergunte-me depois",
  "app.assignLeavePolicy": "Atribuir Política de Licença",
  "app.august": "Agosto",
  "app.autoApproved": "Aprovação automática",
  "app.autoDeleted": "<Excluído Automaticamente>",
  "app.autoImport": "<Importação Automática>",
  "app.automations": "Automações",
  "app.avatar": "Avatar",
  "app.back": "Voltar",
  "app.backToMenu": "Voltar ao menu",
  "app.bi-weekly": "Quinzenal",
  "app.billing.periodAnnual": "Anual",
  "app.billingStatus": "Status de Faturamento",
  "app.billingStatus.ACTIVE": "Pagamento ativo",
  "app.billingStatus.CANCEL_AT_PERIOD_END": "Ativo até o fim do período de faturamento",
  "app.billingStatus.CANCELED": "Pagamento cancelado",
  "app.billingStatus.GRANT_PERIOD": "Período de concessão",
  "app.broughtForward": "Transferido",
  "app.by": "por",
  "app.calendar": "Calendário",
  "app.cancel": "Cancelar",
  "app.cancelLeave": "Cancelar Folga",
  "app.cancelLeaveConfirmText": "Tem certeza de que deseja cancelar esta folga agendada?",
  "app.cancelled": "Cancelado",
  "app.change": "Alterar",
  "app.changePassword": "Alterar senha",
  "app.close": "Fechar",
  "app.code": "Código",
  "app.color": "Cor",
  "app.coming-soon": "(em breve)",
  "app.comingSoon": "Em breve",
  "app.company": "Empresa",
  "app.companyLogs": "Registros da Empresa",
  "app.companyNotExist": "Ops! Parece que você não completou o cadastro no Vacation Tracker. Por favor, visite: app.vacationtracker.io para concluir o cadastro.",
  "app.companyNotExist.description1": "Você adicionou o bot e a aba do Vacation Tracker ao seu espaço de trabalho do Microsoft Teams.",
  "app.companyNotExist.description2": "Por favor, acesse nosso site para completar seu registro e configurar as definições da sua organização.",
  "app.companyNotExist.getStarted": "Concluir Cadastro",
  "app.completed": "Concluído",
  "app.completeRegistration": "Concluir Registro",
  "app.configuration.moreInfo": "Para mais informações, visite nossa central de ajuda: <link>https://vacationtracker.crisp.help/en/</link>",
  "app.configuration.option.overview": "Visão Geral",
  "app.configuration.options.info": "Selecione o tipo de aba: ",
  "app.configuration.welcome": "Bem-vindo ao Vacation Tracker! Esta aba permite que você solicite folgas facilmente sem sair do Microsoft Teams, gerencie suas folgas, verifique o status das suas solicitações, veja quem está fora do escritório e confira os próximos feriados da sua equipe.",
  "app.configure": "Configurar",
  "app.confirm": "Confirmar",
  "app.confirmNewPassword": "Confirmar Nova Senha",
  "app.confirmPassword": "Confirmar Senha",
  "app.consentRequired": "Consentimento de Administrador do Microsoft Teams Necessário. Parece que você não é um administrador da sua organização no Microsoft Teams. Para acessar a aba do Vacation Tracker, você precisará do consentimento do administrador. Por favor, envie o seguinte link para o administrador da sua organização para obter o consentimento: {link}",
  "app.consentRequired.title": "Consentimento de Administrador do Microsoft Teams Necessário",
  "app.contactSupport": "Contatar suporte",
  "app.continue": "Continuar",
  "app.copyright": "Direitos Autorais VacationTracker",
  "app.correlationIdError": "Ocorreu um erro. Por favor, entre em contato com nosso suporte ao cliente em hello@vacationtracker.io com o seguinte ID: {correlationId}",
  "app.country": "País",
  "app.create": "Criar",
  "app.created": "Criado",
  "app.createdAt": "Criado em",
  "app.createdBy": "Criado por",
  "app.createdSuccessfully": "Solicitação enviada",
  "app.creating": "Criando",
  "app.creditCard": "Cartão de crédito",
  "app.currentYear": "Ano Atual",
  "app.customDays": "Dias Personalizados",
  "app.customWorkHours": "Horas de Trabalho Personalizadas",
  "app.daily": "Diário",
  "app.dashboard": "Painel",
  "app.dateRange": "Data de Início e Fim",
  "app.day": "dia",
  "app.days": "dias",
  "app.december": "Dezembro",
  "app.delete": "Excluir",
  "app.deleted": "Excluído",
  "app.deleteFailed": "Falha na exclusão",
  "app.deleteUser": "Excluir Usuário",
  "app.deleting": "Excluindo",
  "app.deliveryFailed": "Falha na Entrega",
  "app.denialReason": "Motivo da negação",
  "app.denied": "Negado",
  "app.deny": "Negar",
  "app.denyLeaveRequest": "Negar Solicitação de Licença",
  "app.denyLeaveRequestText": "Por favor, confirme que deseja negar a solicitação de licença.",
  "app.denyWithReason": "Negar com Motivo",
  "app.department": "Departamento",
  "app.departments": "Departamentos",
  "app.departmentsSub": "Subdepartamentos",
  "app.deselectAll": "Desmarcar Todos",
  "app.displayDaysHoursTooltip.days": "Isso são {value} dias",
  "app.displayDaysHoursTooltip.hours": "Isso são {value} horas",
  "app.documentation": "Documentação",
  "app.done": "Concluído",
  "app.dontSend": "Não enviar",
  "app.downgradeWithoutUnsubscribe": "Fazer downgrade sem cancelar a assinatura",
  "app.downgradeWithSubscribe": "Fazer downgrade e cancelar a assinatura",
  "app.download": "Baixar",
  "app.downloadProgressReport": "Criando seu arquivo, isso pode levar um momento...",
  "app.downloadReady": "Seu arquivo está pronto. Clique aqui para baixá-lo.",
  "app.downloadTemplate": "Baixar Modelo",
  "app.edit": "Editar",
  "app.edited": "Editado",
  "app.editLeave": "Editar Licença",
  "app.email": "E-mail",
  "app.emailDeleted": "E-mail excluído",
  "app.employeeEndDate": "Data de Término do Funcionário",
  "app.enable": "Habilitar",
  "app.enabled": "Habilitado",
  "app.enterWorkEmail": "Digite seu e-mail de trabalho",
  "app.error": "Erro",
  "app.every": "cada",
  "app.expired": "Expirado",
  "app.expires": "Expira",
  "app.export": "Exportar",
  "app.export.comingSoon": "Nossa equipe está polindo este recurso. Você deve esperá-lo em breve.",
  "app.feature.access": "Disponível exclusivamente no plano Completo.",
  "app.february": "Fevereiro",
  "app.firstAndLastName": "Nome e Sobrenome",
  "app.friday": "Sexta-feira",
  "app.friday.short": "Sex",
  "app.full-day": "Dia Inteiro",
  "app.full-name": "Nome Completo",
  "app.general": "Geral",
  "app.goodAfternoon": "Boa tarde",
  "app.goodEvening": "Boa noite",
  "app.goodMorning": "Bom dia",
  "app.gotIt": "Entendi",
  "app.half-day": "Meio dia",
  "app.halfDay": "Meio-Dia",
  "app.halfDayInParenthesis": " (Meio-Dia)",
  "app.happyVacationing": "Boas Férias 🌴",
  "app.helpdesk": "Central de Ajuda",
  "app.holidays": "Feriados",
  "app.home": "Início",
  "app.hour": "hora",
  "app.hours": "horas",
  "app.import": "Importar",
  "app.importFile": "Importar Arquivo",
  "app.importFormat": "Formato de Importação",
  "app.importLeaves": "Importar Folgas",
  "app.importLeaves.alerts.confirmClose.bodyText": "Tem certeza? Suas informações atuais não serão salvas.",
  "app.importLeaves.alerts.confirmClose.cancelButtonTitle": "Cancelar",
  "app.importLeaves.alerts.confirmClose.exitButtonTitle": "Sair",
  "app.importLeaves.alerts.confirmClose.headerTitle": "Sair do fluxo de importação",
  "app.importLeaves.alerts.confirmClose.submitIncomplete.bodyText": "Ainda existem algumas linhas que contêm erros. As linhas com erros serão ignoradas ao enviar.",
  "app.importLeaves.alerts.confirmClose.submitIncomplete.bodyTextSubmitForbidden": "Ainda existem algumas linhas contendo erros. Por favor, remova as linhas inválidas ou corrija os erros e tente novamente.",
  "app.importLeaves.alerts.confirmClose.submitIncomplete.cancelButtonTitle": "Cancelar",
  "app.importLeaves.alerts.confirmClose.submitIncomplete.finishButtonTitle": "Enviar",
  "app.importLeaves.alerts.confirmClose.submitIncomplete.headerTitle": "Erros Detectados",
  "app.importLeaves.alerts.toast": "Erro",
  "app.importLeaves.alerts.unmatchedRequiredFields.bodyText": "Existem colunas obrigatórias que não estão correspondidas ou foram ignoradas. Você deve corresponder todas as colunas obrigatórias para continuar.",
  "app.importLeaves.alerts.unmatchedRequiredFields.cancelButtonTitle": "Cancelar",
  "app.importLeaves.alerts.unmatchedRequiredFields.continueButtonTitle": "Continuar",
  "app.importLeaves.alerts.unmatchedRequiredFields.headerTitle": "Nem todas as colunas foram correspondidas",
  "app.importLeaves.alerts.unmatchedRequiredFields.listTitle": "Colunas obrigatórias não correspondidas:",
  "app.importLeaves.downloadTemplateFileTooltip": "Aqui, você pode baixar nosso arquivo de modelo de importação, que serve como exemplo para preencher os dados que deseja importar. Ao criar seu arquivo de importação, certifique-se de que ele contenha uma coluna de cabeçalho para que o sistema reconheça seus dados corretamente.<br></br><br></br>Verifique se seus dados correspondem ao modelo para garantir uma importação bem-sucedida. Para instruções adicionais, sinta-se à vontade para entrar em contato com nossa equipe de suporte.",
  "app.importLeaves.dropzone.activeDropzoneTitle": "Solte o arquivo aqui",
  "app.importLeaves.dropzone.buttonTitle": "Selecionar arquivo",
  "app.importLeaves.dropzone.errorToastDescription": "Faça upload de um arquivo .xlsx, .xls ou .csv",
  "app.importLeaves.dropzone.loadingTitle": "Processando...",
  "app.importLeaves.dropzone.title": "Faça upload de um arquivo .xlsx, .xls ou .csv",
  "app.importLeaves.errors.dateFromAfterDateTo": "A Data De é posterior à Data Até",
  "app.importLeaves.errors.dateFromNotValid": "A data de início não é válida",
  "app.importLeaves.errors.dateFromRequired": "A data de início é obrigatória",
  "app.importLeaves.errors.dateToNotValid": "A data de término não é válida",
  "app.importLeaves.errors.dateToRequired": "A data de término é obrigatória",
  "app.importLeaves.errors.halfDay": "A licença é maior que um dia",
  "app.importLeaves.errors.hourFromAfterHourTo": "A hora de início é posterior à hora de término",
  "app.importLeaves.errors.hourFromNotValid": "A hora de início não é válida, insira um valor entre 0 e 24",
  "app.importLeaves.errors.hourFromRequired": "A hora de início é obrigatória",
  "app.importLeaves.errors.hourRange": "As horas selecionadas excedem a duração do dia de trabalho deste usuário ({hours} horas). Por favor, ajuste sua seleção.",
  "app.importLeaves.errors.hourToNotValid": "A hora de término não é válida, insira um valor entre 0 e 24",
  "app.importLeaves.errors.hourToRequired": "A hora de término é obrigatória",
  "app.importLeaves.errors.leavePolicyError": "A política de licença permite apenas dia inteiro",
  "app.importLeaves.errors.leaveTypeNotFound": "Tipo de licença não encontrado",
  "app.importLeaves.errors.leaveTypeNotInUserLocation": "Tipo de licença não está na localização do usuário",
  "app.importLeaves.helpdesk": "Para orientações detalhadas sobre como importar seus arquivos, consulte as instruções passo a passo disponíveis na <helpdesk>página da central de ajuda</helpdesk>.",
  "app.importLeaves.importFormatTooltip": "Selecione o formato de importação que corresponde ao seu tipo de dados e preferências.",
  "app.importLeaves.importUsersDataWarning": "Esta importação será aplicada apenas a usuários ativos.",
  "app.importLeaves.leaveStatusWarning": "O status da licença será definido como \"Aprovado\" para todas as licenças que você importar.",
  "app.importLeaves.manifestDescription": "(Você terá a chance de renomear ou remover colunas nas próximas etapas)",
  "app.importLeaves.manifestTitle": "Dados que esperamos:",
  "app.importLeaves.matchColumnsStep.duplicateColumnWarningDescription": "As colunas não podem ser duplicadas",
  "app.importLeaves.matchColumnsStep.duplicateColumnWarningTitle": "Outra coluna foi desmarcada",
  "app.importLeaves.matchColumnsStep.ignoredColumnText": "Coluna ignorada",
  "app.importLeaves.matchColumnsStep.matchDropdownTitle": "Corresponder",
  "app.importLeaves.matchColumnsStep.nextButtonTitle": "Próximo",
  "app.importLeaves.matchColumnsStep.selectPlaceholder": "Selecionar coluna...",
  "app.importLeaves.matchColumnsStep.subSelectPlaceholder": "Selecionar...",
  "app.importLeaves.matchColumnsStep.templateTitle": "Será",
  "app.importLeaves.matchColumnsStep.title": "Corresponder Colunas",
  "app.importLeaves.matchColumnsStep.unmatched": "Não correspondido",
  "app.importLeaves.matchColumnsStep.userTableTitle": "Sua tabela",
  "app.importLeaves.maxRecordsExceeded": "Muitos registros. Até {maxRecords} permitidos",
  "app.importLeaves.nextButtonTitle": "Próximo",
  "app.importLeaves.selectHeaderStep.title": "Selecione a linha de cabeçalho",
  "app.importLeaves.selectSheet.title": "Selecione a planilha a ser usada",
  "app.importLeaves.validationStep.discardButtonTitle": "Descartar linhas selecionadas",
  "app.importLeaves.validationStep.filterSwitchTitle": "Mostrar apenas linhas com erros",
  "app.importLeaves.validationStep.nextButtonTitle": "Confirmar",
  "app.importLeaves.validationStep.noRowsMessage": "Nenhum dado encontrado",
  "app.importLeaves.validationStep.noRowsMessageWhenFiltered": "Nenhum dado contendo erros",
  "app.importLeaves.validationStep.title": "Validar dados",
  "app.imports": "Importações",
  "app.importType": "Tipo de Importação",
  "app.integrations": "Integrações",
  "app.invite": "Convidar",
  "app.january": "Janeiro",
  "app.july": "Julho",
  "app.june": "Junho",
  "app.label": "Etiqueta",
  "app.labels": "Etiquetas",
  "app.lastMonth": "Mês passado",
  "app.learnMore": "Saiba mais",
  "app.leave": "Licença",
  "app.leaveBalanceReport": "Relatório de Saldo de Licença",
  "app.leaveCanceled": "Licença Cancelada",
  "app.leaveHistory": "Histórico de Licenças",
  "app.leaveHistoryReport": "Relatório de Solicitação de Licença",
  "app.leavePolicies": "Políticas de Licença",
  "app.leaveRequestCalendarStatusLabel": "Mostrar no seu calendário como ",
  "app.leaveRequestCreated": "Solicitação de Licença Criada",
  "app.leaveRequests": "Solicitações de Licença",
  "app.leaveType": "Tipo de Licença",
  "app.leaveTypes": "Tipos de Licença",
  "app.link": "Link",
  "app.load": "Carregar",
  "app.loading": "Carregando",
  "app.loadMore": "Carregar mais",
  "app.location": "Localização",
  "app.locations": "Localizações",
  "app.login": "Entrar",
  "app.logInWithMicrosoft": "Entrar com Microsoft",
  "app.logout": "Sair",
  "app.logoutNow": "Sair Agora",
  "app.logs": "Registros",
  "app.manageLabels": "Gerenciar Etiquetas",
  "app.march": "Março",
  "app.may": "Maio",
  "app.maybe": "Talvez",
  "app.message": "mensagem",
  "app.meta.description": "Amado por mais de 1.000 empresas em todo o mundo, o Vacation Tracker torna notoriamente fácil gerenciar seu PTO. Disponível para Slack, Microsoft Teams e Google Workspace.",
  "app.meta.title": "Vacation Tracker - Solução de rastreamento de licenças amigável para trabalho remoto",
  "app.mfa": "Autenticação de múltiplos fatores",
  "app.mfaCode": "Código",
  "app.mfaCodeErrorGeneric": "Falha ao validar o código",
  "app.mfaCodeErrorMissmatch": "Código não corresponde",
  "app.mfaCodeErrorNetwork": "Erro de Rede",
  "app.mfaCodePlaceholder": "Por favor, digite o código exibido no seu aplicativo de autenticação",
  "app.mfaDisableAnswerNo": "Não, manter MFA",
  "app.mfaDisableAnswerYes": "Sim, desativar MFA",
  "app.mfaDisableError": "Falha ao desativar MFA. Por favor, tente novamente mais tarde.",
  "app.mfaDisableSuccess": "Você desativou o MFA com sucesso.",
  "app.mfaDisableWarning": "Tem certeza de que deseja desativar o MFA?",
  "app.mfaDisableWarningTitle": "Desativar autenticação de múltiplos fatores",
  "app.mfaEnableError": "Falha ao ativar MFA. Por favor, tente novamente mais tarde.",
  "app.mfaEnableSuccess": "Você configurou o MFA com sucesso.",
  "app.mfaEnterCode": "Digite o código",
  "app.mfaLabel": "Ativar MFA",
  "app.mfaLoginFailed": "Falha no Login",
  "app.mfaSecretKey": "Ou digite a chave secreta manualmente:",
  "app.mfaSubTitle": "Escaneie o seguinte código QR no seu aplicativo MFA (por exemplo, Authy ou Google Authenticator):",
  "app.mfaTitle": "Ativar autenticação de múltiplos fatores",
  "app.mfaTooltip": "Depois de adicionar o Vacation Tracker ao seu aplicativo MFA, aguarde até que ele gere o código e digite-o abaixo:",
  "app.microsoft.manageLicenses": "Gerenciar licenças",
  "app.microsoftTeams": "Microsoft Teams",
  "app.monday": "Segunda-feira",
  "app.monday.short": "Seg",
  "app.monthly": "Mensal",
  "app.monthlyLeaveBalanceReport": "Relatório Mensal de Saldo de Férias",
  "app.moreDetails": "Mais Detalhes",
  "app.myProfile": "Meu Perfil",
  "app.myStatus": "Meu status",
  "app.name": "Nome",
  "app.newPassword": "Nova Senha",
  "app.next": "Próximo",
  "app.nextInvoice": " Sua próxima fatura será de {amount} (excluindo impostos) em {startDate}",
  "app.nextMonth": "Próximo mês",
  "app.nextYear": "Próximo Ano",
  "app.no": "Não",
  "app.noLeaves": "Sem folgas",
  "app.none": "Nenhum",
  "app.nonWorkingDay": "Dia não útil",
  "app.notDone": "Não Concluído",
  "app.note": "Nota",
  "app.notificationError": "Oh não! 😕 Algo deu errado. ",
  "app.notifications": "Notificações",
  "app.notSet": "Não definido",
  "app.notValidEmail": "Por favor, insira um endereço de e-mail válido.",
  "app.november": "Novembro",
  "app.october": "Outubro",
  "app.of": "de",
  "app.ofThat": "disso",
  "app.ok": "Ok",
  "app.oldPassword": "Senha Antiga",
  "app.one-hour": "1 hora",
  "app.openCalendar": "Abrir Calendário",
  "app.openDashboard": "Abrir painel",
  "app.openWebDashboard": "Abrir Painel Web",
  "app.or": "Ou",
  "app.password": "Senha",
  "app.passwordAndSecurity": "Senha e Segurança",
  "app.pastDue": "Devido a um pagamento malsucedido, seu acesso ao Vacation Tracker será encerrado em breve. Se deseja continuar usando o Vacation Tracker, por favor <a>insira um cartão válido</a>.",
  "app.pending": "Pendente",
  "app.pendingLeaveRequests": "Solicitações de Folga Pendentes",
  "app.pleaseSelect": "Por favor, selecione",
  "app.prev": "Anterior",
  "app.previous": "Anterior",
  "app.proceed": "Prosseguir",
  "app.processingRequest": "Processando solicitação...",
  "app.profilePicture": "Foto do Perfil",
  "app.reason": "Motivo",
  "app.recipients": "Destinatários",
  "app.recommended": "recomendado",
  "app.reconnect": "Reconectar",
  "app.reload": "Recarregar",
  "app.removed": "removido",
  "app.rename": "Renomear",
  "app.reportType": "Tipo de relatório",
  "app.requestLeave": "Solicitar Licença",
  "app.requestToil": "Solicitar TOIL",
  "app.required": "Obrigatório",
  "app.resendCode": "Reenviar código",
  "app.resent": "Reenviado",
  "app.reset": "Redefinir",
  "app.resetPassword": "Redefinir senha",
  "app.resultsLeaves": "Total encontrado: {total} {total, plural, =1 {solicitação de licença} other {solicitações de licença}}",
  "app.resultsUsers": "Total encontrado: {total} {total, plural, =1 {usuário} other {usuários}}",
  "app.reviewAndConfirm": "Revisar e Confirmar",
  "app.role.administrator": "Administrador",
  "app.role.approver": "Aprovador",
  "app.role.approvers": "Aprovadores",
  "app.role.approversLevelFrist": "Nível 1",
  "app.role.approversLevelSecound": "Nível 2",
  "app.saturday": "Sábado",
  "app.saturday.short": "Sáb",
  "app.save": "Salvar",
  "app.scheduledLeaves": "Licenças Agendadas",
  "app.scheduledReports": "Relatórios Agendados",
  "app.seeInCalendar": "🗓️ Ver no Calendário",
  "app.seeMoreOptions": "Ver mais opções",
  "app.select": "Selecionar",
  "app.selectAll": "Selecionar Tudo",
  "app.semi-monthly": "Quinzenal",
  "app.send": "Enviar",
  "app.sendNow": "Enviar Agora",
  "app.september": "Setembro",
  "app.set": "definir",
  "app.setOn": "definido em",
  "app.shortestLeaveDuration": "Menor Duração de Licença",
  "app.showLess": "Mostrar menos",
  "app.showMore": "Mostrar mais",
  "app.skip": "Pular",
  "app.slack": "Slack",
  "app.snooze": "Adiar",
  "app.snoozeForMonths": "Adiar por 2 meses",
  "app.startFreeTrial": "Comece seu Teste Gratuito de 7 Dias",
  "app.state": "Estado",
  "app.status": "Status",
  "app.submit": "Enviar",
  "app.substituteApprover": "Aprovador Substituto",
  "app.substituteApproverSet": "Você foi definido como Aprovador Substituto.",
  "app.sunday": "Domingo",
  "app.sunday.short": "Dom",
  "app.switch": "Alternar",
  "app.sync": "Sincronizar",
  "app.takeQuickTour": "Faça um tour rápido",
  "app.text": "Texto",
  "app.thisMonth": "Este mês",
  "app.thisWeek": "Esta semana",
  "app.thursday": "Quinta-feira",
  "app.thursday.short": "Qui",
  "app.time": "Hora",
  "app.timeOffInLieu": "Folga compensatória",
  "app.timeOffInLieuTooltip": "Quer editar a Folga Compensatória? <helpDesk>Aprenda como fazer isso</helpDesk>",
  "app.timezone": "Fuso horário",
  "app.today": "Hoje",
  "app.toil": "TOIL",
  "app.trialExpired": "A versão de teste expira em {days} {daysInfo}. Se você gostou, por favor <a>assine</a> para continuar usando o Vacation Tracker após o término do período de teste.",
  "app.trialExtend": "Precisa de uma extensão do período de teste?",
  "app.trialPeriod": "Período de Teste",
  "app.tryAgain": "Tentar novamente",
  "app.tryLateOrContactSupport": "Algo deu errado. Por favor, tente mais tarde ou entre em contato com nosso suporte ao cliente em hello@vacationtracker.io com o seguinte ID: {code}",
  "app.tuesday": "Terça-feira",
  "app.tuesday.short": "Ter",
  "app.type": "Tipo",
  "app.unknown": "<Desconhecido>",
  "app.unlimited": "Ilimitado",
  "app.update": "Atualizar",
  "app.updated": "atualizado",
  "app.updatedInProgress": "Processando Resposta",
  "app.updatedSuccessfully": "Solicitação enviada.",
  "app.updating": "Atualizando",
  "app.upload": "Enviar",
  "app.user": "Usuário",
  "app.users": "Usuários",
  "app.users.role": "Função",
  "app.userStatus": "Status do Usuário",
  "app.via": "via",
  "app.view": "Visualizar",
  "app.viewAll": "Ver Todos",
  "app.visitHelpdesk": "Visitar Central de Ajuda",
  "app.wallchart": "Quadro de Parede",
  "app.was": "foi",
  "app.watchVideo": "Assistir vídeo",
  "app.wednesday": "Quarta-feira",
  "app.wednesday.short": "Qua",
  "app.weekly": "Semanal",
  "app.willBe": "será",
  "app.workday": "Duração do Dia de Trabalho",
  "app.workdayWarning": "A opção de meio dia de folga só está disponível para um dia de trabalho de 8 horas. Alterar o dia de trabalho padrão de 8 horas desativará esta opção.",
  "app.workweek": "Semana de trabalho",
  "app.year": "Ano",
  "app.years": "Anos",
  "app.yes": "Sim",
  "automation.feedback.thankYou": "Obrigado por compartilhar seus pensamentos conosco. Sua opinião impulsiona nosso crescimento constante.",
  "automations.activateInProgress": "Em Andamento - Ativação",
  "automations.activateInProgressDescription": "Sua solicitação para ativar a automação está em andamento. Estamos trabalhando para habilitar este recurso para você.",
  "automations.add": "Criar automação",
  "automations.addAddonsDescirptionForAdmin": "Prosseguir com a compra do complemento selecionado? O valor será prorrateado em ${prorateAmount}, com sua próxima fatura totalizando ${nextInvoiceAmount}. Clique em 'Confirmar' para finalizar.",
  "automations.addAddonsDescirptionForAdminInTrial": "Este complemento será cobrado em sua próxima fatura: ${total} com base em {userCount} usuários a ${addonsPrice} por complemento. Durante o período de teste, você pode ativá-lo e desativá-lo livremente sem cobranças se nenhum complemento estiver ativo no final.",
  "automations.addAddonsDescirptionForApprover": "Apenas usuários de nível Admin têm a capacidade de comprar ou cancelar a assinatura de complementos. Se forem necessários ajustes em suas assinaturas de complementos, entre em contato com seu administrador.",
  "automations.addFreeAddonsDescirptionForAdmin": "Este complemento está incluído sem custo adicional em seu plano atual. Você pode ativá-lo e desativá-lo livremente durante a assinatura do seu plano atual sem nenhuma cobrança extra.",
  "automations.addpnSuccessfullyAdded": "Concluído - Ativação",
  "automations.addpnSuccessfullyAddedDescription": "A automação foi ativada com sucesso. Agora você pode usar todos os seus recursos e funcionalidades.",
  "automations.addpnSuccessfullyDeactivated": "Concluído - Desativação",
  "automations.addpnSuccessfullyDeactivatedDescription": "A automação foi desativada com sucesso. Ela não estará mais ativa em seu sistema.",
  "automations.ADP_INTEGRATION": "Integração com ADP",
  "automations.anythingMissing": "Está faltando algo?",
  "automations.automationBlackoutPeriodDeleted": "Período de bloqueio excluído",
  "automations.automationEntitlementByRoleDeleted": "Direito por função excluído",
  "automations.automationProbationPeriodDeleted": "Período de experiência excluído",
  "automations.automationSeniorityEntitlementDeleted": "Direito por antiguidade excluído",
  "automations.automationType": "Tipo de automação",
  "automations.autoRejectResponse": "Resposta de rejeição automática",
  "automations.BAMBOOHR_INTEGRATION": "Integração com BambooHR",
  "automations.BLACKOUT_PERIOD": "Período de Bloqueio",
  "automations.blackoutPeriod.autoRejectReasonDefault": "Durante nosso período atual de alto volume, toda a nossa equipe é essencial para manter as operações. Infelizmente, não podemos aprovar folgas, pois isso afetaria nossos objetivos de negócios e compromissos com clientes.",
  "automations.blackoutPeriod.createInProgress": "Criação do período de bloqueio em andamento",
  "automations.blackoutPeriod.createInProgressCompleted": "Criação do período de bloqueio concluída",
  "automations.blackoutPeriod.updateInProgress": "Atualização do período de bloqueio em andamento",
  "automations.blackoutPeriod.updateInProgressCompleted": "Atualização do período de bloqueio concluída",
  "automations.blackoutPeriodFromTo": "Período de bloqueio de {fromDate} <endDate>até {toDate}</endDate>",
  "automations.cantOpenAutomations": "Esta automação foi criada por outro usuário e você não pode editá-la.",
  "automations.createAutomation": "Criar automação",
  "automations.createPolicy": "Criar política",
  "automations.datePeriod": "{fromDate} <endDate>e {toDate}</endDate><recurring> a cada ano</recurring>",
  "automations.deactivateInPgoress": "Em Andamento - Desativação",
  "automations.deactivateInPgoressDescription": "Sua solicitação para desativar a automação está sendo processada. Por favor, aguarde enquanto atualizamos suas configurações",
  "automations.deactivateVisibilityWarn": "Desabilitar uma automação não cancelará a assinatura. Se você deseja desligar a assinatura, precisa clicar no botão \"Cancelar assinatura\".",
  "automations.deleteInProgress": "Excluindo a automação {automationName}",
  "automations.departmentVisibilityLevelInfo": "Os usuários verão informações de licença apenas dentro de seu próprio departamento. Os aprovadores verão informações de licença dentro de seu próprio departamento e de qualquer departamento para o qual sejam aprovadores, mesmo que não sejam membros. Os administradores sempre verão tudo.",
  "automations.discoveryMoreAddons": "Descubra Mais Complementos",
  "automations.EARLY_EMPLOYMENT_LEAVE_RESTRICTION": "Restrição de Licença no Início do Emprego",
  "automations.emptyViewButton": "Ver complementos disponíveis",
  "automations.emptyViewMessage": "Adicione dias de PTO com base na antiguidade, crie períodos de bloqueio, automatize o processo de aprovação ou negação, e muito mais com nossas automações, complementos e integrações.",
  "automations.emptyViewTitle": "Automatize suas tarefas demoradas e integre com sistemas externos",
  "automations.ENTITLEMENT_BY_ROLE": "Direito por Função",
  "automations.entitlementByRole.createInProgress": "Criação de direito por função em andamento",
  "automations.entitlementByRole.createInProgressCompleted": "Criação de direito por função concluída",
  "automations.entitlementByRole.days": "Dias",
  "automations.entitlementByRole.updateInProgress": "Atualização de direito por função em andamento",
  "automations.entitlementByRole.updateInProgressCompleted": "Atualização de direito por função concluída",
  "automations.filterDescription": "Você pode escolher se deseja aplicar esta automação universalmente a todos os usuários ou personalizá-la com base em departamentos, localizações ou etiquetas específicas.",
  "automations.LEAVE_DURATION_LIMIT": "Limite de Duração da Licença",
  "automations.leaveDurationLimit.autoRejectDescription": "O sistema aplicará automaticamente esses limites quando os usuários enviarem solicitações de licença, fornecendo uma explicação clara se a solicitação não estiver em conformidade com as regras estabelecidas.",
  "automations.leaveDurationLimit.autoRejectResponsePlaceholder": "Sua solicitação de licença não pôde ser aprovada, pois não está em conformidade com a política de duração de licença definida. Por favor, revise os requisitos de duração da licença e ajuste sua solicitação de acordo. Para obter mais assistência, entre em contato com seu administrador.",
  "automations.leaveDurationLimit.createInProgress": "Criando política de Limite de Duração de Licença",
  "automations.leaveDurationLimit.createInProgressCompleted": "Limite de Duração de Licença criado",
  "automations.leaveDurationLimit.deleted": "Limite de Duração de Licença excluído",
  "automations.leaveDurationLimit.duration": "Mín {minimumDuration, select, null {Não definido} undefined {Não definido} other {{minimumDuration} {units}}} / Máx {maximumDuration, select, null {Não definido} undefined {Não definido} other {{maximumDuration} {units}}}",
  "automations.leaveDurationLimit.durationDescription": "Insira o intervalo desejado de durações de licença (em dias ou horas) para garantir que as solicitações de licença estejam alinhadas com as políticas da empresa e requisitos operacionais.",
  "automations.leaveDurationLimit.policyDescription": "Esta seção permite definir os limites mínimos e/ou máximos de duração da licença para cada política.",
  "automations.leaveDurationLimit.summary": "Esta política impõe os limites mínimos e máximos de duração da licença definidos para os tipos de licença {leaveTypes}. Esses limites se aplicam de {startDate} a {endDate}. Esta regra é aplicada a {allUsers, plural, =1 {localizações / departamentos / etiquetas específicos} other {todos os usuários}}. As solicitações de licença que estiverem fora deste intervalo serão automaticamente rejeitadas com a mensagem especificada.",
  "automations.leaveDurationLimit.updateInProgress": "Atualizando política de Limite de Duração de Licença",
  "automations.leaveDurationLimit.updateInProgressCompleted": "Limite de Duração de Licença atualizado",
  "automations.leaveDurationLimitEmptyViewTitle": "Defina e aplique limites mínimos e máximos de duração de licença para usuários dentro de um departamento, localização ou qualquer grupo definido por uma etiqueta.",
  "automations.leaveTypesDescription": "Você também pode selecionar um ou mais tipos de licença aos quais deseja aplicar esta automação.",
  "automations.MAXIMUM_USERS_AWAY": "Máximo de Usuários Ausentes",
  "automations.maximumUsersAway.autoRejectDescription": "Os usuários receberão uma mensagem automática explicando por que sua solicitação de licença foi negada.",
  "automations.maximumUsersAway.createInProgress": "Criando política de Máximo de Usuários Ausentes",
  "automations.maximumUsersAway.createInProgressCompleted": "Nova política de Máximo de Usuários Ausentes criada",
  "automations.maximumUsersAway.definePolicyDescription": "Esta seção permite definir uma política que limita o número de usuários que podem estar de licença ao mesmo tempo.",
  "automations.maximumUsersAway.maxUsersDescription": "Insira o número máximo de usuários permitidos para tirar licença simultaneamente, e garantiremos que o sistema restrinja automaticamente as solicitações que excedam esse limite.",
  "automations.maximumUsersAway.updateInProgress": "Atualizando política de Máximo de Usuários Ausentes",
  "automations.maximumUsersAway.updateInProgressCompleted": "Atualização da política de Máximo de Usuários Ausentes concluída",
  "automations.maximumUsersAwayEmptyViewTitle": "Controle a porcentagem de usuários que podem estar de licença simultaneamente dentro de um departamento, localização ou qualquer grupo definido por uma etiqueta.",
  "automations.maxUsersAway.percentage": "{value}%",
  "automations.maxUsersAway.plural": "{value} usuários",
  "automations.maxUsersAway.singular": "{value} usuário",
  "automations.maxUsersAwayType": "Limitar por",
  "automations.maxUsersAwayTypeAbsolute": "Número",
  "automations.maxUsersAwayTypePercentage": "Porcentagem",
  "automations.maxUsersAwayValue": "Máximo de usuários ausentes",
  "automations.message": "Mensagem",
  "automations.messagePlaceholder": "Sua mensagem",
  "automations.myAddons": "Meus Complementos",
  "automations.OPEN_API": "API Aberta",
  "automations.OPEN_API_DOCUMENTATION": "Documentação da API Aberta",
  "automations.period": "Período",
  "automations.PROBATION_PERIOD": "Período de Experiência",
  "automations.probationPeriod.autoRejectReasonDefault": "Sua solicitação de licença foi automaticamente rejeitada porque você está na fase inicial de emprego, um período em que licenças não são permitidas. Esta política ajuda novos funcionários a se imergir completamente e se adaptar aos seus novos papéis e dinâmicas da equipe.",
  "automations.probationPeriod.createInProgress": "Criação do período de experiência em andamento",
  "automations.probationPeriod.createInProgressCompleted": "Criação do período de experiência concluída",
  "automations.probationPeriod.lengthDays": "Duração (dias)",
  "automations.probationPeriod.name": "Nome da Política de Experiência",
  "automations.probationPeriod.namePlaceholder": "Digite o nome da política de experiência",
  "automations.probationPeriod.settings.applyToMDescription": "Você pode escolher se deseja aplicar esta automação universalmente a todos os usuários | ou personalizá-la com base em departamentos, locais ou etiquetas específicas.",
  "automations.probationPeriod.settings.applyToMDescriptionTwo": "Você também pode selecionar um ou mais tipos de licença aos quais deseja aplicar esta automação.",
  "automations.probationPeriod.settings.lengthDaysDescription": "Digite o número de dias durante os quais novos funcionários serão restritos de tirar tipos específicos de licença, contando a partir da data de início do emprego.",
  "automations.probationPeriod.settings.nameDescription": "Esta seção permite que você defina uma política que restringe novos contratados de tirar licença durante seu período inicial de emprego.",
  "automations.probationPeriod.settings.nameDescriptionThree": "Garanta que os usuários recebam uma mensagem automática explicando por que sua solicitação de licença foi negada.",
  "automations.probationPeriod.settings.nameDescriptionTwo": "Digite o número de dias e calcularemos o período de experiência com base na data de emprego de cada usuário.",
  "automations.probationPeriod.settings.nameTitle": "Definir Política",
  "automations.probationPeriod.updateInProgress": "Atualização do período de experiência em andamento",
  "automations.probationPeriod.updateInProgressCompleted": "Atualização do período de experiência concluída",
  "automations.probationPeriodMessages": "Seu período de experiência dura até {date}",
  "automations.removeAddons": "Remover complementos",
  "automations.removeAddonsDescirptionForAdmin": "Tem certeza de que deseja cancelar a assinatura deste complemento? Você manterá acesso a todos os seus recursos até o final do seu período de faturamento atual. Depois disso, ele será desativado, afetando sua configuração de gerenciamento de licenças de acordo.",
  "automations.removeAddonsDescirptionForApprover": "Apenas usuários de nível Admin têm a capacidade de comprar ou cancelar assinaturas de complementos. Se forem necessários ajustes em suas assinaturas de complementos, entre em contato com seu administrador.",
  "automations.requestAnAutomation": "Solicitar um Complemento",
  "automations.requestAutomationTitle": "Não encontra um Complemento que você precisa?",
  "automations.reSubscribeAddOns": "Reassinar Complemento",
  "automations.reSubscribeAddons": "Reassinar Complemento",
  "automations.reSubscribeAddonsDescirptionForAdmin": "Tem certeza de que deseja reassinar este Complemento? Você será cobrado ${addonsPrice} por usuário por mês a partir da sua próxima fatura. A cobrança do primeiro mês será proporcional com base no número de dias em que o complemento for usado.",
  "automations.reSubscribeAddonsDescirptionForAdminInTrial": "Tem certeza de que deseja reassinar este Complemento? Ele permanecerá gratuito até o final do período de teste. Depois disso, será aplicada uma cobrança de ${addonsPrice} por usuário por mês.",
  "automations.reSubscribeInfo": "Renove sua assinatura de complemento e continue usando nossos recursos avançados. Clique em Reassinar para prosseguir.",
  "automations.SENIORITY_ENTITLEMENT": "Direito por Antiguidade",
  "automations.seniorityEntitlement.createInProgress": "Criação de direito por antiguidade em andamento",
  "automations.seniorityEntitlement.createInProgressCompleted": "Criação de direito por antiguidade concluída",
  "automations.seniorityEntitlement.periodsViewTable": "{days} dias após {years} anos",
  "automations.seniorityEntitlement.updateInProgress": "Atualização de direito por antiguidade em andamento",
  "automations.seniorityEntitlement.updateInProgressCompleted": "Atualização de direito por antiguidade concluída",
  "automations.showCaneledAddons": "Mostrar complementos cancelados",
  "automations.stepByStepHelpDesk": "Para orientações detalhadas sobre como configurar sua Automação <automationName></automationName>, consulte as instruções passo a passo disponíveis na <link>página da central de ajuda</link>.",
  "automations.subscripteToAddOns": "Assinar complementos",
  "automations.subscripteToAddons": "Assinar complementos",
  "automations.TEAM_LEAVE_BALANCING": "Máximo de Usuários Ausentes",
  "automations.unsubscribeForFreeAddonsInfo": "Você está atualmente inscrito neste complemento sem custo adicional, pois está incluído em seu plano. Se deseja cancelar a assinatura, clique no botão cancelar assinatura à direita.",
  "automations.unsubscribeInfo": "Você está atualmente inscrito neste complemento por ${addonsPrice} por usuário. Se deseja cancelar a assinatura, clique no botão cancelar assinatura à direita.",
  "automations.unsubscripteAddOns": "Cancelar assinatura do Complemento?",
  "automations.unsubscripteAddons": "Cancelar assinatura do Complemento?",
  "automations.update": "Atualizar automações",
  "automations.VISIBILITY": "Gerenciador de Visibilidade",
  "automations.visibility.createInProgress": "Limitação de visibilidade em andamento",
  "automations.visibility.createInProgressCompleted": "Limitação de visibilidade concluída",
  "automations.visibility.updateInProgress": "Atualizando complemento de visibilidade",
  "automations.visibility.updateInProgressCompleted": "Atualização do complemento de visibilidade concluída",
  "automations.visibilityForm.DEPARTMENT": "Seu departamento",
  "automations.visibilityForm.limitVisibility": "Limitar visibilidade para usuários",
  "automations.visibilityForm.visibilityLevel": "Usuários podem ver",
  "automations.visibilityForm.visibilityTypeValidationRequired": "Você deve selecionar pelo menos um tipo de visibilidade",
  "automations.visibilityInfo.settings.nameTitle": "Configurações de Visibilidade",
  "automations.visibilityManagerNotificationsInfo": "Calendários Compartilhados e Notificações criados antes da alteração das configurações de visibilidade devem ser excluídos manualmente se não estiverem em conformidade com as novas regras.",
  "automationsForm.advancedFilter.tooltipInfo": "Você pode filtrar os usuários aos quais esta automação será aplicada com base em departamentos, locais ou etiquetas.",
  "automationsForm.applyToAllUsers": "Aplicar a todos os usuários?",
  "automationsForm.applyToAllUsersTooltip": "Se definido como Sim, as configurações de limite de licença serão aplicadas em toda a organização. Se definido como Não, você pode especificar o limite para departamentos, localizações ou grupos específicos para um gerenciamento mais direcionado.",
  "automationsForm.autoRejectReason": "Resposta Automática de Rejeição",
  "automationsForm.autoRejectReasonTooltip": "Forneça ao usuário uma resposta automática sobre o motivo da rejeição do pedido de licença durante este período.",
  "automationsForm.autoRejectResponseRequired": "A resposta automática de rejeição é obrigatória",
  "automationsForm.blackoutPeriodShortText": "Um período em que os funcionários são proibidos de tirar licença",
  "automationsForm.maximumLeaveDuration": "Duração máxima da licença {units}",
  "automationsForm.maxmimumUsersAway.autoRejectResponsePlaceholder": "Infelizmente, sua solicitação de licença foi recusada. Atingimos nossa capacidade máxima permitida de licenças da equipe para este período, conforme nossa política de Limite de Porcentagem de Licenças da Equipe.",
  "automationsForm.maxmimumUsersAway.maxUsersAwayTypeTooltip": "Escolha como definir o máximo de ausências permitidas. Selecione 'Porcentagem' para definir a porcentagem de usuários que podem estar ausentes ao mesmo tempo, ou escolha 'Número' para especificar o número exato de usuários permitidos ausentes simultaneamente.",
  "automationsForm.maxmimumUsersAway.maxUsersAwayValueAbsoluteError": "Você inseriu {entered} usuários. O número máximo de usuários não pode exceder {max}. Por favor, insira um valor menor.",
  "automationsForm.maxmimumUsersAway.maxUsersAwayValuePercentageError": "Você inseriu {entered}%. A porcentagem não pode exceder 90%. Por favor, insira um valor menor.",
  "automationsForm.maxmimumUsersAway.nameRequired": "O nome é obrigatório",
  "automationsForm.maxUsersAwayValueRequired": "O valor máximo de usuários ausentes é obrigatório",
  "automationsForm.minimumLeaveDuration": "Duração mínima da licença {units}",
  "automationsForm.period": "Período",
  "automationsForm.periodRequired": "O período é obrigatório",
  "automationsForm.policy.name": "Digite o nome da política",
  "automationsForm.recurring": "Recorrer anualmente",
  "automationsForm.recurringTooltip": "Ative esta opção para que as configurações de limite de licença sejam renovadas automaticamente a cada ano, garantindo a aplicação consistente desses parâmetros anualmente sem reconfiguração manual.",
  "automationsForm.seniorityEntitlement.applyToAllUsersTooltip": "Você deseja aplicar este direito por antiguidade a todos os usuários em sua empresa ou a usuários em uma localização específica? Se você tiver regras de direito por antiguidade diferentes em localizações diferentes, deve criar várias regras de automação de direito por antiguidade, uma para cada localização.",
  "automationsForm.seniorityEntitlement.beginningOfTheFiscalYear": "Início do ano fiscal",
  "automationsForm.seniorityEntitlement.employeeStartDate": "Data de Início do Funcionário",
  "automationsForm.seniorityEntitlement.endOfTheFiscalYear": "Final do ano fiscal",
  "billing.applyingCoupon": "Aplicando cupom",
  "billing.applyingCouponAndResubscribing": "Aplicando cupom e reativando assinatura",
  "billing.cancelSubscription": "Cancelar Assinatura",
  "billing.cancelTrial": "Seu período de teste permanecerá ativo até {stripeCurrentPeriodEnd}. Para continuar usando o Vacation Tracker, por favor, insira suas informações de faturamento.",
  "billing.cancelTrialButton": "Cancelar Teste",
  "billing.cancelUpdateInProgress": "Cancelando...",
  "billing.linkPaymentMethod": "Link do Stripe",
  "billing.microsoft.billedLabel": "Faturado",
  "billing.microsoft.billingCycle": "O ciclo de faturamento atual termina em {date}",
  "billing.microsoft.billingCycleEnds": "Sua assinatura será cancelada após o término do ciclo de faturamento e você não poderá mais usar o Vacation Tracker.",
  "billing.microsoft.billingCycleLabel": "Ciclo de faturamento",
  "billing.microsoft.buyLessLicensesConfirmModalContent": "Você tem {currentNoOfLicenses} licenças. Você está prestes a remover {additionalLicences}. Tem certeza?",
  "billing.microsoft.buyMoreLicensesConfirmModalContent": "Você tem {currentNoOfLicenses} licenças. Você está prestes a comprar mais {additionalLicences}. Tem certeza?",
  "billing.microsoft.canceledSubscriptionMessage": "Sua assinatura será cancelada em ~15 minutos",
  "billing.microsoft.changePlanModalTitle": "Alterar o plano <strong>{plan}</strong> atual",
  "billing.microsoft.info": "Suas informações de pagamento são gerenciadas pela Microsoft. Visite o <adminCenterLink>Centro de Administração</adminCenterLink> para atualizar seu {labelInfo}",
  "billing.microsoft.licensesSideActionLabel": "Ver histórico",
  "billing.microsoft.licensesTitle": "Licenças",
  "billing.microsoft.pricingPlanLabel": "Plano de preços",
  "billing.microsoft.switchPlanModal.confirm": "Por favor, confirme que deseja mudar para este plano.",
  "billing.paymentInformation": "Informações de pagamento",
  "billing.paymentMethod": "Método de Pagamento",
  "billing.pricingPlanPeriodAnnual": "Anual",
  "billing.pricingPlanPeriodMonthly": "Mensal",
  "billing.pricingPlansText": "Esta seção exibe seu plano de assinatura. Você pode reassinar o plano que tinha anteriormente ou alterar o plano antes de reassinar.",
  "billing.pricingPlansTitle": "Planos de Preços",
  "billing.promoCodeErrorDescription": "O código promocional {code} não é válido.",
  "billing.resubscribeCTA": "Reassinar",
  "billing.resubscribingUpdateInProgress": "Reassinando...",
  "billing.seats": "Assentos",
  "billing.seats.buyLessSeatsConfirmModalContent": "Você tem {currentNoOfSeats} assentos. Você está prestes a remover {additionalSeats}.",
  "billing.seats.buyMoreSeatsConfirmModalContent": "Você tem {currentNoOfSeats} assentos. Você está prestes a comprar mais {additionalSeats}.",
  "billing.seats.canNotDowngrade": "Você não pode reduzir o número de assentos ({currentNumberOfSeats}) sem desativar um ou mais usuários atualmente ativos.",
  "billing.seats.info": "O número de assentos que você comprou.",
  "billing.seats.loadingUpcomingPaymentInformation": "Carregando informações de pagamento futuro...",
  "billing.seats.manage": "Gerenciar assentos",
  "billing.seats.noBillingChange": "Você está prestes a comprar {noOfSeats} {noOfSeats, plural, =1 {assento} other {assentos}}. Seu faturamento não será afetado.",
  "billing.seats.warningOfProrateAdditional": "Uma cobrança proporcional estimada de {proratedAmount} (excluindo impostos) será aplicada hoje.",
  "billing.seats.warningOfSeatsAnnualProrateAdditionalNewBucket": "Adicionar {noOfSeats} {noOfSeats, plural, =1 {assento} other {assentos}} aumentará sua próxima fatura para {amount} (excluindo impostos) a partir de {startDate}.",
  "billing.seats.warningOfSeatsProrateAdditionalNewBucketMonthly": "Comprar {noOfSeats} {noOfSeats, plural, =1 {assento} other {assentos}} afetará seu faturamento. Sua próxima fatura será de {amount} (excluindo impostos) em {startDate}",
  "billing.seats.warningOfSeatsRemovingAnnualProrateAdditionalNewBucket": "Remover {noOfSeats} {noOfSeats, plural, =1 {assento} other {assentos}} diminuirá sua próxima fatura para {amount} (excluindo impostos) a partir de {startDate}.",
  "billing.seats.warningOfSeatsRemovingProrateAdditionalNewBucketMonthly": "Remover {noOfSeats} {noOfSeats, plural, =1 {assento} other {assentos}} afetará sua cobrança. Sua próxima fatura será de {amount} (excluindo impostos) em {startDate}",
  "billing.subscriptionCanceled": "Assinatura cancelada",
  "billing.subscriptionExpired": "Assinatura não ativa",
  "billing.subscriptionExpiredDescription": "Sua assinatura foi cancelada ou expirou. Atualizações não são possíveis neste momento.",
  "billing.subscriptionExpiredText": "Sua assinatura expirou. Assine novamente para continuar usando o Vacation Tracker. Se você tiver algum problema ou dúvida, entre em contato com nosso {supportLink}.",
  "billing.SubscriptionPlan": "Plano de assinatura",
  "billing.subscriptionUpdateInProgress": "Atualização da assinatura em andamento",
  "billing.subscriptionUpdateInProgressDescription": "A operação anterior ainda está em andamento. Por favor, tente novamente em um minuto.",
  "billing.updateBillingInfo": "Atualizar Informações de Cobrança",
  "billing.updatedFaild": "Falha na atualização das informações de cobrança",
  "billing.updatedSuccessfully": "Informações de cobrança atualizadas",
  "billing.updateInProgress": "Atualizando informações de cobrança",
  "bot.announce.slack": "Olá <@{slackUserId}>\n\nMeu nome é Vacation Tracker. Eu vou ajudar você a reservar folgas através do Slack e também manterei todos informados sobre as próximas folgas em sua organização.\n\nDigite `/vacation` em qualquer lugar no Slack para começar ou confira meu tutorial no YouTube para saber mais:\nhttps://youtu.be/9e6J1AmfdwM",
  "bot.announce.slack.info": "Olá <@{slackUserId}>, meu nome é Vacation Tracker.",
  "bot.announce.slackCompany": "Olá Equipe,\n\nMeu nome é Vacation Tracker Bot!\n\nEu permito que você reserve suas folgas através do Slack e também mantenho todos informados sobre as próximas folgas dos membros da equipe. Vamos começar a rastrear algumas folgas!\n\nDigite `/vacation` em qualquer lugar no Slack ou confira o tutorial no YouTube para saber mais:\nhttps://youtu.be/9e6J1AmfdwM",
  "bot.message.about.commandDescription": "O Vacation Tracker simplifica o processo de solicitação e reserva de folgas da sua organização. Os membros da equipe podem reservar qualquer tipo de folga, ou qualquer tipo de dia de folga remunerado/não remunerado, sem sair do Slack e sem precisar lembrar de outro login ou senha.\n\nConfigure tipos de folga personalizados e permita que cada indivíduo em sua organização controle seu próprio tempo livre. O Vacation Tracker fornece a cada membro da equipe acesso ao Painel (http://app.vacationtracker.io) onde podem solicitar folga, verificar seu status atual de folga ou histórico, e ver as folgas programadas para toda a equipe.\n\nDe acordo com as configurações do Administrador ou Aprovador, os Membros da Equipe receberão notificações diárias/semanais sobre as próximas ausências. Sem complicações e sem surpresas desagradáveis.\n\nQuer saber mais sobre o Vacation Tracker? Nossa <https://vacationtracker.crisp.help/en/|Central de Ajuda> é sua segunda melhor opção depois da <https://vacationtracker.io/request-a-demo/|demonstração>.\n\nSe suas perguntas ou preocupações permanecerem sem resposta, entre em contato conosco a qualquer momento. Falar com nossos clientes é a melhor parte do nosso dia: hello@vacationtracker.io",
  "bot.message.accessExpired": "Oh não! Seu acesso ao Vacation Tracker expirou. Por favor, entre em contato com seu administrador para obter mais informações.",
  "bot.message.accruedDaysInfoForApprover": "ℹ️ Esta solicitação de folga é baseada em dias futuros acumulados, não na quantidade atual. <@{name}> tem {amount} dia(s) restante(s) hoje. Eles acumularão {accrued} em {earningDate}.",
  "bot.message.accruedDaysInfoForUser": "ℹ️ Sua solicitação de folga é baseada em dias futuros acumulados, não na quantidade atual. Você tem {amount} dia(s) restante(s) hoje, e acumulará {accrued} em {earningDate}.",
  "bot.message.approveLeave": "Aprovar Folga",
  "bot.message.approveLeaves.approvedRequest": "✅ Você aprovou a solicitação. Uma notificação será enviada para <@{userSlackId}> para informá-lo.",
  "bot.message.approveLeaves.approvedRequest.firstLevel": "✅ Você aprovou o primeiro nível da solicitação, nenhuma ação adicional é necessária nesta etapa. A solicitação de licença foi encaminhada para o segundo nível de aprovação.",
  "bot.message.approveLeaves.deniedRequest": "❌ Você negou a solicitação. Uma notificação será enviada para <@{userSlackId}> para informá-lo.",
  "bot.message.approver": "*Aprovador:*\n<@{approverSlackId}>",
  "bot.message.approver.autoApproved": "*Aprovador:*\nAprovação automática",
  "bot.message.approveToil": "Aprovar TOIL",
  "bot.message.backToOptions": "Voltar às opções",
  "bot.message.blackoutPeriod": "*Período de blackout*: {startDate} - {endDate} \n \n_{message}_",
  "bot.message.couldNotProcessRequest": "Ops! Não conseguimos processar sua solicitação.",
  "bot.message.date": "*Data*",
  "bot.message.dateEdited": "*Data [Editada]*",
  "bot.message.dates": "*Datas*",
  "bot.message.datesEdited": "*Datas [Editadas]*",
  "bot.message.deniedLeave": "Folga Negada",
  "bot.message.deniedLeaveDate": "*Data*\n{leaveDate}",
  "bot.message.deniedLeaveDates": "*Datas*\n{leaveDate}",
  "bot.message.deniedLeaveLeaveType": "*Tipo de folga:*\n{leaveTypeName}",
  "bot.message.deniedLeaveWithReason": "Olá, <@{userSlackId}> enviou a seguinte solicitação de folga:",
  "bot.message.deniedToil": "TOIL Negado",
  "bot.message.error.endDateCantBeInPast": "A data final não pode ser anterior à inicial, por favor selecione uma data no futuro",
  "bot.message.error.endTimeCantBeBeforeStartTime": "Parece que a hora de início que você selecionou é depois da hora de término.*\n *Por favor, selecione uma hora de início que seja antes da hora de término.",
  "bot.message.error.leaveRequestOverlap": "As datas que você escolheu se sobrepõem a uma folga existente.*\n *Por favor, selecione um período diferente.",
  "bot.message.error.negativeBallanceNotAllowed": "Saldo negativo não é permitido para este tipo de folga.",
  "bot.message.error.oneHoureIntervalNotAllowed": "O intervalo de tempo mais curto que você pode solicitar para este tipo de folga é de 4 horas (meio dia).",
  "bot.message.error.partTimeIsntAllowed": "Meio período não é permitido",
  "bot.message.error.reasonRequired": "O campo de motivo é obrigatório.",
  "bot.message.error.requestedMoreThanFullDay": "Você solicitou mais de um dia inteiro de folga em um dia útil.*\n *Por favor, selecione um dia diferente.",
  "bot.message.error.startDateCantBeInPast": "A data de início não pode estar no passado, por favor selecione uma data no futuro",
  "bot.message.error.userCannotBeFound": "O usuário selecionado não pode ser encontrado em sua organização.*\n *Por favor, entre em contato com o administrador da sua organização ou com o suporte do Vacation Tracker para obter mais informações.",
  "bot.message.error.userEndDateInFuture": "As datas solicitadas estão após a data de término de emprego do usuário.*\n *Por favor, entre em contato com o administrador da sua organização ou com o suporte do Vacation Tracker para obter mais informações.",
  "bot.message.error.userStartDateInFuture": "As datas solicitadas são anteriores à data de início de emprego do usuário. Por favor, entre em contato com o administrador da sua organização ou com o suporte do Vacation Tracker para obter mais informações.",
  "bot.message.error.zeroDaysLeaveRequest": "Você selecionou um feriado ou um dia não útil.*\n *Por favor, selecione um intervalo de datas válido.",
  "bot.message.feedback.chooseAnOption": "Escolha uma opção...",
  "bot.message.feedback.form": "Formulário de feedback",
  "bot.message.feedback.haveBeenSend": "O feedback foi enviado",
  "bot.message.feedback.howDoYouLikeOurApp": "O que você acha do nosso aplicativo?",
  "bot.message.feedback.info": "Dê-nos seu feedback :heart:",
  "bot.message.feedback.itMakesMeCry": "Me faz chorar 😢",
  "bot.message.feedback.itsOk": "Está ok 😐",
  "bot.message.feedback.likeIt": "Eu gosto 😀",
  "bot.message.feedback.loveIt": "Eu amo 😍",
  "bot.message.feedback.message": "Mensagem de feedback",
  "bot.message.feedback.nah": "Nah 🙁",
  "bot.message.feedback.response": "Se seu feedback requer uma resposta, por favor, insira seu e-mail para que possamos entrar em contato com você.",
  "bot.message.feedback.thanks": "Obrigado pelo feedback! Você sempre pode entrar em contato conosco usando support@vacationtracker.io.",
  "bot.message.feedback.youEmailAddress": "Seu endereço de e-mail",
  "bot.message.genericError": "Ops! Ocorreu um erro inesperado. 😱 Se este problema persistir, entre em contato com o suporte e forneça o seguinte código de erro: {requestId}.",
  "bot.message.goToTheMyProfileTab": "Vá para a aba Meu Perfil",
  "bot.message.helpMessage": "Olá, \nCom o Vacation Tracker, você pode realizar uma das seguintes ações:\n- Envie **Solicitar folga** em uma mensagem direta para solicitar uma nova folga usando o bot do Microsoft Teams.\n- Envie **Solicitar TOIL** em uma mensagem direta para solicitar um novo TOIL usando o bot do Microsoft Teams.\n- Visite a aba **Meu Perfil** para ver suas informações pessoais, como suas próximas folgas e feriados, e seu histórico de folgas.\n- Fixe a aba **Visão Geral** em um de seus canais para ver a visão geral da equipe, incluindo próximas folgas e feriados para sua equipe.\n- Visite o [**Painel Web do Vacation Tracker ⧉**]({dashboardLink}/connect?platform=microsoft&redirect=/app/dashboard).\nPara mais ajuda e tutoriais, visite nossa [**Central de Ajuda ⧉**](https://vacationtracker.crisp.help/en/). Você também pode agendar uma demonstração personalizada do Vacation Tracker neste link: [https://vacationtracker.io/request-a-demo](https://vacationtracker.io/request-a-demo).",
  "bot.message.holidays": "*Feriados:*",
  "bot.message.leaveDate": "*Data:*\n{leaveDate}",
  "bot.message.leaveDateEdited": "*Data [Editada]:*\n{leaveDate}",
  "bot.message.leaveDates": "*Datas:*\n{leaveDate}",
  "bot.message.leaveDatesEdited": "*Datas [Editadas]:*\n{leaveDate}",
  "bot.message.leaveDuration1": "*{word} solicitados para o ano atual:*\n{requestedThisYearNumber}",
  "bot.message.leaveDuration2": "*{word} restantes para o ano atual:*\n{currentYearNumber}",
  "bot.message.leaveDuration2Unlimited": "*{word} restantes para o ano atual:*\nIlimitado",
  "bot.message.leaveDuration3": "*{word} solicitados para o próximo ano:*\n{requestedNextYearNumber}",
  "bot.message.leaveDuration4": "*{word} restantes para o próximo ano:*\n{nextYearNumber}",
  "bot.message.leaveDuration4Unlimited": "*{word} restantes para o próximo ano:*\nIlimitado",
  "bot.message.leaveRemaining": "Folgas Restantes",
  "bot.message.leaveRemainingCurrent": "Folgas Restantes (Ano Atual)",
  "bot.message.leaveRemainingDays": "Dias de Folga Restantes",
  "bot.message.leaveRemainingDaysCurrent": "Dias de Folga Restantes (Ano Atual)",
  "bot.message.leaveRemainingDaysNext": "Dias de Folga Restantes (Próximo Ano)",
  "bot.message.leaveRemainingHours": "Horas de Folga Restantes",
  "bot.message.leaveRemainingHoursNext": "Horas de Folga Restantes (Próximo Ano)",
  "bot.message.leaveRemainingNext": "Folgas Restantes (Próximo Ano)",
  "bot.message.leaveRequest.FULL-DAY": "{leaveTypeName}",
  "bot.message.leaveRequest.HALF-DAY": "{leaveTypeName} (Meio Dia)",
  "bot.message.leaveRequest.halfDay": "(Meio Dia)",
  "bot.message.leaveRequest.hourly": "(Por Hora)",
  "bot.message.leaveRequest.leaveTypeName": "{leaveTypeName}",
  "bot.message.leaveRequest.leaveTypeNamePartTime": "{leaveTypeName} (Por Hora)",
  "bot.message.leaveRequest.ONE-HOUR": "{leaveTypeName} (Por Hora)",
  "bot.message.leaveRequestCreated.error": "A solicitação de folga não pode ser enviada",
  "bot.message.leaveRequestCreated.errorBadTime": "❌ A solicitação de folga não pode ser enviada porque é mais longa que as horas de trabalho, ou o intervalo de tempo é inválido.",
  "bot.message.leaveRequestCreated.errorDateAfter": "❌ A solicitação de folga não pode ser enviada porque a data de início que você selecionou é posterior à data de término. Certifique-se de que a data de início seja anterior à data de término e envie a solicitação novamente.",
  "bot.message.leaveRequestCreated.errorDateInThePast": "❌ A solicitação de folga não pode ser enviada porque a data de início que você selecionou está no passado. Certifique-se de que as datas selecionadas estejam no futuro e envie a solicitação novamente.",
  "bot.message.leaveRequestCreated.errorDateNotSelected": "❌ A solicitação de folga não pode ser enviada porque você não selecionou a data de término, ou a data é inválida.",
  "bot.message.leaveRequestCreated.errorNoStartDate": "❌ A solicitação de folga não pode ser enviada porque você não selecionou a data de início, ou a data é inválida.",
  "bot.message.leaveRequestCreated.errorNoStartTime": "❌ A solicitação de licença não pode ser enviada porque você não selecionou o horário de início ou o horário é inválido.",
  "bot.message.leaveRequestCreated.errorReasonRequired": "❌ A solicitação de licença não pode ser enviada, o motivo é obrigatório.",
  "bot.message.leaveRequested": "Licença Solicitada",
  "bot.message.leaveRequestedCurrent": "Licença Solicitada (Ano Atual)",
  "bot.message.leaveRequestedDays": "Dias de Licença Solicitados",
  "bot.message.leaveRequestedDaysCurrent": "Dias de Licença Solicitados (Ano Atual)",
  "bot.message.leaveRequestedDaysNext": "Dias de Licença Solicitados (Próximo Ano)",
  "bot.message.leaveRequestedHours": "Horas de Licença Solicitadas",
  "bot.message.leaveRequestedHoursCurrent": "Horas de Licença Solicitadas (Ano Atual)",
  "bot.message.leaveRequestedHoursNext": "Horas de Licença Solicitadas (Próximo Ano)",
  "bot.message.leaveRequestedNext": "Licença Solicitada (Próximo Ano)",
  "bot.message.leaveRequestNext.endDate": "Data de Término:",
  "bot.message.leaveRequestNext.info": "Ótimo! 🐨 Selecione o período e envie a solicitação.",
  "bot.message.leaveRequestNext.isHalfDay": "{leaveTypeName} (Meio Dia)",
  "bot.message.leaveRequestNext.leaveTime": "Horário da Licença:",
  "bot.message.leaveRequestNext.reasonOptional": "Motivo (Opcional)",
  "bot.message.leaveRequestNext.reasonRequired": "Motivo (Obrigatório)",
  "bot.message.leaveRequestNext.startDate": "Data de Início:",
  "bot.message.leaveRequestNext.title": "Solicitar licença",
  "bot.message.leaves": "*Licenças:*",
  "bot.message.leavesHistory": "Seu histórico de licenças:",
  "bot.message.leavesHistory.leaveMessageFullDay": "- Licença (*{leaveTypeName}*) de {start} até {end} *[{leaveStatus}]*\n",
  "bot.message.leavesHistory.leaveMessageOn": "- Licença (*{leaveTypeName}*) em {start} *[{leaveStatus}]*\n",
  "bot.message.leavesHistory.leaveMessageOnPartDay": "- Licença (*{leaveTypeName}*) em {start} das {partDayStartHour} às {partDayEndHour} *[{leaveStatus}]*\n",
  "bot.message.leavesHistoryNot": "Hm, parece que você ainda não tirou nenhuma licença.",
  "bot.message.leaveTypeName": "*Tipo de licença:*\n{leaveTypeName}",
  "bot.message.leaveTypeWithHalfDay": "*Tipo de licença:*\n{leaveTypeName} (meio-dia)",
  "bot.message.manageLeaves.cancelRequest": "Cancelar Solicitação",
  "bot.message.manageLeaves.confirmCancellation": "Por favor, confirme que deseja cancelar sua solicitação de licença",
  "bot.message.manageLeaves.summary": "Aqui está o resumo das suas próximas licenças e solicitações pendentes.",
  "bot.message.manageLeaves.summaryNoLeaves": "Você não tem licenças programadas",
  "bot.message.manageLeaves.upcomingLeaves": "*Você tem {upcomingLeaves} solicitação confirmada:*",
  "bot.message.manageLeaves.upcomingLeavesPlural": "*Você tem {upcomingLeaves} solicitações confirmadas:*",
  "bot.message.manageLeaves.waitingLeaves": "*Você tem {waitingLeaves} solicitação pendente:*",
  "bot.message.manageLeaves.waitingLeavesPlural": "*Você tem {waitingLeaves} solicitações pendentes:*",
  "bot.message.membersAway": "Membros ausentes",
  "bot.message.membersAway.holiday": "- *{holidayLocationName}* estará de folga para *{holidayName}* em {date}.\n",
  "bot.message.membersAway.leaveMessage": "- *{user}* está de licença{leaveTypeName} hoje {partDay}\n",
  "bot.message.membersAway.leaveMessage.fullDay": "- *{user}* está de licença{leaveTypeName} de {startDate} até {endDate}\n",
  "bot.message.membersAway.leaveMessageWasWillBe": "- *{user}* {verb} de licença{leaveTypeName} em {startDate}{partDay}\n",
  "bot.message.membersAway.leaveMessageWasWillBe.fullDay": "- *{user}* {verb} de licença{leaveTypeName} de {startDate} até {endDate}\n",
  "bot.message.membersAway.MEMBERS_AWAY_MONTH": "Membros da equipe que estão ausentes este mês.",
  "bot.message.membersAway.MEMBERS_AWAY_NEXT_MONTH": "Membros da equipe que estarão ausentes no próximo mês.",
  "bot.message.membersAway.MEMBERS_AWAY_NEXT_WEEK": "Membros da equipe que estarão ausentes na próxima semana.",
  "bot.message.membersAway.MEMBERS_AWAY_TODAY": "Membros da equipe que estão ausentes hoje.",
  "bot.message.membersAway.MEMBERS_AWAY_WEEK": "Membros da equipe que estão ausentes esta semana.",
  "bot.message.membersAway.multiHoliday": "- *{holidayLocationName}* estará de folga para *{holidayName}* de {start} até {end}.\n",
  "bot.message.membersAway.noLeaves": "Atualmente não há licenças programadas para este período em sua equipe 😎.",
  "bot.message.memersAway.noLeaves": "Atualmente não há licenças programadas para este período em sua equipe 😎.",
  "bot.message.menageLeaves": "Gerenciar Licenças",
  "bot.message.moreOptions": "Olá <@{username}>!\nComo posso ajudar você?",
  "bot.message.moreOptions.about": "Mais sobre mim :coala:",
  "bot.message.moreOptions.leaveHistoryTitle": "Histórico de Folgas",
  "bot.message.moreOptions.leavesAndHolidaysTitle": "Suas folgas e feriados:",
  "bot.message.moreOptions.membersAway": "Veja quais membros da equipe estão ausentes:",
  "bot.message.moreOptions.menageLeavesTitle": "Gerenciar Folgas",
  "bot.message.moreOptions.showHolidaysTitle": "Mostrar Feriados",
  "bot.message.myStatus.currentYear": "_Ano atual, até {yearEnd}_",
  "bot.message.myStatus.leaveTypeStatusEarned": "*{leaveTypeName}*\nRestante: {current}\nUsado: {used}\nTotal: {earned}",
  "bot.message.myStatus.leaveTypeStatusEarnedDays": "*{leaveTypeName}*\nDias restantes: {current}\nDias usados: {used}\nTotal de dias: {earned}",
  "bot.message.myStatus.leaveTypeStatusEarnedHours": "*{leaveTypeName}*\nHoras restantes: {current}\nHoras usadas: {used}\nTotal de horas: {earned}",
  "bot.message.myStatus.leaveTypeStatusUnlimited": "*{leaveTypeName}*\nDias restantes: Ilimitado\nDias usados: {usedDays}\nTotal de dias: Ilimitado",
  "bot.message.myStatus.nextYear": "_Próximo ano, de {startYear} até {endYear}_",
  "bot.message.myStatus.summary": "Aqui está o resumo das suas folgas para o ano atual e o próximo ano.",
  "bot.message.noLeavePolicies": "Parece que sua Localização não tem políticas de folga atribuídas. 🤔 Entre em contato com seu Administrador para obter mais informações.",
  "bot.message.notification.overAllowedLimitForCurrentAndFollowingYear": "O usuário está solicitando {leaveDurationTotal} dias de folga, que é {overLimitCurrentYear} dia acima do limite que o usuário tem para o ano atual, e {overLimitNextYear} dia acima do limite para o próximo ano.",
  "bot.message.notification.overAllowedLimitForCurrentAndFollowingYearDays": "O usuário está solicitando {leaveDurationTotal} {leaveDurationTotal, plural, =1 {dia} other {dias}}, que é {overLimitCurrentYear} {overLimitCurrentYear, plural, =1 {dia} other {dias}} acima do limite que o usuário tem para o ano atual, e {overLimitNextYear} {overLimitNextYear, plural, =1 {dia} other {dias}} acima do limite para o próximo ano.",
  "bot.message.notification.overAllowedLimitForCurrentAndFollowingYearHours": "O usuário está solicitando {leaveDurationTotal} {leaveDurationTotal, plural, =1 {hora} other {horas}}, que é {overLimitCurrentYear} {overLimitCurrentYear, plural, =1 {hora} other {horas}} acima do limite que o usuário tem para o ano atual, e {overLimitNextYear} {overLimitNextYear, plural, =1 {hora} other {horas}} acima do limite para o próximo ano.",
  "bot.message.notification.overAllowedLimitForCurrentYear": "O usuário está solicitando {leaveDurationCurrentYear} dia de folga, que é {overLimit} dia acima do limite permitido que este usuário tem para o ano atual.",
  "bot.message.notification.overAllowedLimitForCurrentYearDays": "O usuário está solicitando {leaveDurationCurrentYear} {leaveDurationCurrentYear, plural, =1 {dia} other {dias}}, que é {overLimitCurrentYear} {overLimitCurrentYear, plural, =1 {dia} other {dias}} acima do limite permitido que este usuário tem para o ano atual.",
  "bot.message.notification.overAllowedLimitForCurrentYearHours": "O usuário está solicitando {leaveDurationCurrentYear} {leaveDurationCurrentYear, plural, =1 {hora} other {horas}}, que é {overLimitCurrentYear} {overLimitCurrentYear, plural, =1 {hora} other {horas}} acima do limite permitido que este usuário tem para o ano atual.",
  "bot.message.notification.overAllowedLimitForCurrentYearPlural": "O usuário está solicitando {leaveDurationCurrentYear} dias de folga, que é {overLimit} dia acima do limite permitido que este usuário tem para o ano atual.",
  "bot.message.notification.overAllowedLimitForFollowingYear": "O usuário está solicitando {leaveDurationNextYear} dia de folga, que é {overLimit} dia acima do limite permitido que este usuário tem para o próximo ano.",
  "bot.message.notification.overAllowedLimitForFollowingYearDays": "O usuário está solicitando {leaveDurationNextYear} {leaveDurationNextYear, plural, =1 {dia} other {dias}}, que é {overLimitNextYear} {overLimitNextYear, plural, =1 {dia} other {dias}} acima do limite permitido que este usuário tem para o próximo ano.",
  "bot.message.notification.overAllowedLimitForFollowingYearHours": "O usuário está solicitando {leaveDurationNextYear} {leaveDurationNextYear, plural, =1 {hora} other {horas}}, que é {overLimitNextYear} {overLimitNextYear, plural, =1 {hora} other {horas}} acima do limite permitido que este usuário tem para o próximo ano.",
  "bot.message.notification.overAllowedLimitForFollowingYearPlural": "O usuário está solicitando {leaveDurationNextYear} dias de folga, que é {overLimit} dia acima do limite permitido que este usuário tem para o próximo ano.",
  "bot.message.notSignedUpError": "Parece que você não está cadastrado no Vacation Tracker. Por favor, navegue até [{link}]({utmLink}) e crie uma conta primeiro. Levará menos de um minuto!",
  "bot.message.notSignedUpError.slack": "Parece que você não está cadastrado no Vacation Tracker. Por favor, clique no botão abaixo para criar uma conta. Levará menos de um minuto!",
  "bot.message.on": "Em",
  "bot.message.openTheWebDashboard": "Abrir o Painel Web",
  "bot.message.partDay": "das {partDayStartHour} às {partDayEndHour}",
  "bot.message.probationPeriod": "*Período de experiência*: \nSeu período de experiência dura até {date} \n \nMotivo: _{message}_",
  "bot.message.reminder": "Ei equipe! Um lembrete amigável do bot do Vacation Tracker",
  "bot.message.reminderSnoozed": "Lembretes de solicitação adiados",
  "bot.message.requestLeave": "Por favor, selecione um dos tipos de folga disponíveis na lista abaixo e selecione as datas de início e fim da sua folga. Quando você clicar no botão Enviar solicitação, sua solicitação será enviada para aprovação.",
  "bot.message.requestLeave.error": "*Erro: {errorMessage}*",
  "bot.message.requestLeave.errorAutomation": "{errorMessage}",
  "bot.message.requestLeave.initialHelp": "Estou aqui para ajudar você a enviar uma solicitação de folga. 🐨 Por favor, selecione um Tipo de Folga e clique em Próximo.",
  "bot.message.requestLeave.leaveDuration1": "Isso é 1 dia de folga.",
  "bot.message.requestLeave.leaveDurationHalfDay": "Isso é meio dia de folga",
  "bot.message.requestLeave.leaveDurationYear1": "Isso é {year1Value} de folga.",
  "bot.message.requestLeave.leaveDurationYear1And2": "Isso é {year1Value} de folga para o ano atual e {year2Value} de folga para o próximo ano.",
  "bot.message.requestLeave.leaveDurationYear1And2Days": "Isso é {year1Value} {year1Value, plural, =1 {dia} other {dias}} de folga para o ano atual e {year2Value} {year2Value, plural, =1 {dia} other {dias}} de folga para o próximo ano.",
  "bot.message.requestLeave.leaveDurationYear1And2Hours": "Isso é {year1Value} {year1Value, plural, =1 {hora} other {horas}} de folga para o ano atual e {year2Value} {year2Value, plural, =1 {hora} other {horas}} de folga para o próximo ano.",
  "bot.message.requestLeave.leaveDurationYear1Days": "Isso é {year1Value} {year1Value, plural, =1 {dia} other {dias}} de folga.",
  "bot.message.requestLeave.leaveDurationYear1Hours": "Isso é {year1Value} {year1Value, plural, =1 {hora} other {horas}} de folga.",
  "bot.message.requestLeave.leaveDurationYear1PluralYear2": "Isso é {year1Days} dias de folga para o ano atual e {year2Days} dia de folga para o próximo ano.",
  "bot.message.requestLeave.leaveDurationYear1Year2AllPlural": "Isso é {year1Days} dias de folga para o ano atual e {year2Days} dias de folga para o próximo ano.",
  "bot.message.requestLeave.leaveDurationYear2": "Isso é {year2Value} de folga para o próximo ano.",
  "bot.message.requestLeave.leaveDurationYear2Days": "Isso é {year2Value} {year2Value, plural, =1 {dia} other {dias}} de folga para o próximo ano.",
  "bot.message.requestLeave.leaveDurationYear2Hours": "Isso dá {year2Value} {year2Value, plural, =1 {hora} other {horas}} de folga para o próximo ano.",
  "bot.message.requestLeave.leaveDurationYear2Plural": "Isso dá {year2Days} dias de folga para o próximo ano.",
  "bot.message.requestLeave.leaveDurationYear2PluralYear1": "Isso dá {year1Days} dia de folga para o ano atual e {year2Days} dias de folga para o próximo ano",
  "bot.message.requestLeave.selectCalendarStatus": "Selecione o status do calendário",
  "bot.message.requestLeave.selectCalendarStatus.info": "*Status do calendário*\nMostrar no seu calendário como",
  "bot.message.requestLeave.selectDate.from": "*Data de início*\nPor favor, selecione uma data de início",
  "bot.message.requestLeave.selectDate.info": "*Data de início*\nPor favor, selecione uma data de início",
  "bot.message.requestLeave.selectDate.oneDay": "*Data*\nPor favor, selecione uma data",
  "bot.message.requestLeave.selectDate.to": "*Data de término*\nPor favor, selecione uma data de término",
  "bot.message.requestLeave.selectLeaveType": "Selecione o tipo de folga",
  "bot.message.requestLeave.selectLeaveType.info": "*Tipo de folga*\nPor favor, selecione o tipo de folga",
  "bot.message.requestLeave.selectTimeFormat": "*AM/PM*",
  "bot.message.requestLeave.selectTimeRange": "Selecione o intervalo de tempo",
  "bot.message.requestLeave.selectTimeRange.end": "*Data de término*\nPor favor, selecione uma data de término",
  "bot.message.requestLeave.selectTimeRange.from": "*Hora de início*",
  "bot.message.requestLeave.selectTimeRange.info": "*Intervalo de tempo*\n Por favor, selecione o intervalo de tempo",
  "bot.message.requestLeave.selectTimeRange.range": "*Intervalo de tempo*",
  "bot.message.requestLeave.selectTimeRange.to": "*Hora de término*",
  "bot.message.requestLeave.submitted.end": ":tada: *{message}*.\n Até a próxima!",
  "bot.message.requestLeave.submitted.letTeamKnow": "P.S. Avise minha equipe se eu fui útil!",
  "bot.message.requestLeave.submitted.withApproval": "As datas que você solicitou foram enviadas para aprovação do seu gerente",
  "bot.message.requestLeave.submitted.withoutApproval": "As datas que você solicitou foram aprovadas automaticamente",
  "bot.message.requestLeaveModal.requestALeave": "Solicitar uma folga",
  "bot.message.requestLeaveModal.requestingLeave": "Solicitando folga",
  "bot.message.requestLeaveModal.sendFeedback": "Enviar feedback",
  "bot.message.requestLeaveModal.sendRequest": "Enviar solicitação",
  "bot.message.requestToil.initialHelp": "Estou aqui para ajudar você a enviar uma solicitação de TOIL. 🐨 Por favor, selecione um Tipo de Folga e clique em Próximo.",
  "bot.message.selectADate": "Selecione uma data",
  "bot.message.selectSubstituteApprover": "*Selecione o Aprovador Substituto*\nSelecione uma pessoa para gerenciar suas solicitações de folga enquanto você estiver ausente.",
  "bot.message.showHolidays.holidaysHalfDay": "- *{holidayLocationName}* estará de folga para *{holidayName}* em {date} (Meio-dia).\n",
  "bot.message.showHolidays.noUpcomingHolidays": "Não há feriados próximos em sua organização ou seu administrador ainda não definiu nenhum feriado.",
  "bot.message.showHolidays.one": "- *{holidayLocationName}* estará de folga para *{holidayName}* de {startDate} a {endDate}.\n",
  "bot.message.showHolidays.two": "- *{holidayLocationName}* estará de folga para *{holidayName}* em {date}.\n",
  "bot.message.showHolidays.upcomingHolidays": "Próximos feriados",
  "bot.message.slack.userChanged": "O usuário alterou esta solicitação",
  "bot.message.slashCommand.helpCommand1": "*Aqui estão mais algumas informações sobre mim:*",
  "bot.message.slashCommand.helpCommand2": "Confiado por mais de 1000 organizações em todo o mundo, *o Vacation Tracker permite que sua equipe acompanhe sem esforço quem está dentro e fora do escritório o tempo todo.*",
  "bot.message.slashCommand.helpCommand3": "Saiba mais sobre nossa história em nossa <https://vacationtracker.io/about|Página Sobre>.",
  "bot.message.slashCommand.helpCommand4": "Para guias passo a passo, visite nossa <https://vacationtracker.crisp.help/en/|Central de Ajuda>.",
  "bot.message.slashCommand.helpCommand5": "Para fazer login no Painel online, visite <{dashboardUrl}/connect?platform=slack>.",
  "bot.message.slashCommand.helpCommand6": "E se você precisar de nós em algum momento, estamos a apenas um e-mail de distância em hello@vacationtracker.io.",
  "bot.message.slashCommand.noPermissions": "Sinto muito ser o portador de más notícias, mas parece que você não tem permissão para acessar o Vacation Tracker. 😟 Por favor, entre em contato com seu Administrador.",
  "bot.message.slashCommand.title": "Olá <@{userId}>! Quer tirar um tempo de folga? Deixe-me ajudar!",
  "bot.message.sorryLetsSpokePriveate": "Hmm, vamos conversar em particular. 🤫",
  "bot.message.sorryYouAreNotActive": "Desculpe, sua conta está inativa",
  "bot.message.sorryYouAreNotExist": "Desculpe, sua conta não foi encontrada",
  "bot.message.submittedLeaveRequest": "Olá, <@{userSlackId}> enviou a seguinte solicitação de folga:",
  "bot.message.submittedLeaveRequestReminder": "Olá, apenas um lembrete de que <@{name}> enviou uma solicitação que requer sua atenção.",
  "bot.message.submittedLeaveRequestReminderExpiration": "⏰ Por favor, observe que esta solicitação expirará em {days} dia(s). Este é o lembrete {reminderNo} de 3.",
  "bot.message.substituteApproverPlaceholder": "🔍 Nenhum",
  "bot.message.unableToRequestLeave": "Não é possível solicitar licença.",
  "bot.message.unknownCommand": "Desculpe, não entendo esse comando.\nDigite **help** para ver a lista de comandos disponíveis.",
  "bot.microsoft.whatsNewInfoMessages": "Esta é uma mensagem de anúncio do **Vacation Tracker**. Você está recebendo porque é um administrador ou um aprovador.",
  "bot.notification.accruedDaysInfoForApprover": "ℹ️ Esta solicitação de licença é baseada em dias futuros acumulados, não na quantidade atual. @{name} tem {amount} dia(s) restante(s) hoje. Eles acumularão {accrued} em {earningDate}.",
  "bot.notification.accruedDaysInfoForUser": "ℹ️ Sua solicitação de licença é baseada em dias futuros acumulados, não na quantidade atual. Você tem {amount} dia(s) restante(s) hoje, e acumulará {accrued} em {earningDate}.",
  "bot.notification.adminToApprovers": "Você foi nomeado como o novo Aprovador para {teamName}, já que a conta do Aprovador anterior foi desativada.",
  "bot.notification.approverCard.approved": "✅ @{approverName} aprovou<firstLevelOfApproval> o primeiro nível de</firstLevelOfApproval> a solicitação, nenhuma ação adicional é necessária<firstLevelOfApproval> nesta etapa. A solicitação de licença foi encaminhada para o segundo nível de aprovação</firstLevelOfApproval>.",
  "bot.notification.approverCard.autoApproved": "A solicitação foi aprovada automaticamente, nenhuma ação adicional é necessária.",
  "bot.notification.approverCard.cancelled": "⚠️ Parece que {requesterName} mudou de ideia. A solicitação foi cancelada.",
  "bot.notification.approverCard.cancelledLeave": "Olá, {requesterName} cancelou sua licença.",
  "bot.notification.approverCard.cancelledTitle": "⚠️ Esta licença foi cancelada por um aprovador.",
  "bot.notification.approverCard.denied": "❌ @{approverName} negou a solicitação, nenhuma ação adicional é necessária.",
  "bot.notification.approverCard.deniedEdited": "❌ @{approverName} negou a solicitação editada, nenhuma ação adicional é necessária.",
  "bot.notification.approverCard.editedByApprover": "@{approverName} editou uma solicitação de licença enviada por @{requesterName}",
  "bot.notification.approverCard.editedByApproverForUser": "@{approverName} editou uma solicitação de licença para @{requesterName}.",
  "bot.notification.approverCard.info": "Adicione um motivo para esta aprovação ou negação",
  "bot.notification.approverCard.infoSecondLevel": "Você é o segundo nível de aprovação para esta solicitação. Ela já foi aprovada por @{approverName}.",
  "bot.notification.approverCard.notActive": "⚠️ Esta solicitação de licença não está mais ativa.",
  "bot.notification.assignedAsApproverCard.infoMultilevel.level1": "Você foi designado como aprovador de primeiro nível para o departamento {departmentName}. Clique no botão 'Guia do Aprovador' abaixo para saber o que os aprovadores podem fazer.",
  "bot.notification.assignedAsApproverCard.infoMultilevel.level1and2": "Você foi designado como aprovador de primeiro e segundo nível para o departamento {departmentName}. Clique no botão 'Guia do Aprovador' abaixo para saber o que os aprovadores podem fazer.",
  "bot.notification.assignedAsApproverCard.infoMultilevel.level2": "Você foi designado como aprovador de segundo nível para o departamento {departmentName}. Clique no botão 'Guia do Aprovador' abaixo para saber o que os aprovadores podem fazer.",
  "bot.notification.assignedAsApproverCard.infoSinglelevel": "Você foi designado como aprovador para o departamento {departmentName}. Clique no botão 'Guia do Aprovador' abaixo para saber o que os aprovadores podem fazer.",
  "bot.notification.assignedAsApproverCard.openLeaveRequests": "Existem {numberOfOpenLeaveRequests} {numberOfOpenLeaveRequests, plural, =1 {solicitação aberta de licença} other {solicitações abertas de licença}} que requerem sua atenção. Clique no botão 'Visitar Painel' abaixo para revisá-las. Não se preocupe, enviaremos notificações para novas solicitações de licença assim que forem enviadas.",
  "bot.notification.assignedAsApproverCard.title": "Você foi designado como aprovador",
  "bot.notification.assignedAsApproverCard.tutorialButton": "Guia do Aprovador",
  "bot.notification.changeOutOfOfficeMessage": "Editar modelo",
  "bot.notification.daily.info": "É hora da sua atualização diária! 🐨 Aqui estão as próximas licenças:",
  "bot.notification.daily.noLeaves.isNotForToday": "Não há licenças programadas para {dayName}",
  "bot.notification.daily.noLeaves.isToday": "Não há licenças programadas para hoje",
  "bot.notification.holiday.plural": "- As localidades **{locationNames}** estarão ausentes para **{holidayName}** {holidayPeriodText}",
  "bot.notification.holiday.singular": "- A localidade **{locationNames}** estará ausente para **{holidayName}** {holidayPeriodText}",
  "bot.notification.infoFirstLevel": "Você é o primeiro nível de aprovação para esta solicitação.",
  "bot.notification.leave.info.isInTheFuture": "estará de licença{leaveTypeText} {leavePeriodText}",
  "bot.notification.leave.info.isInThePast": "esteve de licença{leaveTypeText} {leavePeriodText}",
  "bot.notification.leave.info.isToday": "está de licença{leaveTypeText} {leavePeriodText}",
  "bot.notification.leaveDates.partDay": "{start} das {partDayStartHour} às {partDayEndHour}",
  "bot.notification.leavePeriod.fullDay": "no dia {day}",
  "bot.notification.leavePeriod.halfDay": "no dia {day} (Meio-dia)",
  "bot.notification.leavePeriod.moreDays": "de {start} até {end}",
  "bot.notification.leavePeriod.partDay": "no dia {start} das {partDayStartHour} às {partDayEndHour}",
  "bot.notification.leavePolicyInfo": "*Observação: este usuário ou este tipo de licença tem uma cota de 0 definida para a licença solicitada.*",
  "bot.notification.leaveRequestError.general": "Erro com uma solicitação de licença",
  "bot.notification.leaveRequestError.notApprover": "O fluxo de aprovação para o seu departamento foi alterado. Entre em contato com seu administrador se tiver dúvidas adicionais.",
  "bot.notification.leaveRequestError.notOpen": "A solicitação de licença foi {leaveRequestStatus}",
  "bot.notification.leaveRequestError.notOpenIsSameAppover": "A solicitação de licença foi {leaveRequestStatus}",
  "bot.notification.leaveRequestExpired": "Sua solicitação expirou",
  "bot.notification.leaveRequestExpiredGeneral": "A solicitação expirou",
  "bot.notification.leaveTypeText.isPartDay": "({leaveTypeName} - Meio-dia)",
  "bot.notification.oooDefaultMessage": "Olá,\n\nObrigado pela sua mensagem. Atualmente estou fora do escritório e não poderei responder a e-mails até {firstWorkingDay}.\n\nFarei o possível para responder ao seu e-mail prontamente após meu retorno.\n\nObrigado pela sua compreensão.\n\nAtenciosamente",
  "bot.notification.processingApproval": "Processando aprovação...",
  "bot.notification.processingDenial": "Processando negação...",
  "bot.notification.processingResend": "Reenviando solicitação de licença...",
  "bot.notification.processingSnooze": "Adiando lembretes...",
  "bot.notification.reconnectCalendar": "Reconectar",
  "bot.notification.reminderSent": "Lembrete de solicitação de licença enviado",
  "bot.notification.reminderSentToil": "Lembrete de solicitação de TOIL enviado",
  "bot.notification.requesterCard.addedLeave": "Uma licença foi criada para você",
  "bot.notification.requesterCard.addedLeaveBy": "Adicionado por {approverName}",
  "bot.notification.requesterCard.addedLeaveToil": "Um TOIL foi criado para você",
  "bot.notification.requesterCard.approvedTitle": "Sua solicitação de licença foi aprovada",
  "bot.notification.requesterCard.approvedTitleToil": "Sua solicitação de TOIL foi aprovada",
  "bot.notification.requesterCard.approverInfo": "Aprovado por {approverName}",
  "bot.notification.requesterCard.autoApprovedInfo": "A solicitação foi aprovada automaticamente.",
  "bot.notification.requesterCard.blackoutPeriod": "Período: {startDate} - {endDate}",
  "bot.notification.requesterCard.blackoutPeriodReason": "Motivo: {message}",
  "bot.notification.requesterCard.blackoutPeriodTitle": "Período de Bloqueio",
  "bot.notification.requesterCard.cancelled": "⚠️ Cancelado",
  "bot.notification.requesterCard.cancelledEdited": "⚠️ Alterações foram feitas na solicitação original.",
  "bot.notification.requesterCard.cancelledEditedByRequester": "@{requesterName} editou esta solicitação de licença.",
  "bot.notification.requesterCard.cancelledTitle": "⚠️ Sua licença foi cancelada por um aprovador.",
  "bot.notification.requesterCard.deniedBy": "Sua solicitação de licença foi aprovada",
  "bot.notification.requesterCard.deniedEditedDescription": "Sua solicitação de licença editada foi negada, no entanto, sua solicitação original permanece ativa.",
  "bot.notification.requesterCard.deniedEditedTitle": "Edição da solicitação de licença negada.",
  "bot.notification.requesterCard.deniedOpenDescription": "Tanto a sua solicitação de licença original quanto a editada foram negadas.",
  "bot.notification.requesterCard.deniedOpenTitle": "Solicitação de licença negada",
  "bot.notification.requesterCard.deniedTitle": "Sua solicitação de licença foi negada",
  "bot.notification.requesterCard.edited": "@{requesterName} editou sua solicitação previamente aprovada e está reenviando para aprovação:",
  "bot.notification.requesterCard.editedOpen": "@{requesterName} editou e reenviou sua solicitação de licença.",
  "bot.notification.requesterCard.maximumUsersAwayTitle": "Máximo de usuários ausentes",
  "bot.notification.requesterCard.moreDays": "❌ Você solicitou mais dias do que o disponível.",
  "bot.notification.requesterCard.moreThanFullDay": "❌ A solicitação de licença não pode ser enviada porque você solicitou mais de um dia inteiro de licença em um dia de trabalho.",
  "bot.notification.requesterCard.noWorkingDays": "❌ A solicitação de licença não pode ser enviada porque você não incluiu nenhum dia útil. Certifique-se de revisar os dias de trabalho da sua equipe antes de enviar a solicitação.",
  "bot.notification.requesterCard.originalLeaveRequest": "Solicitação de licença original:",
  "bot.notification.requesterCard.overlap": "❌ A solicitação de licença não pode ser enviada porque as datas que você solicitou se sobrepõem às datas de uma solicitação de licença existente.",
  "bot.notification.requesterCard.probationPeriod": "Seu período de experiência dura até {date}",
  "bot.notification.requesterCard.probationPeriodReason": "Motivo: {message}",
  "bot.notification.requesterCard.probationPeriodTitle": "Período de Experiência",
  "bot.notification.requesterCard.requestInfo": "Sua solicitação está a caminho do aprovador. Até a próxima! 🐨",
  "bot.notification.requesterCard.requestPossibleActions": "Para editar ou cancelar a solicitação, visite o Painel",
  "bot.notification.requesterCardapp.tryAnotherDates": "Tente outras datas",
  "bot.notification.requesterInfo": "Olá, @{userName} enviou uma solicitação para sua revisão.",
  "bot.notification.requesterInfoAutoApproved": "Olá, @{userName} enviou uma solicitação.",
  "bot.notification.requesterInfoEdited": "@{userName} editou e reenviou sua solicitação.",
  "bot.notification.requesterInfoEditedByApprover": "@{approverName} editou uma solicitação enviada por @{userName}",
  "bot.notification.requesterInfoEditedForUser": "Sua solicitação foi editada.",
  "bot.notification.requesterInfoEditedForUserByApprover": "O aprovador @{approverName} fez alterações em sua solicitação.",
  "bot.notification.requesterInfoEditedOpen": "@{userName} editou e reenviou sua solicitação.",
  "bot.notification.setOutOfOffice": "Sua licença {leaveTypeName} está programada para começar em menos de {days} {days, plural, =1 {dia} other {dias}} em {leavePeriodStart}. Gostaria de configurar uma mensagem automática de ausência para ser enviada durante sua ausência?",
  "bot.notification.setOutOfOfficeButton": "Configurar mensagem de ausência",
  "bot.notification.setOutOfOfficeFallbackText": "Configurar ausência para sua {leaveTypeName}",
  "bot.notification.slack.additional": "... e mais.\nLicenças adicionais não puderam ser exibidas devido ao limite de tamanho. Por favor, abra o Painel para uma visão geral completa.",
  "bot.notification.slack.approved": "✅ <@{approverName}> aprovou<firstLevelOfApproval> o primeiro nível de</firstLevelOfApproval> a solicitação, nenhuma ação adicional é necessária<firstLevelOfApproval> nesta etapa. A solicitação de licença foi encaminhada para o segundo nível de aprovação</firstLevelOfApproval>.",
  "bot.notification.slack.approverChanged": "Olá, o aprovador <@{approverSlackId}> alterou uma solicitação de licença.",
  "bot.notification.slack.approverChangedDatesOfLeaveReuquest": "O aprovador <@{approverSlackId}> alterou as datas da solicitação",
  "bot.notification.slack.approverChangedLeaveReqest": "Olá, o aprovador <@{approverSlackId}> alterou a seguinte solicitação de licença para <@{userSlackId}>:",
  "bot.notification.slack.approverChangedYour": "O aprovador <@{approverSlackId}> alterou sua solicitação de licença.",
  "bot.notification.slack.approverHasDeniedThisRequest": "<@{approverSlackId}> negou esta solicitação, nenhuma ação adicional é necessária",
  "bot.notification.slack.calendar": ":calendar: |  *{dayName}*  | :calendar: ",
  "bot.notification.slack.cancelled": "Olá, <@{userName}> cancelou sua licença.",
  "bot.notification.slack.cancelledByApprover": "Sua licença foi cancelada por um aprovador",
  "bot.notification.slack.cancelledInfo": "Esta folga foi cancelada por um aprovador",
  "bot.notification.slack.created": "Uma folga foi criada para você por um aprovador",
  "bot.notification.slack.holiday.plural": "- Locais *{locationNames}* estarão ausentes para *{holidayName}* {holidayPeriodText}",
  "bot.notification.slack.holiday.singular": "- Local *{locationNames}* estará ausente para *{holidayName}* {holidayPeriodText}",
  "bot.notification.slack.holidays": ":airplane: *Feriados: *",
  "bot.notification.slack.infoSecondLevel": "Você é o segundo nível de aprovação para esta solicitação. Ela já foi aprovada por <@{approverName}>.",
  "bot.notification.slack.leave.info.isInTheFuture": "- *{userName}* estará de folga{leaveTypeText} {leavePeriodText}",
  "bot.notification.slack.leave.info.isInThePast": "- *{userName}* estava de folga{leaveTypeText} {leavePeriodText}",
  "bot.notification.slack.leave.info.isToday": "- *{userName}* está de folga{leaveTypeText} {leavePeriodText}",
  "bot.notification.slack.leaveRequestCreated": "Olá, <@{userName}> enviou uma solicitação de folga.",
  "bot.notification.slack.leaveRequestInfo": "Olá, <@{userName}> por favor confirme as informações da solicitação de folga.",
  "bot.notification.slack.leaveRequestInfoDateFrom": "Data de início: {date}",
  "bot.notification.slack.leaveRequestInfoDateTo": "Data de término: {date}",
  "bot.notification.slack.leaveRequestInfoError": "Não foi possível sugerir uma folga a partir dos dados fornecidos",
  "bot.notification.slack.leaveRequestInfoLeaveType": "Tipo de folga: {leaveType}",
  "bot.notification.slack.leaveRequestInfoReason": "Motivo: {reason}",
  "bot.notification.slack.leaveRequestInfoToil": "Olá, <@{userName}> por favor, confirme as informações da solicitação de TOIL.",
  "bot.notification.slack.leaveRequestSuggestionCancelled": "Formulário de solicitação de folga cancelado ❌",
  "bot.notification.slack.leaveRequestSuggestionCancelledToil": "Formulário de solicitação de TOIL cancelado ❌",
  "bot.notification.slack.leaves": ":loud_sound: *Folgas: *",
  "bot.notification.slack.reason": "*Motivo*\n{reason}",
  "bot.notification.slack.reasonEdited": "*Motivo [Editado] *\n{reason}",
  "bot.notification.slack.requestedBy": "*Solicitado por:*\n<@{userSlackId}>",
  "bot.notification.slack.statusApproved": "*Status:*\n✅ Aprovado",
  "bot.notification.slack.statusAutoApproved": "*Status*\nAprovado automaticamente",
  "bot.notification.slack.statusDenied": "*Status*\n❌ Negado",
  "bot.notification.slack.statusInfo": "👋 Agora podemos definir seu status do Slack automaticamente para este tipo de folga! Clique no botão Ativar para permitir que atualizemos seu status quando sua folga começar. Nós o limparemos quando terminar.",
  "bot.notification.slack.thisLeaveHasBeenCancelledByAppover": "Esta folga foi cancelada por um aprovador. Veja abaixo mais informações.",
  "bot.notification.slack.title": "Notificação: \"_{title}_\"",
  "bot.notification.slack.userCancelledLeaveRequest": "Parece que <@{userSlackId}> mudou de ideia. A solicitação foi cancelada.",
  "bot.notification.slack.warning": ":warning: *Aviso: {infoMessage}*",
  "bot.notification.slack.yourLeaveHasBeenCancelledByAppover": "Sua folga foi cancelada por um aprovador. Veja abaixo mais informações.",
  "bot.notification.slack.yourLeaveRequestApproved": "Sua solicitação foi aprovada",
  "bot.notification.slack.yourRequestHasBeenDenied": "Sua solicitação foi negada",
  "bot.notification.snoozeOutOfOfficeButton": "Não me pergunte novamente",
  "bot.notification.snoozeOutOfOfficeMessage": "Não perguntaremos mais para você configurar o status Fora do escritório para {leaveTypeName}. Você sempre pode alterar essa configuração na página de Configurações da Conta no Painel Web do Vacation Tracker",
  "bot.notification.submittedLeaveRequestReminder": "Olá, apenas um lembrete de que @{name} enviou uma solicitação que requer sua atenção.",
  "bot.notification.substituteApproverDeleted": "Sua função como Aprovador Substituto para as datas {startDate} - {endDate} foi removida.",
  "bot.notification.substituteApproverSelected": "Você foi selecionado por {creatorName} como aprovador substituto para o(s) departamento(s) {teamNames} em nome de {approverName} durante sua ausência. Como aprovador substituto, você será responsável por revisar e agir sobre as solicitações de folga enviadas para {approverName} de {startDate} a {endDate}.",
  "bot.notification.substituteApproverUpdated": "Seu período como Aprovador Substituto foi atualizado de {startDate} para {endDate}.",
  "bot.notification.title": "Notificação: {title}",
  "bot.notification.weekly.current.info": "Olá!\n 🐨 Estou aqui para atualizá-lo sobre as folgas programadas para a semana atual ({start} - {end})",
  "bot.notification.weekly.next.info": "Olá!\n 🐨 Estou aqui para atualizá-lo sobre as folgas programadas para a próxima semana ({start} - {end})",
  "bot.notification.weekly.noHolidays": "Não há feriados para esta semana.",
  "bot.notification.weekly.noLeaves": "Não há folgas programadas para esta semana.",
  "bot.slack.whatsNewInfoMessages": "Esta é uma mensagem de anúncio do *Vacation Tracker*. Você está recebendo isso porque é um administrador ou aprovador.",
  "bot.slackFuckedUpAgain": "O Slack está fazendo alterações novamente, por favor, entre em contato com nosso suporte para corrigir os bugs do Slack.",
  "bot.somethingNewFromVacationTracker": "Algo novo do Vacation Tracker",
  "bot.welcomeMessage.furtherAssistance": "Está se sentindo sobrecarregado com todas essas informações? Não se preocupe! Você não precisa memorizar tudo isso. Basta digitar 'Ajuda' em nosso chat, e nosso bot enviará isso para você. Para mais assistência, sinta-se à vontade para conversar com nossa equipe de Suporte ao Cliente através do nosso site ou visite nossa Central de Ajuda.",
  "bot.welcomeMessage.goToMyProfile": "Ir para a aba Meu Perfil",
  "bot.welcomeMessage.greeting": "Saudações {name}, eu sou o Bot do Vacation Tracker! Estou aqui para ajudá-lo a agendar folgas através do Microsoft Teams, além de manter todos atualizados sobre quem está dentro e fora do escritório. Sem mais delongas, vamos começar a rastrear essas folgas!",
  "bot.welcomeMessage.happyVacationing": "Boas férias 🌴",
  "bot.welcomeMessage.howToAccessTabs": "Como acessar as Abas",
  "bot.welcomeMessage.noCompany": "Olá, \n\n Obrigado por instalar o bot e a aba do Vacation Tracker no Microsoft Teams. Como última etapa, acesse nosso site para completar o registro e configurar as configurações da sua organização: [{dashboardLink}]({utmDashboardLink})",
  "bot.welcomeMessage.noCompanyInfo": "Se tiver alguma dúvida, sinta-se à vontade para iniciar um chat com nossa equipe de suporte ao cliente em nosso site ou visite nossa central de ajuda.",
  "bot.welcomeMessage.openWebDashboardInfo": "As mesmas informações também estão acessíveis através do nosso painel web. Basta clicar no botão 'Abrir Painel Web' abaixo e fazer login com sua conta Microsoft para acessá-lo.",
  "bot.welcomeMessage.requestLeaveInfo": "Para começar, simplesmente me envie uma mensagem direta com 'Solicitar folga' ou assista a este breve tutorial sobre o Vacation Tracker:",
  "bot.welcomeMessage.restInfo": "Além do bot, você também pode enviar solicitações de folga, verificar suas cotas de folga, visualizar calendários da equipe e muito mais no Microsoft Teams usando as abas:",
  "bot.welcomeMessage.title": "Olá do Vacation Tracker",
  "botUpdatePermissions.thanks": "Obrigado! Tudo está configurado. Você pode fechar o painel.",
  "button.no": "Não",
  "button.yes": "Sim",
  "calendar.all": "Todos",
  "calendar.calendar": "Calendário",
  "calendar.filterByLocation": "Filtrar por Localização:",
  "calendar.filterByTeam": "Filtrar por Departamento:",
  "calendar.forAllUsers": "Para todos os usuários",
  "calendar.getCalendarLink": "Obter link do calendário",
  "calendar.halfdayTags": "Folgas e Feriados menores que um dia inteiro são representados por blocos listrados.",
  "calendar.halfdayTagsShort": "Folgas menores que um dia",
  "calendar.labels": "Rótulo(s)",
  "calendar.listView": "Visualização em lista",
  "calendar.location": "Localização(ões)",
  "calendar.monthView": "Visualização mensal",
  "calendar.sync.calendarAlreadyConnected": "Este calendário já está conectado",
  "calendar.sync.calendarNotFound": "Não foi possível encontrar o calendário. Ele pode ter sido excluído ou não está mais disponível. Por favor, verifique suas configurações de calendário.",
  "calendar.sync.canNotUpdateSharedCalendar": "Você conectou o calendário {calendarName} do {provider}. Não é permitido alterar o calendário. Para mudar o calendário conectado, exclua esta integração e crie uma nova.",
  "calendar.sync.confirmDelete": "Por favor, confirme que deseja excluir o calendário <strong>{name}</strong>.",
  "calendar.sync.connect": "Conecte seu Calendário {provider}",
  "calendar.sync.connectCalendar": "Conectar Calendário",
  "calendar.sync.connectCalendarCompletePlanyOnly": "APENAS PLANO COMPLETO",
  "calendar.sync.connectCalendarIcalOptionLabel": "Qualquer calendário (via link iCal)",
  "calendar.sync.connectCalendarOptionLabel": "calendário {platform}",
  "calendar.sync.connectInfo": "Conecte sua conta {provider} e selecione um calendário compartilhado onde todas as folgas aprovadas serão inseridas e sincronizadas automaticamente.",
  "calendar.sync.createICalButton": "Criar link iCal",
  "calendar.sync.createICalFiltersInfo": "Os seguintes filtros serão aplicados a este link de calendário iCal:",
  "calendar.sync.createICalFiltersWarning": "Seu calendário não atualiza os dados iCal em tempo real. O atraso pode ser de até 24 horas (dependendo do seu provedor de calendário). Para atualizações em tempo real, conecte seu Google Calendar ou Microsoft Outlook Calendar diretamente.",
  "calendar.sync.createICalInfo": "O link do calendário iCal permite que você se inscreva e visualize as folgas programadas de suas equipes do Vacation Tracker em qualquer calendário (incluindo Google Calendar, Microsoft Outlook Calendar, Apple Calendar e muitos outros).",
  "calendar.sync.createPushToSharedInfo": "E envie e sincronize as folgas aprovadas filtradas para o seguinte calendário compartilhado do {provider}:",
  "calendar.sync.createSyncCalendarButton": "Criar sincronização de calendário",
  "calendar.sync.createSyncCalendarFiltersInfo": "O Vacation Tracker aplicará os seguintes filtros a todas as folgas aprovadas:",
  "calendar.sync.creating": "Criando sincronização de calendário",
  "calendar.sync.creatingError": "Erro ao criar sincronização de calendário",
  "calendar.sync.creatingSuccess": "Sincronização de calendário criada com sucesso",
  "calendar.sync.deletedCalendar": "Esta sincronização de calendário foi excluída",
  "calendar.sync.deleteTitle": "Excluir calendário",
  "calendar.sync.deleting": "Excluindo sincronização de calendário",
  "calendar.sync.deletingError": "Erro ao excluir sincronização de calendário",
  "calendar.sync.deletingSuccess": "Sincronização de calendário excluída com sucesso",
  "calendar.sync.emptyDataMessage": "Sincronize e visualize todas as folgas da equipe em seu calendário compartilhado. Você pode conectar seu calendário Outlook ou Google Calendar, ou usar iCal para sincronizar o Vacation Tracker com qualquer outro tipo de calendário.",
  "calendar.sync.emptyDataTitle": "Sincronize folgas com o calendário da sua equipe",
  "calendar.sync.loginWithDifferentAccountInfo": "Não vê o calendário?",
  "calendar.sync.loginWithDifferentAccountLink": "Faça login com outra conta {provider}",
  "calendar.sync.reconnect": "Reconecte seu Calendário {provider}",
  "calendar.sync.reconnectInfo": "Reconecte sua conta {provider} para continuar a sincronização automática de folgas com seu calendário compartilhado.",
  "calendar.sync.reconnecting": "Reconectando sincronização do calendário",
  "calendar.sync.reconnectingError": "Erro ao reconectar sincronização do calendário",
  "calendar.sync.reconnectingSuccess": "Reconexão da sincronização do calendário bem-sucedida",
  "calendar.sync.selectSharedCalendarPlaceholder": "Selecione o calendário compartilhado do {provider}",
  "calendar.sync.stepByStep": "Instruções passo a passo",
  "calendar.sync.title": "Sincronize seu calendário de equipe",
  "calendar.sync.tooltipInfo": "Todos os seus Calendários Sincronizados estão reunidos nesta página para facilitar o gerenciamento. Para saber mais, consulte nosso <helpDesk>artigo da Central de Ajuda.</helpDesk>",
  "calendar.sync.update": "Atualize seu Calendário {provider}",
  "calendar.sync.updateSyncCalendarButton": "Atualizar sincronização do calendário",
  "calendar.sync.updating": "Atualizando sincronização do calendário",
  "calendar.sync.updatingError": "Erro ao atualizar sincronização do calendário",
  "calendar.sync.updatingSuccess": "Atualização da sincronização do calendário bem-sucedida",
  "calendar.syncButton": "Sincronizar com seu calendário (Google, Outlook, etc.)",
  "calendar.syncModal.description": "Copie o link abaixo e integre-o ao seu Google Calendar, Outlook Calendar ou qualquer outro calendário que suporte feeds de calendário iCal.",
  "calendar.syncModal.filters": "Os seguintes filtros são aplicados a este link de calendário:",
  "calendar.syncModal.helpdesk": "Visite nossa <helpDesk>Central de Ajuda</helpDesk> para obter ajuda e instruções passo a passo.",
  "calendar.team": "Departamento(s)",
  "calendar.timezone.info": "O horário é convertido para o seu fuso horário",
  "calendar.week.monFri": "Seg-Sex",
  "calendar.week.sunThu": "Dom-Qui",
  "calendarListModal.description": "Por favor, selecione o calendário da seguinte lista de calendários disponíveis. Após conectar um calendário, o Vacation Tracker adicionará, editará ou cancelará suas folgas no calendário escolhido.",
  "calendarListModal.descriptionGoogleFirstLine": "Por favor, selecione o calendário da seguinte lista de calendários disponíveis. ",
  "calendarListModal.descriptionOutlookFirstLine": "Você só pode conectar seu calendário padrão. ",
  "calendarListModal.descriptionSecondLine": "Após conectar um calendário, o Vacation Tracker adicionará, editará ou cancelará suas folgas no calendário escolhido.",
  "calendarListModal.hiddenLeaveTypesDescription": "Saiba mais sobre <helpdesk>exibir tipos de folga ocultos</helpdesk>.",
  "calendarListModal.reconnectDescription": "Reconecte seu calendário para continuar a sincronização automática de folgas com seu calendário conectado.",
  "calendarListModal.reconnectTitle": "Reconectar Calendário",
  "calendarListModal.selectPlaceHolder": "Por favor, selecione um calendário...",
  "calendarListModal.title": "Selecionar Calendário",
  "channel_not_found": "O canal selecionado foi excluído ou não está mais disponível. Por favor, selecione outro canal para receber Notificações.",
  "choiceAutomations.accessibleTo": "Acessível a <approver>Aprovador </approver><and>e </and><admin>Administrador </admin><only>apenas</only>",
  "choiceAutomations.activeSubscribedAddons": "Complementos assinados ativos",
  "choiceAutomations.activeSubscribedAutomations": "Automações assinadas ativas",
  "choiceAutomations.adpIntegration": "Fornece uma conexão direta com sua ferramenta de folha de pagamento, garantindo que os dados de pagamento estejam precisos e atualizados em todas as plataformas.",
  "choiceAutomations.automationPrice": "Preço: ${price}/usuário/mês",
  "choiceAutomations.automationPriceInfo": "Você pode comprar e ativar qualquer uma das automações abaixo por $0,50/usuário/mês",
  "choiceAutomations.bambooHrIntegration": "Fornece uma conexão direta com sua ferramenta de RH, mantendo os dados precisos e consistentes em todos os sistemas.",
  "choiceAutomations.blackoutPeriodInfo": "Um período de bloqueio é um intervalo de tempo especificado durante o qual os funcionários não têm permissão para tirar folga do trabalho.",
  "choiceAutomations.cancelAtPeriodEnd": "Ativo até o final do período de cobrança",
  "choiceAutomations.cancelAtPeriodEndTooltip": "Você cancelou a assinatura deste complemento. Ele permanecerá ativo até o final do período de cobrança atual e será desativado depois disso.",
  "choiceAutomations.comingSoonScheduleCall": "Sinta-se à vontade para compartilhar detalhes sobre suas necessidades específicas em relação à automação em que estamos trabalhando.",
  "choiceAutomations.comingSoonScheduleCallTitle": "Em breve",
  "choiceAutomations.deacticate": "Cancelar assinatura",
  "choiceAutomations.employmentLeaveRestrictionInfo": "A política de folga no início do emprego permite garantir um tempo dedicado de adaptação durante os primeiros dias de emprego, restringindo solicitações de folga de novos funcionários.",
  "choiceAutomations.enitlementByRoleShortInfo": "Os funcionários recebem dias adicionais de folga com base em sua função, conforme definido por rótulo(s).",
  "choiceAutomations.entitlementByRoleInfo": "Os funcionários recebem dias adicionais de folga com base em sua função, conforme definido por rótulo(s). Por exemplo, você pode criar um rótulo chamado 'Gerente' e atribuí-lo a todos os gerentes em sua organização. Em seguida, você pode criar uma automação que concede 5 dias adicionais de folga a todos os funcionários com o rótulo 'Gerente'. Você pode criar várias automações para diferentes funções. Esta automação é válida a partir do seu ano fiscal atual. <link>Entre em contato conosco</link> se precisar aplicar esta automação a anos anteriores.",
  "choiceAutomations.includedInYourPlan": "Incluído no seu plano",
  "choiceAutomations.leaveDurationLimitInfo": "Personalize a Duração da Folga: Defina o número mínimo e máximo de dias para o período desejado.",
  "choiceAutomations.mySbscribedAddons": "Meus complementos assinados",
  "choiceAutomations.notActivatedAddons": "Disponível para assinatura",
  "choiceAutomations.openApiInfo": "Fornece integração perfeita com sistemas externos, permitindo sincronização automatizada de dados e funcionalidade aprimorada",
  "choiceAutomations.reviewAndBuy": "Revisar e comprar",
  "choiceAutomations.selectAutomations": "Selecione sua automação preferida",
  "choiceAutomations.seniorityEntitlementInfo": "O direito por antiguidade é um sistema onde os funcionários ganham mais tempo livre remunerado (PTO) com base no tempo de serviço na empresa. Atualmente, você pode criar apenas um direito por antiguidade por local. Esta automação é válida a partir do seu ano fiscal atual. <link>Entre em contato conosco</link> se precisar de mais de um direito por antiguidade em um local ou precisar aplicar esta automação a anos anteriores.",
  "choiceAutomations.seniorityEntitlementShortInfo": "O direito por antiguidade é um sistema onde os funcionários ganham mais tempo livre remunerado (PTO) com base no tempo de serviço na empresa.",
  "choiceAutomations.teamLeaveBalancingInfo": "Gerenciar a porcentagem de licenças simultâneas da equipe.",
  "choiceAutomations.visibilityInfo": "Permite que os administradores personalizem a visibilidade das informações de licença para usuários regulares, melhorando a privacidade e a relevância dos dados.",
  "company.platformChanged": "Migrando para uma nova plataforma",
  "company.platformChanged.confirmDescription": "Você está prestes a migrar para uma nova plataforma. Esta ação é irreversível. Por favor, revise as seguintes informações antes de prosseguir.",
  "company.platformChanged.confirmDescription.unmappedUsers.deleted": "Encontramos {unmappedUsers} usuários que não foram encontrados na sua conta do {newPlatform}. Esses usuários serão marcados como EXCLUÍDOS quando a migração for concluída.",
  "company.platformChanged.confirmDescription.unmappedUsers.unmatched": "Encontramos {unmappedUsers} usuários que não foram encontrados na sua conta do {newPlatform}. Esses usuários não poderão fazer login no Vacation Tracker.",
  "company.platformChanged.confirmQuestion": "Tem certeza de que deseja prosseguir?",
  "company.platformChanged.confirmTitle": "Confirmar migração",
  "company.platformChanged.description": "Por favor, associe seus usuários do Vacation Tracker às suas contas do {newPlatform}. Associaremos automaticamente os usuários com os mesmos e-mails.",
  "company.platformChanged.googleAccount": "Conta do Google",
  "company.platformChanged.hideInactive": "Ocultar inativos:",
  "company.platformChanged.markUnmappedAsDeleted": "Marcar usuários não associados como excluídos?",
  "company.platformChanged.microsoftAccount": "Conta da Microsoft",
  "company.platformChanged.slackAccount": "Conta do Slack",
  "company.platformChanged.title": "Migrando de {oldPlatform} para {newPlatform}",
  "company.platformChanged.unmatched": "Não encontrado no {platform}",
  "company.platformChanged.vacationTrackerUser": "Usuário do Vacation Tracker",
  "component.filterSimple.filterBy": "Filtrar por",
  "component.filterSimple.selectType": "Selecionar tipo",
  "component.filterSimple.tooltipInfo": "Se você não selecionar nenhum filtro, a exportação incluirá todos os usuários.",
  "component.notificationDestination.label": "Enviar para",
  "component.notificationDestination.tooltip": "Observe que você pode enviar a Notificação para vários e-mails, mas apenas um canal por vez.",
  "component.password.timeToHack.days": "{duration} dias",
  "component.password.timeToHack.hours": "{duration} horas",
  "component.password.timeToHack.instantly": "Instantaneamente",
  "component.password.timeToHack.label": "Tempo necessário para os hackers quebrarem esta senha:",
  "component.password.timeToHack.minutes": "{duration} minutos",
  "component.password.timeToHack.perfect": "Mais longo que uma vida",
  "component.password.timeToHack.seconds": "{duration} segundos",
  "component.password.timeToHack.years": "{duration} anos",
  "component.setPassword.enterOldPassword": "Digite a senha antiga",
  "component.setPassword.enterPassword": "Digite a senha",
  "component.setPassword.tooltip.description.line1": "As informações acima mostram o tempo máximo estimado necessário para que hackers quebrem sua senha selecionada por força bruta. A estimativa é baseada na tabela <italic>\"Tabela de senhas para quebrar uma função hash PBKDF2 usando uma GPU RTX 3090\"</italic> deste <link>artigo</link>.",
  "component.setPassword.tooltip.description.line2": "Como escolher uma boa senha? Existem muitas maneiras de selecionar uma boa senha. Nós usamos gerenciadores de senhas e não reutilizamos senhas. Dê uma olhada neste <link>guia engraçado, mas correto</link> para mais inspiração.",
  "component.setPassword.tooltip.title": "Tempo necessário para os hackers quebrarem sua senha por força bruta",
  "component.setPassword.validation": "A senha deve conter pelo menos: 8 caracteres, uma letra minúscula, um número e um símbolo.",
  "components.accrualsCalculator.calculate": "Calcular",
  "components.accrualsCalculator.calculateAccrualsDays": "Calcular Dias de Acúmulo",
  "components.accrualsCalculator.youWillEarnedInCurrentPeriod": "Você ganhará <strong>{days}</strong> dias no período atual nessa data",
  "components.accrualsCalculator.youWillEarnedInNextPeriod": "Você ganhará <strong>{days}</strong> dias no próximo período nessa data",
  "components.addHolidayForm.addHolidays": "Adicionar Feriado",
  "components.addHolidayForm.addHolidays.cancel": "Cancelar",
  "components.addHolidayForm.addHolidays.isHalfDayHoliday": "Feriado de Meio Dia",
  "components.addHolidayForm.addHolidays.isHolidayMultiday": "Mais de Um Dia?",
  "components.addHolidayForm.addHolidays.saveHoliday": "Salvar",
  "components.addHolidayForm.date.error": "Por favor, selecione uma data",
  "components.addHolidayForm.editHoliday": "Editar feriado",
  "components.addHolidayForm.editHolidays": "Editar feriados",
  "components.addHolidayForm.endDate.error": "Por favor, selecione a data final",
  "components.addHolidayForm.holidaysName": "Nome do feriado",
  "components.addHolidayForm.holidaysName.error": "Por favor, insira um nome para o feriado",
  "components.addHolidayForm.startDate.error": "Por favor, selecione a data inicial",
  "components.announcementModal.announceToCompany": "Você deseja informar sua equipe que está usando o Vacation Tracker?",
  "components.announcementModal.send": "Enviar Anúncio",
  "components.automationBlackoutSummaryInfo.blacoutSummary": "{noOfUsers} usuário<multiUsers>s</multiUsers> <link>(Ver Usuário<multiUsers>s</multiUsers>)</link> não poderão tirar {leaveTypes} folgas,<endDate> entre</endDate> {fromDate} <endDate>e {toDate}</endDate><recurring> todos os anos.</recurring>",
  "components.billing.email": "E-mail de Cobrança",
  "components.billing.emailPlaceholder": "Digite o endereço de e-mail.",
  "components.billing.emailsValidationRequired": "Um e-mail é necessário para fins de cobrança.",
  "components.billing.resubscribe": "Reinscrever-se",
  "components.billingCouponCode.activeCouponInfo": "<percentOffCover>{off}%</percentOffCover><amountOffCover>US${off}</amountOffCover> de desconto <durationCover>{duration}</durationCover><durationInMonthsCover>{duration} mês</durationInMonthsCover>, a partir de {start}",
  "components.billingCouponCode.activeCouponInfoTitle": "Código de Cupom Ativo",
  "components.billingCouponCode.applyCode": "Aplicar código",
  "components.billingCouponCode.applyCodeAndResubscribe": "Aplicar código e Reinscrever-se",
  "components.billingCouponCode.couponCode": "Código do Cupom",
  "components.billingCouponCode.text": "Você tem um código de cupom? Digite-o aqui para aplicá-lo à sua assinatura.",
  "components.billingCouponCode.title": "Cupons",
  "components.billingEmails.emailPlaceholder": "Digite o endereço de e-mail.",
  "components.billingEmails.emails": "E-mails",
  "components.billingEmails.emailsValidationRequired": "Um e-mail é necessário para fins de cobrança.",
  "components.billingEmails.title": "E-mails de Cobrança",
  "components.billingForm.address1": "Endereço linha 1",
  "components.billingForm.address1ValidationRequired": "O endereço é obrigatório.",
  "components.billingForm.address2": "Endereço linha 2",
  "components.billingForm.billingEmails": "E-mail de Cobrança",
  "components.billingForm.billingEmailsPlaceholder": "Digite o endereço de e-mail.",
  "components.billingForm.billingEmailsValidationRequired": "Um e-mail é necessário para fins de cobrança.",
  "components.billingForm.ccRequired": "O número do cartão é obrigatório.",
  "components.billingForm.city": "Cidade",
  "components.billingForm.cityValidationRequired": "A cidade é obrigatória.",
  "components.billingForm.companyName": "Nome da Empresa",
  "components.billingForm.companyNameValidationRequired": "O nome da empresa é obrigatório.",
  "components.billingForm.country": "País",
  "components.billingForm.countryValidationRequired": "O país é obrigatório.",
  "components.billingForm.couponCode": "Código do Cupom",
  "components.billingForm.coupons": "Cupons",
  "components.billingForm.description": "Por favor, insira suas informações de pagamento no formulário acima para pagar sua assinatura com um cartão de crédito.",
  "components.billingForm.invoiceDescription": "Se a pessoa responsável pelo pagamento da assinatura não for usuária do Slack ou Microsoft Teams, podemos enviar-lhe uma fatura por e-mail que pode ser paga usando um cartão de crédito.",
  "components.billingForm.invoiceLabel": "Enviar faturas por e-mail",
  "components.billingForm.invoiceTitle": "Pagamento com fatura enviada por e-mail",
  "components.billingForm.paymentCC": "Cartão de Crédito",
  "components.billingForm.paymentTitle": "Informações de pagamento",
  "components.billingForm.planTitle": "Plano de cobrança",
  "components.billingForm.state": "Estado",
  "components.billingForm.stateValidationRequired": "O estado é obrigatório.",
  "components.billingForm.title": "Informações de cobrança",
  "components.billingInfo.address": "Endereço",
  "components.billingInfo.canceledDescription": "Você cancelou sua assinatura do Vacation Tracker. Ela permanecerá ativa até {periodEnd}",
  "components.billingInfo.canceledTrialDescription": "Seu período de teste permanecerá ativo até {periodEnd}, depois disso sua conta do Vacation Tracker será cancelada.",
  "components.billingInfo.companyName": "Nome da Empresa",
  "components.billingInfo.email": "E-mail de Faturamento",
  "components.billingInfo.resubscribe": "Reassinar",
  "components.billingInfo.text": "Suas informações de faturamento são usadas para processar pagamentos de sua assinatura. Você pode atualizar suas informações de faturamento e adicionar informações de cartão de crédito aqui.",
  "components.billingInfo.title": "Informações de faturamento",
  "components.billingInvoice.description": "Você pode gerenciar métodos de pagamento e faturas em nosso portal do cliente. <link>Clique aqui para visitar o portal do cliente</link><creatingLink>Criando o link do portal do cliente</creatingLink>",
  "components.billingInvoice.title": "Faturas",
  "components.cancelSubscriptionModal.anotherLeaveTrackingTool": "Encontramos outra ferramenta de controle de férias",
  "components.cancelSubscriptionModal.anotherLeaveTrackingToolAdditionalInfo": "Você pode nos dizer qual ferramenta você está mudando?",
  "components.cancelSubscriptionModal.cancelText": "Não cancelar assinatura",
  "components.cancelSubscriptionModal.contactInfo": "Para qualquer outra coisa, entre em contato com nosso suporte em {email}",
  "components.cancelSubscriptionModal.info": "Você pode nos informar qual é o motivo pelo qual está cancelando sua assinatura do Vacation Tracker?",
  "components.cancelSubscriptionModal.min20characters": "Mínimo de 20 caracteres",
  "components.cancelSubscriptionModal.missingFeatures": "A ferramenta não tem as funcionalidades que minha equipe precisa",
  "components.cancelSubscriptionModal.missingFeaturesAdditionalInfo": "Você pode nos dizer em poucas palavras qual funcionalidade está faltando?",
  "components.cancelSubscriptionModal.moreInfo": "O que poderíamos ter feito para mantê-lo como cliente?",
  "components.cancelSubscriptionModal.okText": "Cancelar Agora",
  "components.cancelSubscriptionModal.option1": "Mudamos para um software de RH",
  "components.cancelSubscriptionModal.option2": "Encontramos outra ferramenta de controle de férias",
  "components.cancelSubscriptionModal.option3": "A ferramenta não tem as funcionalidades que minha equipe precisa",
  "components.cancelSubscriptionModal.option4": "O preço está acima do nosso orçamento",
  "components.cancelSubscriptionModal.option5": "Outro",
  "components.cancelSubscriptionModal.other": "Outro",
  "components.cancelSubscriptionModal.otherAdditionalInfo": "Outro",
  "components.cancelSubscriptionModal.priceOverBudget": "O preço está acima do nosso orçamento",
  "components.cancelSubscriptionModal.priceOverBudgetAdditionalInfo": "Qual é o seu orçamento para uma ferramenta como esta?",
  "components.cancelSubscriptionModal.title": "Lamentamos ver você partir...",
  "components.cancelSubscriptionModal.weAreMovingToPayroll": "Estamos mudando para um sistema de Folha de Pagamento",
  "components.cancelSubscriptionModal.weAreMovingToPayrollAdditionalInfo": "Você pode nos dizer para qual sistema de Folha de Pagamento está mudando?",
  "components.cancelSubscriptionModal.weAreMovingToPayrollAdditionalInfoIntegration": "Se tivéssemos uma integração com este sistema de Folha de Pagamento, você consideraria ficar?",
  "components.cancelSubscriptionModal.weSiwtchedToAnHrSolution": "Mudamos para um software de RH",
  "components.cancelSubscriptionModal.weSiwtchedToAnHrSolutionAdditionalInfo": "Você pode nos dizer para qual sistema de RH está mudando?",
  "components.cancelSubscriptionModal.weSiwtchedToAnHrSolutionAdditionalInfoIntegration": "Se tivéssemos uma integração com este sistema de RH, você consideraria ficar?",
  "components.cancelTrialSubscriptionModal.cancelText": "Continuar teste",
  "components.cancelTrialSubscriptionModal.info": "Por favor, explique em uma frase por que você quer cancelar seu teste?",
  "components.cancelTrialSubscriptionModal.okText": "Cancelar teste",
  "components.cancelTrialSubscriptionModal.title": "Lamentamos ver você partir...",
  "components.changeTeamForm.changeTeamWarning": "Selecione o Departamento para o qual você deseja transferir o Usuário.",
  "components.changeTeamForm.changeUserTeam": "Mudar Departamento do Usuário",
  "components.changeTeamForm.selectTeam": "Selecionar Departamento",
  "components.changeTeamForm.selectTeamError": "Por favor, selecione um departamento.",
  "components.companyAlreadySignup.connectWith": " Conectar com {existsOnAnotherPlatform}",
  "components.companyAlreadySignup.info": "Notamos que sua empresa já tem uma conta existente no Vacation Tracker. Se você queria fazer login, simplesmente clique aqui:",
  "components.companyAlreadySignup.signupWithMail": "Conectar com seu e-mail de trabalho",
  "components.companyAlreadySignup.title": "Sua empresa já está usando o Vacation Tracker",
  "components.companyLogs.dateRangeLimit": "Logs com mais de 3 meses estão disponíveis apenas no plano completo.",
  "components.companyLogs.rangeLimit": "O intervalo de datas pode ser no máximo de um ano",
  "components.companySettings.contactEmailInfo": "O novo endereço de e-mail de contato da sua empresa é: {email}.",
  "components.comparePlatformsModal.forMoreInfoSeeOurHelpdesk": "Para saber mais, visite nossa <helpDesk>Central de Ajuda</helpDesk>.",
  "components.comparePlatformsModal.info": "Independentemente da plataforma que você escolher, terá acesso a todos os principais recursos do Vacation Tracker. Alguns, no entanto, têm benefícios adicionais que não estão disponíveis em outros. Consulte a tabela de comparação abaixo.",
  "components.comparePlatformsModal.table.comingSoon": "Em breve",
  "components.comparePlatformsModal.table.email": "E-mail",
  "components.comparePlatformsModal.table.feature": "Recurso",
  "components.comparePlatformsModal.table.google": "Google",
  "components.comparePlatformsModal.table.importUsersFromGoogleWorkspace": "Importar usuários do seu Google Workspace",
  "components.comparePlatformsModal.table.importUsersFromMicrosoftTeams": "Importar usuários do seu Microsoft Active Directory",
  "components.comparePlatformsModal.table.importUsersFromSlack": "Importar usuários do seu espaço de trabalho do Slack",
  "components.comparePlatformsModal.table.inviteUsersViaEmail": "Convidar usuários por e-mail",
  "components.comparePlatformsModal.table.manageLeaveTypes": "Gerenciar tipos de licença, feriados e solicitações de licença",
  "components.comparePlatformsModal.table.microsoft": "Microsoft",
  "components.comparePlatformsModal.table.multiTeamLocation": "Configurar vários departamentos e locais com diferentes políticas de licença",
  "components.comparePlatformsModal.table.requestAndManageLeaveFromMicrosoftTeams": "Enviar e gerenciar solicitações de licença diretamente do Microsoft Teams",
  "components.comparePlatformsModal.table.requestAndManageLeaveFromSlack": "Enviar e gerenciar solicitações de licença diretamente do Slack",
  "components.comparePlatformsModal.table.slack": "Slack",
  "components.comparePlatformsModal.table.syncToGoogleCalendar": "Sincronizar o Vacation Tracker com o Google Calendar",
  "components.comparePlatformsModal.table.syncToOutlookCalendar": "Sincronizar o Vacation Tracker com o Outlook 365 Calendar",
  "components.comparePlatformsModal.title": "Qual integração do Vacation Tracker é mais adequada para mim?",
  "components.configureImport.configureProratedLeaveTypesErrorMessage": "Você precisa selecionar pelo menos um tipo de licença",
  "components.configureImport.defaultDepartmentTooltip": "Todos os usuários importados serão adicionados a este departamento. Após importados, você pode movê-los para um departamento diferente.",
  "components.configureImport.defaultLocationTooltip": "Todos os usuários importados serão adicionados a este local. Após importados, você pode movê-los para um local diferente.",
  "components.configureImport.guestUsers": "Você deseja importar usuários convidados multicanal?",
  "components.configureImport.guestUsersTooltip": "Se esta opção estiver ativada, os usuários convidados multicanal do seu espaço de trabalho serão importados para o Vacation Tracker.",
  "components.configureImport.proRateLabel": "Calcular proporcionalmente a cota dos novos usuários para este ano?",
  "components.configureImport.proRateLeaveTypes": "Aplicar cálculo proporcional aos seguintes tipos de licença",
  "components.configureImport.proRateLeaveTypesTooltip": "Por favor, liste os tipos de licença para os quais você gostaria de calcular proporcionalmente as cotas de licença disponíveis dos usuários.",
  "components.configureImport.proRateTooltip": "Por favor, selecione se você deseja que nosso sistema calcule a cota de licença disponível dos usuários importados para o ano inicial com base nas configurações da sua empresa e na data de início do usuário.",
  "components.createLeaveType.assignToLocationManyInProgress": "Atribuindo política de licença aos locais",
  "components.createLeaveType.assignToLocationManyInProgressCompleted": "Atribuição de política de licença aos locais concluída",
  "components.createLeaveType.assignToLocationOneInProgress": "Atribuindo política de licença ao local",
  "components.createLeaveType.assignToLocationOneInProgressCompleted": "Atribuição de política de licença ao local concluída",
  "components.createLeaveType.createInProgress": "Criando o tipo de licença {leaveTypeName}",
  "components.createLeaveTypeForm.assignAndConfigure": "Atribuir e Configurar",
  "components.createLeaveTypeForm.assignToLocations": "Atribuir aos Locais",
  "components.createLeaveTypeForm.calendarStatusBusy": "Ocupado",
  "components.createLeaveTypeForm.calendarStatusFree": "Livre",
  "components.createLeaveTypeForm.calendarStatusLabel": "Mostrar este tipo de licença no seu calendário como",
  "components.createLeaveTypeForm.calendarStatusLabelTooltipInfo": "Escolha 'Ocupado' para ausência completa como férias, sinalizando indisponibilidade, ou 'Livre' para dias de trabalho remoto, indicando que você não está no escritório, mas disponível.",
  "components.createLeaveTypeForm.continueCreatingLeaveType": "Sim, quero criar o tipo de licença",
  "components.createLeaveTypeForm.createAndAssign": "Criar e Atribuir",
  "components.createLeaveTypeForm.createLeaveType": "Criar Tipo de Licença",
  "components.createLeaveTypeForm.justLastStep.assign": "Atribuir",
  "components.createLeaveTypeForm.justLastStep.title": "Atribuir Política de Licença",
  "components.createLeaveTypeForm.Locations": "Locais",
  "components.createLeaveTypeForm.locationsInfo": "Atribuir <strong>{leaveTypeName}</strong> a todos os locais ou a um local específico.",
  "components.createLeaveTypeForm.locationsPolicyHint": "Para editar a política (regras) para este tipo de licença, vá para {locations, plural, =1 {o Local} other {os Locais}} específico(s):",
  "components.createLeaveTypeForm.selectLocation": "Selecionar local",
  "components.createLeaveTypeForm.similarLeaveTypeChecking": "Verificando tipos de licença existentes...",
  "components.createLeaveTypeForm.similarLeaveTypeInfoAreYouSure": "Você ainda deseja criar um novo tipo (<strong>{newLeaveTypeName}</strong>)?",
  "components.createLeaveTypeForm.similarLeaveTypeInfoAssigningTip": "Você pode atribuir um tipo de licença a vários locais e personalizar as regras por local (como cotas, transferências, acumulações, etc.). Não há necessidade de criar tipos separados para cada lugar!",
  "components.createLeaveTypeForm.similarLeaveTypeInfoRecommend": "Recomendamos usar um único tipo de licença em vários locais para manter a consistência e os dados de licença do usuário.",
  "components.createLeaveTypeForm.similarLeaveTypeInfoSetRules": "Defina as regras específicas do local para:",
  "components.createLeaveTypeForm.similarLeaveTypeInfoSimilarLeaveType": "Já existe um tipo de licença similar (<strong>{leaveTypeName}</strong>).",
  "components.createLeaveTypeForm.similarLeaveTypeTitle": "Tipo de Licença Similar",
  "components.createLeaveTypeForm.title": "Criar Tipo de Licença e Atribuir Política de Licença",
  "components.denyWithReasonForm.deny": "Negar",
  "components.denyWithReasonForm.denyLeaveRequest": "Negar Solicitação de Licença",
  "components.denyWithReasonForm.reason": "Adicionar um Motivo",
  "components.denyWithReasonForm.reasonIsRequired": "Este campo é obrigatório.",
  "components.downgradeAlert.cancelChangeToPlan": "Cancelar mudança para o plano <strong>{nextPlan}</strong>",
  "components.downgradeAlert.cancelSwitchPlan": "Você deseja cancelar a mudança programada para o plano <strong>{nextPlan}</strong> {nextPeriod}?",
  "components.downgradeAlert.noIWantToSwitch": "Não, eu quero mudar para o plano <strong>{nextPlan}</strong> {nextPeriod}",
  "components.downgradeAlert.yesIWantToStay": "Sim, eu quero ficar no plano <strong>{currentPlan}</strong> {currentPeriod}",
  "components.entitlementByRole.additionalDays": "dia(s) adicional(is)",
  "components.entitlementByRole.additionalHours": "hora(s) adicional(is)",
  "components.entitlementByRole.applayToUsersWithLabel": "Aplicar aos usuários com rótulo",
  "components.entitlementByRole.applyToUsersWithLabel": "Aplicar aos usuários com rótulo",
  "components.entitlementByRole.completePlanOnly": "Este recurso é exclusivamente acessível aos usuários inscritos em nosso plano Completo.",
  "components.entitlementByRole.noLabels": "Não há rótulos criados ou todos estão sendo utilizados para outras automações de Direito por Função.",
  "components.entitlementByRole.startApplayingEnintlementByRoleFrom": "Começar a aplicar o direito por função a partir de",
  "components.entitlementByRole.startApplyingEntitlementByRoleFrom": "Começar a aplicar o direito por função a partir de",
  "components.fakeDoorMailLoginModal.discount": "Como parte do grupo de acesso antecipado, você também receberá 50% de desconto no Vacation Tracker pelos primeiros 3 meses.",
  "components.fakeDoorMailLoginModal.info": "Estamos atualmente trabalhando para habilitar o registro por e-mail. Para obter acesso antecipado, por favor, inscreva-se abaixo e entraremos em contato em breve.",
  "components.fakeDoorMailLoginModal.no": "Não, vou esperar.",
  "components.fakeDoorMailLoginModal.title": "Interessado em acesso antecipado? 🚀 ",
  "components.fakeDoorMailLoginModal.yes": "Sim, quero obter acesso antecipado.",
  "components.feedbackModal.button": "Dê-nos feedback",
  "components.feedbackModal.emailPlaceholder": "Qual é o seu endereço de e-mail?",
  "components.feedbackModal.feedbackForm": "Formulário de feedback",
  "components.feedbackModal.feedbackMessage": "Mensagem de feedback",
  "components.feedbackModal.howDoYouLikeApp": "O que você acha do nosso aplicativo?",
  "components.feedbackModal.howDoYouLikeAppPlaceholder": "Escolha uma opção...",
  "components.feedbackModal.iLikeIt": "Eu gosto",
  "components.feedbackModal.iLoveIt": "Eu amo",
  "components.feedbackModal.ItMakesMeCry": "Me faz chorar",
  "components.feedbackModal.itsOk": "Está ok",
  "components.feedbackModal.messagePlaceholder": "Sua mensagem de feedback",
  "components.feedbackModal.Nah": "Nah",
  "components.feedbackModal.namePlaceholder": "Digite seu nome",
  "components.feedbackModal.ratingRequired": "Por favor, selecione uma das avaliações fornecidas",
  "components.feedbackModal.sendFeedback": "Enviar feedback",
  "components.feedbackModal.yourEmail": "Seu endereço de e-mail",
  "components.feedbackModal.yourEmailInfo": "Se o seu feedback requer uma resposta, por favor, insira seu e-mail para que possamos entrar em contato com você.",
  "components.feedbackModal.yourName": "Seu nome",
  "components.filterSimple.filterBy": "Filtrar por",
  "components.filterSimple.selectType": "Selecione o tipo",
  "components.filterSimple.tooltipInfo": "Se você não selecionar nenhum filtro, a exportação incluirá todos os usuários.",
  "components.generalSettings.aiBotHelperLabel": "Permitir comunicação com o Bot do Vacation Tracker",
  "components.generalSettings.aiBotHelperLabelDisable": "Desativar comunicação com o Bot do Vacation Tracker",
  "components.generalSettings.aiBotHelperTooltip": "Mensagens com o bot são uma maneira conveniente e eficiente para os funcionários solicitarem folgas do trabalho. Os funcionários podem simplesmente enviar uma mensagem de solicitação de folga para o bot, especificando detalhes como as datas de início e término, o número de dias solicitados e o tipo de folga.",
  "components.heatmap.clickForMore": "Clique para mais informações",
  "components.heatmap.holiday": "Feriado",
  "components.heatmap.holidayDescription": "A localização {location} está ausente para {holiday}",
  "components.heatmap.loading": "carregando {day}",
  "components.heatmap.nextMonth": "Próximo Mês",
  "components.heatmap.nonWorkingDay": "dia não útil",
  "components.heatmap.previousMonth": "Mês Anterior",
  "components.heatmap.showLess": "Mostrar menos",
  "components.heatmap.showMore": "Mostrar mais {amount}",
  "components.heatmap.today": "hoje",
  "components.helpDrawer.adminProductTourInfo": "Como Administrador, você tem o mais alto nível de permissões no Vacation Tracker e pode personalizar todas as configurações da organização através do Painel do Administrador.\n\nO Painel permite que você:\n- Solicite Folga ou adicione Folga para outros membros do departamento.\n- Tenha uma visão geral completa através da Página Inicial do Painel e do Calendário.\n- Crie e configure vários departamentos (Atribua Aprovadores)\n- Defina Notificações\n- Gerencie Usuários (Ativos, Inativos, Excluídos).\n- Crie e gerencie Localizações (Tipos de Folga, Feriados, Notificações)\n\nO Bot permite que você:\n- Solicite Folga\n- Aprove/Negue Solicitações de Folga\n- Receba Notificações Diárias/Semanais\n\nSe suas perguntas ou preocupações permanecerem sem resposta, entre em contato conosco a qualquer momento. Falar com nossos clientes é a melhor parte do nosso dia:",
  "components.helpDrawer.adminProductTourTitle": "Sua função: Administrador",
  "components.helpDrawer.approverProductTourInfo": "Como Aprovador, você tem o segundo maior nível de permissões no Vacation Tracker e pode aprovar ou negar solicitações de folga enviadas por Usuários do departamento para o qual você foi designado como Aprovador.\n\nO Painel permite que você:\n- Solicite Folga ou adicione Folga para outros membros do departamento.\n- Tenha uma visão geral completa através da Página Inicial do Painel e do Calendário.\n- Defina Notificações\n- Visualize seu Perfil de Usuário.\n- Gerencie Solicitações (Aprove/Negue Solicitações, Visualize Histórico).\n\nO Bot permite que você:\n- Solicite Folga\n- Aprove/Negue Solicitações de Folga\n- Receba Notificações Diárias/Semanais\n\nSe suas perguntas ou preocupações permanecerem sem resposta, entre em contato conosco a qualquer momento. Falar com nossos clientes é a melhor parte do nosso dia:",
  "components.helpDrawer.approverProductTourTitle": "Sua função: Aprovador",
  "components.helpDrawer.google.adminProductTourInfo": "Como Administrador, você tem o mais alto nível de permissões no Vacation Tracker e pode personalizar todas as configurações da organização através do Painel do Administrador.\n\nO Painel permite que você:\n- Solicite Folga ou adicione Folga para outros membros do departamento.\n- Tenha uma visão geral completa através da Página Inicial do Painel e do Calendário.\n- Crie e configure vários departamentos (Atribua Aprovadores)\n- Defina Notificações\n- Gerencie Usuários (Ativos, Inativos, Excluídos).\n- Crie e gerencie Localizações (Tipos de Folga, Feriados, Notificações)\n\nSe suas perguntas ou preocupações permanecerem sem resposta, entre em contato conosco a qualquer momento. Falar com nossos clientes é a melhor parte do nosso dia:",
  "components.helpDrawer.google.approverProductTourInfo": "Como Aprovador, você tem o segundo maior nível de permissões no Vacation Tracker e pode aprovar ou negar solicitações de folga enviadas por Usuários do departamento para o qual você foi designado como Aprovador.\n\nO Painel permite que você:\n- Solicite Folga ou adicione Folga para outros membros do departamento.\n- Tenha uma visão geral completa através da Página Inicial do Painel e do Calendário.\n- Defina Notificações\n- Visualize seu Perfil de Usuário.\n- Gerencie Solicitações (Aprove/Negue Solicitações, Visualize Histórico).\n\nSe suas perguntas ou preocupações permanecerem sem resposta, entre em contato conosco a qualquer momento. Falar com nossos clientes é a melhor parte do nosso dia:",
  "components.helpDrawer.google.userProductTourInfo": "Como Usuário do Vacation Tracker, você pode enviar e gerenciar suas Solicitações de Folga através do Painel, bem como ter uma visão geral completa das ausências planejadas dentro de sua organização.\n\nO Painel permite que você:\n- Solicite Folga\n- Tenha uma visão geral completa através da Página Inicial do Painel e do Calendário.\n- Visualize seu Perfil de Usuário.\n\nSe suas perguntas ou preocupações permanecerem sem resposta, entre em contato conosco a qualquer momento. Falar com nossos clientes é a melhor parte do nosso dia:",
  "components.helpDrawer.helpDeskInfo": "Visite nossa Central de Ajuda para saber mais sobre nosso produto e obter respostas para algumas das perguntas comuns.",
  "components.helpDrawer.learnMore": "Saiba mais",
  "components.helpDrawer.needhelp": "Precisa de ajuda?",
  "components.helpDrawer.quickTourInfo": "Reunimos alguns passos curtos que recomendamos que você siga para configurar sua organização rapidamente.",
  "components.helpDrawer.quickTourTitle": "Tour rápido",
  "components.helpDrawer.supportInfo": "Se você tiver alguma dúvida ou precisar de assistência, inicie um chat com nossa equipe de suporte ao cliente ou nos envie um e-mail para <link>hello@vacationtracker.io</link>",
  "components.helpDrawer.supportTitle": "Suporte",
  "components.helpDrawer.userProductTourInfo": "Como Usuário do Vacation Tracker, você pode enviar e gerenciar suas Solicitações de Folga através do Painel ou do Bot, bem como ter uma visão geral completa das ausências planejadas dentro de sua organização.\n\nO Painel permite que você:\n- Solicite Folga\n- Tenha uma visão geral completa através da Página Inicial do Painel e do Calendário.\n- Visualize seu Perfil de Usuário.\n\nO Bot permite que você:\n- Solicite Folga\n- Receba Notificações Diárias/Semanais\n\nSe suas perguntas ou preocupações permanecerem sem resposta, entre em contato conosco a qualquer momento. Falar com nossos clientes é a melhor parte do nosso dia:",
  "components.helpDrawer.userProductTourTitle": "Sua função: Usuário",
  "components.importHolidaysForm.backButton": "Voltar",
  "components.importHolidaysForm.selectCountry": "Selecione o país",
  "components.importHolidaysForm.selectHolidays": "Selecione os feriados",
  "components.importHolidaysForm.selectYear": "Selecione o ano",
  "components.imports.bfDescription": "Transferência Inicial é o saldo de licença que um Usuário trouxe do ano anterior, quando o Vacation Tracker ainda não fazia parte da sua organização. Observe que você só pode editar o saldo inicial de Transferência, enquanto a licença disponível que você pode transferir nos anos seguintes será calculada pelo Vacation Tracker.",
  "components.importUsersForm.button.importAllUsers": "Importar e Ativar Todos os {users} Usuários",
  "components.importUsersForm.button.importSelectedUsers": "Importar {noOfUsers} {noOfUsers, plural, =1 {Usuário Selecionado} other {Usuários Selecionados}}",
  "components.importUsersForm.button.importUsersAutmaticallyFromPlatform": "Você deseja importar todos os usuários do {platform} automaticamente?",
  "components.importUsersForm.calculationStartYearInfo": "O Vacation Tracker calculará as folgas a partir de {year}",
  "components.importUsersForm.cancel": "Cancelar",
  "components.importUsersForm.chooseUsersPlaceholder": "Nome do Membro da Equipe",
  "components.importUsersForm.confirmImportSlackMultichannelUsersInfoOff": "O Vacation Tracker não importará usuários convidados multicanal do seu espaço de trabalho automaticamente. Você ainda pode importá-los manualmente, se necessário.",
  "components.importUsersForm.confirmImportSlackMultichannelUsersInfoOn": "O Vacation Tracker importará usuários convidados multicanal do seu espaço de trabalho automaticamente.",
  "components.importUsersForm.confirmImportUsersModalContentChangeLaterInfo": "Você pode alterar essas configurações posteriormente no painel do Vacation Tracker",
  "components.importUsersForm.confirmImportUsersModalContentDepartmentAndLocationInfo": "{noOfUsers, plural, =1 {O novo usuário será adicionado} other {Os novos usuários serão adicionados}} ao departamento {chosenDepartment} e na localização {chosenLocation}",
  "components.importUsersForm.confirmImportUsersModalContentNoOfUsers": "Importar {noOfUsers} {noOfUsers, plural, =1 {usuário} other {usuários}}",
  "components.importUsersForm.confirmImportUsersModalContentOpener": "Você selecionou:",
  "components.importUsersForm.confirmImportUsersModalContentProrateInfo": "Prorratear a cota dos usuários para os seguintes tipos de folga: {leaveTypes}",
  "components.importUsersForm.confirmImportUsersModalContentProrateInfoTurnOff": "As cotas dos usuários não serão mais prorateadas",
  "components.importUsersForm.confirmImportUsersModalContentWelcomeMessage": "Enviar uma {channel, select, Email {mensagem de boas-vindas por e-mail} other {mensagem de boas-vindas}} para {noOfUsers, plural, =1 {o novo usuário} other {os novos usuários}}",
  "components.importUsersForm.confirmImportUsersModalContentWelcomeMessageTurnOff": "Não enviaremos mais uma mensagem de boas-vindas para os novos usuários",
  "components.importUsersForm.confirmImportUsersModalTitle": "Confirmar importação de {noOfUsers} usuários selecionados",
  "components.importUsersForm.consent.line1": "Para importar usuários, você precisará obter o consentimento de administrador do seu administrador do Microsoft Teams. Para obter este consentimento único, por favor, envie o seguinte link para o seu administrador do Microsoft Teams:",
  "components.importUsersForm.consent.line2": "Depois de obter o consentimento, feche e reabra este modal, e esta mensagem desaparecerá.",
  "components.importUsersForm.consent.warning": "Por favor, feche e reabra este modal após obter o consentimento para poder importar todos os usuários.",
  "components.importUsersForm.googleAPIErrorMessage": "Parece haver um problema com as configurações do seu diretório Google. Por favor, entre em contato com seu administrador e envie-lhes o seguinte erro:",
  "components.importUsersForm.importAllUsers": "Deseja importar todos os usuários?",
  "components.importUsersForm.importAllUsersExceedingLimt": "Você tem um grande número de usuários em seu diretório. Se deseja importar todos os usuários, entre em contato com nossa equipe de Suporte e podemos habilitar a importação. Alternativamente, você pode selecionar usuários específicos para importar.",
  "components.importUsersForm.importAllUsersTooltip": "{noOfUsersToImport} {noOfUsersToImport, plural, =1 {usuário} other {usuários}} do {platform} serão importados para o Vacation Tracker. Isso pode afetar sua faturação.",
  "components.importUsersForm.importUsersAutomaticallyExceedingLimit": "Você tem um grande número de usuários em seu diretório. Se deseja importar usuários automaticamente, entre em contato com nossa equipe de Suporte e podemos habilitar a importação.",
  "components.importUsersForm.importUsersAutomaticallyExceedingLimt": "Você tem um grande número de usuários em seu diretório. Se deseja importar usuários automaticamente, entre em contato com nossa equipe de Suporte e podemos habilitar a importação.",
  "components.importUsersForm.importUsersAutomaticallyTooltip": "Assim que um usuário recém-adicionado ao seu {platform} for importado para o Vacation Tracker, ele receberá uma breve mensagem explicando o que é o Vacation Tracker e como navegar nele.",
  "components.importUsersForm.importUsersTitle": "Importar Usuários",
  "components.importUsersForm.newCompanyNameInfo": "O novo nome da empresa será: {newName}",
  "components.importUsersForm.selectUsers": "Selecione os usuários para importar:",
  "components.importUsersForm.selectUsersNext": "Configurar as definições para os usuários selecionados",
  "components.importUsersForm.selectUsersStepName": "Selecionar Usuários",
  "components.importUsersForm.sendWelcomeMessageLabel": "Enviar uma mensagem de boas-vindas aos novos usuários?",
  "components.importUsersForm.trackingQuotaInfoDays": "O Vacation Tracker irá rastrear a duração de todas as licenças e cotas em dias.",
  "components.importUsersForm.trackingQuotaInfoHours": "O Vacation Tracker irá rastrear a duração de todas as licenças e cotas em horas.",
  "components.importUsersForm.userEndDateInfo": "Usuários com uma data de término não poderão enviar solicitações de licença após essa data, e nós os desativaremos automaticamente.",
  "components.importUsersForm.userEndDateTurnOffInfo": "Usuários com uma data de término poderão enviar solicitações de licença após essa data, e não os desativaremos automaticamente.",
  "components.importUsersForm.usersIsRequired": "Este campo é obrigatório.",
  "components.importUsersForm.warningAllUsersImport": "O Vacation Tracker pode sincronizar, importar e excluir usuários automaticamente do seu {platform}.",
  "components.importUsersForm.warningNumberOfUsersToImport": "Quando você clicar no botão \"Importar e Ativar Todos os Usuários\", você ativará todos os {users} usuários do seu diretório. Esta ação pode afetar sua faturação.",
  "components.importUsersForm.warningOfUsersImport": "Importar {noOfUsers} {noOfUsers, plural, =1 {usuário selecionado} other {usuários selecionados}} pode afetar sua faturação.",
  "components.importUsersForm.warningOfUsersImportAnnualProrateAdditionalNewBucket": "Adicionar {noOfUsers} {noOfUsers, plural, =1 {usuário} other {usuários}} aumentará sua próxima fatura para {amount} (excluindo IVA) a partir de {startDate}.",
  "components.importUsersForm.warningOfUsersImportAnnualProrateAdditionalNoOfUsers": "Uma cobrança estimada proporcional de {proratedAmount} (excluindo IVA) será aplicada hoje.",
  "components.importUsersForm.warningOfUsersImportNoChange": "Você está prestes a importar {noOfUsers} {noOfUsers, plural, =1 {usuário} other {usuários}}. Sua faturação não será afetada.",
  "components.importUsersForm.warningOfUsersImportOverSeatsLimit": "Você atingiu seu limite atual de assentos ({seats}). Deseja comprar mais?",
  "components.importUsersForm.warningOfUsersImportOverSeatsLimitImportAll": "Você não pode importar todos os usuários do seu espaço de trabalho/diretório porque ultrapassará seu limite atual de assentos ({seats}). Deseja comprar mais?",
  "components.importUsersForm.warningOfUsersImportProrateAdditionalNewBucketMonthly": "Importar {noOfUsers} {noOfUsers, plural, =1 {usuário} other {usuários}} afetará sua faturação. Sua próxima fatura será de {amount} (excluindo IVA) em {startDate}",
  "components.importUsersForm.warningOfUsersImportTrial": "Você está atualmente no modo de teste. Será cobrado {amount} (excluindo IVA) em {startDate}",
  "components.importUsersForm.warningOfUsersImportTrialWithoutPaymentMethod": "Você está em um período de teste e não será cobrado por nenhuma importação adicional de usuários antes de {stripeCurrentPeriodEnd}. Adicione suas informações de faturação para ver a estimativa de cobrança.",
  "components.insertHolidaysForm.back": "Voltar",
  "components.insertHolidaysForm.deselectAll": "Desmarcar Todos",
  "components.insertHolidaysForm.findHolidays": "Encontrar Feriados",
  "components.insertHolidaysForm.insertHolidays": "Importar Feriados",
  "components.insertHolidaysForm.pleaseChooseACountry": "Escolha um País",
  "components.insertHolidaysForm.saveHolidays": "Salvar Feriados",
  "components.insertHolidaysForm.selectAll": "Selecionar Todos",
  "components.insertHolidaysForm.selectHolidays": "Selecionar feriados",
  "components.insertHolidaysForm.subtitle": "A alteração pode impactar o número de dias de licença para alguns usuários. Esses usuários terão dia(s) adicionados à sua cota disponível para a licença solicitada. Tem certeza de que deseja prosseguir?",
  "components.insertHolidaysForm.title": "Importar Feriados substituirá quaisquer Feriados adicionados anteriormente.",
  "components.inviteAndManageUsersWithEmail.canNotInviteYourself": "Você não pode convidar a si mesmo",
  "components.inviteAndManageUsersWithEmail.header": "Convites por e-mail",
  "components.inviteAndManageUsersWithEmail.userAlreadyExists": "Usuário com o e-mail fornecido já existe",
  "components.inviteBotToPrivateChannel.descriptionOne": "Para adicionar o Vacation Tracker a um canal privado, digite",
  "components.inviteBotToPrivateChannel.descriptionTwo": "no canal e atualize a lista",
  "components.inviteBotToPrivateChannel.text": "Deseja selecionar um canal privado?",
  "components.inviteBotToPrivateChannel.title": "Adicionar canal privado",
  "components.leaveBox.approve": "Aprovar",
  "components.leaveBox.approvedBy": "Aprovado Por",
  "components.leaveBox.approveLeaveText": "Por favor, confirme que deseja aprovar a solicitação de licença.",
  "components.leaveBox.approveLeaveTitle": "Aprovar Solicitação de Licença",
  "components.leaveBox.autoApproved": "Aprovação automática",
  "components.leaveBox.cancelLeave": "Cancelar Licença",
  "components.leaveBox.cancelLeaveConfirm": "Tem certeza que deseja cancelar a licença?",
  "components.leaveBox.cancelLeaveTitle": "Cancelar Licença",
  "components.leaveBox.columns.daysOut": "{value} {value, plural, =1 {dia} other {dias}}",
  "components.leaveBox.columns.hoursOut": "{value} {value, plural, =1 {hora} other {horas}}",
  "components.leaveBox.daysLeft": "Licença Restante:",
  "components.leaveBox.daysOut": "Isso são {value} {value, plural, =1 {dia} other {dias}} fora do escritório.",
  "components.leaveBox.deny": "Negar",
  "components.leaveBox.denyLeaveConfirm": "Por favor, confirme que deseja negar a solicitação de licença.",
  "components.leaveBox.denyLeaveTitle": "Negar Solicitação de Licença",
  "components.leaveBox.denyWithReason": "Negar com Motivo",
  "components.leaveBox.edit": "Editar",
  "components.leaveBox.formattedLeaveInfo": "Isso são {leave} fora do escritório.",
  "components.leaveBox.hoursOut": "Isso são {value} {value, plural, =1 {hora} other {horas}} fora do escritório.",
  "components.leaveBox.leavesBoxHalfDay": "Estará ausente para {leaveTypeName} em {leaveStartDate} das {startHour}{hourFormat24, select, true {:00} other {}} às {endHour}{hourFormat24, select, true {:00} other {}}.",
  "components.leaveBox.leavesBoxHalfDayPending": "Solicitação de {leaveTypeName} em {leaveStartDate} das {startHour}{hourFormat24, select, true {:00} other {}} às {endHour}{hourFormat24, select, true {:00} other {}}.",
  "components.leaveBox.leavesBoxHalfDayToday": "Está de folga hoje das {startHour}{hourFormat24, select, true {:00} other {}} às {endHour}{hourFormat24, select, true {:00} other {}}.",
  "components.leaveBox.leavesBoxHalfDayTodayWithoutReason": "Está de folga hoje das {startHour}{hourFormat24, select, true {:00h} other {}} às {endHour}{hourFormat24, select, true {:00h} other {}}.",
  "components.leaveBox.leavesBoxHalfDayWithoutReason": "Está tirando uma licença em {leaveStartDate} das {startHour}{hourFormat24, select, true {:00} other {}} às {endHour}{hourFormat24, select, true {:00} other {}}.",
  "components.leaveBox.leavesOffTodayInfo": "Está ausente para {leaveTypeName} de {leaveStartDate} até {leaveEndDate}. Ambas as datas inclusas.",
  "components.leaveBox.leavesOffTodayWithoutReasonInfo": "Está ausente de {leaveStartDate} até {leaveEndDate}. Incluindo ambas as datas.",
  "components.leaveBox.leavesPendingInfo": "Solicitação de {leaveTypeName} de {leaveStartDate} até {leaveEndDate}. Ambas as datas inclusas.",
  "components.leaveBox.leavesPendingOneDay": "Solicitação de {leaveTypeName} em {leaveStartDate}.",
  "components.leaveBox.leavesUpcomingOneDayInfo": "Estará ausente para {leaveTypeName} em {leaveStartDate}.",
  "components.leaveBox.leavesUpcomingOneDayWithoutReasonInfo": "Estará ausente em {leaveStartDate}.",
  "components.leaveBox.reason": "Motivo",
  "components.leaveBox.toilDuration": "Duração: {leave}",
  "components.leaveForm.addLeave": "Adicionar Licença",
  "components.leaveForm.addLeaveDescription": "Use este formulário se estiver adicionando licença para outro usuário, ou se estiver adicionando licença para si mesmo que não requer aprovação.",
  "components.leaveForm.connectedCalendarTimezone": "O fuso horário do calendário {calendarType} conectado é {timezone}",
  "components.leaveForm.date": "Data",
  "components.leaveForm.editLeave": "Editar Licença",
  "components.leaveForm.editLeaveDescription.adminAndApprover": "Você pode editar as datas desta licença. Se quiser editar o tipo de licença, por favor, cancele a licença e adicione uma nova.",
  "components.leaveForm.editLeaveDescription.user": "Você pode editar as datas e o motivo desta licença. Se quiser editar o tipo de licença, por favor, cancele a licença e crie uma nova solicitação.",
  "components.leaveForm.endTime": "Hora de Término",
  "components.leaveForm.halfDay": "{leaveTypeName} (Meio Período)",
  "components.leaveForm.hourly": "{leaveTypeName} (Por Hora)",
  "components.leaveForm.leaveTypeNamePartTime": "{leaveTypeName} (Por Hora)",
  "components.leaveForm.pleaseInputReason": "O motivo é obrigatório",
  "components.leaveForm.pleaseSelectEndDate": "Selecione uma Data de Término",
  "components.leaveForm.pleaseSelectLeaveType": "Selecione um Tipo de Licença",
  "components.leaveForm.pleaseSelectRangeTime": "Selecione um Intervalo de Tempo",
  "components.leaveForm.pleaseSelectstartDate": "Selecione uma Data de Início",
  "components.leaveForm.pleaseSelectUser": "Selecione um Usuário",
  "components.leaveForm.pleaseSelectValidRangeTime": "Selecione um Intervalo de Tempo Válido",
  "components.leaveForm.rangeDate": "Data de Início e Término",
  "components.leaveForm.reason": "Motivo",
  "components.leaveForm.request": "Solicitar",
  "components.leaveForm.requestLeave": "Solicitar Licença",
  "components.leaveForm.requestLeaveDescription": "Use este formulário se sua solicitação precisar ser aprovada por outra pessoa além de você mesmo.",
  "components.leaveForm.selectSubstituteApprover": "Selecionar Aprovador Substituto",
  "components.leaveForm.selectSubstituteApproverInfoMessage": "Selecione uma pessoa para gerenciar suas solicitações de licença enquanto você estiver ausente. O Aprovador Substituto terá direitos limitados, pois só poderá aprovar ou negar solicitações de licença. Esta etapa é opcional.",
  "components.leaveForm.selectUser": "Selecionar um Usuário",
  "components.leaveForm.startTime": "Hora de Início",
  "components.leaveForm.time": "Tempo",
  "components.leaveForm.user": "Selecionar um Usuário",
  "components.leavePolicyForm.accrualBiWeeklySummary": "Os usuários acumularão {amount} ({quota}/26) dias a cada segunda {day}",
  "components.leavePolicyForm.accrualBiWeeklySummaryHours": "Os usuários acumularão {amount} ({quota}/26) horas a cada segunda {day}",
  "components.leavePolicyForm.accrualCap": "Habilitar Limite de Acúmulo",
  "components.leavePolicyForm.accrualCapRate": "Limitar dias acumulados a",
  "components.leavePolicyForm.accrualCapRateTimes": "vezes a taxa anual de acúmulo",
  "components.leavePolicyForm.accrualCapTooltip": "O Limite de Acúmulo restringe quanto PTO um funcionário pode acumular durante um período específico. É definido como um múltiplo da taxa anual de acúmulo, não um número preciso.",
  "components.leavePolicyForm.accrualCapWarning": "Limite de Acúmulo para {leaveTypeName} atingido. O acúmulo de novos dias será retomado assim que alguns dias existentes forem usados.",
  "components.leavePolicyForm.accrualCapWarning2": "Este usuário atingiu o limite de acúmulo de {leaveTypeName} e não acumulará mais dias até usar parte do saldo atual.",
  "components.leavePolicyForm.accrualDay": "Dia de Acúmulo",
  "components.leavePolicyForm.accrualDayBiWeekly": "Acúmulo Concedido em",
  "components.leavePolicyForm.accrualDisabledInfo": "Desative a cota ilimitada e defina a cota para qualquer número maior que 0 para habilitar acúmulos.",
  "components.leavePolicyForm.accrualMonthlySummary": "Os usuários acumularão {amount} ({quota}/12) dias no dia {date} de cada mês",
  "components.leavePolicyForm.accrualMonthlySummaryHours": "Os usuários acumularão {amount} ({quota}/12) horas no dia {date} de cada mês",
  "components.leavePolicyForm.accruals": "Acúmulos",
  "components.leavePolicyForm.accrualSemiMonthlySummary": "Os usuários acumularão {amount} ({quota}/24) dias no {firstDate} e no {secondDate} de cada mês",
  "components.leavePolicyForm.accrualSemiMonthlySummaryHours": "Os usuários acumularão {amount} ({quota}/24) horas no {firstDate} e no {secondDate} de cada mês",
  "components.leavePolicyForm.accrualType": "Tipo de Acúmulo",
  "components.leavePolicyForm.accrualTypeError": "Obrigatório",
  "components.leavePolicyForm.accrualTypeInfo": "A Licença Acumulada acumula-se gradualmente durante o ano com base na política da empresa. Se sua empresa tem Acúmulos, selecione o Tipo de Acúmulo determinado pela sua gestão.",
  "components.leavePolicyForm.accrualWeeklySummary": "Os usuários acumularão {amount} ({quota}/52) dias toda {day}",
  "components.leavePolicyForm.accrualWeeklySummaryHours": "Os usuários acumularão {amount} ({quota}/52) horas toda {day}",
  "components.leavePolicyForm.addTitle": "Atribuir Política de Licença",
  "components.leavePolicyForm.allDays": "Todos",
  "components.leavePolicyForm.allowAddAndEditLeaveInPast": "Editar e Solicitar Licenças no Passado",
  "components.leavePolicyForm.allowAddAndEditLeaveInPastInfo": "Escolha se deseja permitir que usuários regulares editem ou solicitem licenças no passado.",
  "components.leavePolicyForm.allowAdvanceAccrualUsage": "Permitir Solicitação de Licença Baseada em Acúmulo Futuro",
  "components.leavePolicyForm.allowAdvanceAccrualUsageInfo": "Permitir que os funcionários solicitem licença com base em seu acúmulo projetado de dias de folga, em vez de apenas o que eles acumularam no momento da solicitação. Isso significa que se um funcionário sabe que terá dias acumulados suficientes até o momento da licença solicitada, ele pode enviar a solicitação antecipadamente, mesmo que não tenha dias suficientes no momento da solicitação.",
  "components.leavePolicyForm.approvalRequired": "Aprovação Necessária",
  "components.leavePolicyForm.assignLeavePolicy": "Atribuir Política de Licença",
  "components.leavePolicyForm.back": "Voltar",
  "components.leavePolicyForm.BI-WEEKLY": "Quinzenal",
  "components.leavePolicyForm.cancel": "Cancelar",
  "components.leavePolicyForm.copyLeavePolicy": "Copiar Política De",
  "components.leavePolicyForm.copyLeavePolicyDivider": "Você pode usar um modelo de outra licença ou localização.",
  "components.leavePolicyForm.copyLeavePolicyInfo": "Copie as configurações da Política de Licença de um Tipo de Licença previamente definido. No menu suspenso, escolha o Tipo de Licença do qual deseja copiar a Política de Licença, e as configurações serão automaticamente configuradas. Se desejar fazer isso manualmente, você pode fazê-lo configurando as configurações abaixo.",
  "components.leavePolicyForm.createLeaveTypeFormAccrualsInfo1": "Os acúmulos estão inativos ao criar um tipo de licença. Para ativá-lo, personalize a política de licença para uma localização específica.",
  "components.leavePolicyForm.createLeaveTypeFormToilInfo1": "TOIL (Folga Compensatória) está inativo ao criar um tipo de licença. Para ativá-lo, personalize a política de licença para uma localização específica.",
  "components.leavePolicyForm.currentAccrualPeriod": "Período de Acúmulo Atual",
  "components.leavePolicyForm.daysPerYear": "Cota Anual de Licença",
  "components.leavePolicyForm.defaultLeavePolicy": "Não copiar, vou iniciar uma nova política",
  "components.leavePolicyForm.editTitle": "Editar Política de Licença {leavePolicyName} para {locationName}",
  "components.leavePolicyForm.futureBroughtForward": "Transferência futura",
  "components.leavePolicyForm.halfDays": "Permitir Meio-Dia",
  "components.leavePolicyForm.hasUnlimitedDays": "Cota Ilimitada",
  "components.leavePolicyForm.hideLeaveType": "Ocultar Tipo de Licença",
  "components.leavePolicyForm.hideLeaveTypeInfo": "Escolha se deseja ocultar este Tipo de Licença do Calendário e Notificações. Apenas Administradores, Aprovadores e o Usuário que solicitou o Tipo de Licença poderão identificá-lo no Calendário.",
  "components.leavePolicyForm.hourlyIncrementsTooltip": "Permitir que os membros da sua equipe solicitem licença por hora.",
  "components.leavePolicyForm.isActive": "Ativo",
  "components.leavePolicyForm.leavePolicy": "Política de Licença",
  "components.leavePolicyForm.limitedNumberOfDays": "Limitado",
  "components.leavePolicyForm.maxRolloverDays": "Máximo de Dias Transferidos",
  "components.leavePolicyForm.maxRolloverDaysError": "Obrigatório",
  "components.leavePolicyForm.maxRolloverDaysInfo": "Selecione o número de dias que podem ser transferidos para o ano seguinte para este Tipo de Licença.",
  "components.leavePolicyForm.maxRolloverDaysInfoHours": "Selecione o número de horas que podem ser transferidas para o ano seguinte para este Tipo de Licença.",
  "components.leavePolicyForm.MONTHLY": "Mensal",
  "components.leavePolicyForm.negativeBalance": "Permitir Saldo Negativo",
  "components.leavePolicyForm.negativeBalanceInfo": "Escolha se deseja autorizar os Usuários a ultrapassar a Cota de Licença permitida que você definiu para este Tipo de Licença. Se você não quiser permitir esta opção, deixe o botão desmarcado.",
  "components.leavePolicyForm.nextAccrualDay": "Próxima Acumulação Concedida Em",
  "components.leavePolicyForm.noLeaveTypes": "Não há Tipos de Licença disponíveis. Para adicionar um novo Tipo de Licença com uma Política de Licença personalizada, vá para a aba Tipos de Licença para criar um novo Tipo de Licença e, em seguida, adicione-o à Localização.",
  "components.leavePolicyForm.NONE": "Nenhum",
  "components.leavePolicyForm.noRollover": "Desativado",
  "components.leavePolicyForm.override.affectedUsers": "Usuários afetados: <strong>{totalUsers}</strong>",
  "components.leavePolicyForm.override.change": "Alteração: <unlimited>Mudança na política de licença ilimitada</unlimited><customDays>Atualização da cota de licença</customDays>",
  "components.leavePolicyForm.override.customLeaveQuotaInfo": "Alguns dos Usuários afetados (<strong>{totalUsers}</strong>) têm cota(s) de licença personalizada(s). Deseja substituir esta configuração?",
  "components.leavePolicyForm.override.leaveType": "Tipo de licença: <strong>{name}</strong>",
  "components.leavePolicyForm.override.location": "Localização: <strong>{name}</strong>",
  "components.leavePolicyForm.override.note": "Nota: Esta alteração afetará todos os usuários sem uma cota de licença personalizada, independentemente da opção escolhida.",
  "components.leavePolicyForm.override.overrideDisable": "Substituir sua cota de licença personalizada",
  "components.leavePolicyForm.override.overrideEnable": "Manter a cota de licença existente",
  "components.leavePolicyForm.pauseAccrualsWhenUserIsDeactivated": "Pausar Acumulações Quando o Usuário é Desativado",
  "components.leavePolicyForm.pauseAccrualsWhenUserIsDeactivatedInfo": "Ative este recurso para suspender temporariamente as acumulações de dias de licença para qualquer usuário que seja desativado. Quando o usuário for reativado, a acumulação de seus dias de licença continuará automaticamente, garantindo o rastreamento preciso de seus direitos de licença",
  "components.leavePolicyForm.pauseAccrualsWhenUserIsDeactivatedUserInfo": "De acordo com as políticas da sua organização, a acumulação de licença é pausada enquanto a conta deste usuário está inativa. Entre em contato com seu administrador para mais detalhes.",
  "components.leavePolicyForm.previouesBroughForwardAndFutureOnce": "Anteriores transferidos e futuros",
  "components.leavePolicyForm.reasonRequired": "Motivo Obrigatório",
  "components.leavePolicyForm.required": "Obrigatório",
  "components.leavePolicyForm.rolloverDaysPerYear": "Número máximo de dias",
  "components.leavePolicyForm.rolloverPerYearDays": "Número Máximo de Dias",
  "components.leavePolicyForm.rolloverPerYearHours": "Número Máximo de Horas",
  "components.leavePolicyForm.rolloverSettings": "Configurações de Transferência",
  "components.leavePolicyForm.rolloverType": "Transferido",
  "components.leavePolicyForm.save": "Salvar",
  "components.leavePolicyForm.saveLeavePolicy": "Salvar",
  "components.leavePolicyForm.selectLeaveType": "Selecionar Tipo de Licença",
  "components.leavePolicyForm.SEMI-MONTHLY": "Quinzenal",
  "components.leavePolicyForm.updateLeavePolicy": "Atualizar",
  "components.leavePolicyForm.whenWouldYouLikeToApplayTheseChanges": "Quando Gostaria de Aplicar Essas Mudanças?",
  "components.leavePolicyForm.whenWouldYouLikeToApplayTheseChangesInfo": "Selecione o período para aplicar suas mudanças de licença. Escolha 'Transferência futura' para ajustar apenas as datas de licença futuras, ou 'Transferências anteriores e futuras' para modificar datas passadas e futuras.",
  "components.leavePolicyForm.whenWouldYouLikeToApplyTheseChanges": "Quando Gostaria de Aplicar Essas Mudanças?",
  "components.leavePolicyForm.whenWouldYouLikeToApplyTheseChangesInfo": "Selecione o período para aplicar suas mudanças de licença. Escolha 'Transferência futura' para ajustar apenas as datas de licença futuras, ou 'Transferências anteriores e futuras' para modificar datas passadas e futuras.",
  "components.leavePolicyForm.yearlyQuota": "Cota Anual de Licença",
  "components.leavePolicyForm.yearlyQuotaHours": "Cota Anual de Licença (Horas)",
  "components.leavePolicyForm.yearlyQuotaInfo": "Este campo representa a cota anual base de licença. Você poderá ajustar cotas individuais manualmente através dos perfis de usuário ou automatizar ajustes entre diferentes usuários com nosso recurso de automações.",
  "components.leaveRequestAction.added": "Licença <forUser>para </forUser>{requestor} adicionada <byActionCreator>por {actionCreator}</byActionCreator>",
  "components.leaveRequestAction.addToCalendar": "Adicionar ao Calendário",
  "components.leaveRequestAction.addToCalendarMoreInfo": "Mais informações sobre o recurso \"Adicionar ao Calendário\"",
  "components.leaveRequestAction.approved": "Licença de {requestor} aprovada <byActionCreator>por {actionCreator}</byActionCreator> ✅",
  "components.leaveRequestAction.approversLabel": "Aprovadores: ",
  "components.leaveRequestAction.autoApproved": "Licença de {requestor} foi auto-aprovada",
  "components.leaveRequestAction.cancelled": "Licença de {requestor} cancelada <byActionCreator>por {actionCreator}</byActionCreator>",
  "components.leaveRequestAction.created": "Licença solicitada <byActionCreator>por {actionCreator}</byActionCreator>",
  "components.leaveRequestAction.deleted": "Licença excluída <byActionCreator>por {actionCreator}</byActionCreator>",
  "components.leaveRequestAction.denied": "Licença de {requestor} negada <byActionCreator>por {actionCreator}</byActionCreator> ❌",
  "components.leaveRequestAction.edited": "Licença de {requestor} editada <byActionCreator>por {actionCreator}</byActionCreator>",
  "components.leaveRequestAction.editedApproved": "Uma licença agendada foi editada <byActionCreator>por {actionCreator}</byActionCreator> ✅",
  "components.leaveRequestAction.editedAutoApproved": "Licença editada auto-aprovada <byActionCreator>por {actionCreator}</byActionCreator>",
  "components.leaveRequestAction.editedDenied": "Licença editada negada <byActionCreator>por {actionCreator}</byActionCreator>",
  "components.leaveRequestAction.fullDayLeavePeriod": "{date}{endDate} ",
  "components.leaveRequestAction.leaveRemaining": "Licença Restante: ",
  "components.leaveRequestAction.leaveRequested": "Licença Solicitada: ",
  "components.leaveRequestAction.leaveTypeLabel": "Tipo de Licença: ",
  "components.leaveRequestAction.partDayLeavePeriod": "{date} - das {partDayStartHour}{hourFormat24, select, true {:00} other {}} às {partDayEndHour}{hourFormat24, select, true {:00} other {}} ",
  "components.leaveRequestAction.periodLabel": "Data: ",
  "components.leaveRequestAlertStatus.leaveRequestApprovedByYouInfo": "Você aprovou a solicitação. Uma notificação será enviada para {name} para informá-lo.",
  "components.leaveRequestAlertStatus.leaveRequestApprovedInfo": "A solicitação de licença foi aprovada por {approverName}.",
  "components.leaveRequestAlertStatus.leaveRequestCancelledInfo": "A solicitação de licença foi cancelada.",
  "components.leaveRequestAlertStatus.leaveRequestDeniedByYouInfo": "Você negou a solicitação. Uma notificação será enviada para {name} para informá-lo.",
  "components.leaveRequestAlertStatus.leaveRequestDeniedInfo": "A solicitação de licença foi negada por {approverName}.",
  "components.leaveRequestAlertStatus.leaveRequestExpiredInfo": "A solicitação expirou.",
  "components.leaveRequestAlertStatus.leaveRequestHasApprovedByYouInfo": "A solicitação de licença foi aprovada.",
  "components.leaveRequestAlertStatus.leaveRequestHasDeniedByYouInfo": "A solicitação de licença foi negada.",
  "components.leavesColumns.approver": "Aprovador",
  "components.leavesColumns.autoApproved": "Auto aprovado",
  "components.leavesColumns.cancelledBeforeReview": "Cancelado antes da revisão",
  "components.leavesColumns.dates": "Data(s)",
  "components.leavesColumns.days": "{value} dias",
  "components.leavesColumns.denyReason": "Motivo do Aprovador",
  "components.leavesColumns.duration": "Duração",
  "components.leavesColumns.halfDayHoliday": "(Feriado de Meio Dia)",
  "components.leavesColumns.hours": "{value} horas",
  "components.leavesColumns.leaveType": "Tipo de Licença",
  "components.leavesColumns.reason": "Motivo do Solicitante",
  "components.leavesColumns.reviewedby": "Revisado por",
  "components.leavesColumns.status": "Status",
  "components.leaveTypeForm.cancel": "Cancelar",
  "components.leaveTypeForm.color": "Cor",
  "components.leaveTypeForm.editLeaveTypeTitle": "Editar Tipo de Licença {leaveTypeName}",
  "components.leaveTypeForm.forbiddenCharacters": "O nome do Tipo de Licença não pode conter pontuação ou caracteres especiais.",
  "components.leaveTypeForm.isActive": "Ativo",
  "components.leaveTypeForm.maxLength": "O nome do Tipo de Licença é muito longo.",
  "components.leaveTypeForm.name": "Nome",
  "components.leaveTypeForm.nameIsRequired": "Este campo é obrigatório.",
  "components.leaveTypeForm.save": "Salvar",
  "components.leaveTypeForm.setSlackStatus": "Definir Status do Slack",
  "components.leaveTypeForm.setSlackStatusPlaceholder": "Por favor, defina o status do Slack",
  "components.leaveTypeForm.slackStatus": "Status do Slack",
  "components.leaveTypeForm.title": "Tipo de Licença",
  "components.leaveTypeForm.tooltipElement1": "<strong>Aprovação Necessária</strong>: Esta opção pode ser ativada se uma licença requer aprovação de um gerente.",
  "components.leaveTypeForm.tooltipElement2": "<strong>Permitir Saldo Negativo</strong>: Esta opção permite que os usuários solicitem licença mesmo após terem esgotado sua cota.",
  "components.leaveTypeForm.tooltipElement3": "<strong>Permitir Meio Período</strong>: Esta opção pode ser ativada conforme as necessidades da sua organização.",
  "components.leaveTypeForm.tooltipFooter": "Lembre-se, é importante notar que as Políticas de Licença são definidas por localização. Você pode personalizar essas políticas de acordo com as necessidades da sua organização e atribuí-las aos tipos de licença apropriados.",
  "components.leaveTypeForm.tooltipHeader": "No Vacation Tracker, 'Tipos de Licença' referem-se a categorias globais de licenças, como Folga Remunerada, Dia de Doença, Trabalho em Casa, e assim por diante. Por outro lado, 'Políticas de Licença' são regras e configurações específicas atribuídas a cada Tipo de Licença por localização. Essas políticas podem incluir várias opções, como:",
  "components.legacyPlanAlert.warning": "Você está atualmente usando o plano legado. Se você mudar de plano, não poderá ativá-lo novamente. Para mais informações, entre em contato com nosso <link>suporte</link>.",
  "components.locationForm.changeLocationWarning": "Selecione a Localização para a qual você deseja transferir o Usuário.",
  "components.locationForm.changeUserLocation": "Alterar Localização do Usuário",
  "components.locationForm.days": "Dias",
  "components.locationForm.daysAfterEmploymentDate": "dias após a data de contratação.",
  "components.locationForm.daysInfo": "A transferência de licença não utilizada para o ano calendário seguinte expirará após o número de dias que você definiu após a Data de Contratação do Usuário. A Cota de Licença para cada Tipo de Licença é configurada através da aba Tipos de Licença nas Configurações de Localização, incluindo a opção de habilitar ou desabilitar a transferência.",
  "components.locationForm.defaultLocation": "Tornar Esta Localização Padrão",
  "components.locationForm.firstDayOfWeek": "A Semana Começa Em",
  "components.locationForm.fiscalYear": "Ano Contábil",
  "components.locationForm.fiscalYearStart": "Início do Ano Fiscal",
  "components.locationForm.name": "Nome",
  "components.locationForm.noRolloverExpiryDate": "Sem Data de Expiração para Dias Transferidos",
  "components.locationForm.overrideUserSettings": "Substituir Configurações do Usuário",
  "components.locationForm.overrideUserSettingsInfo": "A configuração da semana de trabalho será aplicada apenas aos Usuários que não têm Configurações personalizadas em seu Perfil de Usuário. Se você quiser aplicar as novas configurações de Semana de Trabalho a todos os Usuários, incluindo aqueles que têm uma Semana de Trabalho personalizada definida, selecione a opção \"Substituir Configurações do Usuário\".",
  "components.locationForm.resetQuotas": "Redefinição de Cota de Licença Baseada Em",
  "components.locationForm.resetQuotasInfo": "Esta configuração determinará se o seu saldo de licença será redefinido com base no ano contábil (ano fiscal da empresa) ou com base na data de início do funcionário. Além das cotas, sua política de transferência também será afetada de acordo com esta configuração.",
  "components.locationForm.rolloverExpiryAfterDays": "Sem Data de Expiração para Dias Transferidos",
  "components.locationForm.rolloverExpiryAfterDaysInfo": "A cada ano, as licenças transferidas dos usuários expirarão após esse número de dias após a data de contratação. As cotas para cada tipo de licença são configuradas através da seção Tipos de Licença para esta localização e cada uma pode ser configurada individualmente para permitir ou não transferências.",
  "components.locationForm.rolloverExpiryDay": "Sem Data de Expiração para Dias Transferidos",
  "components.locationForm.rolloverExpiryDayInfo": "A cada ano, as licenças transferidas do usuário expirarão na data que você definir. As cotas para cada tipo de licença são configuradas através da seção Tipos de Licença para esta localização e cada uma pode ser configurada individualmente para permitir ou não transferências.",
  "components.locationForm.selectLocation": "Selecionar Localização",
  "components.locationForm.selectLocationError": "Por favor, selecione uma localização.",
  "components.locationForm.settings.makeDefaultDescription": "Ao tornar esta Localização a padrão, todos os novos membros da equipe serão automaticamente adicionados a esta Localização.",
  "components.locationForm.settings.makeDefaultTitle": "Localização Padrão",
  "components.locationForm.settings.nameDescription": "Defina o nome da sua localização e selecione seus usuários. Se você quiser configurar a localização antes de adicionar usuários, você pode criar a localização primeiro e adicionar usuários depois.",
  "components.locationForm.settings.nameDescription2": "Este fuso horário padrão é usado em todo o sistema. Por exemplo, para exibir com precisão as informações de licença no calendário e para os eventos do sistema listados nos Logs.",
  "components.locationForm.settings.namePlaceholder": "Digite o nome da localização",
  "components.locationForm.settings.nameTitle": "Configurações de Localização",
  "components.locationForm.settings.quotasDescription": "Esta configuração determinará se o seu saldo de licença será redefinido com base no ano contábil (ano fiscal da empresa) ou com base na data de início do funcionário. Além das cotas, sua política de transferência também será afetada de acordo com esta configuração.",
  "components.locationForm.settings.quotasTitle": "Cota de Licença e Ano Fiscal",
  "components.locationForm.settings.workWeekDescription": "Por favor, selecione os dias de trabalho da semana para garantir o rastreamento preciso dos dados de licença.",
  "components.locationForm.settings.workWeekTitle": "Semana de Trabalho e Dia de Trabalho",
  "components.locationForm.users": "Usuários",
  "components.locationForm.userYear": "Data de Contratação do Usuário",
  "components.locationForm.warningInitialRollover": "Quaisquer alterações nas configurações de localização podem impactar a configuração dos usuários e as informações de licença, como cota de licença e cálculos. \nPor favor, note que se você tiver uma data de expiração de transferência definida em qualquer política de licença nesta localização, ela será desativada.",
  "components.locationForm.warningInitialRolloverFiscalYear": "Quaisquer alterações nas configurações de localização podem impactar a configuração dos usuários e as informações de licença, como cota de licença e cálculos.",
  "components.locationForm.warningInitialRolloverResetQuotas": "Quaisquer alterações nas configurações de localização podem impactar a configuração dos usuários e as informações de licença, como cota de licença e cálculos.",
  "components.locationForm.warningInitialRolloverUsers": "Adicionar ou remover um usuário pode impactar a configuração do usuário e as informações de licença (por exemplo, os dias iniciais transferidos).",
  "components.locationForm.workWeek": "Semana de Trabalho",
  "components.locationHolidays.addHolidays": "Adicionar um Feriado",
  "components.locationHolidays.deleteHolidaysConfirm": "Tem certeza de que deseja excluir o feriado {name}?",
  "components.locationHolidays.deleteHolidaysTitle": "Excluir Feriado",
  "components.locationHolidays.holidaysForLocation": "Feriados para a localização {locationName}",
  "components.locationHolidays.insertHolidays": "Importar Feriados",
  "components.locationHolidays.overlap": "Este feriado se sobrepõe a um existente",
  "components.locationLeavePolicies.activatingLeaveTypeInfo": "Ao ativar este Tipo de Licença, você está permitindo que os Usuários solicitem o Tipo de Licença específico. \n\nAs configurações e dados anteriores serão restaurados.",
  "components.locationLeavePolicies.activatingLeaveTypeTitle": "Ativar Tipo de Licença",
  "components.locationLeavePolicies.allowHalfDays": "Permitir Meio-Dia",
  "components.locationLeavePolicies.createNewLeavePolicy": "Atribuir Política de Licença",
  "components.locationLeavePolicies.createNewLeavePolicyInfo": "Para adicionar um Tipo de Licença personalizado a uma Localização, vá para Atribuir Política de Licença. Selecione o Tipo de Licença que você já criou na aba Tipos de Licença e configure as configurações da Política de Licença.",
  "components.locationLeavePolicies.daysPerYear": "Cota Anual de Licença",
  "components.locationLeavePolicies.deactivateLeaveTypeInfo": "Depois de desativar este Tipo de Licença, os Usuários nesta Localização não poderão mais solicitar o Tipo de Licença específico. Quando você ativá-lo novamente, o Tipo de Licença será restaurado com suas configurações e dados anteriores. \n\nSe você quiser desativar este Tipo de Licença para todas as Localizações, pode fazê-lo na aba Tipos de Licença.",
  "components.locationLeavePolicies.deactivateLeaveTypeTitle": "Desativar Tipo de Licença",
  "components.locationLeavePolicies.hasUnlimitedDays": "Ilimitado",
  "components.locationLeavePolicies.isApprovalRequired": "Aprovação Necessária",
  "components.locationLeavePolicies.leaveTypeName": "Nome do Tipo de Licença",
  "components.locationLeavePolicies.status": "Status",
  "components.locationLeavePolicy.createInProgress": "Criando a política de licença {name}",
  "components.locationLeavePolicy.successTitle": "A política de licença está definida",
  "components.locationLeavePolicy.updateInProgress": "Atualizando a política de licença",
  "components.locationLeaveTypesTag.hiddenLeaveType": "Tipo de Licença Oculto",
  "components.locationLeaveTypesTag.holidays": "Feriados",
  "components.logs.accrual": "Usuário acumulou {earned} {leaveTypeName} {earnedDayInGrammaticalNumber} (de uma cota de {defaultDaysPerYear} {defaultDayInGrammaticalNumber})",
  "components.logs.accrualDays": "Usuário acumulou {earned} {leaveTypeName} {earned, plural, =1 {dia} other {dias}} (de uma cota de {defaultDaysPerYear} {defaultDaysPerYear, plural, =1 {dia} other {dias}})",
  "components.logs.accrualDisplay": "Usuário acumulou {earned} {leaveTypeName} (de uma cota de {defaultDaysPerYear})",
  "components.logs.accrualHours": "Usuário acumulou {earned} {leaveTypeName} {earned, plural, =1 {hora} other {horas}} (de uma cota de {defaultDaysPerYear} {defaultDaysPerYear, plural, =1 {hora} other {horas}})",
  "components.logs.automationAddonsUpdatedPurchased": "{user} comprou o Add-On: {automationType}",
  "components.logs.automationAddonsUpdatedUnsubscribed": "{user} cancelou a assinatura do Add-On: {automationType}",
  "components.logs.billingCancelled": "Faturamento cancelado em {paymentProcessor}",
  "components.logs.billingUpdated": "Faturamento atualizado em {paymentProcessor}",
  "components.logs.blackoutPeriodCreated": "{user} criou um Período de Blackout <b>{name}</b>.<br></br>Ativado: {enabled}.<br></br>Recorrente: {recurring}.<br></br>Data de Início: {startDate}.<br></br>Data de Término: {endDate}.<br></br>Tipos de Licença: {leaveTypes}.<br></br>Localizações: {locations}.<br></br>Departamentos: {teams}.<br></br>Etiquetas: {labels}.<br></br>",
  "components.logs.blackoutPeriodDeleted": "{user} excluiu um Período de Blackout.",
  "components.logs.blackoutPeriodUpdated": "{user} atualizou um Período de Blackout <b>{name}</b>.<br></br>Ativado: {enabled}.<br></br>Recorrente: {recurring}.<br></br>Data de Início: {startDate}.<br></br>Data de Término: {endDate}.<br></br>Tipos de Licença: {leaveTypes}.<br></br>Localizações: {locations}.<br></br>Departamentos: {teams}.<br></br>Etiquetas: {labels}.<br></br>",
  "components.logs.companyCreated": "Empresa <b>{name}</b> foi criada",
  "components.logs.companySettingsUpdated": "<b>{user}</b> atualizou as configurações da empresa.<br></br>Anunciar novos usuários: {announceNewUsers}.<br></br>Contabilização de licença por hora: {hourlyLeaveAccounting}.<br></br>Data de término do usuário: {userEndDate}.<br></br>Ano de Início do Cálculo: {calculationStartYear}<br></br>Importar usuários automaticamente: {importUsersAutomatically}",
  "components.logs.deleted": "<Excluído>",
  "components.logs.earned": "Usuário ganhou {total} dias de {leaveTypeName} por trabalho extra (TOIL) {period}. {showExpiration, plural, =1 {Os dias expirarão em } other {}}{expiration}",
  "components.logs.entitlementByRoleCreated": "{user} criou um Direito por Função <b>{name}</b>.<br></br>Ativado: {enabled}.<br></br>Dias: {days}.<br></br>Etiqueta: {label}.<br></br>Tipo de Licença: {leaveType}.<br></br>",
  "components.logs.entitlementByRoleDeleted": "{user} excluiu um Direito por Função.",
  "components.logs.entitlementByRoleUpdated": "{user} atualizou um Direito por Função <b>{name}</b>.<br></br>Ativado: {enabled}.<br></br>Dias: {days}.<br></br>Etiqueta: {label}.<br></br>Tipo de Licença: {leaveType}.<br></br>",
  "components.logs.failedToLoadLog": "Falha ao carregar o log",
  "components.logs.importLeaves": "<b>{user}</b> importou {totalLeaves} {totalLeaves, plural, =1 {licença} other {licenças}}.",
  "components.logs.importUsers": "<b>{user}</b> importou {totalUsers} {totalUsers, plural, =1 {usuário} other {usuários}}.",
  "components.logs.initialRolloverDeleted": "Transferência Inicial para o Tipo de Licença: {leaveTypeName} redefinida para 0 por <link>{creatorName}</link>",
  "components.logs.initialRolloverUpdated": "Transferência Inicial para o Tipo de Licença: {leaveTypeName} alterada para {customRolloverDays} por <link>{creatorName}</link>",
  "components.logs.leaveDurationLimitCreated": "{user} criou um Limite de Duração de Licença <b>{name}</b>.<br></br>Ativado: {enabled}.<br></br>Data de início: {startDate}.<br></br>Data de término: {endDate}.<br></br>Duração mínima: {minimumDuration}.<br></br>Duração máxima: {maximumDuration}.<br></br>Recorrente: {recurring}.<br></br>Resposta de rejeição automática: {autoRejectResponse}.",
  "components.logs.leaveDurationLimitDeleted": "{user} excluiu um Limite de Duração de Licença.",
  "components.logs.leaveDurationLimitUpdated": "{user} atualizou um Limite de Duração de Licença <b>{name}</b>.<br></br>Ativado: {enabled}.<br></br>Data de início: {startDate}.<br></br>Data de término: {endDate}.<br></br>Duração mínima: {minimumDuration}.<br></br>Duração máxima: {maximumDuration}.<br></br>Recorrente: {recurring}.<br></br>Resposta de rejeição automática: {autoRejectResponse}.",
  "components.logs.leavePolicyDisabled": "{user} desativou a política de licença <b>{leaveTypeName}</b> na localização <b>{locationName}</b>",
  "components.logs.leavePolicyEnabled": "{user} ativou a política de licença <b>{leaveTypeName}</b> na localização <b>{locationName}</b>.<br></br>Dias por ano: {daysPerYear}.<br></br>Máximo de dias transferidos: {maxRolloverDays}.<br></br>Os dias transferidos devem expirar: {enableRolloverExpiry}.<br></br>Os dias transferidos expiram em: {rolloverExpiryDate}.<br></br>Os dias transferidos expiram após dias: {rolloverExpiryAfterDays}.<br></br>Aprovação necessária: {isApprovalRequired}.<br></br>Dias ilimitados: {hasUnlimitedDays}.<br></br>Intervalo mínimo de licença: {shortestLeaveInterval}.<br></br>Motivo necessário: {isReasonRequired}.<br></br>Tipo de acúmulo: {accrualType}.<br></br>Saldo negativo permitido: {negativeBallanceAllowed}.<br></br>Permitir licença no passado: {allowLeaveInPast}.<br></br>Limite de acúmulo: {accrualCapRate, plural, =0 {Desativado} other {Ativado com taxa {accrualCapRate}}}.<br></br>Ocultar tipo de licença: {hideLeaveType}.<br></br>TOIL ativado: {toil}.<br></br>Intervalo mínimo de TOIL: {toilShortestInterval}.<br></br>TOIL para todos os usuários: {toilRequestsAllowedForUsers}.<br></br>Expiração do TOIL: {toilExpiration}.<br></br>Data de expiração do TOIL: {toilExpirationDate}.<br></br>Meses de expiração do TOIL: {toilExpirationMonths}.",
  "components.logs.leaveTypeCreated": "{user} criou o tipo de licença <b>{name}</b> e atribuiu à(s) localização(ões): <b>{locations}</b>.<br></br>Cor: {color}.<br></br>Dias por ano: {daysPerYear}.<br></br>Número máximo de dias transferidos: {maxRolloverDays}.<br></br>Aprovação necessária: {isApprovalRequired}.<br></br>Dias ilimitados: {hasUnlimitedDays}.<br></br>Intervalo mínimo de licença: {shortestLeaveInterval}.<br></br>Motivo necessário: {isReasonRequired}.<br></br>Tipo de acúmulo: {accrualType}.<br></br>Saldo negativo permitido: {negativeBallanceAllowed}.<br></br>Permitir adicionar licenças no passado: {allowLeaveInPast}.<br></br>Ocultar tipo de licença: {hideLeaveType}",
  "components.logs.leaveTypeDeleted": "{user} excluiu o tipo de licença <b>{name}</b>",
  "components.logs.leaveTypeUpdated": "{user} atualizou o tipo de licença <b>{name}</b>.<br></br>Ativo: {isActive}.<br></br>Cor: {color}",
  "components.logs.locationCreated": "{user} criou uma localização <b>{name}</b>.<br></br>Mês de início do ano: {yearStartMonth}.<br></br>Dia de início do ano: {yearStartDay}.<br></br>Localização padrão: {isDefault}.<br></br>Primeiro dia da semana: {firstDayOfWeek}.<br></br>Redefinição de Cota Baseada Em: {resetQuotas}.<br></br>Mês de expiração da transferência: {rolloverExpiryMonth}.<br></br>Dia de expiração da transferência: {rolloverExpiryDay}.<br></br>Expiração da transferência após dias: {rolloverExpiryAfterDays}.<br></br>Fuso horário: {timezone}.<br></br>Usuários: {users}.<br></br>Dias: {days}",
  "components.logs.locationDeleted": "{user} excluiu a localização",
  "components.logs.locationHolidaysAutoimportOFF": "<b>{user}</b> desativou a importação automática de feriados para a localização <b>{location}</b>",
  "components.logs.locationHolidaysAutoimportON": "<b>{user}</b> ativou a importação automática de feriados para a localização <b>{location}</b><br></br>Feriados: {holidays}",
  "components.logs.locationUpdated": "{user} atualizou a localização <b>{name}</b>.<br></br>Mês de início do ano: {yearStartMonth}.<br></br>Dia de início do ano: {yearStartDay}.<br></br>Localização padrão: {isDefault}.<br></br>Primeiro dia da semana: {firstDayOfWeek}.<br></br>Redefinir cotas: {resetQuotas}.<br></br>Mês de expiração da transferência: {rolloverExpiryMonth}.<br></br>Dia de expiração da transferência: {rolloverExpiryDay}.<br></br>Expiração da transferência após dias: {rolloverExpiryAfterDays}.<br></br>Fuso horário: {timezone}.<br></br>Usuários: {users}",
  "components.logs.locationWorkWeekUpdated": "{user} atualizou a semana de trabalho na localização <b>{name}</b>.<br></br>Dias: {days}.<br></br>Horas: {hours}",
  "components.logs.locationYearHolidaysChanged": "<b>{user}</b> alterou o(s) feriado(s) para o ano {year} na localização <b>{name}</b>.<br></br>Feriados: {holidays}",
  "components.logs.locationYearRollover": "A transferência de ano da localização ocorreu na localização <b>{name}</b>",
  "components.logs.nonAccrualLeaveType": "Usuário recebeu {earned} <b>{leaveTypeName}</b> para {period}",
  "components.logs.nonAccrualLeaveTypeDays": "Usuário recebeu {earned} <b>{leaveTypeName}</b> {earned, plural, =1 {dia} other {dias}} para {period}",
  "components.logs.nonAccrualLeaveTypeHours": "Usuário recebeu {earned} <b>{leaveTypeName}</b> {earned, plural, =1 {hora} other {horas}} para {period}",
  "components.logs.notificationCreated": "Usuário {user} criou uma notificação <b>{name}</b>.<br></br>Frequência: {frequency}.<br></br>Habilitado: {enabled}.<br></br>Localizações: {locations}.<br></br>Departamentos: {teams}.<br></br>Etiquetas: {labels}.<br></br>Primeiro dia da semana: {firstDayOfWeek}.<br></br>Fuso horário: {timezone}.<br></br>Agrupar por {groupBy}.<br></br>Enviar vazio: {sendEmpty}.<br></br>Destinos: {destinations}.<br></br>Minuto: {minute}.<br></br>Hora: {hour}.<br></br>Dia: {day}.<br></br>Idioma: {locale}.<br></br>Enviar nos dias: {sendOnDays}.<br></br>Semana atual: {currentWeek}",
  "components.logs.notificationDeleted": "Usuário {user} excluiu a notificação",
  "components.logs.notificationDetailsDaily": "Usuário {user} {action} notificação <b>{name}</b>.<br></br>Frequência: {frequency}.<br></br>Habilitado: {enabled}.<br></br>Localizações: {locations}.<br></br>Departamentos: {teams}.<br></br>Etiquetas: {labels}.<br></br>Fuso horário: {timezone}.<br></br>Agrupar por {groupBy}.<br></br>Enviar vazio: {sendEmpty}.<br></br>Destinos: {destinations}.<br></br>Minuto: {minute}.<br></br>Hora: {hour}.<br></br>Idioma: {locale}.<br></br>Enviar nos dias: {sendOnDays}.",
  "components.logs.notificationDetailsMonthly": "Usuário {user} {action} notificação <b>{name}</b>.<br></br>Frequência: {frequency}.<br></br>Habilitado: {enabled}.<br></br>Localizações: {locations}.<br></br>Departamentos: {teams}.<br></br>Etiquetas: {labels}.<br></br>Primeiro dia da semana: {firstDayOfWeek}.<br></br>Fuso horário: {timezone}.<br></br>Agrupar por {groupBy}.<br></br>Enviar vazio: {sendEmpty}.<br></br>Destinos: {destinations}.<br></br>Minuto: {minute}.<br></br>Hora: {hour}.<br></br>Dia: {day}.<br></br>Idioma: {locale}.<br></br>Semana atual: {currentWeek}",
  "components.logs.notificationUpdated": "Usuário {user} atualizou uma notificação <b>{name}</b>.<br></br>Frequência: {frequency}.<br></br>Habilitado: {enabled}.<br></br>Localizações: {locations}.<br></br>Departamentos: {teams}.<br></br>Etiquetas: {labels}.<br></br>Primeiro dia da semana: {firstDayOfWeek}.<br></br>Fuso horário: {timezone}.<br></br>Agrupar por {groupBy}.<br></br>Enviar vazio: {sendEmpty}.<br></br>Destinos: {destinations}.<br></br>Minuto: {minute}.<br></br>Hora: {hour}.<br></br>Dia: {day}.<br></br>Idioma: {locale}.<br></br>Enviar nos dias: {sendOnDays}.<br></br>Semana atual: {currentWeek}",
  "components.logs.probationPeriodCreated": "{user} criou um Período de Experiência <b>{name}</b>.<br></br>Habilitado: {enabled}.<br></br>Duração (dias): {lengthDays}.<br></br>Tipos de Licença: {leaveTypes}.<br></br>Localizações: {locations}.<br></br>Departamentos: {teams}.<br></br>Etiquetas: {labels}.<br></br>",
  "components.logs.probationPeriodDeleted": "{user} excluiu um Período de Experiência.",
  "components.logs.probationPeriodUpdated": "{user} atualizou um Período de Experiência <b>{name}</b>.<br></br>Habilitado: {enabled}.<br></br>Duração (dias): {lengthDays}.<br></br>Tipos de Licença: {leaveTypes}.<br></br>Localizações: {locations}.<br></br>Departamentos: {teams}.<br></br>Etiquetas: {labels}.<br></br>",
  "components.logs.seniorityEntitlementCreated": "{user} criou um Direito por Antiguidade <b>{name}</b>.<br></br>Habilitado: {enabled}.<br></br>Proporcional: {prorated}.<br></br>Data de Início: {startDate}.<br></br> Tipo de Licença: {leaveType}.<br></br>Concedido: {entitlementPeriods}.<br></br>Localizações: {locations}.<br></br>Departamentos: {teams}.<br></br>Etiquetas: {labels}.<br></br>",
  "components.logs.seniorityEntitlementDeleted": "{user} excluiu um Direito por Antiguidade.",
  "components.logs.seniorityEntitlementUpdated": "{user} atualizou o Direito por Antiguidade <b>{name}</b>.<br></br>Habilitado: {enabled}.<br></br>Proporcional: {prorated}.<br></br>Data de Início: {startDate}.<br></br> Tipo de Licença: {leaveType}.<br></br>Concedido: {entitlementPeriods}.<br></br>Localizações: {locations}.<br></br>Departamentos: {teams}.<br></br>Etiquetas: {labels}.<br></br>",
  "components.logs.subscriptionCancelled": "<b>{user}</b> cancelou a assinatura.<br></br>Motivo: {reason}.<br></br>Comentário: {comment}",
  "components.logs.subscriptionChanged": "Assinatura alterada.<br></br>Novo plano: {plan}.<br></br>Período: {period}.<br></br>Status: {status}.<br></br>Processador de pagamento: {paymentProcessor}",
  "components.logs.substituteApproverCreated": "{substituteApproverName} foi definido como Aprovador Substituto para {approverFor} por {creator} a partir de {startDate} até {endDate}",
  "components.logs.substituteApproverDeleted": "{creator} removeu {substituteApproverName} como Aprovador Substituto.",
  "components.logs.substituteApproverUpdated": "{creator} atualizou o aprovador substituto.<br></br>Nome do Aprovador Substituto: {substituteApproverName}<br></br>Data de início: {startDate}<br></br>Data de término: {endDate}<br></br>",
  "components.logs.teamApproverStatusChanged": "{user} definiu os aprovadores {approvers} para a equipe {team}",
  "components.logs.teamCreated": "{user} criou um departamento <b>{team}</b>.<br></br>Departamento padrão: {isDefault}.<br></br>Usuários: {users}.<br></br>Aprovador(es): {approvers} <br></br>Departamento pai: {parentDepartment}",
  "components.logs.teamDeleted": "{user} excluiu a equipe",
  "components.logs.teamMaximumUsersAwayCreated": "{user} criou uma configuração de Máximo de Usuários Ausentes <b>{name}</b>.<br></br>Ativado: {enabled}.<br></br>Recorrente: {recurring}.<br></br>Máximo de usuários {maxUsersAwayType} permitidos: {maxUsersAllowed}.<br></br>Data de Início: {startDate}.<br></br>Data de Término: {endDate}.<br></br>Tipos de Licença: {leaveTypes}.<br></br>Localizações: {locations}.<br></br>Departamentos: {teams}.<br></br>Etiquetas: {labels}.<br></br>",
  "components.logs.teamMaximumUsersAwayDeleted": "{user} excluiu uma configuração de Máximo de Usuários Ausentes.",
  "components.logs.teamMaximumUsersAwayUpdated": "{user} atualizou uma configuração de Máximo de Usuários Ausentes <b>{name}</b>.<br></br>Ativado: {enabled}.<br></br>Recorrente: {recurring}.<br></br>Máximo de usuários {maxUsersAwayType} permitidos: {maxUsersAllowed}.<br></br>Data de Início: {startDate}.<br></br>Data de Término: {endDate}.<br></br>Tipos de Licença: {leaveTypes}.<br></br>Localizações: {locations}.<br></br>Departamentos: {teams}.<br></br>Etiquetas: {labels}.<br></br>",
  "components.logs.teamUpdated": "{user} atualizou um departamento <b>{team}</b>.<br></br>Departamento padrão: {isDefault}.<br></br>Usuários: {users}.<br></br>Aprovador(es): {approvers} <br></br>Departamento pai: {parentDepartment}",
  "components.logs.userActivatedBy": "Usuário foi ativado por <link>{creatorName}</link>",
  "components.logs.userApproverStatus": "Usuário é <status></status> como aprovador para o departamento <team>{teamName}</team> por <link>{creatorName}</link>",
  "components.logs.userChangedAvatar": "Usuário atualizou sua foto de perfil",
  "components.logs.userDeactivatedBy": "Usuário foi desativado por <link>{creatorName}</link>",
  "components.logs.userDeletedBy": "Usuário foi excluído por <link>{creatorName}</link>",
  "components.logs.userEntitlementByRole": "Usuário recebeu {totalDays} dia(s) de {leaveTypeName} através do Direito por Função",
  "components.logs.userEntitlementByRoleDeleted": "Os dias de Direito por Função do usuário para {leaveTypeName} foram removidos devido à remoção ou exclusão da etiqueta <b>{labelName}</b>.",
  "components.logs.userEntitlementByRoleOverride": "Os dias de direito do usuário para {leaveTypeName} foram substituídos para {totalDays} dia(s) devido a ajustes no Direito por Função",
  "components.logs.userInvitationAccepted": "Usuário aceitou o convite de <link>{creatorName}</link>",
  "components.logs.userLeaveTypeDeleteQuota": "A cota para o tipo de licença <b>{leaveTypeName}</b> foi redefinida para o valor padrão por <link>{creatorName}</link>",
  "components.logs.userLeaveTypeUnlimitedDays": "ilimitado",
  "components.logs.userLeaveTypeUpdatedQuota": "<link>{creatorName}</link> alterou a cota deste usuário para <b>{leaveTypeName}</b> para <quota></quota> dias",
  "components.logs.userLeaveTypeUpdatedQuotaDays": "<link>{creatorName}</link> alterou a cota deste usuário para <b>{leaveTypeName}</b> para <quota></quota> dias",
  "components.logs.userLeaveTypeUpdatedQuotaHours": "<link>{creatorName}</link> alterou a cota deste usuário para <b>{leaveTypeName}</b> para <quota></quota> horas",
  "components.logs.userMovedToLocation": "Usuário foi movido para a localização <locationLink>{location}</locationLink> por <link>{creatorName}</link>",
  "components.logs.userMovedToTeam": "Usuário foi movido para o departamento <teamLink>{team}</teamLink> por <link>{creatorName}</link>",
  "components.logs.userRolloverDays": "Transferido do ano anterior: {leaveTypeName} - {value} dia{s}",
  "components.logs.userRolloverDisplay": "Transferido do ano anterior: {leaveTypeName} - {value}",
  "components.logs.userRolloverExpired": "<b>{total}</b> dias de <b>{leaveTypeName}</b> do ano anterior expiraram",
  "components.logs.userRolloverExpiredDays": "Dias do ano anterior expiraram: <b>{leaveTypeName}</b> - {value} dia{s}",
  "components.logs.userRolloverExpiredHours": "Os dias do ano anterior expiraram: <b>{leaveTypeName}</b> - {value} hora{s}",
  "components.logs.userRolloverHours": "Trazido do ano anterior: <b>{leaveTypeName}</b> - {value} hora{s}",
  "components.logs.userSeniorityEntitlement": "O usuário recebeu {totalDays} dia(s) de {leaveTypeName} através do Direito por Antiguidade",
  "components.logs.userSeniorityEntitlementOverride": "Os dias de direito do usuário para {leaveTypeName} foram substituídos por {totalDays} dia(s) devido a ajustes no Direito por Antiguidade",
  "components.logs.userUpdated": "Usuário <name></name> <email></email> <startDate></startDate> <endDate></endDate> <userStatus></userStatus> por <link>{creatorName}</link>",
  "components.logs.userUpdatedChangeStatusToAdmin": "função alterada para Administrador",
  "components.logs.userUpdatedChangeStatusToUser": "função alterada para Usuário",
  "components.logs.userUpdatedEmail": "e-mail alterado para {email}",
  "components.logs.userUpdatedEndDate": "data de término alterada para {endDate}",
  "components.logs.userUpdatedName": "Nome alterado para <wrappedName>{name}</wrappedName>",
  "components.logs.userUpdatedStartDate": "Data de início alterada para {startDate}",
  "components.logs.userWorkWeek": "A semana de trabalho do usuário foi alterada para {workDays} por <link>{creatorName}</link>",
  "components.logs.userWorkWeekDefaultLocation": "A semana de trabalho do usuário foi redefinida para o padrão da localização por <link>{creatorName}</link>",
  "components.logs.visibilityAddonCreated": "{user} limitou a visibilidade para a empresa.<br></br>Ativado: {enabled}.<br></br> Nível de visibilidade: {visibilityLevel}.<br></br>",
  "components.logs.visibilityAddonUpdated": "{user} atualizou a visibilidade limitada para a empresa.<br></br>Ativado: {enabled}.<br></br> Nível de visibilidade: {visibilityLevel}.<br></br>",
  "components.manageAssignLicenses.assignLicenses": "Atribuir {numberOfToAssignLicenses} licenças",
  "components.manageAssignLicenses.avaliableToAssign": "Disponível para atribuir: {avaliableLicenses}",
  "components.manageAssignLicenses.microsoftTeamsMessage": "Mensagem do Microsoft Teams",
  "components.manageAssignLicenses.moreUserThenLicenses": "Você atribuiu {users} licenças a mais do que o disponível",
  "components.manageAssignLicenses.placeholderBulkWelcomeMessage": "Selecionar ação em massa",
  "components.manageAssignLicenses.placeholderSelectRole": "Selecionar função",
  "components.manageAssignLicenses.roleTooltipInfo": "Atribua funções de usuário no Vacation Tracker. Para mais informações, visite nossa <helpDesk>Central de Ajuda</helpDesk>.",
  "components.manageAssignLicenses.sendWelcomeMessage": "Enviar mensagem de boas-vindas",
  "components.manageAssignLicenses.totalLicenses": "Total de licenças: {numberOflicenses}",
  "components.maximumUsersAwaySummaryInfo.summary": "Esta política limita o número de usuários que podem estar de licença simultaneamente a {noOfUsers} usuário<multiUsers>s</multiUsers> <link>(Ver Usuário<multiUsers>s</multiUsers> <isAbsolute>Filtrados</isAbsolute>)</link> Este limite se aplica <endDate>entre</endDate> {fromDate} <endDate>e {toDate}</endDate><recurring> a cada ano</recurring>. Quando o limite for atingido, quaisquer novas solicitações de licença serão automaticamente rejeitadas com a mensagem especificada.",
  "components.modal.confirm": "Confirmar",
  "components.modal.verify": "Verificar",
  "components.notificationDestination.label": "Enviar para",
  "components.notificationDestination.tooltip": "Observe que você pode enviar a Notificação para vários e-mails, mas apenas um canal por vez.",
  "components.notificationForm.channel": "Canal",
  "components.notificationForm.channelSlackInfo": "As notificações podem ser enviadas apenas para os canais dos quais você faz parte.",
  "components.notificationForm.daily": "Diariamente",
  "components.notificationForm.day": "Dia",
  "components.notificationForm.firstDayOfWeek": "A Semana Começa Em",
  "components.notificationForm.refreshTheList": "Atualizar a lista",
  "components.notificationForm.team": "Equipe do Microsoft Teams",
  "components.notificationForm.time": "Hora",
  "components.notificationForm.timezone": "Fuso horário",
  "components.notificationForm.weekly": "Semanalmente",
  "components.notificationSummaryInfo.notificationSummary": "A Notificação será enviada <to>para</to> <channelWrapper>{channelName} </channelWrapper> <and>e</and> <addressesWrapper>{numberOfEmailAddress} endereço<multiEmailAddress>s</multiEmailAddress> de e-mail</addressesWrapper> em {sendingDaysInfo} às {time} horas. Incluirá informações de ausência de <danger>{noOfUsers} Usuário<multiUsers>s</multiUsers></danger> <link>(Ver Usuário<multiUsers>s</multiUsers>)</link>",
  "components.onboarding.assignLicensesTitle": "<icon></icon> Atribuir Licenças",
  "components.onboarding.installMSBotButton": "Instalar o bot",
  "components.onboarding.installMSBotDescription": "Instale o Bot e as Abas do Vacation Tracker para gerenciar facilmente as ausências da sua equipe no Microsoft Teams.",
  "components.onboarding.installMSBotTitle": "<icon></icon> Completar Configuração do Bot",
  "components.onboarding.inviteFriendsDescription": "Traga sua equipe para um gerenciamento de ausências preciso e oportuno. (Adicione pelo menos 3 membros da equipe para concluir esta etapa.)",
  "components.onboarding.inviteFriendsTitle": "<icon></icon> Convide os Membros da Sua Equipe",
  "components.onboarding.joyride.admin.assignLeavePolicy.info1": "Como mencionamos antes, os Tipos de Ausência são criados para toda a Organização. As Políticas de Ausência estão vinculadas às Localizações. Cada Tipo de Ausência pode ser definido para cada Localização atribuindo-lhe uma Política de Ausência personalizada.",
  "components.onboarding.joyride.admin.assignLeavePolicy.info2": "Depois de selecionar o Tipo de Ausência que você deseja adicionar à Localização, você pode atribuir a Política de Ausência. Você tem a opção de copiar as Configurações de uma Política de Ausência existente ou criar uma Política de Ausência completamente nova com novos valores.",
  "components.onboarding.joyride.admin.assignLeavePolicy.title": "Atribuir Políticas de Ausência",
  "components.onboarding.joyride.admin.createLocation.info1": "Se você tem membros da equipe em diferentes localizações, você tem a opção de criar várias Localizações no Vacation Tracker e adicionar Usuários à Localização correspondente.",
  "components.onboarding.joyride.admin.createLocation.info2": "Cada Localização tem suas próprias Políticas de Ausência que você precisa atribuir a um determinado Tipo de Ausência. Os feriados também estão vinculados às Localizações.",
  "components.onboarding.joyride.admin.createLocation.title": "Criar uma Localização",
  "components.onboarding.joyride.admin.createNotifications.info1": "Agora que você concluiu as etapas mencionadas acima, é hora de criar Notificações.",
  "components.onboarding.joyride.admin.createNotifications.title": "Criar e Editar Notificações",
  "components.onboarding.joyride.admin.createTeams.info1": "Depois de concluir o Dashboard, que fornece uma visão geral das folgas de hoje e programadas em sua organização mencionadas acima, é hora de criar Departamentos.",
  "components.onboarding.joyride.admin.createTeams.info2": "Ao criar um Departamento, você terá que adicionar os Aprovadores. Os Aprovadores têm o segundo nível mais alto de permissões no Vacation Tracker e podem aprovar/negar solicitações de folga enviadas pelos Usuários do Departamento em que são Aprovadores.",
  "components.onboarding.joyride.admin.createTeams.title": "Criar Departamentos e Atribuir Aprovadores",
  "components.onboarding.joyride.admin.helpDesk.info": "Se você tiver alguma dúvida ou precisar de mais informações, consulte nossa Central de Ajuda ou inicie um chat com nossa equipe de suporte ao cliente.",
  "components.onboarding.joyride.admin.helpDesk.title": "Central de Ajuda",
  "components.onboarding.joyride.admin.importHolidays.info1": "No Vacation Tracker, os feriados são definidos para cada Localização, e não para toda a Organização.",
  "components.onboarding.joyride.admin.importHolidays.info2": "Você tem a opção de Importar Feriados de nossa lista de Feriados predefinidos para cada país, e a opção de importar um Feriado manualmente.",
  "components.onboarding.joyride.admin.importHolidays.title": "Importar Feriados",
  "components.onboarding.joyride.admin.leaveTypesMenu.info1": "Sempre recomendamos que você inicie sua jornada no Vacation Tracker criando Tipos de Folga. Você atribuirá esses Tipos de Folga mais tarde ao criar todas as localizações onde sua empresa tem funcionários.",
  "components.onboarding.joyride.admin.leaveTypesMenu.info2": "Visite a <link>Central de Ajuda</link> para um guia passo a passo sobre como criar e editar Tipos de Folga.",
  "components.onboarding.joyride.admin.leaveTypesMenu.title": "Criar Tipos de Folga",
  "components.onboarding.joyride.admin.newLeaveType.info1": "Além do Tipo de Folga Padrão (PTO), você pode criar até 24 Tipos de Folga adicionais.",
  "components.onboarding.joyride.admin.start.title": "Aprenda sobre o Vacation Tracker! 🏖️",
  "components.onboarding.joyride.admin.start1": "Para ajudar você a começar, reunimos algumas etapas curtas que recomendamos seguir para configurar rapidamente sua organização.",
  "components.onboarding.joyride.admin.start2": "Se precisar de nossa ajuda ao longo do caminho, estamos a apenas um e-mail de distância em <link>hello@vacationtracker.io</link>",
  "components.onboarding.joyride.admin.teams.cta1": "Instalar bot do Microsoft Teams",
  "components.onboarding.joyride.admin.teams.cta2": "Saiba mais em nossa Central de Ajuda",
  "components.onboarding.joyride.admin.teams.info1": "Você está tudo configurado no painel. A última etapa é instalar o bot do Microsoft Teams para que sua equipe possa fazer solicitações diretamente no Microsoft Teams.",
  "components.onboarding.joyride.admin.teams.title": "Microsoft Teams",
  "components.onboarding.joyride.approver.dashboard.info1": "Esta página é seu centro de comando. Ela fornece uma visão geral das folgas pendentes, quem está fora hoje e as próximas folgas para sua organização.",
  "components.onboarding.joyride.approver.leaveRequests.info1": "Como Aprovador, você pode \"Adicionar Folga\" para qualquer membro da equipe da qual você é Aprovador. Você também pode \"Solicitar Folga\" para si mesmo, que será enviada para aprovação ao seu Aprovador.",
  "components.onboarding.joyride.approver.notifications.info1": "Esta página oferece uma visão geral de todas as Notificações sendo enviadas em toda a sua organização.",
  "components.onboarding.joyride.approver.notifications.info2": "Você pode criar ou editar Notificações para Usuários dos Departamentos dos quais você é Aprovador.",
  "components.onboarding.joyride.approver.notifications.info3": "Observe que você só pode gerenciar as Notificações criadas por você.",
  "components.onboarding.joyride.approver.users.info1": "A página 'Usuários' fornece uma visão geral completa de todos os usuários ativos em seu Departamento, juntamente com acesso somente leitura aos seus Perfis de Usuário. Suas solicitações pendentes estão disponíveis na página 'Dashboard'.",
  "components.onboarding.joyride.user.calendar.info1": "Nesta página, você pode ver o histórico e as próximas folgas da sua organização em formato de calendário.",
  "components.onboarding.joyride.user.dashboard.info1": "O Dashboard fornece uma visão geral das folgas de hoje e programadas dentro da sua organização.",
  "components.onboarding.joyride.user.holidays.info1": "Aqui você pode revisar os Feriados dentro da sua Localização, para garantir que você saiba quais dias já tem folga.",
  "components.onboarding.joyride.user.myProfile.info1": "Aqui você pode ver seus tipos de folga disponíveis, cotas, dias de trabalho e seu histórico de folgas.",
  "components.onboarding.joyride.user.requestLeave.info1": "Para solicitar Folga através do Dashboard do Vacation Tracker, selecione um dos tipos de folga disponíveis, adicione o período e o motivo, se necessário, e envie a solicitação. Uma vez enviada, ela será encaminhada para aprovação do Aprovador.",
  "components.onboarding.joyride.user.start1": "Para ajudar você a começar, reunimos uma lista de cinco etapas que recomendamos seguir para configurar rapidamente sua organização.",
  "components.onboarding.openNotificationsButton": "Abrir notificações",
  "components.onboarding.requestALeaveDescription": "Envie sua primeira solicitação de folga em 3 etapas simples.",
  "components.onboarding.requestALeaveTitle": "<icon></icon> Solicitar uma Folga",
  "components.onboarding.setNotificationsDescription": "Escolha com que frequência você gostaria de ser notificado sobre as folgas da sua equipe para se manter informado.",
  "components.onboarding.setNotificationsTitle": "<icon></icon> Configurar Notificações",
  "components.onboarding.skipOnboarding": "Pular integração",
  "components.onboarding.syncTheCalendarDescription": "Integre o calendário do Vacation Tracker com seu calendário para que as folgas da sua equipe fiquem atualizadas.",
  "components.onboarding.syncTheCalendarTitle": "<icon></icon> Sincronizar Calendários",
  "components.onboarding.syncTheCalendarVideoForSyncingWithGoogleCalendar": "Vídeo para sincronização com o Google Calendar",
  "components.onboarding.syncTheCalendarVideoForSyncingWithIcal": "Vídeo para sincronização com iCal",
  "components.onboarding.syncTheCalendarVideoForSyncingWithMicrosoftOutlookCalendar": "Vídeo para sincronização com MS Windows",
  "components.onboarding.title": "Lista de verificação de integração <progressBar>{progressStatus}</progressBar>",
  "components.paymentInformation.expiryDate": "Data de Vencimento",
  "components.paymentInformation.labelCreditCard": "Cartão de crédito",
  "components.paymentInformation.title": "Informações de pagamento",
  "components.platformSwitchModal.content": "Você deve primeiro fazer login com sua conta {platform} para mudar para a plataforma {platform}. Por favor, clique no botão abaixo para continuar. Após fazer login e conceder as permissões necessárias, redirecionaremos para o processo de mapeamento de usuários.",
  "components.platformSwitchModal.title": "Migração para a plataforma {platform}",
  "components.pricePlanCard.accruals": "Acumular Folgas",
  "components.pricePlanCard.APISupport": "Suporte à API",
  "components.pricePlanCard.calendarIntegrations": "Integração de Calendário",
  "components.pricePlanCard.contactUs": "Entre em contato",
  "components.pricePlanCard.coreDepartmentsAndLocations": "Crie até 3 Locais e 10 Departamentos",
  "components.pricePlanCard.currentPlan": "Plano Atual",
  "components.pricePlanCard.customContract": "Contrato personalizado",
  "components.pricePlanCard.customizeLeaveTypes": "Personalize Tipos de Folga",
  "components.pricePlanCard.customLocationsDepartmentsLeaveTypes": "Locais, Departamentos e Tipos de Folga Personalizados Ilimitados",
  "components.pricePlanCard.customOnboardingAndSetup": "Integração e configuração personalizadas",
  "components.pricePlanCard.dedicatedSupport": "Suporte Dedicado",
  "components.pricePlanCard.disableDowngradeToCore": "O downgrade para o plano Core requer {noOfActions} {noOfActions, plural, =1 {ação} other {ações}}",
  "components.pricePlanCard.disableDowngradeToCoreDescription": "Para mudar para o plano Core, faça os seguintes ajustes:",
  "components.pricePlanCard.disableDowngradeToCoreReasonDepartments": "O plano Core permite um máximo de 10 departamentos. Por favor, remova quaisquer departamentos adicionais.",
  "components.pricePlanCard.disableDowngradeToCoreReasonLocations": "O plano Core permite um máximo de 3 locais. Por favor, remova quaisquer locais adicionais.",
  "components.pricePlanCard.disableDowngradeToCoreReasonSubdepartments": "No plano Core, os departamentos só podem ser aninhados em um nível. Atualmente, você tem {total} subdepartamentos. Por favor, exclua alguns subdepartamentos para mudar para o plano Core.",
  "components.pricePlanCard.disableDowngradeToCoreReasonWorkday": "Não é possível mudar para o plano Core enquanto estiver usando horas de trabalho personalizadas. Por favor, redefina as horas de trabalho personalizadas para o padrão (8) nas configurações de locais",
  "components.pricePlanCard.disableDowngradeToCoreTitle": "Downgrade do plano. Ajustes necessários",
  "components.pricePlanCard.disableDowngradeToMultiLevelApproval": "Não é possível mudar para o plano Core enquanto o fluxo de aprovação multinível estiver ativo. Para fazer o downgrade, por favor, mude para aprovações de nível único nas configurações dos Departamentos",
  "components.pricePlanCard.displayLeaveInformationInDaysHours": "Exibir Informações de Folga em Dias/Horas",
  "components.pricePlanCard.downgradePlan": "O plano será rebaixado no próximo ciclo de faturamento",
  "components.pricePlanCard.duringTheTrial": "Durante o período de teste, qualquer mudança de plano será implementada imediatamente",
  "components.pricePlanCard.everythingInTheCompletePlan": "Tudo no plano Complete",
  "components.pricePlanCard.EverythingInTheCorePlan": "Tudo no plano Core",
  "components.pricePlanCard.exportsAndReports": "Exportações e Relatórios",
  "components.pricePlanCard.forSeats": "para menos de {users} assentos",
  "components.pricePlanCard.forUsers": "para menos de {users} usuários",
  "components.pricePlanCard.hourlyLeaves": "Rastrear Folgas em Horas",
  "components.pricePlanCard.HRFields": "Campos de RH (em breve)",
  "components.pricePlanCard.labels": "Organizar Usuários por Rótulos",
  "components.pricePlanCard.managePTO": "Gerenciar solicitações de PTO",
  "components.pricePlanCard.minimumPlanPrice": "mínimo <price><oldPrice>R${totalPrice}</oldPrice><newPrice>R${newTotalPrice}</newPrice></price>",
  "components.pricePlanCard.notifications": "Enviar Notificações de PTO",
  "components.pricePlanCard.pricePerSeat": "<price><oldPrice>R${pricePerUser}</oldPrice><newPrice>R${newPricePerUser}</newPrice></price>por assento/mês",
  "components.pricePlanCard.pricePerUser": "<price><oldPrice>R${pricePerUser}</oldPrice><newPrice>R${newPricePerUser}</newPrice></price>por usuário/mês",
  "components.pricePlanCard.reports": "Gerar Relatórios",
  "components.pricePlanCard.reportsSchedule": "Agendar Relatórios",
  "components.pricePlanCard.requestHourlyLeaveIncrements": "Solicitar Folgas em Incrementos de Hora",
  "components.pricePlanCard.scheduleAConsultation": "Agendar uma Consulta",
  "components.pricePlanCard.scheduledReport": "Relatórios Agendados",
  "components.pricePlanCard.selectPlan": "Selecionar Plano",
  "components.pricePlanCard.sharedCalendars": "Calendários Compartilhados",
  "components.pricePlanCard.SSOIntegration": "Integração SSO Empresarial (Okta, etc.)",
  "components.pricePlanCard.startFreeTrial": "Comece seu Teste Gratuito de 14 Dias",
  "components.pricePlanCard.substituteApprovers": "Aprovadores Substitutos",
  "components.pricePlanCard.support": "Suporte Prioritário",
  "components.pricePlanCard.switchPlan": "Mudar <period> Para o Plano {newPeriod}</period>",
  "components.pricePlanCard.totalPrice": "<price><oldPrice>R${totalPrice}</oldPrice><newPrice>R${newTotalPrice}</newPrice></price>/mês para {totalUsers} usuários ativos",
  "components.pricePlanCard.totalPriceForLessThan25Users": "<price><oldPrice>R${totalPrice}</oldPrice><newPrice>R${newTotalPrice}</newPrice></price>/mês para menos de 25 usuários",
  "components.pricePlanCard.totalPricePerSeats": "<price><oldPrice>R${totalPrice}</oldPrice><newPrice>R${newTotalPrice}</newPrice></price>/ano",
  "components.pricePlanCard.totalPricePerUser": "<price><oldPrice>R${totalPrice}</oldPrice><newPrice>R${newTotalPrice}</newPrice></price>/ano",
  "components.pricePlanCard.totalPriceSeats": "<price><oldPrice>${totalPrice}</oldPrice><newPrice>${newTotalPrice}</newPrice></price>/mês para {totalUsers} assentos",
  "components.pricePlanCard.unlimitedDepartmentsAndLocations": "Crie Departamentos e Localizações Ilimitados",
  "components.pricePlanCard.upgradePlan": "O plano será ativado imediatamente e o preço será prorrateado",
  "components.reports.introOldOnes": "Ir para Relatórios Antigos",
  "components.reports.introPre": "Seus Relatórios ficaram mais inteligentes! Visualize dados instantaneamente, filtre com precisão e personalize suas colunas — tudo na nova interface. Não está pronto para mudar? Sua visualização familiar de Relatórios está a apenas um clique de distância.",
  "components.requestAutomation.anythingElse": "Mais alguma coisa que devemos saber?",
  "components.requestAutomation.automationExtraCharge": "Você usaria este Add-On se houvesse uma cobrança extra?",
  "components.requestAutomation.automationFunctionalities": "Quais funcionalidades ou tarefas o Add-On permitirá que você execute?",
  "components.requestAutomation.automationShouldWeAdd": "Qual Add-On devemos adicionar?",
  "components.requestAutomation.submiteRequest": "Enviar Solicitação",
  "components.requestAutomation.suggestAnAutomation": "Sugerir um Add-On",
  "components.resetQuotas.fiscalYear": "Ano Contábil ({date})",
  "components.resetQuotas.userYear": "Data de Admissão do Usuário",
  "components.selectChannelForm.addNewEmail": "Adicionar Destinatário",
  "components.selectChannelForm.channel": "Canal",
  "components.selectChannelForm.channelSlackInfo": "As notificações podem ser enviadas apenas para os canais dos quais você faz parte.",
  "components.selectChannelForm.email": "Destinatários",
  "components.selectChannelForm.errorNotification": "Erro de Notificação",
  "components.selectChannelForm.errorNotInTeams": "Acesso negado. Para usar este canal, você deve ser membro deste departamento.",
  "components.selectChannelForm.notFound": "Sua organização se inscreveu com uma única equipe do Microsoft Teams, e você não é membro da equipe com o seguinte ID: {msTeamId}.\n\nVocê pode ingressar nesta equipe no seu aplicativo Microsoft Teams e tentar novamente, ou contatar nosso suporte para alterar seu tipo de instalação.",
  "components.selectChannelForm.notFoundTitle": "Você não é membro da equipe selecionada do Microsoft Teams",
  "components.selectChannelForm.team": "Equipe",
  "components.selectChannelFormMicrosoft.channelPlaceholder": "Por favor, selecione um Canal",
  "components.selectChannelFormMicrosoft.errorNotInTeams": "Acesso negado. Para usar este canal, você deve ser membro deste departamento.",
  "components.selectChannelFormMicrosoft.notFound": "Sua organização se inscreveu com uma única equipe do Microsoft Teams, e você não é membro da equipe com o seguinte ID: {msTeamId}.\n\nVocê pode ingressar nesta equipe no seu aplicativo Microsoft Teams e tentar novamente, ou contatar nosso suporte para alterar seu tipo de instalação.",
  "components.selectChannelFormMicrosoft.notFoundTitle": "Você não é membro da equipe selecionada do Microsoft Teams",
  "components.selectChannelFormMicrosoft.teamPlaceholder": "Por favor, selecione uma Equipe",
  "components.selectChannelFormSlack.errorNotification": "Erro de Notificação",
  "components.selectChannelFormSlack.missingSlackScopes": "Escopos do Slack Ausentes",
  "components.selectChannelFormSlack.privateChannel": "(canal privado)",
  "components.selectChannelFormSlack.slackBotIdIsRequred": "Erro do Slack: ID do Bot do Slack é obrigatório! Tente atualizar a página!",
  "components.selectChannelFormSlack.slackError": "Erro do Slack",
  "components.selectChannelFormSlack.toProccedPleaseLogin": "Para prosseguir, faça login na sua conta do Slack",
  "components.selectChannelFormSlack.youCanAddTheBotOnlyOnTheChannels": "Você não é membro de {channelName}. Para enviar a notificação para este canal, você precisa ser membro dele.",
  "components.seniorityEntitlement.addDaysFrom": "Adicionar dias a partir de",
  "components.seniorityEntitlement.allFiledsAreRequired": "Todos os campos são obrigatórios",
  "components.seniorityEntitlement.confirmDescription": "Optar por começar a calcular o direito por antiguidade a partir da data de início do funcionário ajustará as cotas do usuário retroativamente a partir do ano de início do cálculo.",
  "components.seniorityEntitlement.confirmTitle": "Confirmar criação da automação",
  "components.seniorityEntitlement.daysAfter": "dia(s) depois",
  "components.seniorityEntitlement.disableEditInfo": "Você não pode editar a automação de direito por antiguidade. Se precisar fazer alterações, exclua esta automação e crie uma nova. <link>Entre em contato conosco</link> se tiver alguma dúvida ou precisar de ajuda.",
  "components.seniorityEntitlement.hoursAfter": "hora(s) depois",
  "components.seniorityEntitlement.noLocations": "Você tem uma automação que foi aplicada a todos os usuários, não é possível ter várias automações para todos os usuários.",
  "components.seniorityEntitlement.notSortedYearInPeriodError": "Os anos devem ser classificados cronologicamente.",
  "components.seniorityEntitlement.prorateForTheFirstYear": "Deseja habilitar o pró-rata?",
  "components.seniorityEntitlement.prorateForTheFirstYearTooltip": "Se a data de início do funcionário não coincidir com a data de início do ano fiscal, faremos o pró-rata dos dias baseados na antiguidade no ano em que são concedidos. Por exemplo, se o seu ano fiscal começa em 1º de janeiro, o usuário começou a trabalhar em 1º de julho há 5 anos, e sua empresa concede 5 dias adicionais de PTO em 5 de julho após 5 anos de serviço, o usuário receberá 2,5 dias em 1º de julho devido ao pró-rata. Este usuário receberá todos os 5 dias baseados na antiguidade no próximo ano fiscal.",
  "components.seniorityEntitlement.sameYearError": "Você não pode atribuir várias regras ao mesmo número de anos.",
  "components.seniorityEntitlement.startApplyingSeniorityEntitlementFrom": "Começar a aplicar o direito por antiguidade a partir de",
  "components.seniorityEntitlement.startApplyingSeniorityEntitlementFromInfo": "Se você escolher começar a aplicar o direito por antiguidade a partir da data de início do funcionário, essa mudança afetará as cotas do usuário no passado.",
  "components.seniorityEntitlement.summaryInfoPrefix": "A Automação de Antiguidade concede licença adicional em uma abordagem baseada em marcos. Por exemplo: ",
  "components.seniorityEntitlement.summaryInfoSuffix": ", totalizando {amount} <showDays>dias</showDays><showHour>hora(s)</showHour> de licença por antiguidade.",
  "components.seniorityEntitlement.summeryInfoFirstRow": "{amount} <showDays>dias</showDays><showHour>hora(s)</showHour> são adicionados na marca de {year} ano(s)",
  "components.seniorityEntitlement.summeryInfoMoreDays": ", mais {amount} <showDays>dias</showDays><showHour>hora(s)</showHour> na marca de {year} anos",
  "components.seniorityEntitlement.yearsInTheCOmpany": "anos na empresa",
  "components.setupMsBot.followDirectLink": "1) Clique em <directLink>este link</directLink> para encontrar o aplicativo Vacation Tracker na loja do Microsoft Teams.",
  "components.setupMsBot.inMSAppPreviewWindows": "2) Na janela de visualização do aplicativo, clique na seta ao lado do botão \"Abrir\" e clique em \"Adicionar a uma equipe\"",
  "components.setupMsBot.setUpInMS": "3) Você será solicitado a selecionar a equipe e o canal onde deseja instalar o Vacation Tracker. Você também terá a opção de fixar o Vacation Tracker como uma guia para acesso mais fácil e rápido!",
  "components.setupMsBot.stepByStep": "Para instruções detalhadas passo a passo, assista a este vídeo ou visite nossa <helpDesk>Central de Ajuda</helpDesk>.",
  "components.setupMsBot.title": "Configure o bot e as guias do Microsoft Teams",
  "components.signupWithAnotherPlatform.connectWith": " Inscreva-se com {platform}",
  "components.signupWithAnotherPlatform.info": "Gostaria de conectar com sua conta {platform}? Ao fazer isso, você poderá importar e sincronizar usuários do seu {importUsersPlatform} e gerenciar licenças diretamente do seu aplicativo {platform}. Para mais informações, confira nossa <helpDesk>Central de Ajuda</helpDesk>.",
  "components.signupWithAnotherPlatform.or": "Ou",
  "components.signupWithAnotherPlatform.title": "Parece que você está usando {platform}",
  "components.switchPlanModal.downgradeProbationPeriodWarning": "O Add-On de Período de Experiência é um recurso pago para o plano Core. Se você fizer o downgrade e não cancelar a assinatura deste Add-On, será cobrado $0,50 por usuário por mês.",
  "components.switchPlanModal.switch": "Mudar",
  "components.switchPlanModal.switchCoreMonthlyToCompleteMonthly": "O plano mensal <strong>Complete</strong> será ativado imediatamente e o preço será prorrateado para o ciclo de faturamento atual.<legacyPlan> Observe que você não poderá voltar para o plano legado.</legacyPlan>",
  "components.switchPlanModal.switchFromAnnuallyToMonthly": "O plano mensal <strong>{newPlan}</strong> será ativado e cobrado após o término do ciclo de faturamento anual atual ({endDatePeriod}).<legacyPlan> Observe que você não poderá voltar para o plano legado.</legacyPlan>",
  "components.switchPlanModal.switchFromCompleteAnnualToCoreAnnual": "O plano anual <strong>Core</strong> será ativado após o término do ciclo de faturamento anual atual ({endDatePeriod}), até lá você pode continuar usando os recursos do plano <strong>Complete</strong>",
  "components.switchPlanModal.switchFromCompleteAnnualToCoreMonthly": "O plano <strong>Core</strong> será ativado após o término do ciclo de faturamento anual atual ({endDatePeriod}), até lá você pode continuar usando os recursos do plano <strong>Complete</strong>.",
  "components.switchPlanModal.switchFromCompleteMonthlyToCoreAnnual": "O plano anual <strong>Core</strong> será ativado após o término do ciclo de faturamento mensal atual ({endDatePeriod}), até lá você pode continuar usando os recursos do plano <strong>Complete</strong>.",
  "components.switchPlanModal.switchFromCompleteMonthlyToCoreMonthly": "O plano mensal <strong>Core</strong> será ativado após o término do ciclo de faturamento atual ({endDatePeriod}), até lá você pode continuar usando os recursos do plano <strong>Complete</strong>.",
  "components.switchPlanModal.switchFromCoreAnnualToCompleteAnnual": "O plano anual <strong>Complete</strong> será ativado imediatamente e você será cobrado pela diferença prorrateada no preço até o final do ciclo de faturamento anual atual.<legacyPlan> Observe que você não poderá voltar para o plano legado.</legacyPlan>",
  "components.switchPlanModal.switchFromCoreMonthlyToCompleteAnnual": "O plano anual <strong>Complete</strong> será ativado imediatamente. Seu preço anual será reduzido pelo valor restante na sua fatura mensal atual.<legacyPlan> Observe que você não poderá voltar para o plano legado.</legacyPlan>",
  "components.switchPlanModal.switchFromMonthlyToAnnually": "O plano anual <strong>{newPlan}</strong> será ativado imediatamente. Seu preço anual será reduzido pelo valor restante na sua fatura mensal atual.<legacyPlan> Observe que você não poderá voltar para o plano legado.</legacyPlan>",
  "components.switchPlanModal.title": "Mudar para o plano <strong>{plan}</strong>",
  "components.switchPlanModal.unsubscribeProbationPeriodAddons": "Cancelar assinatura do período de experiência",
  "components.teamForm.approvers": "Aprovador(es)",
  "components.teamForm.generalSettings": "Configurações Gerais",
  "components.teamForm.isDefault": "Tornar Este Departamento Padrão",
  "components.teamForm.isDefaultExtra": "Ao tornar este Departamento o padrão, todos os novos membros da equipe serão automaticamente adicionados a este Departamento",
  "components.teamForm.name": "Nome",
  "components.teamForm.pleaseEnterTeamName": "Digite o nome do departamento",
  "components.teamForm.users": "Usuários",
  "components.toil.accrualsToilsTotal": "Total ganho de {hours, plural, =1 {horas} other {dias}} (TOIL + acumulado): {total}",
  "components.toil.added": "TOIL Adicionado",
  "components.toil.addToil": "Adicionar TOIL",
  "components.toil.addToilProgress": "Adicionando TOIL",
  "components.toil.anotherAdd": "Adicionar outro TOIL",
  "components.toil.anotherRequest": "Solicitar outro TOIL",
  "components.toil.approveInProgress": "Aprovando solicitação de TOIL",
  "components.toil.bot.message.editedToilRequest": "Olá, <@{userSlackId}> editou a seguinte solicitação de TOIL:",
  "components.toil.bot.message.leaveTypeName": "*Tipo de licença:*\nTOIL - {leaveTypeName}",
  "components.toil.bot.message.requestToil": "Solicitar TOIL",
  "components.toil.bot.message.requestToilSummary": "Por favor, inicie sua solicitação de Folga Compensatória especificando as datas em que você realizou trabalho extra e inclua observações relevantes. Note que as solicitações de Folga Compensatória só podem ser feitas para datas no passado, incluindo hoje.",
  "components.toil.bot.message.submittedToilRequest": "Olá, <@{userSlackId}> enviou a seguinte solicitação de TOIL:",
  "components.toil.bot.notification.slack.toilRequestAdded": "Uma licença TOIL foi criada para você por um aprovador",
  "components.toil.bot.notification.slack.toilRequestAddedSelf": "Você adicionou uma licença TOIL",
  "components.toil.bot.notification.slack.toilRequestApproved": "✅ <@{approverName}> aprovou a solicitação, nenhuma ação adicional é necessária.",
  "components.toil.bot.notification.slack.toilRequestApprovedTitle": "Sua Solicitação de Licença foi Aprovada",
  "components.toil.bot.notification.slack.toilRequestCreated": "Olá, <@{userSlackId}> enviou uma solicitação de TOIL.",
  "components.toil.bot.notification.slack.toilRequestEdited": "Olá, <@{userSlackId}> editou uma solicitação de TOIL.",
  "components.toil.cancelInProgress": "Cancelando a solicitação de TOIL",
  "components.toil.deleteInProgress": "Excluindo TOIL",
  "components.toil.denied": "Sua solicitação de TOIL foi negada",
  "components.toil.deniedBy": "A solicitação de TOIL foi negada por {approverName}.",
  "components.toil.denyInProgress": "Negando solicitação de TOIL",
  "components.toil.durationLabel": "Menor duração de Folga Compensatória",
  "components.toil.durationLabelTooltip": "Por favor, selecione a menor duração que se aplica à solicitação de TOIL. Esta seleção deve refletir a quantidade mínima de horas extras que um usuário pode registrar.",
  "components.toil.earned": "TOIL acumulado",
  "components.toil.edit": "Atualizar TOIL",
  "components.toil.edited": "TOIL atualizado",
  "components.toil.editRequestToil": "Editar Solicitação de Folga Compensatória",
  "components.toil.editToilProgress": "Editando Folga Compensatória",
  "components.toil.enableLabel": "Habilitar Folga Compensatória:",
  "components.toil.enableLabelTooltip": "TOIL é uma prática onde os funcionários são compensados por trabalhar horas extras recebendo tempo equivalente de folga, ao invés de serem pagos extra pelas horas adicionais trabalhadas.",
  "components.toil.expiration": "Expiração",
  "components.toil.expirationDuration": "{noOfMonths} {noOfMonths, plural, =1 {mês} other {meses}}",
  "components.toil.expirationDurationCalendar": "Final do ano civil",
  "components.toil.expirationLabel": "Os dias de Folga Compensatória devem expirar?",
  "components.toil.expirationLabelTooltip": "Ative esta configuração se você quiser que os dias de TOIL tenham uma data de expiração, após a qual não poderão mais ser usados. Selecionar 'Sim' solicitará que você escolha regras de expiração. Se 'Não' for selecionado, os dias de TOIL não expirarão e permanecerão disponíveis até serem usados.",
  "components.toil.featurePlan": "O recurso TOIL está disponível apenas para o plano Completo.",
  "components.toil.fullDayLeavePeriod": "{date}{endDate} ",
  "components.toil.halfDay": "TOIL de meio dia",
  "components.toil.hourly": "TOIL por hora",
  "components.toil.lapsed": "expirado",
  "components.toil.locationNotHaveToil": "Nenhuma política de licença com TOIL está configurada para {me, plural, =1 {sua} other {a}} localização {me, plural, =1 {} other {do usuário}}",
  "components.toil.notification.added": "TOIL <forUser>para </forUser>{requestor} adicionado <byActionCreator>por {actionCreator}</byActionCreator>",
  "components.toil.notification.approved": "TOIL de {requestor} aprovado <byActionCreator>por {actionCreator}</byActionCreator> ✅",
  "components.toil.notification.cancelled": "TOIL de {requestor} cancelado <byActionCreator>por {actionCreator}</byActionCreator>",
  "components.toil.notification.created": "TOIL solicitado <byActionCreator>por {actionCreator}</byActionCreator>",
  "components.toil.notification.deleted": "TOIL excluído <byActionCreator>por {actionCreator}</byActionCreator>",
  "components.toil.notification.denied": "TOIL de {requestor} negado <byActionCreator>por {actionCreator}</byActionCreator> ❌",
  "components.toil.notification.edited": "TOIL de {requestor} editado <byActionCreator>por {actionCreator}</byActionCreator>",
  "components.toil.notification.editedApproved": "Um TOIL agendado foi editado <byActionCreator>por {actionCreator}</byActionCreator> ✅",
  "components.toil.notification.editedDenied": "TOIL editado negado <byActionCreator>por {actionCreator}</byActionCreator>",
  "components.toil.partDayPeriod": "{date} - de {partDayStartHour}{hourFormat24, select, true {:00} other {}}  até {partDayEndHour}{hourFormat24, select, true {:00} other {}} ",
  "components.toil.reasonDefault": "Horas adicionais trabalhadas durante o período especificado acima.",
  "components.toil.request": "Solicitação de TOIL",
  "components.toil.requested": "TOIL solicitado",
  "components.toil.requestedOrAddedDaysHours": "{action, plural, =1 {Solicitado} other {Adicionado}} {period}",
  "components.toil.requester": "Quem pode solicitar Folga Compensatória?",
  "components.toil.requesterAdmin": "Apenas Administradores e Aprovadores",
  "components.toil.requesterAll": "Todos os Usuários",
  "components.toil.requesterTooltip": "Escolha 'Todos os Usuários' para permitir que cada funcionário envie suas próprias solicitações de TOIL após trabalhar horas extras. Opte por 'Apenas Administradores e Aprovadores' se preferir centralizar o gerenciamento de TOIL e manter um controle mais rígido sobre sua aprovação e registro.",
  "components.toil.requestNotFound": "Solicitação de TOIL não encontrada",
  "components.toil.requestToil": "Solicitar Folga Compensatória",
  "components.toil.requestToilProgress": "Solicitando Folga Compensatória",
  "components.toil.submitError": "Erro ao enviar TOIL",
  "components.toil.submitErrorDate": "A solicitação de TOIL não pode ser enviada porque a data de início selecionada é posterior à data de término. Certifique-se de que a data de início seja anterior à data de término e envie a solicitação novamente",
  "components.toil.submitErrorDateFuture": "A data solicitada para TOIL deve estar no passado",
  "components.toil.submitErrorLongerThanWorkday": "As horas de TOIL solicitadas são maiores que as horas de trabalho",
  "components.toil.submitErrorNotOpen": "TOIL não está aberto",
  "components.toil.submitErrorOverlap": "Os dias de TOIL solicitados estão se sobrepondo com TOIL existente",
  "components.toil.submitErrorPlan": "Este recurso é exclusivamente acessível aos usuários inscritos em nosso plano Completo.",
  "components.toil.submitErrorPolicy": "A política de licença para este tipo de licença não permite TOIL",
  "components.toil.submitErrorPolicyDuration": "Usuários com horas de trabalho personalizadas não podem solicitar TOIL de vários dias",
  "components.toil.submitErrorPolicyLocation": "A opção TOIL não está habilitada para sua localização. Você pode habilitá-la na página de políticas de licença ou entrar em contato com seu administrador para obter suporte.",
  "components.toil.submitErrorReasonNotSet": "O motivo do TOIL é obrigatório",
  "components.toil.submitErrorRole": "Solicitação de TOIL não permitida",
  "components.updateWorkweekForm.cancel": "Cancelar",
  "components.updateWorkweekForm.form.error": "Por favor, selecione pelo menos um dia útil",
  "components.updateWorkweekForm.title": "Atualizar Semana de Trabalho",
  "components.updateWorkweekForm.update": "Atualizar",
  "components.updateWorkweekForm.workWeek": "Semana de Trabalho",
  "components.userBroughtForwardForm.customRolloverDays": "Dias",
  "components.userBroughtForwardForm.leaveTypeEditInfo": "Você está editando o tipo de licença \"{leaveTypeName}\" para o período {dateRangeName}.",
  "components.userBroughtForwardForm.update": "Atualizar",
  "components.userBroughtForwardForm.updateUserLeaveType": "Atualizar dias trazidos para frente para o tipo de licença {name}",
  "components.userLabes.addLabel": "Adicionar Etiqueta",
  "components.userLabes.manageLabels": "Gerenciar Etiquetas",
  "components.userLabes.newLabel": "(nova etiqueta)",
  "components.userLabes.noLabelsFound": "Nenhuma Etiqueta Encontrada",
  "components.userLeaveQuotas.accrualsTooltipTitle": "Acúmulo é o aumento gradual de suas horas de folga remunerada durante o ano.",
  "components.userLeaveQuotas.accrualsTooltipTitle-bi-weekly": "Um acúmulo quinzenal de {leaveType} ocorre a cada duas semanas",
  "components.userLeaveQuotas.accrualsTooltipTitle-monthly": "Um acúmulo mensal de {leaveType} ocorre uma vez por mês.",
  "components.userLeaveQuotas.accrualsTooltipTitle-semi-monthly": "Um acúmulo quinzenal de {leaveType} ocorre duas vezes por mês.",
  "components.userLeaveQuotas.accrualsTooltipTitle-weekly": "Um acúmulo semanal de {leaveType} ocorre toda semana",
  "components.userLeaveQuotas.accruedDays": "Dias Acumulados",
  "components.userLeaveQuotas.accruedHours": "Horas Acumuladas",
  "components.userLeaveQuotas.accruedInfo1": "{userName} acumulou {earnedDays} dias ({earnedDaysInHours} horas) de {defaultDays} dias ({defaultDaysInHours} horas)",
  "components.userLeaveQuotas.accruedInfo1Days": "{userName} acumulou {earnedDays} de {defaultDays} dias",
  "components.userLeaveQuotas.accruedInfo1Hours": "{userName} acumulou {earnedDaysInHours} de {defaultDaysInHours} horas",
  "components.userLeaveQuotas.accruedInfo2": "{userName} acumulará {toAccrueDays} dias ({toAccrueHours} horas) em {earnDate}",
  "components.userLeaveQuotas.accruedInfo2Days": "{userName} acumulará {toAccrueDays} dias em {earnDate}",
  "components.userLeaveQuotas.accruedInfo2Hours": "{userName} acumulará {toAccrueHours} horas em {earnDate}",
  "components.userLeaveQuotas.baseQuota": "Cota base",
  "components.userLeaveQuotas.broughtForward": "Trazido para Frente",
  "components.userLeaveQuotas.broughtForwardDaysInfo": "<rolloverDays>Máximo de {maxRolloverDays} dias<br></br></rolloverDays><expiresDate>Expira em {date}</expiresDate>",
  "components.userLeaveQuotas.broughtForwardInfo": "Trazido para Frente representa o número de dias não utilizados transferidos do período anterior.",
  "components.userLeaveQuotas.broughtForwardInfoDetails": "Para {leaveTypeName} na localização {locationName}, os funcionários podem trazer para frente até {broughtForwardLimit} {broughtForwardLimit, plural, =1 {dia} other {dias}} não utilizado(s).",
  "components.userLeaveQuotas.broughtForwardInfoDetailsAll": "Para {leaveTypeName} na localização {locationName}, todos os dias não utilizados podem ser transferidos para o próximo período.",
  "components.userLeaveQuotas.broughtForwardValue": "Trazido para Frente",
  "components.userLeaveQuotas.editInitialQuotaTitle": "Editar dias Iniciais Trazidos para Frente para {leaveTypeName}",
  "components.userLeaveQuotas.editInitialQuotaTooltip": "Inicial Trazido para Frente permite que você insira manualmente os saldos iniciais dos funcionários de um sistema de rastreamento ou RH anterior",
  "components.userLeaveQuotas.editQuotaButton": "Alterar",
  "components.userLeaveQuotas.expirationTooltip": "Dias que o funcionário trouxe para frente do período anterior, mas que não foram usados antes da data de expiração",
  "components.userLeaveQuotas.expiredDateInfo": "em {date}",
  "components.userLeaveQuotas.forMoreDetails": "Para mais detalhes, veja",
  "components.userLeaveQuotas.initialBroughtForward": "Inicial Trazido para Frente",
  "components.userLeaveQuotas.initialBroughtForwardInfo": "Inicial Trazido para Frente é o saldo de licença que um Usuário transferiu do ano passado, quando o Vacation Tracker não fazia parte da sua organização. Observe que você só pode editar o saldo de licença Inicial Trazido para Frente, enquanto a licença disponível que você pode trazer para frente nos anos seguintes será calculada pelo Vacation Tracker.",
  "components.userLeaveQuotas.leavePoliciesNotExistInfo": "Atualmente não há Tipos de Licença disponíveis. Por favor, atribua Políticas de Licença à Localização à qual este usuário pertence.",
  "components.userLeaveQuotas.leaveTypes": "Tipos de Licença",
  "components.userLeaveQuotas.logsTab": "aba de registros.",
  "components.userLeaveQuotas.noLimit": "Você tem uma cota ilimitada.",
  "components.userLeaveQuotas.ofDefaultDays": "de {value} dias",
  "components.userLeaveQuotas.ofDefaultHours": "de {value} horas",
  "components.userLeaveQuotas.overlapDaysContactSupport": "Sobreposição de datas, por favor, entre em contato com o Suporte para mais informações.",
  "components.userLeaveQuotas.paidDays": "Dias pagos",
  "components.userLeaveQuotas.quota": "Cota",
  "components.userLeaveQuotas.quotaInfo": "A cota representa o número de dias de Folga Remunerada disponíveis para o período atual. Este número inclui a cota base, compensação de horas extras e dias concedidos com base na função ou antiguidade do funcionário.",
  "components.userLeaveQuotas.quotaInfoSummary": "Cota para o período atual:",
  "components.userLeaveQuotas.remaining": "Restante",
  "components.userLeaveQuotas.remainingAfterRequest": "Restante após solicitação",
  "components.userLeaveQuotas.remainingTooltip": "Dias restantes de {leaveTypeName} para o período atual. Total:",
  "components.userLeaveQuotas.remainingTooltipFooter": "Até {broughtForwardLimit} {period} não utilizados serão transferidos para o próximo período",
  "components.userLeaveQuotas.remainingTooltipFooterAll": "Todos os dias não utilizados podem ser transferidos para o próximo período.",
  "components.userLeaveQuotas.remainingTooltipFooterZero": "Nenhum dia não utilizado pode ser transferido para o próximo período.",
  "components.userLeaveQuotas.requested": "Solicitado",
  "components.userLeaveQuotas.saveQuotaButton": "Salvar",
  "components.userLeaveQuotas.scheduled": "Agendado",
  "components.userLeaveQuotas.scheduledDays": "Dias agendados",
  "components.userLeaveQuotas.sectionTitle": "Cotas de licença",
  "components.userLeaveQuotas.selectPeriod": "Selecionar Período:",
  "components.userLeaveQuotas.unlock": "Desbloquear",
  "components.userLeaveQuotas.unlockBroughtForwardInfo": "Para desbloquear, vá para Configurações → Locais → {locationName} → Ver Políticas de Licença, e edite a política de {leaveTypeName} para permitir Transferência.",
  "components.userLeaveQuotas.used": "Utilizado",
  "components.userLeaveQuotas.usedAlready": "Já utilizado",
  "components.userLeaveQuotas.usedDays": "Dias utilizados",
  "components.userLeaveQuotas.usedTooltip": "Dias de {leaveTypeName} utilizados, agendados e pagos para o período atual. Total:",
  "components.userLeaveQuotas.userInactiveInfo": "Como a conta está inativa no momento, não serão adicionados mais dias até a reativação.",
  "components.userLeaveQuotasCompact.title": "Seu saldo de licença",
  "components.userLeaveQuotasCompact.titleForApprover": "Saldo de licença",
  "components.userLeaveTypeForm.applyForFutureYears": "Você deseja aplicar a cota base de licença para os anos futuros?",
  "components.userLeaveTypeForm.cancel": "Cancelar",
  "components.userLeaveTypeForm.daysPerYear": "Qual é a sua nova cota de licença?",
  "components.userLeaveTypeForm.hasUnlimitedDays": "Você deseja permitir Licenças Ilimitadas?",
  "components.userLeaveTypeForm.leaveTypeEditInfo": "Você está editando o tipo de licença \"{leaveTypeName}\" para o período de {dateRangeName}.",
  "components.userLeaveTypeForm.prorateInfo": "{user} terá {proratedQuota} ({preciseQuota} dias, para ser preciso) para o ano inicial ({yearPeriod}) e {fullQuota} para todos os anos a partir de {startOfNextYear}.",
  "components.userLeaveTypeForm.prorateInfoDays": "{user} terá {proratedQuota} ({preciseQuota} dias, para ser preciso) para o ano inicial ({yearPeriod}) e {fullQuota} dias para todos os anos a partir de {startOfNextYear}.",
  "components.userLeaveTypeForm.prorateInfoHours": "{user} terá {proratedQuota} ({preciseQuota} horas, para ser preciso) para o ano inicial ({yearPeriod}) e {fullQuota} horas para todos os anos a partir de {startOfNextYear}.",
  "components.userLeaveTypeForm.prorateLabel": "Prorratear para o ano inicial com base na data de início do usuário ({startDate})",
  "components.userLeaveTypeForm.quotaAdditionalRole": "Definir os dias adicionais com base na função",
  "components.userLeaveTypeForm.quotaAdditionalRoleInfo": "Aloque dias adicionais de licença aos funcionários dependendo de sua função no trabalho. Este campo permite ajustar os direitos de licença para refletir as responsabilidades ou requisitos específicos de diferentes cargos dentro da organização.",
  "components.userLeaveTypeForm.quotaAdditionalSeniority": "Definir os dias adicionais com base no direito por antiguidade",
  "components.userLeaveTypeForm.quotaAdditionalSeniorityInfo": "Os dias baseados em antiguidade são adicionados pela automação de direito por antiguidade. Você pode sobrescrever os dias de {leaveTypeName} baseados em antiguidade definindo o valor neste campo.",
  "components.userLeaveTypeForm.quotaAdditionalSeniorityInfoUpcomingYear": "Os dias baseados em antiguidade são adicionados pela automação de direito por antiguidade. Você pode sobrescrever os dias de {leaveTypeName} baseados em antiguidade definindo o valor neste campo. No entanto, este campo está bloqueado porque você não pode editar este valor para anos futuros antes que os dias sejam concedidos.",
  "components.userLeaveTypeForm.quotaAdditionalSummary": "Você definirá a cota de licença de {leaveTypeName} para {user} para o período selecionado como {total} {hourlyLeaveAccounting, plural, =1 {horas} other {dias}}. A cota base e os dias adicionais baseados no direito por antiguidade e direito por função ({total} {hourlyLeaveAccounting, plural, =1 {horas} other {dias}} no total) serão aplicados para o {future, plural, =1 {período selecionado e períodos futuros} other {período selecionado}}. {prorateText}",
  "components.userLeaveTypeForm.quotaAdditionalSummaryUnlimited": "Você definirá a cota de licença de {leaveTypeName} para {user} para o período selecionado como Ilimitada.",
  "components.userLeaveTypeForm.quotaBase": "Definir a cota base de licença",
  "components.userLeaveTypeForm.quotaBaseTooltip": "A cota base é a cota padrão do usuário, que exclui dias adicionais recebidos com base em sua antiguidade, função, compensação de horas extras ou quaisquer outros dias extras. Você pode alterar a cota base para todos em um local na página de política de licença ou usar este campo para definir uma cota personalizada para um usuário selecionado.",
  "components.userLeaveTypeForm.required": "Este campo é obrigatório.",
  "components.userLeaveTypeForm.update": "Atualizar",
  "components.userLeaveTypeForm.updateUserLeaveType": "Atualizar cotas do usuário para o tipo de licença {name}",
  "components.userLogsTab.ACCRUED_DAYS": "Dias acumulados",
  "components.userLogsTab.ALL": "Todos",
  "components.userLogsTab.APPROVER_STATUS": "Mudança de função",
  "components.userLogsTab.filter": "Filtrar:",
  "components.userLogsTab.LOCATION_USER_MOVED": "Mudança de localização",
  "components.userLogsTab.logsNotFound": "Registros não encontrados para o intervalo de datas selecionado.",
  "components.userLogsTab.ROLLOVER_EXPIRED": "Dias transferidos expirados",
  "components.userLogsTab.selectFromTo": "Selecione o intervalo de datas:",
  "components.userLogsTab.TEAM_USER_MOVED": "Mudança de departamento",
  "components.userLogsTab.TOIL_REQUEST_APPROVED": "TOIL",
  "components.userLogsTab.USER_ENTITLEMENT_BY_ROLE": "Direito por função",
  "components.userLogsTab.USER_ENTITLEMENT_BY_ROLE_DELETED": "Direito por função excluído",
  "components.userLogsTab.USER_ENTITLEMENT_BY_ROLE_OVERRIDE": "Substituição do direito por função",
  "components.userLogsTab.USER_LEAVE_TYPES_DELETED": "Cota de licença redefinida para o padrão",
  "components.userLogsTab.USER_LEAVE_TYPES_ROLLOVER_DELETED": "Transferência inicial excluída",
  "components.userLogsTab.USER_LEAVE_TYPES_ROLLOVER_UPDATED": "Transferência inicial atualizada",
  "components.userLogsTab.USER_LEAVE_TYPES_UPDATED": "Cota de licença atualizada",
  "components.userLogsTab.USER_SENIORITY_ENTITLEMENT": "Direito por antiguidade",
  "components.userLogsTab.USER_SENIORITY_ENTITLEMENT_OVERRIDE": "Substituição do direito por antiguidade",
  "components.userLogsTab.USER_STATUS_CHANGED": "Mudança de status",
  "components.userLogsTab.USER_UPDATED": "Atualização de informações do usuário",
  "components.userLogsTab.USER_WORK_WEEK_DELETED": "Semana de trabalho personalizada excluída",
  "components.userLogsTab.USER_WORK_WEEK_UPDATED": "Semana de trabalho atualizada",
  "components.userLogsTab.YEAR_START": "Início do ano",
  "components.userManageLabels.actions": "Ações",
  "components.userManageLabels.color": "Cor",
  "components.userManageLabels.deleteLabel": "Tem certeza de que deseja excluir este rótulo?\n\nIsso excluirá o rótulo para todos os usuários atribuídos.",
  "components.userManageLabels.findLabel": "Encontrar Rótulo",
  "components.userManageLabels.isRequired": "{title} é obrigatório",
  "components.userManageLabels.manageLabels": "Gerenciar Rótulos",
  "components.userManageLabels.name": "Nome",
  "components.userManageLabels.userCount": "Contagem de Usuários",
  "components.userProfileTab.admin": "Administrador",
  "components.userProfileTab.approver": "Aprovador",
  "components.userProfileTab.approvers": "Aprovadores:",
  "components.userProfileTab.approverSuffix": " (Aprovador)",
  "components.userProfileTab.customWorkWeekEditedBefore": "A semana de trabalho de {userName} foi modificada na versão anterior do Vacation Tracker (antes de 1º de janeiro de 2021)",
  "components.userProfileTab.customWorkWeekLabel": "semana de trabalho personalizada",
  "components.userProfileTab.customWorkWeekTooltip": "A semana de trabalho de {userName} foi editada por {adminName} em {timestamp}",
  "components.userProfileTab.employeeIdNotSet": "Não definido",
  "components.userProfileTab.employeeIdTooltip": "ID do funcionário em um sistema externo",
  "components.userProfileTab.employeeSince": "Funcionário desde:",
  "components.userProfileTab.endDate": "Data de Término:",
  "components.userProfileTab.location": "Localização:",
  "components.userProfileTab.name": "Nome:",
  "components.userProfileTab.nameEdited": "O nome foi alterado manualmente e não será mais sincronizado automaticamente. Este nome é usado apenas dentro do Vacation Tracker.",
  "components.userProfileTab.role": "Função:",
  "components.userProfileTab.status": "Status:",
  "components.userProfileTab.team": "Departamento:",
  "components.userProfileTab.user": "Usuário",
  "components.userProfileTab.userProfile": "Perfil do Usuário",
  "components.userProfileTab.workingDays": "Dias de Trabalho",
  "components.userTodayOff.cancelLeave": "Cancelar folga",
  "components.userTodayOff.editLeave": "Editar folga",
  "components.userTodayOff.leaveInfo": "Informações da folga",
  "components.userTodayOff.offFromTo": "O usuário tirou {leaveTypeName} de {startDate} a {endDate}",
  "components.userTodayOff.offTodayFullDay": "O usuário tirou {leaveTypeName} hoje, {today}",
  "components.userTodayOff.offTodayPartDay": "O usuário tirou {leaveTypeName} hoje das {from}:00h às {to}:00h.",
  "components.userTodayOff.offTodayReason": "Motivo: {reason}",
  "components.userUpdateForm.active": "Ativo",
  "components.userUpdateForm.admin": "Administrador",
  "components.userUpdateForm.approver": "Aprovador",
  "components.userUpdateForm.approverSuffix": " (Aprovador)",
  "components.userUpdateForm.cancel": "Cancelar",
  "components.userUpdateForm.employeeSince": "Funcionário Desde",
  "components.userUpdateForm.location": "Localização",
  "components.userUpdateForm.name": "Nome",
  "components.userUpdateForm.pleaseSelectALocation": "Selecione uma localização",
  "components.userUpdateForm.pleaseSelectATeam": "Selecione um departamento",
  "components.userUpdateForm.pleaseSelectAUserRole": "Selecione uma Função de Usuário",
  "components.userUpdateForm.pleaseSelectEmployeeSince": "Adicione a Data de Início do Funcionário",
  "components.userUpdateForm.pleaseSelectUserEndDate": "Adicione a Data de Término do Usuário",
  "components.userUpdateForm.role": "Função",
  "components.userUpdateForm.team": "Departamento",
  "components.userUpdateForm.update": "Atualizar",
  "components.userUpdateForm.updateUser": "Atualizar Usuário",
  "components.userUpdateForm.user": "Usuário",
  "components.userUpdateForm.userEndDate": "Data de Término do Funcionário",
  "components.userUpdateForm.warningInitialRolloverForChangeLocation": "Alterar a localização do usuário pode impactar a configuração e as informações de folga do usuário (por exemplo, os dias iniciais transferidos).",
  "components.userUpdateForm.warningInitialRolloverForChangeStartDate": "Alterar a data de início de um usuário pode impactar as informações de folga do usuário (por exemplo, os Dias Iniciais Transferidos).",
  "components.whatsNew.newMessage": "Nova mensagem",
  "components.whatsNew.whatsNew": "O que há de novo",
  "connect.alreadyHaveAnAccount": "Já tem uma conta?",
  "connect.alreadyHaveAnAccountWithLink": "Já tem uma conta? <b><link>Entrar</link></b>",
  "connect.backToLoginWithOtherPlatofrm": "Voltar para entrar com outra plataforma",
  "connect.companyExists.line1": "{companyName} já está registrada no Vacation Tracker",
  "connect.companyExists.line2Other": "Você está usando o e-mail correto?",
  "connect.companyExists.line2Slack": "Você está usando as credenciais corretas?",
  "connect.companyExists.line3aSlack": "Seu Espaço de Trabalho do Slack: {workspaceName}",
  "connect.companyExists.line3Other": "Seu endereço de e-mail: {email}",
  "connect.companyExists.line3Slack": "Seu endereço de e-mail: {email}",
  "connect.companyExists.line4Other": "Se este e-mail estiver correto, sua conta não está ativada. Entre em contato com o administrador da sua organização no Vacation Tracker:",
  "connect.companyExists.line4Slack": "Se essas credenciais estiverem corretas, sua conta não está ativada. Entre em contato com o administrador da sua organização no Vacation Tracker:",
  "connect.companyExists.line5": "Caso contrário, faça login com uma conta <capitalize>{platform}</capitalize> diferente ou faça login em uma janela anônima do seu navegador.",
  "connect.companyExists.line6Microsoft": "Entre em contato com o Suporte para criar um novo departamento em {companyName}",
  "connect.companyExists.lineEmailAddress": "Seu endereço de e-mail",
  "connect.companyExists.title": "A empresa já existe",
  "connect.connect": "Conectar",
  "connect.connectWithWorkEmail": "Entrar com e-mail de trabalho",
  "connect.connectWithYourWorkEmail": "Entre usando seu e-mail de trabalho",
  "connect.copyEmail": "Clique para copiar o e-mail",
  "connect.copyTextEnd": "Boas férias! 🌴",
  "connect.createAnOrganization": "Criar uma organização",
  "connect.description": "Para acessar o Painel do Vacation Tracker, selecione o botão \"Conectar com\" que corresponde à plataforma usada pela sua equipe.",
  "connect.dontHaveAnAccount": "Não tem uma conta?",
  "connect.doYouWantToLoginInToOrganization": "Você deseja fazer login em {existingOrganizationName}?",
  "connect.forgotYourPassword": "Esqueceu sua senha?",
  "connect.forgotYourPasswordWhatsYourEmail": "Qual é o seu e-mail?",
  "connect.google.notWorkspaceUserDescription": "Parece que você está tentando se inscrever com uma conta pessoal e não com uma conta do Google Workspace. No momento, o Vacation Tracker suporta apenas contas do Google Workspace.",
  "connect.google.notWorkspaceUserTitle": "É necessária uma conta do Google Workspace",
  "connect.google.sharedPermissionsConsent": "Ao clicar no botão abaixo, você está concedendo ao Vacation Tracker permissão para acessar o diretório de usuários administradores do Google.",
  "connect.google.sharedPermissionsError": "Falha ao compartilhar permissões do Google, seu link expirou.",
  "connect.google.sharedPermissionsRequired": "Permissões insuficientes",
  "connect.google.sharedPermissionsRequiredDescription": "Para prosseguir com a importação de usuários, você precisará obter permissões do seu administrador do Google. Por favor, compartilhe este link com seu administrador para conceder o consentimento necessário: ",
  "connect.google.sharedPermissionsSuccess": "Permissões do Google compartilhadas com sucesso!",
  "connect.google.sharedPermissionsSuccessRedirect": "Você será redirecionado para a página inicial do Vacation Tracker em alguns segundos.",
  "connect.howShouldIConnect": "Como devo me conectar?",
  "connect.inputEmailPlaceholder": "Digite seu e-mail de trabalho",
  "connect.inputPasswordPlaceholder": "Digite sua senha",
  "connect.interactionRequiredMSErrorDescription1": "Parece que sua conta Microsoft requer permissões adicionais. Por favor, clique no botão Entrar abaixo para conceder permissões adicionais.",
  "connect.interactionRequiredMSErrorDescription2": "Se este problema persistir, entre em contato com nosso suporte e envie o seguinte código:",
  "connect.interactionRequiredMSErrorTitle": "O Microsoft requer permissões adicionais",
  "connect.login": "Entrar",
  "connect.meta.description": "Acesse o Painel do Vacation Tracker e desbloqueie todo o poder do gerenciamento de licenças sem esforço.",
  "connect.meta.title": "Cadastro/Login - Vacation Tracker",
  "connect.meta.title.signin": "Entrar - Vacation Tracker",
  "connect.meta.title.signup": "Cadastro - Vacation Tracker",
  "connect.or": "Ou",
  "connect.platformErrorDescription1": "Ocorreu o seguinte erro:",
  "connect.platformErrorDescription2": "Isso pode ser um erro da {platform}. No entanto, sinta-se à vontade para entrar em contato com nosso suporte para obter mais assistência.",
  "connect.platformErrorTitle": "Algo deu errado",
  "connect.pleaseInputValidEmail": "Por favor, insira um e-mail válido",
  "connect.pleaseInputValidPassword": "Por favor, insira uma senha válida",
  "connect.pleaseLogIn": "Por favor, faça login",
  "connect.readMore": "Para mais informações, visite nossa <helpdesk>Central de Ajuda</helpdesk> ou acesse nosso <website>site</website>.",
  "connect.signInWithEmail": "Entrar com e-mail",
  "connect.signInWithGoogle": "Entrar com o Google",
  "connect.signInWithGoogleToOrganization": "Entrar no Google Workspaces para {existingOrganizationName}",
  "connect.signInWithGoogleVerification": "Entrar com o Google",
  "connect.signInWithMS": "Entrar com Microsoft Teams",
  "connect.signInWithMSToOrganization": "Entrar com Microsoft Teams em {existingOrganizationName}",
  "connect.signInWithSlack": "Entrar com Slack",
  "connect.signInWithSlackInstallBot": "Adicionar Vacation Tracker ao Slack",
  "connect.signInWithSlackToOrganization": "Entrar com Slack em {existingOrganizationName}",
  "connect.signUp": "Cadastre-se usando seu e-mail de trabalho",
  "connect.signUpInfo": "Cadastre-se para sua avaliação gratuita",
  "connect.signupNewOrganizationUsingNewPlatform": "Cadastre-se como uma nova organização usando sua conta {selectedPlatform}",
  "connect.signUpWithEmail": "Cadastrar-se com e-mail",
  "connect.signUpWithGoogle": "Inscreva-se com o Google",
  "connect.signUpWithMicrosoft": "Inscreva-se com a Microsoft",
  "connect.signUpWithSlack": "Inscreva-se com o Slack",
  "connect.slack.usersLoginRequiredButton": "Conceder permissões do Slack",
  "connect.slack.usersLoginRequiredDescription1": "Seu token do bot do Slack expirou ou não é mais válido. Você não poderá acessar o bot do Vacation Tracker ou receber notificações até ativar um novo token.",
  "connect.slack.usersLoginRequiredDescription2": "Para ativar um novo token do bot do Slack, clique em OK.",
  "connect.slack.usersLoginRequiredDescription3": "Para pular esta etapa e prosseguir para o Painel online, clique em Pular.",
  "connect.slack.usersLoginRequiredTitle": "É necessário fazer login na sua conta do Slack",
  "connect.slackPostInstallation1": "Você instalou com sucesso o aplicativo Slack do Vacation Tracker 🎉",
  "connect.slackPostInstallation2": "Para começar a usar o aplicativo, faça login no Painel do Vacation Tracker e complete a configuração.",
  "connect.slackPostInstallation3": "Faremos algumas perguntas simples. Não deve levar mais do que alguns minutos. Num piscar de olhos estará pronto (assim como suas últimas férias).",
  "connect.slackPostInstallationError.generic": "Ei, precisamos instalar o bot do Vacation Tracker primeiro. Você poderá revisar as permissões do aplicativo Slack do Vacation Tracker e concedê-las na próxima etapa.",
  "connect.slackPostInstallationError.invalidCode": "O código de redirecionamento não é mais válido. Isso pode acontecer se o código expirou, já foi usado ou você atualizou a página antes de terminarmos o processo de login. Mas não se preocupe, vamos obter um novo!",
  "connect.subscriptionExpired": "Ops, parece que a assinatura da sua empresa no Vacation Tracker expirou! Entre em contato com um administrador para reativar a assinatura da sua empresa.",
  "connect.subscriptionExpiredTitle": "Assinatura Expirada",
  "connect.thisOrganizationUsePlatform": "No entanto, {existingOrganizationName} usa {existsOnAnotherPlatform}, então você precisará fazer login com {existsOnAnotherPlatform} para acessar sua conta",
  "connect.welcome": "Bem-vindo, {name}!",
  "connect.youAreAlreadyMember": "Você já é membro da organização {existingOrganizationName}",
  "connectCalendar.connect": "Conectar com",
  "connectCalendar.disconnect": "Desconectar",
  "connectCalendar.disconnectDescription": "Tem certeza de que deseja desconectar o calendário? O Vacation Tracker não o atualizará mais com suas futuras ausências",
  "connectCalendar.disconnectError": "Erro ao desconectar o calendário",
  "connectCalendar.disconnectErrorDescription": "Ocorreu um erro ao tentar desconectar seu calendário do Vacation Tracker. Por favor, clique em Ok para tentar novamente.",
  "connectCalendar.disconnectSuccess": "Você desconectou com sucesso o calendário {calendar}.",
  "connectCalendar.disconnectTitle": "Desconectar Calendário {calendarPlatform}",
  "connectCalendar.error": "Erro ao conectar o calendário",
  "connectCalendar.errorDescription": "Ocorreu um erro ao tentar conectar seu calendário ao Vacation Tracker. Por favor, clique em Ok para tentar novamente.",
  "connectCalendar.errorReconnectDescription": "Ocorreu um erro ao tentar reconectar seu calendário ao Vacation Tracker. Por favor, clique em Ok para tentar novamente.",
  "connectCalendar.reconnect": "Reconectar",
  "connectCalendar.reconnectError": "Erro ao reconectar o calendário",
  "connectCalendar.reconnectInfo": "Para garantir que sua ausência seja visível, reconecte-se ao seu calendário.",
  "connectCalendar.reconnectNotification": "A conexão com seu calendário expirou. Clique aqui para reconectar e garantir que sua ausência seja visível em seu calendário.",
  "connectCalendar.reconnectNotificationSharedCalendar": "Sua conexão com o calendário compartilhado: {calendarName} expirou. Clique aqui para reconectar e garantir que as ausências sejam visíveis em seu calendário.",
  "connectCalendar.reconnectSuccess": "Você reconectou com sucesso o calendário {calendar}.",
  "connectCalendar.success": "Você conectou com sucesso o calendário {calendar}.",
  "connectedCalendar.eventDescription": "Adicionado por <link>Vacation Tracker</link>",
  "connectedCalendar.eventDescriptionShared": "Evento de calendário compartilhado adicionado por <link>Vacation Tracker</link>",
  "containers.google.topbar.adminProductTourInfo": "Como Administrador, você tem o mais alto nível de permissões no Vacation Tracker e pode personalizar todas as configurações da organização através do Painel do Administrador.\n\nO Painel permite que você:\n- Solicite Ausência ou adicione Ausência para outros membros do departamento.\n- Tenha uma visão geral completa através da Página Inicial do Painel e do Calendário.\n- Crie e configure múltiplos departamentos (Atribuir Aprovadores)\n- Configure Notificações\n- Gerencie Usuários (Ativos, Inativos, Excluídos).\n- Crie e gerencie Localizações (Tipos de Ausência, Feriados, Notificações)\n\nSe suas perguntas ou preocupações permanecerem sem resposta, entre em contato conosco a qualquer momento. Falar com nossos clientes é a melhor parte do nosso dia:",
  "containers.google.topbar.approverProductTourInfo": "Como Aprovador, você tem o segundo maior nível de permissões no Vacation Tracker e pode aprovar ou negar solicitações de ausência enviadas pelos Usuários do departamento para o qual você foi designado como Aprovador.\n\nO Painel permite que você:\n- Solicite Ausência ou adicione Ausência para outros membros do departamento.\n- Tenha uma visão geral completa através da Página Inicial do Painel e do Calendário.\n- Configure Notificações\n- Visualize seu Perfil de Usuário.\n- Gerencie Solicitações (Aprovar/Negar Solicitações, Ver Histórico).\n\nSe suas perguntas ou preocupações permanecerem sem resposta, entre em contato conosco a qualquer momento. Falar com nossos clientes é a melhor parte do nosso dia:",
  "containers.google.topbar.userProductTourInfo": "Como Usuário do Vacation Tracker, você pode enviar e gerenciar suas Solicitações de Ausência através do Painel, bem como ter uma visão geral completa das ausências planejadas dentro da sua organização.\n\nO Painel permite que você:\n- Solicite Ausência\n- Tenha uma visão geral completa através da Página Inicial do Painel e do Calendário.\n- Visualize seu Perfil de Usuário.\n\nSe suas perguntas ou preocupações permanecerem sem resposta, entre em contato conosco a qualquer momento. Falar com nossos clientes é a melhor parte do nosso dia:",
  "containers.topbar.adminProductTourInfo": "Como Administrador, você tem o mais alto nível de permissões no Vacation Tracker e pode personalizar todas as configurações da organização através do Painel do Administrador.\n\nO Painel permite que você:\n- Solicite Ausência ou adicione Ausência para outros membros do departamento.\n- Tenha uma visão geral completa através da Página Inicial do Painel e do Calendário.\n- Crie e configure múltiplos departamentos (Atribuir Aprovadores)\n- Configure Notificações\n- Gerencie Usuários (Ativos, Inativos, Excluídos).\n- Crie e gerencie Localizações (Tipos de Ausência, Feriados, Notificações)\n\nO Bot permite que você:\n- Solicite Ausência\n- Aprove/Negue Solicitações de Ausência\n- Receba Notificações Diárias/Semanais\n\nSe suas perguntas ou preocupações permanecerem sem resposta, entre em contato conosco a qualquer momento. Falar com nossos clientes é a melhor parte do nosso dia:",
  "containers.topbar.adminProductTourTitle": "Administrador",
  "containers.topbar.announcement": "Anúncio",
  "containers.topbar.approverProductTourInfo": "Como Aprovador, você tem o segundo maior nível de permissões no Vacation Tracker e pode aprovar ou negar solicitações de ausência enviadas pelos Usuários do departamento para o qual você foi designado como Aprovador.\n\nO Painel permite que você:\n- Solicite Ausência ou adicione Ausência para outros membros do departamento.\n- Tenha uma visão geral completa através da Página Inicial do Painel e do Calendário.\n- Configure Notificações\n- Visualize seu Perfil de Usuário.\n- Gerencie Solicitações (Aprovar/Negar Solicitações, Ver Histórico).\n\nO Bot permite que você:\n- Solicite Ausência\n- Aprove/Negue Solicitações de Ausência\n- Receba Notificações Diárias/Semanais\n\nSe suas perguntas ou preocupações permanecerem sem resposta, entre em contato conosco a qualquer momento. Falar com nossos clientes é a melhor parte do nosso dia:",
  "containers.topbar.approverProductTourTitle": "Aprovador",
  "containers.topbar.giveUsFeedback": "Dê-nos feedback",
  "containers.topbar.help": "Ajuda",
  "containers.topbar.logout": "Sair",
  "containers.topbar.profile.info": "Você pode acessar seu perfil, alternar o tema e iniciar o tour rápido a partir deste novo menu.",
  "containers.topbar.setUpTheBot": "Configurar o bot",
  "containers.topbar.userProductTourInfo": "Como um Usuário do Vacation Tracker, você pode enviar e gerenciar suas Solicitações de Licença através do Painel ou do Bot, além de ter uma visão geral completa das ausências planejadas dentro da sua organização.\n\nO Painel permite que você:\n- Solicite Licença\n- Tenha uma visão geral completa através da Página Inicial do Painel e do Calendário.\n- Visualize seu Perfil de Usuário.\n\nO Bot permite que você:\n- Solicite Licença \n- Receba Notificações Diárias/Semanais\n\nSe suas perguntas ou preocupações permanecerem sem resposta, entre em contato conosco a qualquer momento. Falar com nossos clientes é a melhor parte do nosso dia: ",
  "containers.topbar.userProductTourTitle": "Usuário",
  "containers.topbar.whatsNewUnread": "O que há de novo - {unread} não lido",
  "Conversation not found.": "O canal selecionado foi excluído ou não está mais disponível. Por favor, selecione outro canal para receber Notificações. ",
  "createCompany.signupError": "Algo deu errado",
  "createCompany.steps.back": "Voltar",
  "createCompany.steps.cancel": "Cancelar",
  "createCompany.steps.companyForm": "Detalhes da Empresa",
  "createCompany.steps.companyForm.agreeTerms": "Eu aceito a <aPP>Política de Privacidade</aPP>, os <aTOS>Termos de Serviço</aTOS> e o <aDPA>DPA</aDPA>. Também reconheço e concordo com o processamento dos meus dados pessoais de acordo com os termos descritos na Política de Privacidade",
  "createCompany.steps.companyForm.agreeToTerms.error": "Você deve concordar com nossos Termos de Serviço e Política de Privacidade para continuar.",
  "createCompany.steps.companyForm.companyInfoSectionDescription": "Conte-nos sobre sua empresa e localização. Usaremos essas informações para criar sua conta empresarial e sua primeira localização. Você sempre pode adicionar mais localizações depois.",
  "createCompany.steps.companyForm.companyLeaveSection": "Tipo de Licença Padrão",
  "createCompany.steps.companyForm.companyName": "Nome da Empresa",
  "createCompany.steps.companyForm.companyNameInput.validation.required": "Este é um campo obrigatório.",
  "createCompany.steps.companyForm.contactEmail.validation.country": "Este é um campo obrigatório.",
  "createCompany.steps.companyForm.contactEmail.validation.required": "Necessário para comunicações importantes sobre o produto.",
  "createCompany.steps.companyForm.contactEmail.validation.state": "Este é um campo obrigatório.",
  "createCompany.steps.companyForm.contactEmail.validation.type": "Por favor, insira um endereço de e-mail válido.",
  "createCompany.steps.companyForm.contactEmailExtra": "O endereço de e-mail que você fornecer será usado apenas para comunicações importantes sobre o produto. Sem spam, prometemos.",
  "createCompany.steps.companyForm.country": "País",
  "createCompany.steps.companyForm.defaultPtoQuota": "Cota de Tempo Livre Remunerado (PTO)",
  "createCompany.steps.companyForm.defaultPtoQuota.validation": "Por favor, insira sua cota padrão de PTO",
  "createCompany.steps.companyForm.defaultPtoQuota.validation.maxDefaultPtoQuota": "Sua cota padrão de PTO não pode ser superior a 366 dias",
  "createCompany.steps.companyForm.defaultPtoQuota.validation.minDefaultPtoQuota": "Sua cota padrão de PTO não pode ser inferior a 0 dias",
  "createCompany.steps.companyForm.defaultPtoQuotaExtra": "Você pode alterar isso posteriormente nas suas Configurações.",
  "createCompany.steps.companyForm.email": "E-mail de Contato",
  "createCompany.steps.companyForm.location": "Informações de localização: ",
  "createCompany.steps.companyForm.state": "Estado",
  "createCompany.steps.companyForm.step1Title": "Detalhes da Empresa",
  "createCompany.steps.companyForm.step2Title": "Selecionar Usuários",
  "createCompany.steps.companyForm.step3Title": "Selecionar Plano",
  "createCompany.steps.companyForm.unlimitedLeavePolicy": "PTO Ilimitado",
  "createCompany.steps.companyForm.UserInfoSectionDescription": "Diga-nos quem você é. Usaremos essas informações para criar seu perfil de usuário. Por padrão, você será designado com a função de administrador e aprovador. Você pode adicionar mais usuários e alterar suas funções posteriormente.",
  "createCompany.steps.inviteUsers.next": "Convidar {noOfUsers} Usuário{postfix}",
  "createCompany.steps.inviteUsers.title": "Convide os membros da sua equipe",
  "createCompany.steps.inviteUsers.title.info": "Convide os usuários que você deseja adicionar à conta do Vacation Tracker da sua organização. Você sempre pode fazer alterações posteriormente através do painel.",
  "createCompany.steps.next": "Continuar",
  "createCompany.steps.selectPlan.noCC": "Não é necessário cartão de crédito para o seu teste de 7 dias",
  "createCompany.steps.selectPlan.title": "Vamos começar!",
  "createCompany.steps.selectPlan.youCanSwitchPlanDuringTrial": "Você pode trocar de plano a qualquer momento durante o período de teste",
  "createCompany.steps.selectUsers": "Importar Usuários",
  "createCompany.steps.selectUsers.deselectAll": "Desmarcar Todos",
  "createCompany.steps.selectUsers.doneButton": "Importar {noOfUsers} Usuário{postfix}",
  "createCompany.steps.selectUsers.doneButtonTryItOut": "Iniciar Seu Teste Gratuito de 14 Dias",
  "createCompany.steps.selectUsers.loadingAll": "Carregando Todos os Usuários",
  "createCompany.steps.selectUsers.searchUsers": "Encontrar Nemo...",
  "createCompany.steps.selectUsers.selectAll": "Selecionar Todos",
  "createCompany.steps.selectUsers.title": "Escolha os membros da sua equipe",
  "createCompany.steps.selectUsers.title.info": "Selecione os usuários que você deseja adicionar à conta do Vacation Tracker da sua organização. Você sempre pode fazer alterações posteriormente através do painel. Os usuários que você selecionar não serão notificados até que você faça o anúncio.",
  "dashboard.accountSettingsLanguageLabel": "Escolha seu idioma preferido",
  "dashboard.accountSettingsOutOfOfficeChangeModalInfo": "O Vacation Tracker configurará todas as suas futuras mensagens de ausência para o e-mail {email}.\n No entanto, se você agendou uma mensagem de ausência antes desta alteração, ainda a configuraremos para o e-mail antigo.",
  "dashboard.accountSettingsOutOfOfficeChangeModalTitle": "Alterar endereço de e-mail para futuras mensagens de ausência",
  "dashboard.accountSettingsOutOfOfficeConnectedWith": "Conectado com",
  "dashboard.accountSettingsOutOfOfficeTitle": "Configurar Resposta Automática de Ausência (RAA)",
  "dashboard.accountSettingsPageOOODescription": "Uma vez ativado, o Vacation Tracker irá notificá-lo dois dias antes da sua licença planejada e apresentará a oportunidade de configurar uma mensagem de resposta automática de ausência (RAA).",
  "dashboard.accountSettingsTimeDisplayLabel": "Escolha seu formato preferido de exibição de hora",
  "dashboard.accountSettingsUIModeLabel": "Modo Escuro vs. Modo Claro",
  "dashboard.b2bSoftware": "Por favor, note que este é um software B2B e, portanto, não podemos alterar ou excluir seus dados mediante solicitação. Para mais informações, entre em contato com seu gerente dedicado. Caso precise de assistência, nossa equipe de suporte está disponível em <mail>hello@vacationtracker.io</mail>",
  "dashboard.days": "Dia(s)",
  "dashboard.daysSmallCase": "dia(s)",
  "dashboard.daysSmallCaseSingle": "d",
  "dashboard.googlePermissionInfo2": "- O acesso às suas Configurações do Google Calendar é necessário para obter suas configurações de fuso horário do Google.",
  "dashboard.googlePermissionInfo3": "- O acesso às suas Configurações do Gmail é necessário para configurar sua resposta de ausência e definir o intervalo de datas relevante.",
  "dashboard.hour": "hora(s)",
  "dashboard.hourSingle": "h",
  "dashboard.minuteSingle": "m",
  "dashboard.noLeaveRequest": "Esta solicitação de licença não está ativa.",
  "dashboard.noOneIsOffToday": "Não há licenças planejadas para hoje.",
  "dashboard.noOneIsTackingLeaves": "Não há licenças agendadas no momento.",
  "dashboard.noOpenLeavesRequests": "Não há solicitações pendentes.",
  "dashboard.noScheduledLeavesHolidays": "Sem licenças ou feriados agendados",
  "dashboard.openLeavesRequests": "Solicitações Pendentes",
  "dashboard.outlookPermissionInfo2": "- Acesso offline: Isso é necessário para o Vacation Tracker configurar sua resposta de ausência.",
  "dashboard.outlookPermissionInfo3": "- Configurações da Caixa de Correio do Outlook: Precisamos de acesso às suas configurações de fuso horário do Outlook para garantir precisão no tempo. Além disso, precisamos de permissão para definir sua mensagem de ausência e definir o intervalo de datas desejado.",
  "dashboard.outOfOffice": "Definir Mensagem de Ausência",
  "dashboard.outOfOffice.requestIntegrationButton": "Solicitar uma integração",
  "dashboard.outOfOfficeActiveLabel": "Fora do Escritório",
  "dashboard.outOfOfficeGoogleMailButton": "E-mail do Google",
  "dashboard.outOfOfficeGoogleSigninModalTitle": "Necessário Login na Conta do Google",
  "dashboard.outOfOfficeMicrosoftSigninModalTitle": "Necessário Login na Conta da Microsoft",
  "dashboard.outOfOfficeNotActiveInfo": "Parece que você ainda não ativou o recurso de Resposta Automática de E-mail de Ausência em sua conta. Esta ferramenta permite configurar e-mails automatizados para tipos específicos de licença, garantindo que seus contatos saibam quando você estará indisponível. Gostaria de ativar este recurso?",
  "dashboard.outOfOfficeOtherEmail": "Você tem um provedor de e-mail diferente?",
  "dashboard.outOfOfficeOtherEmailInfo": "No momento, suportamos Gmail e Outlook. No entanto, estamos constantemente nos esforçando para melhorar nosso software. Seu feedback sobre o provedor de e-mail que você está usando seria muito benéfico para nos ajudar a determinar nossas prioridades de desenvolvimento. Até estendermos nosso suporte a outros provedores, você pode estabelecer manualmente sua resposta de e-mail de ausência dentro do seu serviço de e-mail.",
  "dashboard.outOfOfficeOtherEmailPlaceholder": "Digite seu provedor, por exemplo, ProtonMail, Yahoo! Mail...",
  "dashboard.outOfOfficeOutlookButton": "Outlook",
  "dashboard.outOfOfficePageSettingsTitle": "ESCOLHA SEU PROVEDOR DE E-MAIL",
  "dashboard.outOfOfficePageTitle": "Fora do Escritório",
  "dashboard.outOfOfficeSet": "Definir Mensagem de Ausência",
  "dashboard.outOfOfficeSetError": "Houve um erro ao definir sua resposta automática de Ausência. Por favor, tente novamente.",
  "dashboard.outOfOfficeSetInfo": "Aqui é onde você pode definir sua resposta de e-mail 'Fora do Escritório'. Enquanto você estiver fora, esta mensagem será enviada automaticamente para as pessoas que tentarem entrar em contato com você por e-mail. Caso decida modificar esta mensagem no futuro, isso pode ser feito facilmente através das configurações do seu e-mail.",
  "dashboard.outOfOfficeSetInfoBot": "Sua resposta de e-mail 'Fora do Escritório' está configurada. Enquanto você estiver fora, esta mensagem será enviada automaticamente para as pessoas que tentarem entrar em contato com você por e-mail. Caso decida modificar esta mensagem no futuro, isso pode ser feito facilmente através das configurações do seu e-mail.",
  "dashboard.outOfOfficeSetSuccess": "Parabéns — você configurou com sucesso sua mensagem automática de ausência. Ela ficará ativa quando sua licença começar.",
  "dashboard.peopleOffToday": "Licenças de Hoje",
  "dashboard.permissionModalInfo": "Para que o Vacation Tracker configure automaticamente sua resposta de Ausência (RAA), precisamos de mais permissões:",
  "dashboard.selectSnoozeLeaveTypesPlaceholder": "Selecione tipos de licença...",
  "dashboard.snoozedLeaveTypesLabel": "Desativar para ",
  "dashboard.upcomingDaysOff": "Licenças Agendadas ({total})",
  "dashboard.upcomingHolidays": "Próximos feriados",
  "departments.viewDepartment": "Ver Departamento",
  "disconnectCalendar.success": "Você desconectou o calendário com sucesso.",
  "editLeave.editLeave": "Editar Licença",
  "editLeave.handleSubmitErrorTitle": "Erro na Edição da Licença",
  "editLeave.inProgress": "Editando licença",
  "editLeave.requestLeave": "Editar Licença",
  "editLeave.title": "Licença editada",
  "email.notification.accruedDaysInfoForApprover": "ℹ️ Esta solicitação de licença é baseada em dias futuros acumulados, não na quantidade atual. {name} tem {amount} dia(s) restante(s) hoje. Eles acumularão {accrued} em {earningDate}.",
  "email.notification.accruedDaysInfoForUser": "ℹ️ Sua solicitação de licença é baseada em dias futuros acumulados, não na quantidade atual. Você tem {amount} dia(s) restante(s) hoje e acumulará {accrued} em {earningDate}.",
  "email.notification.holiday.plural": "As localizações <strong>{locationNames}</strong> estarão ausentes para <strong>{holidayName}</strong> {holidayPeriodText}",
  "email.notification.holiday.singular": "A localização <strong>{locationNames}</strong> estará ausente para <strong>{holidayName}</strong> {holidayPeriodText}",
  "email.notification.title": "Notificação de Licença",
  "error.ApproverNotFound": "Aprovador não encontrado",
  "error.auth.companyExists.line1": "Sua conta não está ativada. Para usar o Vacation Tracker, entre em contato com o administrador e peça para ativar sua conta.",
  "error.auth.companyExists.line2": "Administradores:",
  "error.auth.companyExists.line3": "Ou, entre em contato com nosso suporte ({support}) se você deseja usar o Vacation Tracker como um departamento independente na mesma empresa.",
  "error.auth.companyExists.title": "Sua organização já está cadastrada",
  "error.automations.automationSubmitError": "Erro ao Enviar Automação",
  "error.automations.insufficientPrivileges.description": "Você não tem permissão para realizar esta ação. Por favor, entre em contato com seu administrador.",
  "error.automations.insufficientPrivileges.title": "Erro de permissão",
  "error.blackOutPeriod.isntCreated": "Período de bloqueio não foi criado",
  "error.blackOutPeriod.isntDeleted": "Período de bloqueio não foi excluído",
  "error.blackOutPeriod.isntUpdated": "Período de bloqueio não foi atualizado",
  "error.blackOutPeriod.UserNotAdminOrApprover": "Usuário não é administrador ou aprovador",
  "error.blackOutPeriod.UserNotFound": "Usuário não encontrado",
  "error.botTokenNotFound": "Token do bot não encontrado, por favor faça login em nosso painel e tente novamente.",
  "error.CannotDuplicateName": "O nome que você inseriu já está em uso. Por favor, insira um nome diferente.",
  "error.channelNotFound": "O canal selecionado foi excluído ou não está mais disponível. Por favor, selecione outro canal para receber Notificações.",
  "error.codeDeliveryFailureException": "Falha na entrega do código",
  "error.codeDeliveryFailureExceptionMessage": "Não conseguimos entregar um código de verificação. Por favor, verifique se você forneceu um endereço de e-mail válido. Se seu endereço de e-mail estiver correto, tente novamente mais tarde.",
  "error.codeMismatchException": "Código de verificação inválido",
  "error.codeMismatchExceptionMessage": "Código de verificação fornecido é inválido. Por favor, tente novamente ou reenvie o e-mail.",
  "error.companyExists": "A empresa já existe",
  "error.CustomWorkday": "Não é possível fazer downgrade para um plano sem horas de trabalho personalizadas. Por favor, remova as horas de trabalho personalizadas das configurações ou entre em contato com o suporte.",
  "error.deletedLocation": "A Localização selecionada foi excluída ou não está mais disponível. Por favor, selecione outra(s) Localização(ões) para receber Notificações.",
  "error.deletedTeam": "O Departamento selecionado foi excluído ou não está mais disponível. Por favor, selecione outro(s) Departamento(s) para receber Notificações.",
  "error.DepartmentWithChildrenDeleteForbidden": "Você não pode excluir um departamento que tem subdepartamentos. Por favor, exclua os subdepartamentos primeiro.",
  "error.emailError": "E-mail inválido",
  "error.emailError.description": "Por favor, forneça um endereço de e-mail válido",
  "error.endTimeCantBeBeforeStartTime": "Parece que a hora de início que você selecionou é posterior à hora de término. Por favor, selecione uma hora de início anterior à hora de término.",
  "error.entitlementByRole.isntCreated": "Direito por função não foi criado",
  "error.entitlementByRole.isntDeleted": "Direito por função não foi excluído",
  "error.entitlementByRole.isntUpdated": "Direito por função não foi atualizado",
  "error.expiredCodeException": "Código de verificação expirado",
  "error.expiredCodeExceptionMessage": "O código de verificação fornecido expirou.",
  "error.fetchingConnectedCalendarInfo": "Houve um problema ao buscar seu calendário conectado. Por favor, tente novamente. Se o problema persistir, entre em contato com nosso suporte ao cliente",
  "error.fetchingUserOutOfOfficeSettings": "Houve um problema ao buscar suas configurações de ausência do escritório. Por favor, tente novamente. Se o problema persistir, entre em contato com nosso suporte ao cliente",
  "error.fieldIsRequired": "Este campo é obrigatório.",
  "error.firstEarningDateMissing": "Data do primeiro ganho ausente",
  "error.generic": "Ocorreu um erro",
  "error.generic.description": "Por favor, entre em contato com o suporte com a seguinte mensagem:",
  "error.google.directoryApiDisabledP1": "O Vacation Tracker precisa de acesso aos dados do Diretório para importar novos Usuários.",
  "error.google.directoryApiDisabledP2": "O Administrador do seu Google Workspace pode permitir o acesso ao diretório seguindo os passos do artigo de suporte: ",
  "error.google.directoryApiDisabledP3": "Você pode se inscrever sem habilitar essa funcionalidade. No entanto, não poderá importar usuários do seu Google Workspace.",
  "error.google.directoryApiDisabledP4": "Se precisar de ajuda adicional, entre em contato conosco em hello@vacationtracker.io",
  "error.google.directoryApiDisabledTitle": "Compartilhamento de Contatos Desativado",
  "error.google.directoryApiPermissionDeniedP1": "O Vacation Tracker precisa listar os usuários do seu diretório do Google Workspace. Por favor, clique no botão \"Entrar com Google\" e marque a caixa de seleção como mostrado na imagem abaixo para poder se inscrever.",
  "error.google.directoryApiPermissionDeniedP2": "Armazenaremos apenas as seguintes informações para os usuários que você selecionar explicitamente para importar: nome, foto do perfil e e-mail.",
  "error.google.directoryApiPermissionDeniedP3": "Se você não vir a caixa de seleção ao clicar no botão \"Entrar com Google\", por favor, clique no botão \"Continuar\" na janela de permissões do Google para prosseguir com o processo de inscrição.",
  "error.google.directoryApiPermissionDeniedTitle": "Conceda as permissões faltantes para continuar",
  "error.google.loginRequiredTitle": "É necessário fazer login na sua conta do Google",
  "error.google.usersLoginRequiredDescription1": "Gerenciar usuários requer escopos do Google. Se você clicar no botão OK, redirecionaremos você para a página de login do Google. Dependendo das configurações do seu navegador, você já pode estar logado e ser redirecionado automaticamente de volta.",
  "error.google.usersLoginRequiredDescription2": "Após fazer login na sua conta do Google, levaremos você de volta a esta página para que possa gerenciar os usuários.",
  "error.goToRequestLeaveTab": "Erro na transição para a aba de Solicitação de Licença",
  "error.holidayDateInvalid": "Data de feriado inválida {date} para {year}",
  "error.incorrectPassword": "Senha incorreta",
  "error.incorrectPasswordMessage": "A senha que você digitou está incorreta ou expirou. Por favor, tente novamente (certifique-se de que o Caps Lock está desligado). Esqueceu sua senha?",
  "error.invalidFileType": "Tipo de arquivo inválido",
  "error.invalidFileType.description": "Você carregou um tipo de arquivo inválido. Por favor, carregue uma imagem JPEG, PNG ou GIF.",
  "error.invalidPasswordException": "Senha inválida",
  "error.invalidPasswordExceptionMessage": "Por favor, forneça uma senha válida com 8 caracteres ou mais.",
  "error.leaveDurationLimit.isntCreated": "Falha ao criar limite de duração da licença",
  "error.leaveDurationLimit.isntDeleted": "Falha ao excluir limite de duração da licença",
  "error.leaveDurationLimit.isntUpdated": "Falha ao atualizar limite de duração da licença",
  "error.leavePolicyMissing": "Política de Licença Ausente",
  "error.leaveRequestNotEditable": "Você não tem permissão para editar esta solicitação de licença.",
  "error.leaveRequestOverlap": "As datas que você escolheu se sobrepõem a uma licença existente. Por favor, selecione um período diferente.",
  "error.leaveRequestsLoadingError.description": "Suas licenças existem, mas não conseguimos carregá-las devido a um problema técnico do nosso lado. Por favor, tente recarregar a página. Se o problema persistir, entre em contato com nosso <link>Suporte ao Cliente</link>.",
  "error.leaveRequestsLoadingError.title": "Não foi possível carregar as licenças",
  "error.leaveRequestStatusNotApproved": "Status da solicitação de licença: não aprovada.",
  "error.leaveRequestStatusNotOpen": "Status da solicitação de licença: não aberta",
  "error.leaveSubmitError": "Erro ao Enviar Licença",
  "error.leaveTypeMissing": "Tipo de Licença Ausente",
  "error.LocationLimitReached": "Lamentamos, mas você não pode fazer o downgrade para o plano CORE, pois atualmente excede o limite de {limitNumber} locais. Por favor, ajuste seus locais para atender aos critérios do plano CORE ou entre em contato com o suporte para obter orientação adicional.",
  "error.maxmimumUsersAway.isntCreated": "Configuração de Máximo de Usuários Ausentes não foi criada",
  "error.maxmimumUsersAway.isntDeleted": "Configuração de Máximo de Usuários Ausentes não foi excluída",
  "error.maxmimumUsersAway.isntUpdated": "Configuração de Máximo de Usuários Ausentes não foi atualizada",
  "error.microsoft.channelNotFound": "Você não é membro do time selecionado no Microsoft Teams",
  "error.microsoft.logInWithDifferentAccount": "Entrar com uma conta diferente",
  "error.microsoft.microsoftPermissionsNotGranted": "Permissões do Microsoft não concedidas",
  "error.microsoft.notificationsLoginRequiredTitle": "É necessário fazer login na sua conta Microsoft",
  "error.microsoft.notMemberOfTeam": "Sua organização se inscreveu com um único time do Microsoft Teams, e você não é membro do time com o seguinte ID: {msTeamId}.\n\nVocê pode entrar neste time no seu aplicativo Microsoft Teams e tentar novamente, ou entrar em contato com nosso suporte para alterar seu tipo de instalação.",
  "error.microsoft.wrongAccount": "Conta Microsoft incorreta",
  "error.missingSlackBotToken": "A ação foi processada, no entanto, estamos sem o token do bot para notificar o usuário. Por favor, saia e entre novamente para obter o token do bot.",
  "error.missingSlackBotTokenError": "Não podemos processar a solicitação porque você está sem o token do bot. Por favor, saia e entre novamente para receber o token do bot, e tente novamente.",
  "error.MustBeApproverForTeam": "Você não tem permissão para realizar esta ação, pois deve ser um Aprovador deste departamento para fazê-lo.",
  "error.mustBeCreatorOrApprover": "Deve ser o criador ou aprovador para alterar o status da solicitação de licença.",
  "error.negativeBallanceNotAllowed": "Saldo negativo não é permitido para este tipo de licença.",
  "error.notificationGeneral": "Erro de Notificação",
  "error.oneHoureIntervalNotAllowed": "O intervalo de tempo mais curto que você pode solicitar para este tipo de licença é de 4 horas (meio dia).",
  "error.openApi.isntCreated": "A Chave API Aberta não foi criada",
  "error.openApi.isntDeleted": "A Chave API Aberta não foi excluída",
  "error.partTimeIsntAllowed": "Meio período não é permitido",
  "error.passwordResetLimitExceededException": "Limite excedido",
  "error.passwordResetLimitExceededExceptionMessage": "Limite de tentativas de alteração de senha excedido. Por favor, tente novamente em 15 minutos.",
  "error.paymentFailed": "Pagamento falhou",
  "error.paymentNotProcessed": "Pagamento falhou, por favor tente com um cartão diferente.",
  "error.probationPeriod.isntCreated": "Período de experiência não foi criado",
  "error.probationPeriod.isntDeleted": "Período de experiência não foi excluído",
  "error.probationPeriod.isntUpdated": "Período de experiência não foi atualizado",
  "error.PromoCodeDoesntExist": "O código promocional {promoCode} não existe",
  "error.PromoCodeIsntValid": "O código promocional {promoCode} não é válido",
  "error.reasonRequired": "O campo de motivo é obrigatório.",
  "error.requestedMoreThanFullDay": "Você solicitou mais de um dia inteiro de folga em um dia útil. Por favor, selecione um dia diferente.",
  "error.samePasswordException": "Senha não alterada",
  "error.samePasswordExceptionMessage": "A nova senha é igual à antiga. Por favor, digite uma senha diferente se quiser alterá-la.",
  "error.saveFailed": "Falha ao salvar",
  "error.seniorityEntitlement.isntCreated": "O direito por tempo de serviço não foi criado",
  "error.seniorityEntitlement.isntDeleted": "O direito por tempo de serviço não foi excluído",
  "error.seniorityEntitlement.isntUpdated": "O direito por tempo de serviço não foi atualizado",
  "error.signin.inactiveUser": "Usuário Inativo",
  "error.signup.alreadySignedUp.descriptionLine1": "Você já está cadastrado com o seguinte endereço de e-mail: <text>{ email }</text>. Por favor, faça o login.",
  "error.signup.alreadySignedUp.descriptionLine2": "Caso tenha esquecido sua senha, clique no botão abaixo para redefini-la.",
  "error.signup.alreadySignedUp.title": "Você já está cadastrado",
  "error.signup.alreadySignedUpInactive.descriptionLine1": "Você já está cadastrado com o seguinte endereço de e-mail: <text>{ email }</text>.",
  "error.signup.alreadySignedUpInactive.descriptionLine2": "No entanto, sua conta está inativa. Entre em contato com nosso suporte para obter mais informações e envie a eles as seguintes informações:",
  "error.slack.allowConversationWithSlackBotDescription": "Este recurso requer uma permissão adicional do Slack que permite que o bot do Vacation Tracker receba mensagens diretas. Nosso bot poderá ler as mensagens que você e outros usuários do seu Workspace do Slack enviam para ele, mas não terá permissão para ler seus canais, grupos ou mensagens diretas.",
  "error.slack.allowConversationWithSlackBotLlmWarningLine1": "O bot do Vacation Tracker suporta um comando de barra e atalho para solicitações de folga. Além disso, você pode habilitar a conversa em linguagem humana no Painel da Web (e optar pelo uso de LLM).",
  "error.slack.allowConversationWithSlackBotLlmWarningLine2": "Ativar isso pode fazer com que o bot ocasionalmente sugira datas ou tipos de folga incorretos. É importante verificar duas vezes suas recomendações antes de fazer uma solicitação de folga.",
  "error.slack.allowConversationWithSlackBotLlmWarningLine3": "Nem o Vacation Tracker nem seus terceiros usam suas informações para treinar Modelos de Linguagem de Grande Escala.",
  "error.slack.allowConversationWithSlackBotLlmWarningTitle": "Aviso sobre Modelo de Linguagem de Grande Escala (LLM)",
  "error.slack.allowConversationWithSlackBotTitle": "Permitir conversa com o bot do Vacation Tracker",
  "error.slack.logInWithDifferentAccount": "Faça login com uma conta diferente",
  "error.slack.usersLoginRequiredTitle": "É necessário fazer login na sua conta do Slack",
  "error.slack.wrongAccount": "Conta do Slack errada",
  "error.somethingWentWrong": "Oh não! 😕 Algo deu errado. ",
  "error.startDateCantBeInPast": "A data de início não pode estar no passado, por favor selecione uma data no futuro",
  "error.subscriptionExpired": "Ops, parece que a assinatura da sua empresa no Vacation Tracker expirou! Entre em contato com um administrador para reativar a assinatura da sua empresa.",
  "error.subscriptionExpiredTitle": "Assinatura expirada",
  "error.team.delete": "O departamento não pode ser excluído",
  "error.TeamLimitReached": "O número atual de departamentos excede o limite permitido no plano CORE. Para fazer o downgrade, reduza seus departamentos para {limitNumber} ou menos, ou entre em contato com nosso suporte para obter assistência.",
  "error.token.button": "Faça login com {platform}",
  "error.token.description": "Seu token {platform} expirou. Por favor, clique no botão abaixo para obter um novo token",
  "error.token.permissionDenied": "No prompt de login do Google, você clicou em Continuar sem permitir os escopos de autenticação necessários. Por favor, clique em Selecionar tudo ou selecione as permissões adequadas (Ver, editar, compartilhar e excluir permanentemente todos os calendários que você pode acessar usando o Google Calendar e Ver, editar, criar ou alterar suas configurações de e-mail e filtros no Gmail) e então clique em Continuar.",
  "error.userEndDateInFuture": "As datas solicitadas são posteriores à data de término de emprego do usuário. Entre em contato com o administrador da sua organização ou com o suporte do Vacation Tracker para obter mais informações.",
  "error.UserNotAdminOrApprover": "Usuário não é administrador ou aprovador",
  "error.userNotFound": "Usuário não encontrado",
  "error.UserNotFound": "Usuário não encontrado",
  "error.UserNotFoundApprover": "Aprovador não encontrado",
  "error.userSignup.invitationAccepted.description": "Código de convite já aceito, por favor faça login",
  "error.userSignup.invitationAccepted.title": "Código de convite já aceito",
  "error.userSignup.invitationExpired.description": "Código de convite expirado, peça ao seu administrador para reenviar o convite",
  "error.userSignup.invitationExpired.title": "Código de convite expirado",
  "error.userSignup.invitationNotFound.description": "Por favor, verifique se o link do seu convite está correto ou entre em contato com seu administrador para reenviar o convite.",
  "error.userSignup.invitationNotFound.title": "Convite não encontrado",
  "error.userStartDateInFuture": "As datas solicitadas são anteriores à data de início de emprego do usuário. Entre em contato com o administrador da sua organização ou com o suporte do Vacation Tracker para obter mais informações.",
  "error.zeroDaysLeaveRequest": "Você selecionou um feriado ou um dia não útil. Por favor, selecione um intervalo de datas válido.",
  "errors.microsoft.permissions.microsoftPermissionsNotGrantedDescription": "Parece que você cancelou ou fechou a página \"Entrar com Microsoft\" antes de conceder as permissões necessárias. Por favor, clique no botão abaixo para fazer login com a Microsoft e concluir a configuração do Vacation Tracker.\n\nSe você não tem permissão para fazer login com a Microsoft, entre em contato com o administrador do Microsoft 365 da sua organização.",
  "errors.microsoft.subscriptionExpiredDescription": "Parece que sua assinatura expirou. Entre em contato com o administrador da sua organização no Vacation Tracker para obter mais detalhes.\n\nEntre em contato com nosso suporte se precisar alterar o administrador ou tiver outras dúvidas.",
  "errors.microsoft.wrongMicrosoftAccountDescription": "Parece que você fez login com uma conta Microsoft diferente. Você comprou licenças usando o seguinte endereço de e-mail:\n\n{email}.\n\nPor favor, faça login com a conta que você usou para comprar as licenças para concluir a configuração.",
  "errors.slack.wrongSlackAccountDescription": "Parece que você fez login com uma conta Slack diferente.",
  "events.logs.AUTOMATION": "Automações",
  "events.logs.BILLING": "Faturamento",
  "events.logs.BULK_ACTIONS": "Importar usuários",
  "events.logs.COMPANY": "Empresa",
  "events.logs.LABEL": "Etiqueta",
  "events.logs.LEAVE_POLICY": "Política de licença",
  "events.logs.LEAVE_TYPE": "Tipo de licença",
  "events.logs.LOCATION": "Localização",
  "events.logs.LOCATION_YEAR_ROLLOVER": "Virada de ano da localização",
  "events.logs.NOTIFICATION": "Notificações",
  "events.logs.REPORT": "Relatório",
  "events.logs.TEAM": "Departamento",
  "events.logs.TEAM_POLICY": "Política de equipe",
  "export.approved": "Aprovado",
  "export.currentYear": "Ano atual",
  "export.denied": "Negado",
  "export.export": "Exportar",
  "export.exportPeriod": "Período de exportação",
  "export.fileType": "Formato de exportação",
  "export.fileTypeCsv": "CSV",
  "export.fileTypeExcelXlsx": "Excel",
  "export.labels": "Etiquetas",
  "export.lastMonth": "Mês passado",
  "export.leaveBalanceReport": "Relatório de Saldo de Licenças",
  "export.leaveBalanceReportCustomDaysInfo": "Observe que o relatório exibirá apenas os dias ou horas usados se você selecionar um período personalizado. Você não poderá exportar a cota total ou disponível, a menos que escolha uma das opções predefinidas.",
  "export.leaveBalanceReportDescription": "A quantidade de licenças concedidas e tiradas por Tipo de Licença para cada funcionário em um período específico.",
  "export.leaveHistoryReport": "Relatório de Solicitações de Licença",
  "export.leaveHistoryReportDescription": "Histórico de todas as solicitações de licença (aprovadas, negadas, expiradas) por Tipo de Licença para cada funcionário em um período específico.",
  "export.locations": "Localizações",
  "export.monthlyLeaveBalanceReport": "Relatório Mensal de Saldo de Licenças",
  "export.monthlyLeaveBalanceReportDescription": "A quantidade de licenças tiradas (ou agendadas) por Tipo de Licença para cada funcionário para o mês atual e os próximos 11 meses.",
  "export.nextYear": "Próximo ano",
  "export.previousYear": "Ano anterior",
  "export.rangeDate": "Data de Início e Fim",
  "export.reportType": "Tipo de relatório",
  "export.required": "Este campo é obrigatório.",
  "export.status": "Status",
  "export.teams": "Departamentos",
  "externalConnect.faildLogin": "Seu token de uso único expirou ou já foi usado. Vamos redirecioná-lo para a página de destino em alguns segundos, mas você pode precisar fazer login novamente.\n\nSe estiver com pressa, clique no botão abaixo.",
  "externalConnect.parseError": "Oh não! 😕 Algo deu errado.\n\nVocê será redirecionado para a página de login em 10 segundos.",
  "externalConnect.pleaseWait": "Por favor, aguarde. Seu PC não é um super-homem!",
  "form.confirmPasswordInfo": "Por favor, confirme sua senha!",
  "form.department.approverDescription": "Atribua aprovador(es) para este departamento. O(s) aprovador(es) será(ão) responsável(is) por gerenciar as solicitações de licença enviadas pelos membros do departamento. Um aprovador pode pertencer a outro departamento, e um único departamento pode ter vários aprovadores. O fluxo de aprovação pode ser configurado como: Nível único, onde é necessária a aprovação de apenas uma pessoa, ou Multinível (disponível no Plano Completo), com aprovações sequenciais. Em cada nível, as notificações são enviadas para todos os aprovadores daquele nível. A aprovação por qualquer aprovador move a solicitação para o próximo nível, e o processo continua até ser totalmente aprovado",
  "form.department.approvers": "Aprovador(es)",
  "form.department.approversFirstLevel": "Aprovador(es) de Primeiro Nível",
  "form.department.approversSecondLevel": "Aprovador(es) de Segundo Nível",
  "form.department.approverTitle": "Fluxo de Aprovação",
  "form.department.apprverFlowType": "Tipo de Fluxo de Aprovação",
  "form.department.apprverFlowType.multiLevel": "Aprovação Multinível",
  "form.department.apprverFlowType.singleLevel": "Aprovação de Nível Único",
  "form.department.generalDescriptionCreate": "Defina o nome do seu departamento e selecione seus usuários e aprovadores. Se quiser configurar o departamento antes de adicionar usuários, você pode criar o departamento primeiro e adicionar usuários depois, mas adicionar pelo menos um aprovador é obrigatório.",
  "form.department.generalDescriptionEdit": "Defina o nome do seu departamento e selecione seus membros. Se necessário, você pode configurar o departamento primeiro e adicionar usuários depois.",
  "form.department.generalTitle": "Configurações do Departamento",
  "form.department.setDefaultDescription": "Departamento padrão",
  "form.department.setDefaultTitle": "Departamento padrão",
  "form.department.subdepartmentDescription": "Você pode aninhar departamentos para criar uma estrutura organizacional em árvore. Se este for um subdepartamento, você pode definir seu departamento pai nesta seção.",
  "form.department.subdepartmentTitle": "Configurações de Subdepartamento",
  "form.general.companySettingsDescription": "Defina o nome da sua empresa e o e-mail de contato principal. O endereço de e-mail fornecido será usado apenas para comunicações importantes sobre o produto. Sem spam, prometemos.",
  "form.general.companySettingsTitle": "Configurações da empresa",
  "form.general.configurationDescription": "Esta seção permite ajustar aspectos específicos do controle de licenças para sua empresa. Ative as datas de término do usuário para desativar automaticamente funcionários com uma data de término, impedindo-os de enviar solicitações de licença além de sua saída. Escolha rastrear cotas de licença em dias ou horas, de acordo com as políticas da sua empresa. Além disso, defina o ano inicial para o rastreamento de licenças. Por padrão, os cálculos de licença começam a partir do ano em que você se inscreveu, mas você pode ajustar isso para incluir dados históricos de outros sistemas ou planilhas.",
  "form.general.configurationTitle": "Configuração",
  "form.general.migrationDescription": "Você está usando Slack, Microsoft Teams ou Google Workspace? Você pode mudar da autenticação baseada em e-mail e senha para uma integração específica da plataforma. Mudar para uma de nossas integrações simplificaria o processo de login e convite de usuários (importar usuários do seu diretório de usuários) e forneceria integrações adicionais, como abas do Microsoft Teams e bots do Microsoft Teams ou Slack.",
  "form.general.migrationFeatureDescription": "Isso iniciará o processo de migração. Você será redirecionado para uma plataforma onde poderá conectar sua conta.",
  "form.general.migrationFeatureTitle": "Iniciar migração para outra plataforma",
  "form.general.migrationTitle": "Mudar para outra plataforma",
  "form.inputRequired": "Este campo é obrigatório",
  "form.inputSize": "O campo deve ter {len} dígitos",
  "form.nameRequired": "O nome é obrigatório",
  "form.passwordDoNotMatch": "As duas senhas que você digitou não coincidem!",
  "form.userNameRequired": "Por favor, digite seu nome",
  "form.validEmailRequired": "Por favor, digite um endereço de e-mail válido.",
  "general.announceNewUsers": "Anunciar para novos usuários",
  "general.announceNewUsersInfo": "Enviar mensagem de anúncio sobre o Vacation Tracker para novos usuários do Slack/Teams/Google Workspaces",
  "general.announceNewUsersInfoTooltip": "Quando esta caixa está marcada, sempre que você adicionar um novo usuário à sua organização Slack/Teams/GW, eles receberão uma breve mensagem do Vacation Tracker explicando como ele funciona. Se esta caixa não estiver marcada, os novos usuários não receberão nenhuma mensagem de anúncio do Vacation Tracker.",
  "general.announceNewUsersTooltip": "Sempre que você adicionar um novo usuário ao seu {platform}, eles receberão uma breve mensagem do Vacation Tracker explicando como ele funciona. Se você escolher a opção Não enviar, os novos usuários não receberão nenhuma mensagem de anúncio do Vacation Tracker.",
  "general.calculationStartYear": "O Vacation Tracker rastreia suas licenças a partir de",
  "general.calculationStartYearTooltipLine1": "Você só precisa alterar esta configuração se quiser importar licenças históricas de outro sistema ou planilhas e incluir essas licenças nos cálculos do Vacation Tracker.",
  "general.calculationStartYearTooltipLine2": "O Vacation Tracker calcula as licenças a partir do ano em que você se inscreveu. Isso significa que calcularemos automaticamente os dias restantes e transferidos de acordo com suas configurações, e você poderá definir os dias transferidos do seu sistema anterior para cada usuário em suas páginas de perfil. No entanto, se você quiser que o Vacation Tracker calcule licenças de anos anteriores, você pode alterar esta data. Sinta-se à vontade para <support>entrar em contato com nosso suporte</support> para mais informações.",
  "general.clickToSelectPlatform": "Clique para selecionar a plataforma",
  "general.companyId": "ID da Empresa:",
  "general.companyName": "Nome da Empresa",
  "general.confirmModalTitle": "Confirmar Atualizações das Configurações da Empresa",
  "general.connectSync": "Conectar sincronização",
  "general.connectToAnotherPlatform": "Mudar para outra plataforma",
  "general.connectWith": "Mudar para {platform}",
  "general.connectWithHelpdeskInfo": "Não tem certeza de qual plataforma escolher? Este <link>artigo</link> pode ajudar.",
  "general.days": "Dias",
  "general.deleteAccount": "Se você deseja modificar ou excluir seus dados, entre em contato com nossa equipe de suporte em <mail>hello@vacationtracker.io</mail>",
  "general.enableUserEndDate": "Ativar data de término do usuário",
  "general.enableUserEndDateInfo": "Quando ativado, usuários com uma data de término não poderão enviar solicitações de licença após essa data, e nós os desativaremos automaticamente.",
  "general.enableUserEndDateTooltip": "Quando a Data de Término do Usuário é ativada, os Usuários não poderão enviar solicitações de licença para o período após sua data de término. Eles serão automaticamente desativados e incapazes de fazer login no Vacation Tracker.",
  "general.googleConsent": "Login do Google",
  "general.googleConsentInfo": "Consentimento do Google",
  "general.googleMorePermission": "Google zeza sa imenima ako hocete da budete sigurni da imate puno ime i prezime treba nam jos permission-a, kliknite ovde da nam date jos permission-a",
  "general.hourlyLeaveAccounting": "Definir Cotas de Licença em",
  "general.hourlyLeaveAccountingTooltip": "Quando ativada, esta opção rastreia a duração de todas as licenças e cotas em horas. Disponível apenas para o plano Complete.",
  "general.hours": "Horas",
  "general.importUsersAutomatically": "Importar usuários automaticamente",
  "general.importUsersAutomaticallyInfo": "Com a importação automática de usuários ativada, importaremos e ativaremos automaticamente todos os novos usuários regulares do {platform}.",
  "general.importUsersAutomaticallyInfoTooltip": "Por exemplo, quando você convida um novo usuário para seu espaço de trabalho, esse usuário estará disponível no Vacation Tracker alguns minutos após o convite. Se você quiser ativar um usuário convidado multicanal, precisará convidá-lo manualmente.",
  "general.importUsersAutomaticallyTurnOffInfo": "Não importaremos nem ativaremos mais automaticamente todos os novos usuários regulares do {platform}.",
  "general.importUsersConfigSection": "Configuração de Importação de Usuários",
  "general.importUsersConfigSectionDescription": "Esta seção permite habilitar a importação automática de usuários do seu diretório de usuários e configurar as configurações de importação de usuários, como a mensagem de anúncio, o departamento padrão e a localização padrão para novos usuários.",
  "general.pleaseEnterCompanyName": "Por favor, digite o nome da empresa",
  "general.pleaseEnterContactEmail": "Por favor, digite o e-mail da empresa",
  "general.syncGoogleUsers": "Sincronizar usuários do Google",
  "general.syncGoogleUsersInfo": "Sincronizar usuários do Google automatsko brisanje, update i dodavanje usera",
  "general.updateInProgress": "Atualizando configurações da empresa",
  "general.updateSuccessTitle": "Configurações da empresa atualizadas",
  "general.usefulLinks": "<healthcheck>Verificação de Saúde</healthcheck>, <helpdesk>Central de Ajuda</helpdesk>",
  "general.usefulLinksTitle": "Links úteis: ",
  "holidays.filterByType": "Filtrar por tipo de feriado",
  "holidays.holidaysName": "Nome do feriado",
  "holidays.holidaysTab": "Feriados para {year}",
  "holidays.holidaysType": "Tipo de Feriado",
  "holidays.importHolidays": "Importar Feriados",
  "holidays.importHolidaysAutomaticallyQuestion": "Deseja importar automaticamente os feriados selecionados para os próximos anos?",
  "holidays.importHolidaysAutomaticallyTooltip": "O Vacation Tracker ajustará automaticamente os dias para os feriados públicos que não caem na mesma data todos os anos, como Ação de Graças, Páscoa, etc.",
  "holidays.importHolidaysAutomaticallyTooltipForMissingHolidays": "Infelizmente, este recurso não é atualmente suportado para este país. Estamos trabalhando para expandir nossos serviços, então fique atento para atualizações.",
  "holidays.importHolidaysAutomaticallyTurnedOff": "Importação automática de feriados desativada",
  "holidays.importHolidaysAutomaticallyTurnedOn": "Importação automática de feriados ativada",
  "holidays.importHolidaysCount": "Importar {count} feriados selecionados",
  "holidays.importHolidaysFor": "Importar feriados para <b>{year}</b> para <b>{countryState}</b>",
  "holidays.importHolidaysSummary": "Você selecionou os seguintes feriados para <b>{year}</b> para <b>{countryState}</b>",
  "holidays.pastHolidays": "Feriados passados",
  "holidays.totalSelectedHolidays": "Você selecionou {total} feriado(s)",
  "holidays.totalSelectedHolidaysTip": "({total} não visível devido ao filtro aplicado)",
  "holidays.typeBank": "Bancário",
  "holidays.typeObservance": "Observância",
  "holidays.typeOptional": "Opcional",
  "holidays.typePublic": "Público",
  "holidays.typeSchool": "Escolar",
  "importLeaves.importFail": "A importação falhou com a seguinte mensagem de erro:",
  "importLeaves.importSuccess": "Você enviou as folgas para importação. Verifique em alguns minutos para ver se foram importadas com sucesso.",
  "importUsers.allUsersImported.description": "Parece que você já importou todos os usuários do seu diretório! Você deve ver todos os usuários na página Usuários. Se não vir alguém, dê uma olhada na aba Inativos. Sinta-se à vontade para entrar em contato com nosso suporte se tiver alguma dúvida ou precisar de assistência.",
  "importUsers.allUsersImported.goToUsersPageButton": "Ir para a página de Usuários",
  "importUsers.allUsersImported.title": "Todos os usuários já foram importados!",
  "importUsers.form.backButton": "Voltar para a página de Usuários",
  "importUsers.form.searchGoogleUsersPlaceholder": "Pesquisar usuários no seu Google Workspace",
  "importUsers.form.searchMicrosoftUsersPlaceholder": "Digite pelo menos um caractere para começar a pesquisar usuários no seu Diretório Ativo do Microsoft 365",
  "importUsers.form.searchSlackUsersPlaceholder": "Pesquisar usuários no seu Workspace do Slack",
  "importUsers.form.searchUsersNotFound": "Usuário não encontrado ou já importado",
  "importUsers.form.searchUsersPlaceholder": "Pesquisar usuários no seu Diretório Ativo do Microsoft 365",
  "importUsers.form.selectedUsersLabel": "Usuários Selecionados ({selectedUsers})",
  "importUsers.form.showSelectedUsersAsNumber": "Usuários Selecionados",
  "importUsers.statistics.imported": "Usuários importados para o Vacation Tracker",
  "importUsers.statistics.total": "Total de usuários no seu {directoryName}",
  "leaveRequest.halfDay": "Meio dia",
  "leaveRequest.hourly": "Por hora",
  "leaveRequest.sent": "Solicitação de folga enviada",
  "leaveRequest.simpleQuota.addAnotherLeave": "Adicionar outra folga",
  "leaveRequest.simpleQuota.info": "Aqui está o seu saldo de folgas para {leaveTypeName} de {start} - {end}.",
  "leaveRequest.simpleQuota.requestAnotherLeave": "Solicitar outra folga",
  "leaveRequest.simpleQuota.summaryInfo": "Enviamos a seguinte solicitação para aprovação do seu gestor",
  "leaveRequests.addedCurrentYear": "Adicionado para o ano atual",
  "leaveRequests.addeddNextYear": "Adicionado para o próximo ano",
  "leaveRequests.cancelThisAction": "Cancelar esta ação",
  "leaveRequests.dates": "Data{plural}",
  "leaveRequests.daysRemainingForCurrentYear": "Dias restantes para o ano atual",
  "leaveRequests.daysRemainingForNextYear": "Dias restantes para o próximo ano",
  "leaveRequests.daysRequestedForCurrentYear": "Dias solicitados para o ano atual",
  "leaveRequests.daysRequestedForNextYear": "Dias solicitados para o próximo ano",
  "leaveRequests.denyWithReason": "Negar com motivo",
  "leaveRequests.hoursRemainingForCurrentYear": "Horas restantes para o ano atual",
  "leaveRequests.hoursRemainingForNextYear": "Horas restantes para o próximo ano",
  "leaveRequests.hoursRequestedForCurrentYear": "Horas solicitadas para o ano atual",
  "leaveRequests.hoursRequestedForNextYear": "Horas solicitadas para o próximo ano",
  "leaveRequests.leaveRequestInfoTitle": "<strong>{name}</strong> enviou a seguinte solicitação de licença:",
  "leaveRequests.leaveRequestInfoTitleToil": "<strong>{name}</strong> enviou a seguinte solicitação de TOIL:",
  "leaveRequests.leaveTypeName": "Tipo de Licença",
  "leaveRequests.pending": "Solicitação pendente",
  "leaveRequests.reason": "Motivo",
  "leaveRequests.reasonForDenying": "Motivo para negar",
  "leaveRequests.remainingCurrentYear": "Restante para o ano atual",
  "leaveRequests.remainingNextYear": "Restante para o próximo ano",
  "leaveRequests.requestedCurrentYear": "Solicitado para o ano atual",
  "leaveRequests.requestedNextYear": "Solicitado para o próximo ano",
  "leaveRequests.requestSummary": "Resumo da solicitação",
  "leaveRequests.wrongAction": "Esta ação é desconhecida para nós...🤔 Por favor, tente uma diferente! Você será redirecionado para o Painel em 7 segundos.",
  "leaveRequests.wrongLeaveRequestId": "Ops! Esta solicitação de licença não foi encontrada! 🔎 Você será redirecionado para o Painel em 7 segundos.",
  "leaveRequestStatus.approved": "aprovada",
  "leaveRequestStatus.APPROVED": "aprovada",
  "leaveRequestStatus.cancelled": "cancelada",
  "leaveRequestStatus.CANCELLED": "cancelada",
  "leaveRequestStatus.deleted": "excluída",
  "leaveRequestStatus.DELETED": "excluída",
  "leaveRequestStatus.denied": "negada",
  "leaveRequestStatus.DENIED": "negada",
  "leaveRequestStatus.open": "aberta",
  "leaveRequestStatus.OPEN": "aberta",
  "leaveTypes.activatingLeaveType": "Ativar Tipo de Licença",
  "leaveTypes.activatingLeaveTypeInfo": "Ao ativar este Tipo de Licença, você está restaurando sua localização anterior nas Localizações, bem como suas configurações e dados anteriores.",
  "leaveTypes.activatingLeaveTypeTitle": "Ativar Tipo de Licença",
  "leaveTypes.addLeaveTypeInfo": "Crie um Tipo de Licença personalizado adicionando um nome, cor para facilitar a navegação e status (Ativo/Inativo). Não se preocupe, você pode editar todas as configurações do Tipo de Licença mais tarde. \n\nPara atribuir uma Política de Licença personalizada a um Tipo de Licença, visite Localizações -> Atribuir Política de Licença.",
  "leaveTypes.addTypeToLocationButton": "Adicionar",
  "leaveTypes.assignedToLocationsColumn": "Atribuído a Localizações",
  "leaveTypes.assignedToLocationsColumnWarning": "Este tipo de licença não está atribuído a nenhuma localização.",
  "leaveTypes.color": "Cor",
  "leaveTypes.createInProgress": "Criando o tipo de licença {leaveTypeName}",
  "leaveTypes.createNewLeaveType": "Criar um Tipo de Licença",
  "leaveTypes.deactivateLeaveType": "Desativar Tipo de Licença",
  "leaveTypes.deactivateLeaveTypeInfo": "Ao desativar este Tipo de Licença, você está removendo-o de todas as Localizações que atualmente o utilizam. Quando você ativar o Tipo de Licença novamente, ele aparecerá nas mesmas Localizações com as configurações e dados existentes.",
  "leaveTypes.deactivateLeaveTypeTitle": "Desativar Tipo de Licença",
  "leaveTypes.deleteInProgress": "Excluindo o tipo de licença {leaveTypeName}",
  "leaveTypes.deleteLeaveType": "Excluir Tipo de Licença",
  "leaveTypes.deleteLeaveTypeConfirm": "Tem certeza de que deseja excluir o tipo de licença {name}?",
  "leaveTypes.deleteLeaveTypeTitle": "Excluir Tipo de Licença",
  "leaveTypes.editLeaveType": "Editar Tipo de Licença",
  "leaveTypes.isActive": "Ativo",
  "leaveTypes.sort": "Ordenar",
  "leaveTypes.typeName": "Nome",
  "leaveTypes.updateInProgress": "Atualizando o tipo de licença {leaveTypeName}",
  "location.createInProgress": "Criando a localização {locationName}",
  "location.deleteInProgress": "Excluindo a localização {locationName}",
  "location.editLocation": "Editar localização",
  "location.fiscalYearStart": "Início do Ano Fiscal",
  "location.general": "Geral",
  "location.hasNoUsers": "A localização não tem usuários.",
  "location.holidays": "Feriados",
  "location.holidays.autoimportTitle": "Configurações de importação automática de feriados alteradas",
  "location.holidays.brokenHolidays": "Este feriado não pode ser adicionado, entre em contato com o suporte para resolver o problema",
  "location.holidays.successTitle": "Feriados atualizados com sucesso",
  "location.holidays.updateInProgress": "Atualizando feriados",
  "location.holidaysUpdated": "Feriados atualizados",
  "location.leavePolicies": "Políticas de Licença",
  "location.leavePoliciesNotExistInfo": "Atualmente não há Políticas de Licença disponíveis. Por favor, atribua uma Política de Licença à Localização.",
  "location.name": "Nome",
  "location.never": "Nunca",
  "location.resetQuotas": "Redefinição de Cota de Licença Baseada Em",
  "location.resetQuotasInfo": "Esta configuração determinará se o seu saldo de licença anual será redefinido com base no ano contábil (ano fiscal da empresa) ou com base na data de início do funcionário. Além das cotas, sua política de transferência também será afetada de acordo com esta configuração.",
  "location.rolloverExpiryAfterDays": "Expiração de Dias Transferidos",
  "location.rolloverExpiryAfterHours": "Expiração de Horas Transferidas",
  "location.rolloverExpiryDay": "Os Dias Transferidos Devem Expirar?",
  "location.rolloverExpiryHour": "As Horas Transferidas Devem Expirar?",
  "location.rolloverExpiryOnDate": "Dias Transferidos Expiram Em",
  "location.rolloverExpiryOnDate.extra": "Os dias expirarão no final do dia.",
  "location.rolloverExpiryOnDateHours": "Horas Transferidas Expiram Em",
  "location.rolloverExpiryOnDateHours.extra": "As horas expirarão no final do dia.",
  "location.startYear": "Início do Ano Fiscal",
  "location.startYearInfo": "O Ano Fiscal de uma organização é um ano considerado para fins de tributação ou contabilidade. As datas de redefinição do período de férias dependem do início e do fim do ano fiscal da organização.",
  "location.timezone": "Fuso horário",
  "location.timezoneDefault": "(GMT+00:00) GMT (sem horário de verão)",
  "location.updateInProgress": "Atualizando a localização {locationName}",
  "location.users": "Usuário(s)",
  "location.workWeek": "Semana de trabalho",
  "locationForm.assignLeaveTypesInfo": "Depois de criar uma Localização, atribua uma Política de Licença à Localização, para permitir que os Usuários solicitem Licença. \nPara atribuir uma Política de Licença, vá para Localização -> Políticas de Licença -> Atribuir Política de Licença.",
  "locationForm.createdSuccessfully": "Por favor, aguarde um momento, estamos criando sua Localização.",
  "locationForm.createLocation": "Criar Localização",
  "locationForm.editLocation": "Editar Localização",
  "locationForm.locationLimitReached": "Ops! Parece que você atingiu o limite de {limitNumber} localizações para seu plano atual. Para gerenciar ou atualizar seu plano, visite a <billingPage>página de Faturamento</billingPage>.",
  "locationForm.updateLocation": "Atualizar Localização",
  "locations.automaticallyGeneratedName": "Geramos automaticamente esta localização para você agrupando as seguintes equipes: <t>{team}</t>.",
  "locations.automaticallyTransferredLeavePoliciesAndHolidays": "As políticas de licença e feriados são transferidos automaticamente para você, então você <b>{not}</b> precisa recriá-los.",
  "locations.created": "Criado",
  "locations.createNewLocation": "Criar uma Localização",
  "locations.default": "Padrão",
  "locations.defaultLocation": "Local Padrão",
  "locations.defaultLocationDescription": "Todos os novos usuários serão automaticamente adicionados a este local.",
  "locations.deleteLocation": "Excluir Local",
  "locations.deleteLocation.disabled": "Você não pode excluir o local padrão",
  "locations.deleteLocationConfirm": "Por favor, confirme que deseja excluir o local {name}.",
  "locations.deleteLocationConfirmInitialRollover": "Excluir um local pode impactar a configuração dos usuários e as informações de licença (por exemplo, os dias iniciais transferidos).",
  "locations.deleteLocationTitle": "Excluir Local {name}",
  "locations.editLocation": "Editar",
  "locations.hasNoUsers": "Nenhum",
  "locations.holidays": "Feriados",
  "locations.leavePolicies": "Políticas de Licença",
  "locations.leavePolicies.quotaDays": "{value} dias",
  "locations.leavePolicies.quotaHours": "{value} horas",
  "locations.leavePolicies.yearlyQuota": "Cota Anual de Licença",
  "locations.locationLimitReached": "Você está atualmente usando todos os {limitNumber} slots de local disponíveis no plano CORE. Para gerenciar ou atualizar seu plano, por favor, visite a <billingPage>página de Faturamento</billingPage>.",
  "locations.name": "Nome",
  "locations.pleaseRenameLocation": "Por favor, renomeie este local!",
  "locations.pleaseRenameLocationName": "Por favor, renomeie os nomes de local gerados automaticamente *",
  "locations.resetQuotas": "Reinício da Cota de Licença Baseado Em",
  "locations.selectLocation": "Selecionar local",
  "locations.unableToNameThisLocation": "No entanto, nosso sistema não conseguiu nomear este local adequadamente.",
  "locations.users": "Usuário(s)",
  "locations.viewHolidays": "Ver Feriados",
  "locations.viewLeavePolicies": "Ver Políticas de Licença",
  "locations.viewLocation": "Visualizar",
  "locations.weAutomaticallyCreatedLocations": "Criamos automaticamente locais para sua organização agrupando suas equipes por feriados. Todas as políticas de licença são transferidas, você só precisa renomear seus locais.",
  "manageMicrosoftLicenses.assignLicencesSuccessTitle": "As licenças foram atribuídas com sucesso.",
  "manageMicrosoftLicenses.weAreAssignLicenses": "Estamos atribuindo as licenças.",
  "microsoft.adminConsentErrorButton": "Obter acesso",
  "microsoft.adminConsentErrorDescription1": "Parece que você não é um administrador da sua organização no Microsoft Teams. Configurar as notificações requer consentimento do administrador. Por favor, envie o seguinte link para o administrador da sua organização para obter o consentimento do administrador: ",
  "microsoft.adminConsentErrorDescription2": "Quando você obtiver o consentimento do administrador, clique no botão abaixo para obter acesso às notificações. Se você for um administrador, visite o mesmo link, dê o consentimento do administrador e poderá configurar as notificações.",
  "microsoft.adminConsentErrorTitle": "Consentimento do Administrador do Microsoft Teams Necessário.",
  "microsoft.createCompanyTeamLoginAdminConsentError1": "Parece que você não é um administrador da sua organização no Microsoft Teams. Importar membros da equipe de uma única equipe do Microsoft Teams requer consentimento do administrador. Por favor, envie o seguinte link para o administrador da sua organização para obter o consentimento do administrador: ",
  "microsoft.createCompanyTeamLoginAdminConsentError2": "Quando você obtiver o consentimento do administrador, poderá continuar com o processo de inscrição. Se você for um administrador, visite o mesmo link, dê o consentimento do administrador e poderá continuar com a inscrição.",
  "microsoft.createCompanyTeamLoginRequiredDescription1": "Precisamos de permissão adicional do Microsoft Teams para listar usuários da equipe que você selecionou. Se você clicar no botão OK, redirecionaremos você para a página de login da Microsoft. Dependendo das configurações do seu navegador, você pode já estar logado e ser redirecionado automaticamente de volta.",
  "microsoft.createCompanyTeamLoginRequiredDescription2": "Após fazer login na sua conta Microsoft, levaremos você de volta a esta página para que você possa selecionar usuários para importar. Clicar no botão Cancelar ainda permitirá que você se inscreva com toda a sua organização.",
  "microsoft.createCompanyTeamLoginRequiredTitle": "Permissão Adicional do Microsoft Teams Necessária.",
  "microsoft.manageLicenses.loginMicrosoft": "Faça login com sua conta Microsoft 365 para atribuir licenças.",
  "microsoft.manageLicenses.loginMicrosoftInfo": "Fazer login com sua conta listará os usuários em seu Active Directory da Microsoft. Você poderá atribuir licenças compradas e, opcionalmente, notificar os usuários importados.",
  "microsoft.notificationsLoginRequiredDescription1": "Gerenciar notificações requer um token do Microsoft Teams. Se você clicar no botão OK, redirecionaremos você para a página de login da Microsoft. Dependendo das configurações do seu navegador, você pode já estar logado e ser redirecionado automaticamente de volta.",
  "microsoft.notificationsLoginRequiredDescription2": "Após fazer login na sua conta Microsoft, levaremos você de volta a esta página para que possa gerenciar as notificações. Clicar no botão Cancelar o levará de volta à última página que você visitou em nosso painel.",
  "microsoft.notificationsLoginRequiredTitle": "É necessário fazer login na sua conta Microsoft",
  "microsoft.universalLoginRequiredDescription": "Parece que seu token expirou. Por favor, faça login na sua conta do Microsoft Teams para continuar.",
  "microsoft.universalLoginRequiredTitle": "Token expirado",
  "microsoft.usersLoginRequiredDescription1": "Gerenciar usuários requer um token do Microsoft Teams. Se você clicar no botão OK, redirecionaremos você para a página de login da Microsoft. Dependendo das configurações do seu navegador, você pode já estar logado e ser redirecionado automaticamente de volta.",
  "microsoft.usersLoginRequiredDescription2": "Após fazer login na sua conta Microsoft, levaremos você de volta a esta página para que possa gerenciar os usuários.",
  "microsoft.usersLoginRequiredTitle": "É necessário fazer login na sua conta Microsoft",
  "microsoftSass.createCompany.alertError.companyAlreadyExistsDescription": "Sua empresa já está cadastrada, mas você não está ativo. Você está usando o e-mail correto?\nSeu endereço de e-mail: {email}\nSe este e-mail estiver correto, sua conta não está ativada. Entre em contato com o administrador da sua organização no Vacation Tracker:\n{admins}\nOu entre em contato com nosso suporte se quiser se inscrever como uma equipe independente separada no mesmo locatário.\nSe seu e-mail não for o correto, faça login com uma conta Microsoft diferente ou faça login de uma janela anônima em seu navegador.",
  "microsoftSass.createCompany.alertError.errorTitle": "Erro",
  "microsoftSass.createCompany.alertError.goToAdminCenter": "Ir para o Centro de Administração",
  "microsoftSass.createCompany.alertError.goToConnectPage": "Ir para a página de conexão",
  "microsoftSass.createCompany.alertError.logInWithPlatform": "Entrar com {platform}",
  "microsoftSass.createCompany.alertError.resubscribedDescription": "Você foi reescrito com sucesso como empresa - {companyName}.\n\nPor favor, clique no botão para ser redirecionado para a página de login.",
  "microsoftSass.createCompany.alertError.resubscribedTitle": "Você se reinscreveu com sucesso",
  "microsoftSass.createCompany.alertError.tokenExpiredDescription": "Seu token de compra expirou. Por favor, vá para o Centro de Administração do Microsoft Teams e clique no botão \"Configurar conta\" para continuar.",
  "microsoftSass.createCompany.alertError.tokenExpiredTitle": "Token de compra expirado",
  "microsoftSass.createCompany.alertError.tokenInvalidDescription": "Seu token de compra da Microsoft é inválido. Se você comprou o plano Vacation Tracker através da Loja de Aplicativos do Microsoft Teams, por favor, vá para o Centro de Administração do Microsoft Teams e clique no botão \"Configurar conta\" para continuar. Caso contrário, vá para nossa página de conexão para fazer login ou criar uma conta.",
  "microsoftSass.createCompany.alertError.tokenInvalidTitle": "Token de compra inválido",
  "microsoftSass.createCompany.alertError.youAlreadyHaveAnAccountDescription": "Você já tem uma conta. Sua empresa ({companyName}) se cadastrou usando {platform}. Clique no botão abaixo para fazer login usando sua conta {platform}.\n\nNão se preocupe. Cancelamos sua compra e você não será cobrado pelas licenças que acabou de comprar. A antiga assinatura da sua empresa ainda está ativa. Você pode visitar o Centro de Administração da Microsoft para verificar se sua nova assinatura foi cancelada.\n\nSe você deseja comprar outra assinatura intencionalmente, entre em contato com nosso suporte.",
  "microsoftSass.createCompany.alertError.youAlreadyHaveAnAccountTitle": "Você já tem uma conta",
  "microsoftSass.createCompany.assignLicenses.assignPurchasedLicenses": "ATRIBUIR LICENÇAS COMPRADAS",
  "microsoftSass.createCompany.assignLicenses.assignPurchasedLicensesInfo": "Vamos atribuir as licenças que você comprou! Você tem {totalLicenses} licenças. Atribua-as aos usuários que poderão usar o Vacation Tracker.",
  "microsoftSass.createCompany.assignLicenses.buyMoreLicensesLater": "Você pode comprar mais licenças posteriormente na página Usuários do painel do Vacation Tracker.",
  "microsoftSass.createCompany.assignLicenses.loginMicrosoftDontWorry": "Não se preocupe! Não importaremos ou notificaremos ninguém sem sua aprovação.",
  "microsoftSass.createCompany.completed": "Concluído",
  "microsoftSass.createCompany.createCompanySetupInfo": "Vamos definir o e-mail de contato e as configurações padrão para sua organização no Vacation Tracker.",
  "microsoftSass.createCompany.createCompanyTitle": "COMPLETAR CONFIGURAÇÃO DA EMPRESA",
  "microsoftSass.createCompany.finishFristStep": "Por favor, complete a configuração da empresa para prosseguir.",
  "microsoftSass.createCompany.finishSecondStep": "Por favor, atribua licenças para prosseguir.",
  "microsoftSass.createCompany.finishSetup": "Finalizar Configuração",
  "microsoftSass.createCompany.help": "Se precisar de assistência, visite nossa <helpdesk>Central de Ajuda</helpdesk> ou <support>entre em contato com nosso suporte</support>.",
  "microsoftSass.createCompany.importAllUsers": "Atribuído a todos os usuários",
  "microsoftSass.createCompany.letsCompleteSetup": "Vamos concluir a configuração, atribuir licenças aos seus usuários e instalar a integração do Vacation Tracker para o Microsoft Teams.",
  "microsoftSass.createCompany.numberOfLicensesAssigned": "{assignedLicenses} licenças atribuídas",
  "microsoftSass.createCompany.setupMsBot.areYouAdmin": "Você é o administrador da sua integração do Microsoft Teams",
  "microsoftSass.createCompany.setupMsBot.errorExtedScopeLogin": "Parece que você não concedeu as duas permissões adicionais do Microsoft Teams necessárias para configurar o bot e as abas do canal. Sem esta etapa, não poderemos enviar uma mensagem de boas-vindas aos membros da equipe selecionados, e você não poderá usar o aplicativo Vacation Tracker a partir do seu aplicativo Microsoft Teams.",
  "microsoftSass.createCompany.setupMsBot.errorExtedScopeLoginSkip": "Você pode pular esta etapa e instalar o bot e as abas manualmente. Consulte nossa Central de Ajuda para o guia passo a passo.",
  "microsoftSass.createCompany.setupMsBot.installBotAndTabs": "Instalar bot e abas",
  "microsoftSass.createCompany.setupMsBot.installManuallyBotAdnTabs": "Instalarei o bot e as abas manualmente",
  "microsoftSass.createCompany.setupMsBot.microsoftTeamsLogin": "Microsoft Teams",
  "microsoftSass.createCompany.setupMsBot.morePermissionInfo": "Precisamos de permissão adicional para instalar o chatbot e as abas do Vacation Tracker no seu Microsoft Teams. Redirecionaremos você para a tela de permissões da Microsoft quando clicar no botão Prosseguir abaixo.",
  "microsoftSass.createCompany.setupMsBot.notAdmin.installBotManuallyDescription": "Por favor, instale o bot e as abas do Microsoft Teams do Vacation Tracker manualmente seguindo os passos descritos em <helpdesk>este artigo da Central de Ajuda</helpdesk>. Você não deve precisar de privilégios de administrador para a configuração manual. Você não deve precisar de assistência de administrador para a configuração manual. Se precisar de ajuda, entre em contato com <support>nosso suporte</support>.",
  "microsoftSass.createCompany.setupMsBot.notAdmin.installBotManuallyTitle": "Você precisa instalar a integração do Microsoft manualmente",
  "microsoftSass.createCompany.setupMsBot.notAdmin.weNeedAdminConsent": "Precisamos do consentimento do administrador para configurar o bot e as abas. Não podemos enviar mensagens de boas-vindas até que você instale o bot.",
  "microsoftSass.createCompany.setupMsBot.selectMSChannelToInstallTabs": "Selecione o canal do Microsoft Teams onde você deseja instalar as abas",
  "microsoftSass.createCompany.setupMsBot.selectMSChannelToInstallTabsPlaceholder": "Selecione o canal do Microsoft Teams",
  "microsoftSass.createCompany.setupMsBot.selectMSTeamsToInstallBot": "Selecione a equipe do Microsoft Teams onde você deseja instalar o bot",
  "microsoftSass.createCompany.setupMsBot.selectMSTeamsToInstallBotPlaceholder": "Selecione a equipe do Microsoft Teams",
  "microsoftSass.createCompany.setupMsBotAndTabs": "CONFIGURAR BOT E ABAS DO VACATION TRACKER",
  "microsoftSass.createCompany.setupMsBotAndTabsInfo": "Vamos instalar o bot e as abas do Vacation Tracker para o Microsoft Teams!",
  "microsoftSass.createCompany.stepAssignLicenses": "2. Atribuir licenças",
  "microsoftSass.createCompany.stepCreateComapny": "1. Completar a configuração da empresa",
  "microsoftSass.createCompany.stepSetupBotAndTabs": "3. Configurar o bot e as abas do Microsoft Teams",
  "microsoftSass.createCompany.welcomeMessage": "Bem-vindo ao Vacation Tracker! Obrigado por comprar <bold>{totalLicenses} licenças</bold>.",
  "microsoftSass.createCompany.welcomeTitle": "VAMOS CONFIGURAR O VACATION TRACKER",
  "microsoftSubscription.adminInitialContent": "Sua assinatura expirou. Por favor, vá para a loja Microsoft AppSource para reativar a assinatura. Se você tiver dúvidas ou precisar de ajuda, entre em contato com nosso suporte.",
  "microsoftSubscription.goToAppSource": "Comprar uma nova assinatura",
  "modal.dontHaveAnAccount.accountNotFound": "Conta não encontrada",
  "modal.dontHaveAnAccount.asCompany": "Como empresa",
  "modal.dontHaveAnAccount.asCompanyDescription": "Você é um administrador ou proprietário de negócio que deseja experimentar o Vacation Tracker.",
  "modal.dontHaveAnAccount.asEmployee": "Como funcionário",
  "modal.dontHaveAnAccount.asEmployeeDescription": "Sua empresa usa o Vacation Tracker, mas você não tem acesso a ele.",
  "modal.dontHaveAnAccount.employeeSignUp": "Cadastro de funcionário",
  "modal.dontHaveAnAccount.employeeSignupDescription": "Se sua empresa já está usando o Vacation Tracker, você não precisa se cadastrar! Entre em contato com seu administrador para ativar sua conta, e você terá acesso completo ao painel e integrações do Vacation Tracker.",
  "modal.dontHaveAnAccount.employeeSignupSupport": "Se você precisar de assistência ou não tiver certeza de quem é seu administrador, sinta-se à vontade para entrar em contato com nosso suporte.",
  "modal.dontHaveAnAccount.introParagraph": "Parece que você não tem uma conta. Como você deseja usar o Vacation Tracker?",
  "modal.forgotPassword.step2Intro": "Enviamos um código para o seu e-mail. Por favor, insira-o abaixo e defina uma nova senha. Se você não recebeu um e-mail em 5 minutos, verifique sua pasta de spam e se você digitou o endereço de e-mail correto.",
  "myProfile.cancel": "Cancelar",
  "myProfile.cancelLeave": "Cancelar",
  "myProfile.cancelLeaveConfirmTitle": "Cancelar Licença",
  "myProfile.dontHaveAnyHistory": "{name} ainda não tirou nenhuma licença.",
  "myProfile.edit.title": "Editar Meu Perfil",
  "myProfile.editLeave": "Editar Licença",
  "myProfile.editLeaveWord": "Editar",
  "myProfile.general": "Geral",
  "myProfile.history": "Histórico",
  "myProfile.historyLeaves": "Histórico",
  "myProfile.leaveHistory": "Histórico de Licenças",
  "myProfile.leaveTypes": "Tipos de Licença",
  "myProfile.noUpcomingLeaves": "Sem Licenças Agendadas",
  "myProfile.openMyProfile": "Ver Meu Perfil",
  "myProfile.upcomingLeaves": "Licenças Agendadas",
  "myProfile.userWorkWeek": "Semana de Trabalho",
  "not_in_channel": "O canal selecionado foi excluído ou não está mais disponível. Por favor, selecione outro canal para receber Notificações.",
  "notification.codeResent": "Código de verificação reenviado",
  "notification.codeResentMessage": "Por favor, verifique seu e-mail",
  "notification.passwordReset": "Senha redefinida com sucesso",
  "notification.passwordResetMessage": "Agora você pode fazer login usando sua nova senha.",
  "notifications.add": "Nova Notificação",
  "notifications.cantOpenNoptification": "Esta Notificação foi criada por outro usuário e você não pode editá-la.",
  "notifications.createDailyRequestSubmitted": "Configurando notificações diárias",
  "notifications.createWeeklyRequestSubmitted": "Configurando notificações semanais",
  "notifications.deleteAutomationConfirm": "Por favor, confirme que deseja excluir a automação <strong>{name}</strong>.",
  "notifications.deleteAutomationTitle": "Excluir automação",
  "notifications.deleteDailyRequestSubmitted": "Excluindo notificações diárias",
  "notifications.deleteEntitlementByRoleAutomationConfirm": "Tem certeza de que deseja excluir esta automação de Direito por função? Você não pode desfazer esta ação. No entanto, excluir a automação não afetará os dias de {leaveTypeName} já concedidos.",
  "notifications.deleteNotificationConfirm": "Por favor, confirme que deseja excluir a notificação <strong>{name}</strong>.",
  "notifications.deleteNotificationOk": "Excluir",
  "notifications.deleteNotificationTitle": "Excluir notificação",
  "notifications.deleteSeniorityEntitlementAutomationConfirm": "Tem certeza de que deseja excluir esta automação de Direito por antiguidade? Você não pode desfazer esta ação. No entanto, excluir a automação não afetará os dias de {leaveTypeName} já concedidos.",
  "notifications.deleteWeeklyRequestSubmitted": "Excluindo notificações semanais",
  "notifications.emptyViewButton": "Criar uma notificação diária ou semanal",
  "notifications.emptyViewMessage": "enviando notificações automáticas sobre licenças futuras",
  "notifications.emptyViewTitle": "Aumente a visibilidade e mantenha sua equipe informada",
  "notifications.emptyViewVideoLink": "Para mais informações, visite nossa <link>Central de Ajuda</link> ou assista ao vídeo abaixo:",
  "notifications.error": "Se este problema persistir, entre em contato com o suporte e forneça o seguinte código de erro: {correlationId}.",
  "notifications.frequency": "Frequência",
  "notifications.handleSubmitErrorTitle": "Erro de Notificação",
  "notifications.microsoftTokenMissing": "Não podemos configurar notificações porque você não tem o token da Microsoft. Por favor, faça logout e login novamente para receber o token da Microsoft e tente novamente.",
  "notifications.notificationInfo": "Se você deseja configurar Notificações para um departamento específico, vá para a página Departamentos e edite o departamento para o qual deseja configurar as Notificações.",
  "notifications.notificationSendNow": "Sua notificação está a caminho! 🚀",
  "notifications.notificationSendNowFailed": "Falha na Entrega",
  "notifications.notificationSendNowFailedInfo": "Oh não! 😕 Algo deu errado. ",
  "notifications.notificationTitle": "Notificações",
  "notifications.passwordChangedDescription": "Você alterou sua senha com sucesso, por favor faça login",
  "notifications.passwordChangedTitle": "Senha alterada",
  "notifications.sendNotificationNowCofirmModalDesc": "A notificação <strong>{name}</strong> não está ativa. Por favor, confirme se deseja enviá-la agora mesmo assim.",
  "notifications.sendNotificationNowCofirmModalTitle": "Enviar notificação",
  "notifications.sendNotificationNowOk": "Enviar",
  "notifications.table.actions": "Ações",
  "notifications.table.active": "Ativo",
  "notifications.table.DAILY": "Diário",
  "notifications.table.frequency": "Frequência",
  "notifications.table.name": "Nome",
  "notifications.table.owner": "Proprietário",
  "notifications.table.scheduledTime": "Hora Agendada",
  "notifications.table.sendNotificationNow": "Enviar Agora",
  "notifications.table.WEEKLY": "Semanal",
  "notifications.title": "Notificações",
  "notifications.tooltipInfo": "Todas as suas Notificações estão reunidas nesta página para facilitar o gerenciamento. Para saber mais, consulte nosso <helpDesk>artigo da Central de Ajuda.</helpDesk>",
  "notifications.update": "Atualizar Notificação",
  "notifications.updateAutomationConfirm": "Desativar uma automação não cancelará a assinatura. Se você deseja desativar a assinatura, precisa acessar a lista de automações clicando em \"Criar Automação\" e depois clicar no botão \"Cancelar assinatura\"",
  "notifications.updateAutomationTitle": "Atualizar automação",
  "notifications.updateNotification": "Atualizar",
  "notifications.weekStartsOn": "A semana começa em",
  "notificationsForm.advancedFilter.tooltipInfo": "Se nenhum Filtro for selecionado, a Notificação incluirá todos os Usuários.",
  "notificationsForm.createInProgress": "Criando a notificação {notificationName}",
  "notificationsForm.currentWeek": "Semana atual",
  "notificationsForm.DAILY": "Diário",
  "notificationsForm.deleteFailed": "Falha ao excluir",
  "notificationsForm.deleteInProgress": "Excluindo a notificação {notificationName}",
  "notificationsForm.frequency": "Frequência",
  "notificationsForm.groupBy": "Agrupar folgas por",
  "notificationsForm.groupBy.tooltip": "As folgas podem ser exibidas de acordo com suas preferências. Elas podem ser classificadas por Localização dos Usuários, Departamento ou Alfabeticamente (Nenhum).",
  "notificationsForm.locale": "Idioma",
  "notificationsForm.LOCATION": "Localização",
  "notificationsForm.name": "Nome da Notificação",
  "notificationsForm.newNotificationTitle": "Nova Notificação",
  "notificationsForm.nextWeek": "Próxima semana",
  "notificationsForm.NONE": "Nenhum",
  "notificationsForm.periodCovered": "Período Coberto",
  "notificationsForm.saveFailed": "Falha ao salvar",
  "notificationsForm.selectChannel": "Selecionar Canal",
  "notificationsForm.sendEmpty": "Se Não Houver Folgas Futuras",
  "notificationsForm.sendEmptyNo": "Não, não envie a notificação",
  "notificationsForm.sendEmptyYes": "Sim, envie a notificação",
  "notificationsForm.sendOnDays": "Enviar em",
  "notificationsForm.sendOnDaysInfo": "A notificação será enviada no(s) dia(s) selecionado(s).",
  "notificationsForm.submit": "Salvar Notificação",
  "notificationsForm.TEAM": "Departamento",
  "notificationsForm.time": "Hora",
  "notificationsForm.timezone": "Fuso horário",
  "notificationsForm.update": "Atualizar Notificação",
  "notificationsForm.updateInProgress": "Atualizando a notificação {notificationName}",
  "notificationsForm.updateNotificationTitle": "Atualizar Notificação",
  "notificationsForm.WEEKLY": "Semanal",
  "notificationsForm.weekStartsOn": "A semana começa em",
  "overlapInfo.moreUsersAreOnLeaves": "ℹ️ Parece que {overlapperNames} estarão de folga durante o mesmo período.",
  "overlapInfo.youCanSafelyAcceptTheRequest": "Você pode aceitar a solicitação com segurança, ninguém mais de {teamName} está de folga durante este período.",
  "overlapInfo.youCanSafelyAcceptTheRequestAutoApproved": "Ninguém mais de {teamName} está de folga durante este período.",
  "page404.message": "A página que você está procurando está tirando um merecido tempo de folga.",
  "page404.title": "Página de férias.",
  "quickTour.admin.buttonTitle": "Tour rápido",
  "quickTour.buttons.back": "Voltar",
  "quickTour.buttons.close": "Fechar",
  "quickTour.buttons.last": "Fechar",
  "quickTour.buttons.next": "Próximo",
  "quickTour.buttons.skip": "Pular",
  "registration.flowB.progress": "Etapa {current} de {total}",
  "registration.flowB.step1.list1": "<b>Teste gratuito de 7 dias</b>, todas as funcionalidades incluídas",
  "registration.flowB.step1.list2": "Não é necessário cartão de crédito durante o teste",
  "registration.flowB.step1.list3": "Integre com sua ferramenta de colaboração",
  "registration.flowB.step1.submitButton": "Começar",
  "registration.flowB.step1.subtitle": "Qual é o endereço de e-mail da sua empresa?",
  "registration.flowB.step1.title": "Junte-se a milhares de equipes em todo o mundo que otimizam o controle de PTO com o Vacation Tracker.",
  "registration.flowB.step2.submitButton": "Criar Conta",
  "registration.flowB.step2.subtitle": "Bem-vindo ao Vacation Tracker",
  "registration.flowB.step3.continueWithEmailPlatform": "Continuar com e-mail de trabalho",
  "registration.flowB.step3.continueWithPlatform": "Continuar com {platform}",
  "registration.flowB.step3.list1": "Gerenciar Solicitações de Folga",
  "registration.flowB.step3.list10": "e muito mais!",
  "registration.flowB.step3.list2": "Enviar Notificações de Folga",
  "registration.flowB.step3.list3": "Personalizar Tipos de Folga",
  "registration.flowB.step3.list4": "Criar Locais e Departamentos",
  "registration.flowB.step3.list5": "Gerar Relatórios",
  "registration.flowB.step3.list6": "Definir Acúmulos de Folga",
  "registration.flowB.step3.list7": "Agrupar Membros da Equipe por Rótulos",
  "registration.flowB.step3.list8": "Agendar Relatórios",
  "registration.flowB.step3.list9": "Solicitar Folga por Hora",
  "registration.flowB.step3.notice": "Não nos comunicaremos com sua equipe de forma alguma até que você esteja pronto.",
  "registration.flowB.step3.submitButton": "ÚLTIMO PASSO",
  "registration.flowB.step3.subtitle": "Com qual ferramenta você gostaria de integrar, {name}?",
  "registration.flowB.step3.title": "Teste gratuito do plano completo por 7 dias",
  "registration.flowB.step3.title2": "Você receberá um <b>teste gratuito</b> do nosso <b>Plano Completo</b>, que inclui:",
  "registration.flowB.testimonial1.profession": "Produtor",
  "registration.flowB.testimonial1.title": "\"A facilidade com que todos fizeram a transição para usar o Vacation Tracker sem fazer um milhão de perguntas foi impressionante!\"",
  "registration.flowB.testimonial2.profession": "CEO",
  "registration.flowB.testimonial2.title": "\"Muito simples de configurar, torna super fácil solicitar e aprovar dias de folga e a equipe é muito responsiva e leva o feedback a sério.\"",
  "registration.flowB.testimonial3.profession": "Diretor de Serviços Administrativos",
  "registration.flowB.testimonial3.title": "\"Agora posso me concentrar na minha equipe e em muitas outras coisas - o Vacation Tracker me devolveu pelo menos 10 horas por semana, além de inúmeras horas para nossos gerentes e funcionários, porque é tudo eletrônico.\"",
  "requestLeave.handleSubmitErrorTitle": "Erro ao Enviar Licença",
  "requestLeave.inProgress": "Solicitando licença",
  "requestLeave.requestLeave": "Solicitar Licença",
  "requestLeave.title": "Licença solicitada",
  "scheduledReportForm.addNewEmail": "Adicionar Destinatário",
  "scheduledReportForm.approved": "Aprovado",
  "scheduledReportForm.createAScheduledReport": "Novo Relatório",
  "scheduledReportForm.createInProgress": "Criando o relatório agendado {scheduledReportName}",
  "scheduledReportForm.deleteFailed": "Falha na exclusão",
  "scheduledReportForm.deleteInProgress": "Excluindo o relatório agendado {scheduledReportName}",
  "scheduledReportForm.deleteReport": "Excluir Relatório",
  "scheduledReportForm.deleteScheduledReport": "Excluir relatório agendado",
  "scheduledReportForm.deleteScheduledReportConfirm": "Por favor, confirme que deseja excluir o relatório agendado {name}.",
  "scheduledReportForm.deleteScheduledReportTitle": "Excluir relatório agendado {name}",
  "scheduledReportForm.denied": "Negado",
  "scheduledReportForm.emails": "Destinatários",
  "scheduledReportForm.lastMonth": "Mês passado",
  "scheduledReportForm.leaveBalanceReport": "Relatório de Saldo de Licença",
  "scheduledReportForm.leaveHistoryReport": "Relatório de Solicitação de Licença",
  "scheduledReportForm.leaveStatus": "Status da Licença",
  "scheduledReportForm.locations": "Localização(ões)",
  "scheduledReportForm.monthlyLeaveBalanceReport": "Relatório Mensal de Saldo de Licença",
  "scheduledReportForm.period": "Período do Relatório",
  "scheduledReportForm.periodInfo": "Mês Passado ",
  "scheduledReportForm.periodInfoTootltip": "O relatório está atualmente limitado ao mês anterior. Para mais opções, por favor, envie um e-mail para hello@vacationtracker.io e garantiremos que sua solicitação seja anotada para possíveis melhorias futuras.",
  "scheduledReportForm.pleaseInputEmailOrDeleteThisField": "Por favor, insira um e-mail válido ou exclua este campo.",
  "scheduledReportForm.pleaseInputValidEmail": "Por favor, insira um e-mail válido.",
  "scheduledReportForm.reportName": "Nome",
  "scheduledReportForm.reportSummary": "Resumo do Relatório",
  "scheduledReportForm.reportSummaryInfo": "Período do Relatório: {from} - {to}\nTipo de Relatório: {reportType}\nO próximo relatório será enviado em {dateReport} às {timeReport}",
  "scheduledReportForm.reportSummaryInfoMonthlyBalance": "Período do Relatório: Próximos 12 meses. \nTipo de Relatório: {reportType}\nO próximo relatório será enviado em {dateReport} às {timeReport}",
  "scheduledReportForm.reportType": "Tipo de Relatório",
  "scheduledReportForm.required": "Este campo é obrigatório.",
  "scheduledReportForm.saveFailed": "Falha ao salvar",
  "scheduledReportForm.saveReport": "Agendar Relatório",
  "scheduledReportForm.sendDayOfMonth": "Data/Dia de Entrega",
  "scheduledReportForm.sendDayOfMonthInfo": "Configure suas preferências de entrega de relatório, especificando o dia exato em que o relatório será enviado aos destinatários atribuídos.",
  "scheduledReportForm.sendHourAndMinute": "Horário de Entrega",
  "scheduledReportForm.sendHourAndMinuteInfo": "Configure suas preferências de entrega de relatório, especificando a hora exata do dia em que o relatório será enviado aos destinatários atribuídos.",
  "scheduledReportForm.sendReportFailed": "Falha na Entrega",
  "scheduledReportForm.sendReportFailedInfo": "Oh não! 😕 Algo deu errado. ",
  "scheduledReportForm.sendReportNow": "Enviar Agora",
  "scheduledReportForm.sendReportSuccessfully": "Seu relatório está a caminho! 🚀",
  "scheduledReportForm.teams": "Departamento(s)",
  "scheduledReportForm.timezone": "Fuso Horário",
  "scheduledReportForm.updateAScheduledReport": "Atualizar Relatório",
  "scheduledReportForm.updateInProgress": "Atualizando o relatório agendado {scheduledReportName}",
  "scheduledReportForm.updateReport": "Atualizar Relatório",
  "scheduledReports.add": "Novo Relatório",
  "scheduledReports.allLocations": "Todas as Localizações",
  "scheduledReports.allTeams": "Todos os Departamentos",
  "scheduledReports.createScheduledReports": "Novo Relatório",
  "scheduledReports.deleteFailed": "Exclusão falhou",
  "scheduledReports.deleteInProgress": "Excluindo o relatório agendado {scheduledReportName}",
  "scheduledReports.deleteReportConfirm": "Excluir o relatório {name}?",
  "scheduledReports.deleteReportOkText": "Excluir",
  "scheduledReports.deleteReportTitle": "Confirmar Exclusão",
  "scheduledReports.deliveryDate": "Data de Entrega",
  "scheduledReports.edit": "Editar",
  "scheduledReports.lastMonth": "Mês passado",
  "scheduledReports.leaveBalanceReport": "Relatório de Saldo de Licença",
  "scheduledReports.leaveHistoryReport": "Relatório de Solicitação de Licença",
  "scheduledReports.locations": "Localização(ões)",
  "scheduledReports.monthlyLeaveBalanceReport": "Relatório Mensal de Saldo de Licença",
  "scheduledReports.name": "Nome",
  "scheduledReports.period": "Período do Relatório",
  "scheduledReports.receivers": "Destinatários",
  "scheduledReports.reportSentSuccessfully": "Seu relatório está a caminho! 🚀",
  "scheduledReports.reportType": "Tipo de Relatório",
  "scheduledReports.sendReportFailed": "Entrega Falhou",
  "scheduledReports.sendReportFailedInfo": "Oh não! 😕 Algo deu errado. ",
  "scheduledReports.sendReportNow": "Enviar Agora",
  "scheduledReports.sendReportSuccessfully": "Seu relatório está a caminho! 🚀",
  "scheduledReports.teams": "Departamento(s)",
  "sidebar.addLeave": "Adicionar Licença",
  "sidebar.addons": "Complementos",
  "sidebar.billing": "Faturamento",
  "sidebar.calendar": "Calendário",
  "sidebar.changelog": "Registro de Alterações",
  "sidebar.dashboard": "Painel",
  "sidebar.export": "Exportar",
  "sidebar.faq": "Central de Ajuda",
  "sidebar.general": "Geral",
  "sidebar.holidays": "Feriados",
  "sidebar.leaves": "Solicitações de Licença",
  "sidebar.leaveTypes": "Tipos de Licença",
  "sidebar.location": "Localização",
  "sidebar.locations": "Localizações",
  "sidebar.main": "Principal",
  "sidebar.myProfile": "Meu Perfil",
  "sidebar.notifications": "Notificações",
  "sidebar.onboarding": "Integração",
  "sidebar.reports": "Relatórios",
  "sidebar.requestLeave": "Solicitar Licença",
  "sidebar.scheduledReports": "Relatórios Agendados",
  "sidebar.settings": "Configurações",
  "sidebar.teams": "Departamentos",
  "sidebar.user": "Usuário",
  "sidebar.users": "Usuários",
  "signInAsUser.codeIsRequired": "Um código é obrigatório",
  "signInAsUser.email": "Digite seu endereço de e-mail",
  "signInAsUser.emailIsRequired": "Um endereço de e-mail válido é obrigatório",
  "signInAsUser.enterTheCode": "Digite o código",
  "signInAsUser.userId": "Digite o ID do usuário",
  "signInAsUser.userIdIsRequired": "Um ID de usuário é obrigatório",
  "slack.notificationsLoginRequiredDescription1": "O gerenciamento de notificações requer permissões do Slack. Se você clicar no botão OK, redirecionaremos você para a página de login do Slack. Dependendo das configurações do seu navegador, você pode já estar logado e ser redirecionado automaticamente de volta.",
  "slack.notificationsLoginRequiredDescription2": "Após fazer login na sua conta do Slack, levaremos você de volta a esta página para que possa gerenciar as notificações. Clicar no botão Cancelar o levará de volta à última página que você visitou em nosso painel.",
  "slack.notificationsLoginRequiredTitle": "É necessário fazer login na sua conta do Slack",
  "slack.usersLoginRequiredDescription1": "O gerenciamento de usuários requer permissões do Slack. Se você clicar no botão OK, redirecionaremos você para a página de login do Slack. Dependendo das configurações do seu navegador, você pode já estar logado e ser redirecionado automaticamente de volta.",
  "slack.usersLoginRequiredDescription2": "Após fazer login na sua conta do Slack, levaremos você de volta a esta página para que possa gerenciar os usuários.",
  "slack.usersLoginRequiredTitle": "É necessário fazer login na sua conta do Slack",
  "stripe.dynamicMessage": "{message}",
  "subscription.annual.info": "Você ativará o Plano {plan} {period} - ${price}",
  "subscription.hidePlansButton": "Ocultar planos",
  "subscription.logout": "Sair",
  "subscription.monthly.info": "Você ativará o Plano {plan} {period} - ${usersPrice} ({noOfActiveUsers} usuários x {dollarValue})",
  "subscription.monthly.infoUnderUserPLanLimit": "Você ativará o Plano {plan} {period} - ${usersPrice}",
  "subscription.showPlansButton": "Mostrar planos",
  "subscription.stripeCustomerPortalLink": "Clique aqui para visitar o portal do cliente",
  "subscription.subscribe": "Assinar",
  "subscription.title": "Assinatura",
  "subscription.updateInProgress": "Tentando realizar o pagamento",
  "subscriptions.switchPlanTitle": "Mudar para o plano <strong>{plan}</strong>",
  "surveyAboutUs.description": "Você pode nos contar como ficou sabendo sobre nós? Gostaríamos de saber mais sobre sua jornada até o Vacation Tracker. Isso é opcional, mas nos ajudará muito. Obrigado!",
  "surveyAboutUs.OptionQuestion1-1": "Pesquisa no Google",
  "surveyAboutUs.OptionQuestion1-1-1": "O que você pesquisou no Google (por exemplo, \"rastreamento de férias\")?",
  "surveyAboutUs.OptionQuestion1-2": "Loja de aplicativos (Slack - Diretório de Apps, MS Teams - App Source, etc...)",
  "surveyAboutUs.OptionQuestion1-3": "YouTube",
  "surveyAboutUs.OptionQuestion1-3-1": "Qual plataforma ou site?",
  "surveyAboutUs.OptionQuestion1-4": "Facebook",
  "surveyAboutUs.OptionQuestion1-4-1": "Isso é ótimo! Quem nos recomendou?",
  "surveyAboutUs.OptionQuestion1-4-1-1": "Um colega ou amigo",
  "surveyAboutUs.OptionQuestion1-4-1-2": "Outra empresa sugeriu",
  "surveyAboutUs.OptionQuestion1-4-1-3": "Eu já usei no passado",
  "surveyAboutUs.OptionQuestion1-5": "Eu perguntei a uma IA (por exemplo, ChatGPT, Claude, Copilot, etc...)",
  "surveyAboutUs.OptionQuestion1-5-1": "Que ótimo! Qual deles?",
  "surveyAboutUs.OptionQuestion1-5-1-1": "ChatGPT, Claude ou Gemini",
  "surveyAboutUs.OptionQuestion1-5-1-2": "Perplexity",
  "surveyAboutUs.OptionQuestion1-5-1-3": "Copilot",
  "surveyAboutUs.OptionQuestion1-5-1-4": "Outro",
  "surveyAboutUs.OptionQuestion1-6": "Recomendação",
  "surveyAboutUs.OptionQuestion1-6-1": "Que ótimo! Quem nos recomendou?",
  "surveyAboutUs.OptionQuestion1-6-1-1": "Um colega ou amigo",
  "surveyAboutUs.OptionQuestion1-6-1-2": "Outra empresa sugeriu",
  "surveyAboutUs.OptionQuestion1-6-1-3": "Eu já usei no passado",
  "surveyAboutUs.OptionQuestion1-7": "Outro",
  "surveyAboutUs.Question1": "Como você ficou sabendo sobre o Vacation Tracker?",
  "surveyAboutUs.textarea": "Por favor, especifique",
  "surveyAboutUs.title": "Como você ficou sabendo sobre o Vacation Tracker?",
  "team.approvers": "Aprovador(es)",
  "team.createInProgress": "Criando o departamento {teamName}",
  "team.editTeam": "Editar Departamento",
  "team.hasNoUsers": "O departamento não tem usuários.",
  "team.name": "Nome",
  "team.team": "Departamento",
  "team.updateInProgress": "Atualizando o departamento {teamName}",
  "team.users": "Usuário(s)",
  "teamForm.createTeam": "Criar Departamento",
  "teamForm.createTeamError": "Oh não! 😕 Algo deu errado. ",
  "teamForm.editTeam": "Editar Departamento",
  "teamForm.name": "Nome",
  "teamForm.pleaseEnterTeamName": "Digite o nome do departamento",
  "teamForm.setAsSubdepartment": "Escolher Departamento Pai",
  "teamForm.teamLimitReached": "Ops! Você atingiu o limite de {limitNumber} departamentos do seu plano atual. Para gerenciar ou atualizar seu plano, visite a <billingPage>página de cobrança</billingPage>.",
  "teamForm.updateTeam": "Atualizar Departamento",
  "teams.approvers": "Aprovador(es)",
  "teams.created": "Criado",
  "teams.createNewTeam": "Criar Novo Departamento",
  "teams.defaultTeam": "Todos os novos usuários serão automaticamente adicionados a este departamento.",
  "teams.deleteInProgress": "Excluindo o departamento {teamName}",
  "teams.deleteTeam": "Excluir",
  "teams.deleteTeamConfirm": "Tem certeza de que deseja excluir o departamento {name}?",
  "teams.deleteTeamTitle": "Excluir Departamento",
  "teams.editTeam": "Editar Departamento",
  "teams.handleDeleteError": "Erro ao excluir",
  "teams.handleDeleteErrorTitle": "Erro ao excluir departamento",
  "teams.name": "Nome",
  "teams.removeUserInfo": "Você não pode remover o usuário do departamento padrão. Se quiser mover o usuário para outro departamento, você pode editar outro departamento e adicionar o usuário lá.",
  "teams.teamsLimitReached": "Você está atualmente utilizando todos os {limitNumber} slots de departamento disponíveis no plano CORE. Para gerenciar ou atualizar seu plano, visite a <billingPage>página de cobrança</billingPage>.",
  "teams.users": "Usuário(s)",
  "teams.viewTeam": "Visualizar Departamento",
  "user.accrualsCalculator": "Calcular",
  "user.accrualsCalculatorInfo": "Quantos dias terei acumulado até uma data específica?",
  "user.addLeave": "Adicionar Licença",
  "user.approve": "Aprovar",
  "user.approveInProgress": "Aprovando solicitação de licença",
  "user.approveLeaveText": "Por favor, confirme que deseja aprovar a solicitação de licença.",
  "user.approveLeaveTitle": "Aprovar Solicitação de Licença",
  "user.approveSuccessTitle": "Solicitação de licença aprovada",
  "user.bulkChangeStatusInProgress": "Atualizando status dos usuários.",
  "user.bulkChangeStatusSuccessTitle": "Os status dos usuários foram atualizados.",
  "user.cancelLeave": "Cancelar Licença",
  "user.cancelLeave.buttonLink": "Cancelar",
  "user.cancelLeaveConfirmTitle": "Cancelar Licença",
  "user.cancelLeaveRequestInProgress": "Cancelando a solicitação de licença",
  "user.cancelLeaveRequestTitle": "A solicitação de licença foi cancelada",
  "user.currentLeaves": "Licenças Atuais",
  "user.deleteLeave": "Excluir Licença",
  "user.deleteLeave.buttonLabel": "Excluir",
  "user.deleteLeaveConfirmText": "Por favor, confirme que deseja excluir a licença.",
  "user.deleteLeaveConfirmTitle": "Excluir Licença",
  "user.deleteLeaveInProgress": "Excluindo licença",
  "user.deleteLeaveSuccessTitle": "A licença foi excluída",
  "user.deleteToil": "Excluir TOIL",
  "user.deleteToilConfirmText": "Por favor, confirme que deseja excluir o TOIL.",
  "user.deny": "Negar",
  "user.denyInProgress": "Negando solicitação de licença",
  "user.denyLeaveConfirm": "Por favor, confirme que deseja negar a solicitação de licença.",
  "user.denyLeaveTitle": "Negar Solicitação de Licença",
  "user.denySuccessTitle": "Solicitação de licença negada",
  "user.denyWithReason": "Negar com Motivo",
  "user.dontHaveAnyHistory": "{name} ainda não tirou nenhuma licença.",
  "user.editLeave": "Editar Licença",
  "user.editLeave.buttonLabel": "Editar",
  "user.editLeaveWord": "Editar",
  "user.editUser": "Editar",
  "user.editWorkWeek": "Editar",
  "user.employeeEndDate": "Data de Término do Funcionário",
  "user.employeeId": "ID do Funcionário",
  "user.employeeSince": "Funcionário Desde",
  "user.general": "Geral",
  "user.history": "Histórico",
  "user.historyLeaves": "Histórico",
  "user.invitationDeletedTitle": "Convite excluído",
  "user.invitationSuccessTitle": "Convites enviados",
  "user.leaves": "Licenças",
  "user.leaveTypes": "Tipos de Licença",
  "user.location": "Localização",
  "user.location.moveInProgress": "Movendo usuário para a localização {name}",
  "user.location.moveSuccessTitle": "Usuário movido para a localização {name}",
  "user.logs": "Registros",
  "user.noUpcomingLeaves": "Sem Licenças Agendadas",
  "user.pendingLeaves": "Solicitações de Licença Pendentes",
  "user.probationPeriodDescription": "O usuário está em período de experiência sob a política <probationPeriodName></probationPeriodName> para <leaveTypeNames></leaveTypeNames>, com duração de <lengthDays></lengthDays> dia(s), até <date></date>.",
  "user.profile": "Perfil",
  "user.resend": "Reenviar solicitação",
  "user.resending": "Reenviando Solicitação de Licença...",
  "user.resent": "Solicitação reenviada",
  "user.role": "Função",
  "user.signUp": "Cadastrar-se",
  "user.signup.intro": "{invitedByName} convidou você para se juntar à organização deles no Vacation Tracker. Por favor, cadastre-se abaixo para aceitar o convite e acessar o painel do Vacation Tracker.",
  "user.signup.introHelpdesk": "Se você tiver alguma dúvida ou precisar de assistência, entre em contato com nosso suporte ou visite <helpdesk>a Central de Ajuda</helpdesk>.",
  "user.status": "Status",
  "user.status.updateInProgress": "Atualizando status do Usuário.",
  "user.status.updateSuccessTitle": "O status do usuário foi atualizado.",
  "user.statusReason": "Adicionar Motivo",
  "user.team.moveInProgress": "Movendo usuário para o departamento {name}",
  "user.team.moveSuccessTitle": "Usuário movido para o departamento {name}",
  "user.upcomingLeaves": "Licenças Agendadas",
  "user.updateInProgress": "Atualizando usuário",
  "user.updateSuccessTitle": "Usuário atualizado",
  "user.userWorkWeek": "Semana de Trabalho do Usuário",
  "user.workWeek.updateInProgress": "Atualizando semana de trabalho",
  "user.workWeek.updateSuccessTitle": "Semana de trabalho atualizada",
  "users.aboutToSync": "Ao sincronizar todos os usuários existentes, você está sincronizando os dados do usuário da plataforma de colaboração usada por sua equipe com o perfil deles no Vacation Tracker (ex. Nome, Imagem de Perfil etc.)",
  "users.activateUsers": "Ativar",
  "users.activeUser": "Ativar",
  "users.activeUsers": "Ativos",
  "users.assignLicenses": "Atribuir Licenças",
  "users.cancel": "Cancelar",
  "users.changeUserLocation": "Alterar Localização",
  "users.changeUserTeam": "Alterar Departamento",
  "users.deactivate": "Desativar",
  "users.deactivateUser": "Desativar",
  "users.deactivateUserConfirm": "Tem certeza que deseja desativar {name}?",
  "users.deactivateUsers": "Desativar",
  "users.deactivateUserTitle": "Desativar",
  "users.deletedUsers": "Excluídos",
  "users.deleteInvite": "Excluir convite",
  "users.deleteInviteConfirm": "Tem certeza que deseja excluir o convite enviado para {email}?",
  "users.deleteUser": "Excluir Usuário",
  "users.deleteUserUserConfirm": "Tem certeza que deseja excluir {name}?",
  "users.employmentDate": "Data de Contratação do Usuário",
  "users.endDate": "Data de Término",
  "users.import.description": "A importação pode levar algum tempo. Caso não veja novos usuários na lista, por favor, atualize esta página após 10-15 segundos.",
  "users.importedSuccessfully": "Usuários importados com sucesso",
  "users.importMany.InProgress": "Importando usuários",
  "users.importMany.successTitle": "Usuários importados com sucesso",
  "users.importNewUsers": "Adicionar Usuários",
  "users.importOne.InProgress": "Importando um novo usuário",
  "users.importOne.successTitle": "Usuário importado com sucesso",
  "users.importUsers": "Importar Usuários",
  "users.inactiveUsers": "Inativos",
  "users.invitations": "Convites",
  "users.inviteUsers": "Convidar Usuários",
  "users.location": "Localização",
  "users.name": "Nome",
  "users.next": "Próximo",
  "users.prev": "Anterior",
  "users.reinvite": "Reconvidar",
  "users.reInviteConfirm": "Tem certeza que deseja reconvidar {email}?",
  "users.role": "Função",
  "users.search": "Pesquisar usuários",
  "users.sendInvitationsInLanguage": "Enviar convites em",
  "users.startDate": "Data de Início",
  "users.syncExistingUsers": "Sincronizar Usuários Existentes",
  "users.syncExistingUsers.InProgress": "Sincronizando usuários existentes",
  "users.syncExistingUsers.successTitle": "Usuários existentes foram sincronizados com sucesso.",
  "users.syncWithGoogleConsent": "Sincronizar com consentimento do Google",
  "users.syncWithoutGoogleConsent": "Sincronizar sem consentimento do Google",
  "users.team": "Departamento",
  "users.uninvited": "Não Convidado",
  "users.updateQuota.InProgress": "Atualizando cota do usuário em andamento",
  "users.updateQuota.successTitle": "A cota do usuário foi atualizada com sucesso",
  "users.updateRolloverQuota.successTitle": "A cota inicial de Transferência foi atualizada com sucesso.",
  "users.viewUser": "Visualizar Usuário",
  "utomations.maximumUsersAway.maximumUsersAwayDeleted": "Política de Máximo de Usuários Ausentes excluída",
  "wallchart.range.next4Weeks": "Próximas 4 semanas",
  "wallchart.range.next5Weeks": "Próximas 5 semanas",
  "wallchart.range.nextMonth": "Próximo mês",
  "wallchart.range.previousMonth": "Mês anterior",
  "wallchart.range.thisMonth": "Este mês"
}
