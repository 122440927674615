import { IMaxUsersAway, MaxUsersAwayTypeEnum } from '../../types/automations'
import logger from '../logger'

export interface IMaximumUsersAwayResult {
  isValid: boolean
  maxUsersAllowed: number
  usersOnLeave?: number
  percentage: number
}

/**
 * Calculates maximum users away rules and validates against current leaves.
 *
 * @param totalUsers - Total number of users in the team/group being evaluated
 * @param maxUsersAway - Configuration object specifying maximum users allowed to be away
 * @param maxUsersAway.type - Type of limit ('percentage' or 'absolute')
 * @param maxUsersAway.value - Maximum value (percentage or absolute number)
 * @param usersOnLeave - Optional number of users currently on leave
 *
 * @returns Object containing validation results:
 * - isValid: boolean indicating if current leaves are within limits
 * - maxUsersAllowed: calculated maximum number of users allowed to be away
 * - usersOnLeave: number of users currently on leave (if provided)
 * - percentage: actual percentage of team allowed to be away
 *
 * @example
 * // Percentage-based limit
 * const result = calculateMaximumUsersAway(10, {
 *   type: 'percentage',
 *   value: 50
 * }, 4);
 * // Returns { isValid: true, maxUsersAllowed: 5, usersOnLeave: 4, percentage: 50 }
 *
 * @example
 * // Absolute limit
 * const result = calculateMaximumUsersAway(5, {
 *   type: 'absolute',
 *   value: 2
 * });
 * // Returns { isValid: true, maxUsersAllowed: 2, percentage: 40 }
 *
 * @remarks
 * - For percentage limits, the max users is rounded to nearest whole number
 * - For absolute limits, the max cannot exceed total users
 * - Returns isValid: false if totalUsers is 0
 * - Backend validation occurs when usersOnLeave is provided
 * - Frontend calculation occurs when usersOnLeave is omitted
 */

export function calculateMaximumUsersAway(
  totalUsers: number,
  maxUsersAway: IMaxUsersAway,
  usersOnLeave?: number
): IMaximumUsersAwayResult {
  logger.debug('Starting calculateMaximumUsersAway', {
    totalUsers,
    maxUsersAwayType: maxUsersAway?.type,
    maxUsersAwayValue: maxUsersAway?.value,
    usersOnLeave,
  })

  if (totalUsers === 0) {
    logger.debug('No users in filtered group, returning invalid result')
    return {
      isValid: false,
      maxUsersAllowed: 0,
      usersOnLeave: usersOnLeave || 0,
      percentage: 0,
    }
  }

  const maxUsers = maxUsersAway?.type === MaxUsersAwayTypeEnum.percentage
    ? Math.round((maxUsersAway?.value * totalUsers) / 100)
    : Math.min(maxUsersAway?.value, totalUsers)

  logger.debug('Calculated maxUsers', {
    maxUsers,
    calculationType: maxUsersAway?.type,
    originalValue: maxUsersAway?.value,
    totalUsers,
  })

  const actualPercentage = Math.round((maxUsers / totalUsers) * 100)
  logger.debug('Calculated actual percentage', { actualPercentage })

  // If usersOnLeave is provided (backend case), validate against current leaves
  if (typeof usersOnLeave === 'number') {
    const wouldBeOnLeave = usersOnLeave + 1
    const result = wouldBeOnLeave <= maxUsers

    logger.debug('Backend validation result', {
      currentUsersOnLeave: usersOnLeave,
      wouldBeOnLeave,
      maxUsersAllowed: maxUsers,
      isValid: result,
    })

    return {
      isValid: result,
      maxUsersAllowed: maxUsers,
      usersOnLeave,
      percentage: actualPercentage,
    }
  }

  logger.debug('Frontend calculation result', {
    maxUsersAllowed: maxUsers,
    percentage: actualPercentage,
  })

  // Frontend case - just return the calculated maximum
  return {
    isValid: true,
    maxUsersAllowed: maxUsers,
    percentage: actualPercentage,
  }
}