import React from 'react'
import { DeploymentUnitOutlined } from '@ant-design/icons'
import {
  CalendarDaysIcon,
  DocumentPlusIcon,
  ClipboardDocumentListIcon,
  GlobeAltIcon,
  ScaleIcon,
  ClockIcon,
  EyeSlashIcon,
  DocumentCurrencyDollarIcon
} from '@heroicons/react/24/solid'
import { IAddonsData } from '@vacationtracker/shared/types/automations'
import { getAddonsEvents } from '@vacationtracker/shared/common/addons-events'

export const getExtendedAddons = (formatMessage: Function, companyFeatureFlags: string[]) => {
  const events = getAddonsEvents(formatMessage)

  const eventsAddonsExtend = {
    'BLACKOUT_PERIOD': {
      icon: <CalendarDaysIcon />,
    },
    'SENIORITY_ENTITLEMENT': {
      icon: <DocumentPlusIcon />,
    },
    'ENTITLEMENT_BY_ROLE': {
      icon: <ClipboardDocumentListIcon />,
    },
    'VISIBILITY': {
      icon: <EyeSlashIcon />,
    },
    'PROBATION_PERIOD': {
      icon: <GlobeAltIcon />,
    },
    'OPEN_API': {
      icon: <CalendarDaysIcon />,
      allowViewMode: true,
    },
    'BAMBOOHR_INTEGRATION': {
      icon: <DeploymentUnitOutlined />,
    },
    'ADP_INTEGRATION': {
      icon: <DocumentCurrencyDollarIcon />,
    },
    'MAXIMUM_USERS_AWAY': {
      icon: <ScaleIcon />,
    },
    'LEAVE_DURATION_LIMIT': {
      icon: <ClockIcon />,
    },
  }

  const extendedEvents = events.map((event: IAddonsData) => {
    return {
      ...event,
      icon: eventsAddonsExtend[event.type].icon,
      allowViewMode: eventsAddonsExtend[event.type].allowViewMode || false,
      url: event.featureFlag && !companyFeatureFlags?.includes(event.featureFlag) ? 'coming-soon' : event.url,
    }
  })

  return extendedEvents
}
