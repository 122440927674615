import React, { useState, useEffect, useContext } from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { omit } from 'lodash'
import { App, Badge, Breadcrumb, Menu, Tabs, TabsProps } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import { useManualQuery } from 'graphql-hooks'
import { lowerCase } from 'lodash'

import Api from '@vacationtracker/shared/services/api'
import { getCurrentUser } from '../../graphql/custom-queries'
import * as logger from '../../services/logger'

import { notificationStore } from '../../context/notificationsContext/store'
import { useAppSelector, useAppDispatch } from '../../store/hooks'
import { roleAdmin, selectAuthUserSlice } from '../../store/auth-user-slice'
import { selectAuthCompanySlice } from '../../store/auth-company-slice'
import { selectUserIdSlice } from '../../store/user-id-slice'
import { selectLeaveRequestActionEventSlice, setLeaveRequestActionEvent } from '../../store/leave-request-action-event-slice'
import { useShouldEnableFeatures } from '../../store/use-should-enable-features'
import { selectLocaleSlice } from '../../store/locale-slice'

import IntlMessages from '../../util/IntlMessages'
import CircularProgress from '../../components/circular-progress'
import { UserAvatar } from '@vacationtracker/shared/components/user-avatar'
import UserTodayOff from '../../components/user-today-off'
import UserProfileTab from '../../components/user-profile-tab'
import UserLeavesTab from '../../components/user-leaves-tab'
import UserLogsTab from '../../components/user-logs-tab'
import UserLabels from '../../components/user-labels'
import UserMyProfileModal from '../../components/modal-update-my-profile'

import { SubscriptionPlanEnum } from '@vacationtracker/shared/types/company'
import { IGetLabelsShort } from '../../types/labels'
import { IGetCurrentUser, IUserHistory, IUserUpcomingLeaves, IMyProfileUser, IUserToday, IUserPendingLeaves, IMyProfileQuota } from '../../types/custom-queries'
import { Platform } from '@vacationtracker/shared/types/core-event'
import { RcFile } from 'antd/lib/upload'
import { uploadAvatar } from '../../services/api/files'
import { HourFormatEnum, IUserUpdatedEvent } from '@vacationtracker/shared/types/user'
import { LocaleEnum } from '@vacationtracker/shared/types/i18n'
import { FeatureFlagEnum } from '@vacationtracker/shared/types/feature-flags'
import { isToilLeave } from '@vacationtracker/shared/functions/is-toil-leave-request'
import { IResendLeaveRequest } from '@vacationtracker/shared/types/leave-request'
import { FrontendUrls } from '../../types/urls'

const MyProfilePage = () => {
  const { notification } = App.useApp()
  const { userId } = useAppSelector(selectUserIdSlice)
  const { leaveRequestActionEvent } = useAppSelector(selectLeaveRequestActionEventSlice)
  const amIAdmin = useSelector(roleAdmin)
  const { authCompany } = useAppSelector(selectAuthCompanySlice)
  const { authUser } = useAppSelector(selectAuthUserSlice)
  const { locale } = useAppSelector(selectLocaleSlice)
  const { actionNotifications, setActionNotifications } = useContext(notificationStore)
  const { formatMessage } = useIntl()
  const dispatch = useAppDispatch()

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [ editProfileModalVisible, showEditProfileModal ] = useState(false)
  const [user, setUser] = useState<IMyProfileUser>({
    id: '',
    name: '',
    platform: '',
    imageUrl: '',
    role: '',
    isAdmin: false,
    status: 'ACTIVE',
    startDate: '',
    endDate: '',
    workWeekType: 'LOCATION',
    workWeekInfo: [],
    timestamp: '',
    labels: [],
    team: {
      id: '',
      name: '',
      approvers: [],
    },
    location: {
      id: '',
      name: '',
      timezone: '',
      workWeek: [1],
      firstDayOfWeek: 1,
      rolloverNeverExpireDays: false,
      resetQuotas: 'FISCAL_YEAR',
      rolloverExpiryMonth: 0,
      rolloverExpiryDay: 0,
      rolloverExpiryAfterDays: 0,
      leavePolicies: [],
    },
    leaveDays: [],
    workWeek: [],
    upcomingLeaves: [],
    pendingLeaves: [],
    history: [],
    today: [],
    locale: LocaleEnum.en,
    hourFormat: HourFormatEnum.twentyFour,
  })
  const [currentLeaves, setCurrentLeaves] = useState<IUserToday[]>([])
  const [upcomingLeaves, setUpcomingLeaves] = useState<IUserUpcomingLeaves[]>([])
  const [historyLeaves, setHistoryLeaves] = useState<IUserHistory[]>([])
  const [pendingLeaves, setPendingLeaves] = useState<IUserPendingLeaves[]>([])
  const [activeTab, setActiveTab] = useState('profile')
  const [workWeek, setWorkWeek] = useState<number[]>([])
  const [isUserEndDateEnabled, setIsUserEndDateEnabled] = useState(false)
  const [leaveDays, setLeaveDays] = useState<IMyProfileQuota[]>([])
  const [userLabels, setUserLabels] = useState<IGetLabelsShort[]>([])
  const [allLabels, setAllLabels] = useState<IGetLabelsShort[]>([])
  const shouldEnableFeatures = useShouldEnableFeatures(SubscriptionPlanEnum.complete, FeatureFlagEnum.labels)
  const [isResendingLeaveRequest, setIsResendingLeaveRequest] = useState(false)

  const [ getCurrentUserQuery ] = useManualQuery<IGetCurrentUser, {
    id: string
    date: string
    pendingLeavesDate: string
  }>(getCurrentUser)

  useEffect(() => {
    if (userId) {
      fetchUser(userId)
    }
  }, [ userId, actionNotifications ])

  useEffect(() => {
    if (leaveRequestActionEvent) {
      fetchUser(userId)
      dispatch(setLeaveRequestActionEvent(null))
    }
  }, [leaveRequestActionEvent])

  const fetchUser = async (id: string) => {
    try {
      const response = await getCurrentUserQuery({ variables:{
        id,
        date: dayjs().format('YYYY-MM-DD'),
        pendingLeavesDate: dayjs().subtract(2, 'years').startOf('year').format('YYYY-MM-DD'),
      }})
      if (!response.data || response.error) throw response.error
      setUser(response.data.getUser)
      setCurrentLeaves(response.data.getUser.today)
      setUpcomingLeaves(response.data.getUser.upcomingLeaves.sort(
        (a: IUserUpcomingLeaves, b: IUserUpcomingLeaves) => a.startDate > b.startDate ? 1 : -1
      ))
      setHistoryLeaves(response.data.getUser.history.filter(leave => leave.status !== 'OPEN').sort(
        (a: IUserHistory, b: IUserHistory) => a.startDate > b.startDate ? -1 : 1
      ))
      setPendingLeaves(response.data.getUser.pendingLeaves.sort(
        (a: IUserPendingLeaves, b: IUserPendingLeaves) => a.startDate > b.startDate ? -1 : 1
      ))
      setWorkWeek(response.data.getUser.workWeek || [1, 2, 3, 4, 5])
      setUserLabels(response.data.getUser.labels)
      setLeaveDays(response.data.getUser.leaveDays)
      setIsUserEndDateEnabled(response.data.getCompany.userEndDate)
      setAllLabels(response.data.getLabels)
      setIsLoading(false)
      setIsResendingLeaveRequest(false)
    } catch (err) {
      logger.error('error fetching user by id', err)
    }
  }

  const handleTabChange = (event) => {
    setActiveTab(event.key as string)
  }

  const cancelLeave = async (leaveRequestId: string) => {
    try {
      const isToil = isToilLeave(leaveRequestId)
      const body = {
        eventType: isToil ? 'TOIL_REQUEST_CANCELLED' : 'LEAVE_REQUEST_CANCELLED',
        eventGroup: isToil ? 'USER_TOIL_REQUEST' : 'USER_LEAVE_REQUEST',
        userId: user.id,
      }
      isToil ? body['toilRequestId'] = leaveRequestId : body['leaveRequestId'] = leaveRequestId

      const response = await Api.post('/core/event', body)

      notification.open({
        key: response.correlationId,
        message: formatMessage({ id: isToil ? 'components.toil.cancelInProgress' : 'user.cancelLeaveRequestInProgress' }),
        icon: (<LoadingOutlined />),
        duration: 0,
      })
      setActionNotifications([ ...actionNotifications, response.correlationId ])

    } catch (error) {
      const errorDescription = error.response?.data?.message ? error.response?.data.message : error.message ? error.message : JSON.stringify(error)
      notification.error({
        message: formatMessage({ id: 'error.generic' }),
        description: errorDescription,
        duration: 0,
      })
    }
  }

  const renderTabBar = () => {
    return <></>
  }

  const onSaveUserProfileChanges = async (name: string, profilePicture?: RcFile) => {
    const params: Partial<IUserUpdatedEvent> = {
      eventType: 'USER_UPDATED',
      eventGroup: 'USER',
      userId: user.id,
      name,
    }

    if (profilePicture) {
      const url = await uploadAvatar(profilePicture)
      params.imageUrl = url
    }

    const response = await Api.post('/core/event', params)
    if (response.correlationId) {
      setActionNotifications([...actionNotifications, response.correlationId])
    }
    showEditProfileModal(false)
  }

  const onUpdateLeaveRequest = async (leaveRequestId: string, leaveStatus, statusReason?: string) => {
    try {
      if (!user) {
        throw new Error('Missing user')
      }

      let eventGroup: string, eventType: string, messageId: string
      const isToil = isToilLeave(leaveRequestId)
      if (isToil) {
        eventGroup = 'USER_TOIL_REQUEST'
        eventType = leaveStatus ? 'TOIL_REQUEST_APPROVED' : 'TOIL_REQUEST_DENIED'
        messageId = leaveStatus ? 'components.toil.approveInProgress' : 'components.toil.denyInProgress'
      } else {
        eventGroup = 'USER_LEAVE_REQUEST'
        eventType = 'LEAVE_REQUEST_STATUS_CHANGED'
        messageId = leaveStatus ? 'user.approveInProgress' : 'user.denyInProgress'
      }

      const body = { 
        eventType,
        eventGroup,
        userId: user.id,
        statusReason,
        ...((eventType === 'LEAVE_REQUEST_STATUS_CHANGED' && !isToil) ? { leaveStatus: leaveStatus ? 'APPROVED' : 'DENIED'} : {}),
      }
      isToil ? body['toilRequestId'] = leaveRequestId : body['leaveRequestId'] = leaveRequestId

      const response = await Api.post('/core/event', body)

      notification.open({
        key: response.correlationId,
        message: formatMessage({ id: messageId }),
        icon: (<LoadingOutlined />),
        duration: 0,
      })
      setActionNotifications([ ...actionNotifications, response.correlationId ])
    } catch (error) {
      showErrorNotification(error)
    }
  }

  const deleteLeave = async (leaveRequestId: string) => {
    try {
      if (!user) {
        throw new Error('Missing user')
      }

      const isToil = isToilLeave(leaveRequestId)

      const body = {
        eventType: isToil ? 'TOIL_REQUEST_DELETED' : 'LEAVE_REQUEST_DELETED',
        eventGroup: isToil ? 'USER_TOIL_REQUEST' : 'USER_LEAVE_REQUEST',
        userId: user.id,
      }
      isToil ? body['toilRequestId'] = leaveRequestId : body['leaveRequestId'] = leaveRequestId

      const response = await Api.post('/core/event', body)
      notification.open({
        key: response.correlationId,
        message: formatMessage({ id: isToil ? 'components.toil.deleteInProgress' : 'user.deleteLeaveInProgress' }),
        icon: (<LoadingOutlined />),
        duration: 0,
      })
      setActionNotifications([ ...actionNotifications, response.correlationId ])
    } catch (error) {
      showErrorNotification(error)
    }
  }

  const showErrorNotification = (error) => {
    const errorDescription = error.response?.data?.message ? error.response?.data.message : error.message ? error.message : JSON.stringify(error)
    notification.error({
      message: formatMessage({ id: 'error.generic' }),
      description: errorDescription,
      duration: 0,
    })
  }

  const onResendLeaveRequest = async (data: IResendLeaveRequest) => {
    setIsResendingLeaveRequest(true)
    let response
    try {
      const isToilRequest = isToilLeave(data.resentLeaveRequestId as string)
      const type = isToilRequest ? 'TOIL' : 'LEAVE'
      let params = data
      if (isToilRequest) {
        data['resentRequestId'] = data['resentLeaveRequestId']
        params = omit(data, 'resentLeaveRequestId')
      }
      response = await Api.post(`/core/${lowerCase(type)}-request-validate`, {
        eventType: `${type}_REQUEST_CREATED`,
        eventGroup: `USER_${type}_REQUEST`,
        ...params,
        userId: user.id,
      })

      response = await Api.post('/core/event', {
        eventType: `${type}_REQUEST_CREATED`,
        eventGroup: `USER_${type}_REQUEST`,
        ...params,
        userId: user.id,
      })
      notification.open({
        key: response.correlationId,
        message: formatMessage({ id: isToilRequest ? 'components.toil.requestToilProgress' : 'requestLeave.inProgress' }),
        icon: (<LoadingOutlined />),
        duration: 0,
      })
      setActionNotifications([ ...actionNotifications, response.correlationId ])
    } catch (error) {
      setIsResendingLeaveRequest(false)
      logger.error(error)
      if (error?.response?.data?.code === 'BLACKOUT_PERIOD') {
        const data = error?.response?.data?.data
        notification.error({
          message: formatMessage(
            { id: 'automations.blackoutPeriodFromTo' },
            {
              fromDate: dayjs(data.startDate as string).format('MMMM Do YYYY.'),
              toDate: dayjs(data.endDate as string).format('MMMM Do YYYY.'),
              endDate: (...chunks) => data.startDate !== data.endDate ? <>{chunks}</> : '',
            }
          ),
          description: error.response.data.message,
          duration: 0,
        })
      } else if (error?.response?.data?.code === 'PROBATION_PERIOD') {
        const data = error?.response?.data?.data
        notification.error({
          message: formatMessage({ id: 'automations.probationPeriodMessages' }, { date: dayjs(data.date as string).format('MMMM Do YYYY.')}),
          description: error.response.data.message,
          duration: 0,
        })
      } else if (error?.response?.data?.code === 'LEAVE_DURATION_LIMIT') {
        notification.error({
          message: formatMessage(
            { id: 'automations.LEAVE_DURATION_LIMIT' }
          ),
          description: error.response.data.message,
          duration: 0,
        })
      } else if (error.response?.data?.error || error.response.data.message) {
        notification.error({
          message: formatMessage({ id: 'error.leaveSubmitError' }),
          description: formatMessage({ id: error.response.data.message || error.response?.data?.error }),
          duration: 0,
        })
      } else {
        const description = response?.correlationId ? formatMessage({ id: 'app.correlationIdError' }, { correlationId: response.correlationId }) : JSON.stringify(error)

        notification.error({
          message: formatMessage({ id: 'error.leaveSubmitError' }),
          description,
          duration: 0,
        })
      }
    }
  }

  const myProfileTabs: TabsProps['items'] = [
    {
      key: 'profile',
      label: <IntlMessages id="user.profile" />,
      children: <UserProfileTab
        amIAdmin={amIAdmin}
        user={{
          id: user.id,
          isAdmin: user.isAdmin,
          email: user.email,
          name: user.name,
          team: user.team,
          platform: user.platform as Platform,
          location: user.location,
          startDate: user.startDate,
          endDate: user.endDate,
          employeeId: user.employeeId,
          role: user.role,
          status: user.status,
          workWeekType: user.workWeekType,
          workWeekInfo: user.workWeekInfo,
          workHours: user.workHours,
          approvers: user.team.approvers ? user.team.approvers.map(approver => approver.name) : [],
          locale: locale.locale,
        }}
        workingDays={{
          days: workWeek,
          firstDayOfWeek: Number(user.location.firstDayOfWeek),
        }}
        quotas={leaveDays}
        locations={[]}
        isUserEndDateEnabled={isUserEndDateEnabled}
        setActiveTab={handleTabChange}
        hourlyLeaveAccounting={Boolean(authCompany?.hourlyLeaveAccounting)}
        onEdit={() => showEditProfileModal(true)}
      />,
    },
    {
      key: 'leaves',
      label: <IntlMessages id="user.leaves" />,
      children: <UserLeavesTab
        amIAdmin={amIAdmin}
        amIApprover={authUser.approverTo.find((u) => u.id === authUser.id) ? true : false}
        pendingRequests={pendingLeaves}
        currentLeaves={currentLeaves}
        scheduledLeaves={upcomingLeaves}
        leaveHistory={historyLeaves}
        user={{
          id: user.id,
          name: user.name,
          locale: locale.locale,
          hourFormat: user.hourFormat || HourFormatEnum.twentyFour,
        }}
        onCancelLeave={cancelLeave}
        onDeleteLeave={deleteLeave}
        onResendLeaveRequest={onResendLeaveRequest}
        onLeaveRequestUpdate={onUpdateLeaveRequest}
        isResendingLeaveRequest={isResendingLeaveRequest}
      />,
    },
    {
      key: 'logs',
      label: <IntlMessages id="app.logs" />,
      children: <UserLogsTab
        userId={userId}
        amIAdmin={amIAdmin}
        reload={activeTab}
        locationTimezone={user.location.timezone}
        hourlyLeaveAccounting={Boolean(authCompany?.hourlyLeaveAccounting)}
        hourFormat={user.hourFormat || HourFormatEnum.twentyFour}
      />,
    },
  ]

  return (
    <>
      {isLoading ?
        <CircularProgress /> :
        <>
          <div className="profile-banner">
            <div className="profile-container">
              <div className="profile-banner-top center-content">
                <div className="profile-banner-top-left">
                  <div className="profile-banner-avatar">
                    {user.platform === 'email' && <div className="profile-banner-avatar-overlay" onClick={() => showEditProfileModal(true)}><IntlMessages id="app.edit" /></div>}
                    <UserAvatar id={user.id} avatar={user.imageUrl} name={user.name} avatarSize={90} shape="circle" />
                  </div>
                  <div className="profile-banner-avatar-info">
                    <h2>{user.name}</h2>
                    {shouldEnableFeatures ?
                      <UserLabels
                        allLabels={allLabels}
                        userLabels={userLabels}
                        isEditable={false}
                      /> :
                      <p>{user.team.name}</p>
                    }
                  </div>
                </div>
              </div>
              <div className="profile-banner-bottom center-content">
                <Menu
                  onClick={handleTabChange}
                  selectedKeys={[activeTab]}
                  mode="horizontal"
                  items={[
                    {
                      key: 'profile',
                      label: <IntlMessages id="user.profile" />,
                    },
                    {
                      key: 'leaves',
                      label: <>
                        <IntlMessages id="user.leaves" />
                        <Badge style={{marginLeft: 4}} count={pendingLeaves.length} />
                      </>,
                    },
                    {
                      key: 'logs',
                      label: <IntlMessages id="app.logs" />,
                    },
                  ]}
                />
                <Breadcrumb
                  items={[
                    {
                      title: <Link to={FrontendUrls.dashboard}><IntlMessages id="sidebar.dashboard" /></Link>,
                    },
                    {
                      title: <IntlMessages id="app.myProfile" />,
                    },
                  ]}
                />
              </div>
            </div>
          </div>
          <div className="user-page center-content">
            {currentLeaves.length > 0 &&
              <UserTodayOff todayLeaves={currentLeaves} />
            }
            <Tabs activeKey={activeTab} renderTabBar={renderTabBar} items={myProfileTabs} />
          </div>
          <UserMyProfileModal
            name={user.name}
            profilePhoto={user.imageUrl}
            visible={editProfileModalVisible}
            onCancel={() => showEditProfileModal(false)}
            onSave={onSaveUserProfileChanges}
          />
        </>
      }
    </>
  )
}

export default MyProfilePage
