import React from 'react'
import { Col, Row, Statistic, Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'

import { IGetLocationGeneral, ILocationUser } from '../../types/locations'
import timezone from '@vacationtracker/shared/data/timezone'

import IntlMessages from '../../util/IntlMessages'
import { workWeekAsText } from '../work-week'
import { resetQuotas } from '../../util/reset-quotas-based-on'
import { UserAvatar } from '@vacationtracker/shared/components/user-avatar'
import { LocaleEnum } from '@vacationtracker/shared/types/i18n'
import { getUserWorkingHoursPerDay } from '@vacationtracker/shared/functions/work-week'
import { useIntl } from 'react-intl'

interface ILocationGeneral {
  location: IGetLocationGeneral
  locale: LocaleEnum
}

const LocationGeneral = ({ location, locale }: ILocationGeneral): React.ReactElement => {
  const { formatMessage } = useIntl()
  const locationTimezone = timezone.find(l => l.id === location.timezone)

  return (
    <div className="view-form vt-location-general">
      <Row align="top" gutter={[0, 20]}>
        <Col xl={18} lg={24}>
          <Statistic title={<IntlMessages id="app.location" />} value={location.name} />
        </Col>
      
        <Col xl={18} lg={24}>
          <Statistic title={<IntlMessages id="app.workweek" />} value={workWeekAsText({
            days: location.workWeek,
            firstDayOfWeek: location.firstDayOfWeek,
            withAnd: formatMessage({ id: 'app.and' }),
          }, formatMessage)} />
        </Col>
        
        <Col xl={18} lg={24}>
          <Statistic
            title={<IntlMessages id="app.workday" />}
            value={`${getUserWorkingHoursPerDay(location.workHours)} ${formatMessage({ id: 'app.hours'})}`} />
        </Col>

        <Col xl={18} lg={24}>
          <Statistic
            title={<IntlMessages id="location.timezone" />}
            value={locationTimezone ? locationTimezone.text : formatMessage({ id: 'location.timezoneDefault' })} 
          />
        </Col>

        <Col xl={18} lg={24}>
          <Statistic
            title={<>
              <IntlMessages id="locations.resetQuotas" />
              <Tooltip className="info-tooltip" title={<IntlMessages id="location.resetQuotasInfo" />} ><InfoCircleOutlined /></Tooltip>
            </>}
            value={resetQuotas({
              status: location.resetQuotas,
              yearStartDay: location.yearStartDay,
              yearStartMonth: location.yearStartMonth,
              locale: locale,
              locationTimezone: location?.timezone,
            }, formatMessage)} />
        </Col>

        <Col xl={18} lg={24}>
          <Statistic title={<IntlMessages id="app.users" />} value={`${location.users.length} ${formatMessage({ id: 'users.activeUsers' })}`} />
          {location.users.length > 0 ?
            <Row align="top" gutter={10} style={{ marginTop: 10 }}>
              {location.users.map((user: ILocationUser) => {
                return (
                  <Col key={user.id} xs={24} sm={12} md={12} lg={8} xl={8} className="user-avatar">
                    <UserAvatar id={user.id} avatar={user.imageUrl} name={user.name} isShowingName={true} isLinkToPage={true} shape="circle" />
                  </Col>
                )
              })}
            </Row> :
            <IntlMessages id="location.hasNoUsers" />
          }
        </Col>
      </Row>
    </div>
  )
}

export default LocationGeneral
