import React, { useState, useContext, useEffect, useRef } from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { App, Alert, Breadcrumb } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import { isWithinInterval, addYears } from 'date-fns'
import { useManualQuery } from 'graphql-hooks'
import { useFeatureFlagEnabled } from 'posthog-js/react'

import Api from '@vacationtracker/shared/services/api'
import {
  getAutomations, getConnectedCalendars, getLeaveRequestData, getLeaveTypesForUser, getOverlappingUsersInfo, getSuggestedSubstituteApprovers, getTeamsForApprover
} from '../../../graphql/custom-queries'
import { notificationStore } from '../../../context/notificationsContext/store'
import * as logger from '../../../services/logger'

import { getLeavePeriod } from '@vacationtracker/shared/functions/get-leave-period'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { selectUserIdSlice } from '../../../store/user-id-slice'
import { selectAuthUserSlice } from '../../../store/auth-user-slice'
import { selectLeaveRequestActionEventSlice, setLeaveRequestActionEvent } from '../../../store/leave-request-action-event-slice'
import { displayLeaveInDaysAndHours } from '@vacationtracker/shared/components/display-leave-in-days-and-hours'
import { formatDateToLocale } from '@vacationtracker/shared/components/formatted-date'
import { shouldShowFutureAccruedDaysInfo } from '@vacationtracker/shared/functions/should-show-future-accrued-days-info'
import LeaveForm from '@vacationtracker/shared/components/leave-form'
import CircularProgress from '../../../components/circular-progress'
import IntlMessages from '../../../util/IntlMessages'
import {
  LeaveRequestOverlappingInfo,
  LeaveRequestQuota,
  LeaveRequestSummary,
  getLeavePolicyFromQuota
} from '@vacationtracker/shared/components/add-request-leave-additional-info'

import {
  IGetAutomations,
  IGetConnectedCalendarsResponse,
  IGetLeaveRequest,
  IGetLeaveRequestData,
  IGetUsersForAdminAddLeaveData,
  IUserLeaveReuqestLeavePolicy,
  IUserLocationLeavePolicies,
  IUserQuota
} from '../../../types/custom-queries'
import { ILeaveFormSaveData } from '@vacationtracker/shared/components/leave-form/types'
import { ILocationYearHolidays } from '@vacationtracker/shared/types/holidays'
import { IConnectedGoogleCalendar, IConnectedOutlookCalendar } from '@vacationtracker/shared/types/calendar'
import { ILeaveTypeResult, IQuota } from '@vacationtracker/shared/types/calculations'
import { IApprover } from '@vacationtracker/shared/types/leave-request'
import {
  GetTeamsForApproverData,
  IGetLeaveTypesForUserLeaveRequestData,
  IGetUserOverlappingInfo,
  IGetUserOverlappingInfoData,
  IUserLeaveRequestExtended
} from '@vacationtracker/shared/components/add-request-leave-additional-info/types'
import { HourFormatEnum } from '@vacationtracker/shared/types/user'
import { AccrualType, IQuotaAmount } from '@vacationtracker/shared/types/leave-policy'
import { LocaleEnum } from '@vacationtracker/shared/types/i18n'
import { useSubstituteApprover } from '../../../util/hooks/use-substitute-approver'
import { useShouldEnableFeatures } from '../../../store/use-should-enable-features'
import { FeatureFlagEnum } from '@vacationtracker/shared/types/feature-flags'
import { SubscriptionPlanEnum } from '@vacationtracker/shared/types/company'
import { getUserWorkingHoursPerDay } from '@vacationtracker/shared/functions/work-week'
import { FrontendUrls } from '../../../types/urls'
import { createQuotaFromDays, deductFromQuota } from '@vacationtracker/shared/functions/calculation-shared'

interface IRequestsLeaveProps {
  history: {
    push: Function
    goBack: Function
  }
}

const RequestLeavePage = ({ history }: IRequestsLeaveProps) => {
  const { notification } = App.useApp()
  const { userId } = useAppSelector(selectUserIdSlice)
  const { authUser } = useAppSelector(selectAuthUserSlice)
  const enableMinutePickerLeave = useFeatureFlagEnabled(FeatureFlagEnum.enableMinutePickerLeave)

  const { formatMessage } = useIntl()
  const { actionNotifications, setActionNotifications } = useContext(notificationStore)
  const dispatch = useAppDispatch()
  const { leaveRequestActionEvent } = useAppSelector(selectLeaveRequestActionEventSlice)
  const shouldEnableSubstituteApprovers = useShouldEnableFeatures(SubscriptionPlanEnum.complete, FeatureFlagEnum.substituteApprovers)

  const [createLoader, setCreateLoader] = useState(false)
  const [user, setUser] = useState<IUserLeaveRequestExtended | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [thisYearHolidays, setThisYearHolidays] = useState([] as ILocationYearHolidays[])
  const [connectedGoogleCalendar, setConnectedGoogleCalendar] = useState<IConnectedGoogleCalendar | null>(null)
  const [connectedOutlookCalendar, setConnectedOutlookCalendar] = useState< IConnectedOutlookCalendar | null>(null)
  const [isLoadingConnectedCalenadar, setIsLoadingConnectedCalenadar] = useState(true)
  const [isLoadingOverlappingUsersInfo, setIsLoadingOverlappingUsersInfo] = useState(false)
  const [overlappingUsersInfo, setOverlapingUsersInfo] = useState<IGetUserOverlappingInfo | null>(null)
  const [checkForOverlappingUsers, setCheckForOverlappingUsers] = useState(false)
  const [startDate, setStartDate] = useState()
  const [selectedLeaveType, setSelectedLeaveType] = useState<IUserLeaveReuqestLeavePolicy | null>(null)
  const [selectedLeavePolicy, setSelectedLeavePolicy] = useState<IUserLocationLeavePolicies | null>(null)
  const [endDate, setEndDate] = useState()
  const [startTime, setStartTime] = useState()
  const [endTime, setEndTime] = useState()
  const [requestedLeave, setRequestedLeave] = useState<IGetLeaveRequest>()
  const [currentQuota, setCurrentQuota] = useState<IUserQuota | null>(null)
  const [nextYearQuota, setNextYearQuota] = useState<IUserQuota | null>(null)
  const [showSummary, setShowSummary] = useState(false)
  const [correlationId, setCorrelationId] = useState(null)
  
  const [userQuotasForCurrentYear, setUserQuotasForCurrentYear] = useState<ILeaveTypeResult | null>(null)
  const [userQuotasForNextYear, setUserQuotasForNextYear] = useState<ILeaveTypeResult | null>(null)
  const [getTeamsForApproverQuery] = useManualQuery<GetTeamsForApproverData, {
    id: string
    date: string
  }>(getTeamsForApprover)
  const [getAutomationsQuery] = useManualQuery<IGetAutomations>(getAutomations)
  const [getSuggestedSubstituteApproversQuery] = useManualQuery<IGetUsersForAdminAddLeaveData>(getSuggestedSubstituteApprovers)
  const {
    setCheckIfSubstituteNeeded,
    suggestedSubstituteApprovers,
    shouldShowSubstituteApprovers,
    isSubstituteListLoading,
  } = useSubstituteApprover(
    { userId, startDate, endDate },
    { getTeamsForApproverQuery, getAutomationsQuery, getSuggestedSubstituteApproversQuery }
  )
  const [showOverlappingInfoAndQuotas, setShowOverlappingInfoAndQuotas] = useState(true)

  const abortSignalRef = useRef(new AbortController())

  const [ getLeaveRequestDataQuery ] = useManualQuery<IGetLeaveRequestData, { leaveRequestId: string }>(getLeaveRequestData)
  const [ getOverlappingUsersInfoQuery ] = useManualQuery<IGetUserOverlappingInfoData, {
    teamId: string
    leaveRequest: {
      startDate: string
      endDate: string
      userId: string
      isPartDay: boolean
      partDayStartHour?: string
      partDayEndHour?: string
      leaveTypeId: string
    }
  }>(getOverlappingUsersInfo)
  const [getLeaveTypesForUserQuery] = useManualQuery<IGetLeaveTypesForUserLeaveRequestData, { id: string }>(getLeaveTypesForUser)
  const [getConnectedCalendarsQuery] = useManualQuery<IGetConnectedCalendarsResponse, { userId: string }>(getConnectedCalendars)
  const [isMinutePickerEnabled, setIsMinutePickerEnabled] = useState(false)

  useEffect(() => {
    if (typeof enableMinutePickerLeave === 'undefined') {
      return
    }
    setIsMinutePickerEnabled(enableMinutePickerLeave)
  }, [enableMinutePickerLeave])

  useEffect(() => {
    setUserQuotasForCurrentYear(getLeavePolicyFromQuota(selectedLeaveType?.id as string, currentQuota as IQuota) as ILeaveTypeResult)
    setUserQuotasForNextYear(getLeavePolicyFromQuota(selectedLeaveType?.id as string, nextYearQuota as IQuota) as ILeaveTypeResult)
  }, [currentQuota, nextYearQuota, selectedLeaveType])

  useEffect(() => {
    abortSignalRef.current = new AbortController()
    return () => {
      abortSignalRef.current.abort()
    }
  }, [])

  useEffect(() => {
    if (isLoading) {
      getLeaveTypes(userId)
    }
  }, [isLoading, userId])

  useEffect(() => {
    if (checkForOverlappingUsers) {
      getOverlappingUsers(startTime, endTime)
    }
  }, [checkForOverlappingUsers, startDate, endDate, selectedLeaveType, startTime, endTime])

  useEffect(() => {
    if (leaveRequestActionEvent && correlationId === leaveRequestActionEvent.correlationId) {
      getLeaveRequest(leaveRequestActionEvent.leaveRequestId)
      dispatch(setLeaveRequestActionEvent(null))
    }
  }, [leaveRequestActionEvent])

  useEffect(() => {
    if (requestedLeave) {
      setShowSummary(true)
    }
  }, [requestedLeave])

  useEffect(() => {
    if (startTime && endTime) {
      setCheckForOverlappingUsers(true)
    }
  }, [startTime, endTime])

  const getLeaveRequest = async (leaveRequestId: string) => {
    try {
      const leaveRequestResponse = await getLeaveRequestDataQuery({
        variables: {
          leaveRequestId,
        },
        fetchOptionsOverrides: {
          signal: abortSignalRef.current.signal,
        },
      })
      if (!leaveRequestResponse.data || leaveRequestResponse.error) throw leaveRequestResponse.error
      setRequestedLeave(leaveRequestResponse.data.getLeaveRequest)
    } catch (err) {
      logger.error('error fetching leave', err)
    }
  }

  const getOverlappingUsers = async (startTime, endTime) => {
    const teamId = user?.team.id
    if (!teamId || !startDate || !endDate || !selectedLeaveType) {
      setIsLoadingOverlappingUsersInfo(false)
      return
    }
    setIsLoadingOverlappingUsersInfo(true)
    setShowOverlappingInfoAndQuotas(true)
    const overlappingResponse = await getOverlappingUsersInfoQuery({
      variables: {
        teamId,
        leaveRequest: {
          startDate,
          endDate,
          userId: user?.id || userId,
          isPartDay: selectedLeaveType?.allowHalfDays || Boolean(selectedLeaveType?.shortestLeaveInterval !== 'FULL-DAY'),
          partDayStartHour: startTime,
          partDayEndHour: endTime,
          leaveTypeId: selectedLeaveType?.id,
        },
      },
      fetchOptionsOverrides: {
        signal: abortSignalRef.current.signal,
      },
    })
    if (!overlappingResponse.data || overlappingResponse.error) throw overlappingResponse.error
    setOverlapingUsersInfo(overlappingResponse?.data?.getOverlappingUsersInfo)
    setCheckForOverlappingUsers(false)
    setIsLoadingOverlappingUsersInfo(false)
  }

  const handleOnLeaveTypeSelected = (selectedLeaveType) => {
    setSelectedLeaveType(selectedLeaveType)
    setSelectedLeavePolicy(user?.location.leavePolicies.find(policy => policy.leaveType.id === selectedLeaveType.id) as IUserLocationLeavePolicies)
    setOverlapingUsersInfo(null)
    setStartDate(undefined)
    setEndDate(undefined)
    setStartTime(undefined)
    setEndTime(undefined)
  }

  const resetLeaveRequestForm = () => {
    setShowSummary(false)
    setOverlapingUsersInfo(null)
    setStartDate(undefined)
    setEndDate(undefined)
    setStartTime(undefined)
    setEndTime(undefined)
    setUserQuotasForCurrentYear(null)
    setUserQuotasForNextYear(null)
  }

  const handleOnEndDateSelected = (dateRange) => {
    const {startDate, endDate} = dateRange
    user?.leaveDays.map(quota => {
      if (isWithinInterval(new Date(startDate), { start: new Date(quota.yearStart), end: new Date(quota.yearEnd) })) {
        setCurrentQuota(quota)
      } else if (isWithinInterval(addYears(new Date(startDate), 1), { start: new Date(quota.yearStart), end: new Date(quota.yearEnd) })) {
        setNextYearQuota(quota)
      }
    })
    setStartDate(startDate)
    setEndDate(endDate)
    setCheckForOverlappingUsers(true)
    if((authUser?.role === 'Approver' || authUser?.role === 'Admin') && shouldEnableSubstituteApprovers) {
      setCheckIfSubstituteNeeded(true)
    }
  }

  const handleOnStartDateSelected = (startDate) => {
    user?.leaveDays.map(quota => {
      if (isWithinInterval(new Date(startDate), { start: new Date(quota.yearStart), end: new Date(quota.yearEnd) })) {
        setCurrentQuota(quota)
      } else if (isWithinInterval(addYears(new Date(startDate), 1), { start: new Date(quota.yearStart), end: new Date(quota.yearEnd) })) {
        setNextYearQuota(quota)
      }
    })
    setStartDate(startDate)
    setEndDate(startDate)
    setCheckForOverlappingUsers(true)
  }

  const handleOnTimeSelected = ({
    startTime,
    endTime,
  }) => {
    startTime && setStartTime(startTime)
    endTime && setEndTime(endTime)
  }

  const handleSendFeedback = async (body) => {
    await Api.post('/core/event', body)
  }

  const getLeaveTypes = async (id) => {
    try {
      const response = await getLeaveTypesForUserQuery({
        variables: {
          id,
        },
        fetchOptionsOverrides: {
          signal: abortSignalRef.current.signal,
        },
      })
      if (!response.data || response.error) throw response.error
      const connectedCalendarsResponse = await getConnectedCalendarsQuery({
        variables: {
          userId: authUser.id,
        },
        fetchOptionsOverrides: {
          signal: abortSignalRef.current.signal,
        },
      })
      if (!connectedCalendarsResponse.data || connectedCalendarsResponse.error) throw connectedCalendarsResponse.error
      if (connectedCalendarsResponse.data?.getConnectedGooogleCalendar?.calendarId) {
        setConnectedGoogleCalendar(connectedCalendarsResponse.data.getConnectedGooogleCalendar)
      }
      if (connectedCalendarsResponse.data?.getConnectedOutlookCalendar?.calendarId) {
        setConnectedOutlookCalendar(connectedCalendarsResponse.data.getConnectedOutlookCalendar)
      }
      setIsLoadingConnectedCalenadar(false)
      setUser(response.data.getUser)
      setThisYearHolidays(response.data.getUser.location.holidays as ILocationYearHolidays[])

      setIsLoading(false)
    } catch (err) {
      if (err.fetchError?.name === 'AbortError') {
        return
      }
      logger.error('error fetching leave types list', err)
    }
  }

  const handleSubmit = async (data: ILeaveFormSaveData) => {
    setCreateLoader(true)
    setCorrelationId(null)
    let response
    try {
      response = await Api.post('/core/leave-request-validate', {
        eventType: 'LEAVE_REQUEST_CREATED',
        eventGroup: 'USER_LEAVE_REQUEST',
        ...data,
        userId,
      })

      response = await Api.post('/core/event', {
        eventType: 'LEAVE_REQUEST_CREATED',
        eventGroup: 'USER_LEAVE_REQUEST',
        ...data,
        userId,
      })
      notification.open({
        key: response.correlationId,
        message: formatMessage({ id: 'requestLeave.inProgress' }),
        icon: (<LoadingOutlined />),
        duration: 0,
      })
      setActionNotifications([ ...actionNotifications, response.correlationId ])
      setCorrelationId(response.correlationId)
      setCreateLoader(false)
    } catch (error) {
      setCorrelationId(null)
      logger.debug(error)
      setCreateLoader(false)
      if (error?.response?.data?.code === 'BLACKOUT_PERIOD') {
        const data = error?.response?.data?.data
        notification.error({
          message: formatMessage(
            { id: 'automations.blackoutPeriodFromTo' },
            {
              fromDate: dayjs(data.startDate).format('MMMM Do YYYY.'),
              toDate: dayjs(data.endDate).format('MMMM Do YYYY.'),
              endDate: (...chunks) => data.startDate !== data.endDate ? <>{chunks}</> : '',
            }
          ),
          description: error.response.data.message,
          duration: 0,
        })
      } else if (error?.response?.data?.code === 'PROBATION_PERIOD') {
        const data = error?.response?.data?.data
        notification.error({
          message: formatMessage({ id: 'automations.probationPeriodMessages' }, { date: dayjs(data.date as string).format('MMMM Do YYYY.')}),
          description: error.response.data.message,
          duration: 0,
        })
      } else if (error?.response?.data?.code === 'LEAVE_DURATION_LIMIT') {
        notification.error({
          message: formatMessage(
            { id: 'automations.LEAVE_DURATION_LIMIT' }
          ),
          description: error.response.data.message,
          duration: 0,
        })
      } else if (error.response?.data?.error || error.response.data.message) {
        notification.error({
          message: formatMessage({ id: 'error.leaveSubmitError' }),
          description: formatMessage({ id: error.response.data.message || error.response?.data?.error }),
          duration: 0,
        })
      } else {
        const description = response?.correlationId ? formatMessage({ id: 'app.correlationIdError' }, { correlationId: response.correlationId }) : JSON.stringify(error)

        notification.error({
          message: formatMessage({ id: 'error.leaveSubmitError' }),
          description,
          duration: 0,
        })
      }
    }
  }

  return (
    <div className='main-content'>
      <div className="main-content-header">
        <div className="main-content-header-title">
          <IntlMessages id='app.requestLeave' />
        </div>
        <div className="main-content-header-breadcrumb">
          <Breadcrumb
            items={[
              {
                title: <Link to={FrontendUrls.dashboard}><IntlMessages id="sidebar.dashboard" /></Link>,
              },
              {
                title: <IntlMessages id='app.requestLeave' />,
              },
            ]}
          />
        </div>
      </div>
      <div className="main-content-body">
        {!isLoading && showSummary ? <LeaveRequestSummary
          userId={userId}
          numberOfWorkingHoursPerDay={getUserWorkingHoursPerDay(authUser.workHours)}
          isLeaveAddition={false}
          onSave={handleSendFeedback}
          leaveRequestInfo={{
            requestedDays: requestedLeave?.workingDays as number,
            requestedAmountCurrentYear: createQuotaFromDays(Number(overlappingUsersInfo?.year1Days), overlappingUsersInfo?.userWorkingHours),
            requestedAmountNextYear: createQuotaFromDays(Number(overlappingUsersInfo?.year2Days), overlappingUsersInfo?.userWorkingHours),
            remainingAmountCurrentYear: userQuotasForCurrentYear?.quotas.remaining as IQuotaAmount,
            remainingAmountNextYear: userQuotasForNextYear?.quotas.remaining as IQuotaAmount,
            leaveTypeName: selectedLeaveType?.leaveType?.name as string,
            hasUnlimitedDays: Boolean(userQuotasForCurrentYear?.hasUnlimitedDays),
            hasUnlimitedDaysNextYear: Boolean(userQuotasForNextYear?.hasUnlimitedDays),
            isApprovalRequired: selectedLeaveType?.isApprovalRequired,
            leavePeriod: getLeavePeriod({
              isPartDay: selectedLeaveType?.id.includes('|part-day'),
              startDate: requestedLeave?.startDate,
              endDate: requestedLeave?.endDate,
              partDayStartHour: requestedLeave?.partDayStartHour,
              partDayEndHour: requestedLeave?.partDayEndHour,
              partDay: requestedLeave?.partDay,
              formatMessage,
              locale: user?.locale,
              leaveRequestWorkingDays: overlappingUsersInfo?.calendarDays,
              hourFormat: authUser?.hourFormat || HourFormatEnum.twentyFour,
            }),
          }}
          approvers={user?.team.approvers as IApprover[]}
          resetLeaveRequestForm={resetLeaveRequestForm}
          futureAccruals={{
            allowAdvanceAccrualUsage: selectedLeaveType?.allowAdvanceAccrualUsage,
            endDate: endDate as unknown as string,
            daysOnLeaveEndDates: overlappingUsersInfo?.daysOnLeaveEndDates,
          }}
          locale={user?.locale as LocaleEnum}
        /> : null}
        {isLoading || isLoadingConnectedCalenadar ? <CircularProgress /> : showSummary
          ? null
          : <LeaveForm
            authUserId={authUser.id}
            authUserRole={authUser.role}
            hourFormat={authUser.hourFormat}
            loading={createLoader}
            listOfUsers={[user as IUserLeaveRequestExtended]}
            holidays={thisYearHolidays}
            onSave={(data: ILeaveFormSaveData) => {
              (async () => {
                await handleSubmit(data)
              })()
            }}
            onLeaveTypeSelected={handleOnLeaveTypeSelected}
            onEndDateSelected={handleOnEndDateSelected}
            onStartDateSelected={handleOnStartDateSelected}
            onHandleTimeSelected={handleOnTimeSelected}
            onCancel={() => { history.goBack(); history.goBack() }}
            formType={'request'}
            connectedGoogleCalendar={connectedGoogleCalendar}
            connectedOutlookCalendar={connectedOutlookCalendar}
            shouldShowSubstituteApprovers={shouldShowSubstituteApprovers}
            substituteApprovers={suggestedSubstituteApprovers}
            isSubstituteListLoading={isSubstituteListLoading}
            setShowOverlappingInfoAndQuotas={setShowOverlappingInfoAndQuotas}
            isMinutePickerEnabled={isMinutePickerEnabled}
          />}
      </div>
      {!showSummary && showOverlappingInfoAndQuotas && <LeaveRequestOverlappingInfo
        isLoadingOverlappingUsersInfo={isLoadingOverlappingUsersInfo}
        overlappingUsersInfo={overlappingUsersInfo}
      />}
      {!showSummary && showOverlappingInfoAndQuotas &&
        <LeaveRequestQuota
          userQuotasForCurrentYear={userQuotasForCurrentYear}
          userQuotasForNextYear={userQuotasForNextYear}
          currentQuota={currentQuota}
          nextYearQuota={nextYearQuota}
          leavePolicy={selectedLeavePolicy}
          user={user}
          overlappingUsersInfo={overlappingUsersInfo}
          partDayLeaveTotalHours={Number(endTime) - Number(startTime)}
        />
      }
      {!isLoadingOverlappingUsersInfo && showOverlappingInfoAndQuotas && !showSummary && selectedLeaveType?.allowAdvanceAccrualUsage && shouldShowFutureAccruedDaysInfo({
        accrualType: selectedLeaveType?.accrualType as AccrualType,
        allowAdvanceAccrualUsage: selectedLeaveType?.allowAdvanceAccrualUsage,
        currentYearDays: overlappingUsersInfo?.daysOnLeaveEndDates,
        leaveRequestEndDate: endDate,
        currentDays: userQuotasForCurrentYear?.currentDays,
      }) && <Alert
        message={formatMessage(
          { id: 'email.notification.accruedDaysInfoForUser' },
          { amount: displayLeaveInDaysAndHours({
            value: userQuotasForCurrentYear?.currentDays as number,
            formatMessage,
            numberOfWorkingHoursPerDay: getUserWorkingHoursPerDay(authUser.workHours),
          }),
          accrued:  displayLeaveInDaysAndHours({
            value: overlappingUsersInfo?.daysOnLeaveEndDates as number,
            formatMessage,
            numberOfWorkingHoursPerDay: getUserWorkingHoursPerDay(authUser.workHours),
          }),
          earningDate: formatDateToLocale(endDate as unknown as string, 'dddd, MMM D, YYYY', user?.locale),
          })}
        type='info' />}
    </div>
  )
}

export default RequestLeavePage
