import {
  confirmResetPassword,
  confirmSignIn,
  getCurrentUser as amplifyGetCurrentUser,
  fetchAuthSession,
  fetchMFAPreference,
  fetchUserAttributes,
  resetPassword,
  setUpTOTP,
  signIn as amplifySignIn,
  signOut as amplifySignOut,
  updatePassword as amplifyUpdatePassword,
  updateMFAPreference as amplifyUpdateMFAPreference,
  verifyTOTPSetup,
  SignInInput,
  ConfirmSignInInput
} from 'aws-amplify/auth'
import logger from '@vacationtracker/shared/functions/logger'
import { generateError } from '@vacationtracker/shared/errors/generate-error'
import { NoCredentials, NoCurrentUser, NoTokens } from '@vacationtracker/shared/errors/auth'
import { wait } from '../functions/wait'

interface IAuthTokens {
  accessToken: string
  idToken: string
  idTokenExpiration?: number
}

interface ICredentils {
  accessKeyId: string
  secretAccessKey: string
  sessionToken?: string
  expiration?: Date
}

export interface IAuthUser {
  username: string
  userId: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  attributes?: any
}

export interface IAuthNextStep {
  signInStep: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  additionalInfo?: any
  challengeName?: string
  challengeParam?: {
    [key: string]: string
  }
}

const MAX_RETRIES = 4

export async function getAuthTokens(force = false, retries = 0): Promise<IAuthTokens> {
  try {
    const session = await fetchAuthSession({ forceRefresh: force })
    if (!session.tokens || !session.tokens.idToken) {
      if (retries <= MAX_RETRIES) {
        const retryAfter = 500 * (retries + 1)
        // We'll remove this console.log in one of the next releases
        // eslint-disable-next-line no-console
        console.log('No tokens, retrying after ', retryAfter, 'ms')
        await wait(500)
        // Always force refresh if there are no tokens
        const shouldForce = force || retries > 0
        return getAuthTokens(shouldForce, retries + 1)
      }
      throw generateError(NoTokens)
    }

    return {
      accessToken: session.tokens.accessToken.toString(),
      idToken: session.tokens.idToken.toString(),
    }
  } catch (error) {
    // We'll remove this console.log in one of the next releases
    // eslint-disable-next-line no-console
    console.log('~~~ getAuthTokens ERROR', error.name, error.message, error)
    if (error.name === 'NetworkError' && retries <= MAX_RETRIES) {
      const retryAfter = 500 * (retries + 1)
      // We'll remove this console.log in one of the next releases
      // eslint-disable-next-line no-console
      console.log('Network error, retrying after ', retryAfter, 'ms')
      // Retry after 500ms, 1000ms, 1500ms, and 2000ms if there is a network error (net::ERR_TIMED_OUT)
      await wait(retryAfter)
      // Always force refresh if there are no tokens
      const shouldForce = force || retries > 0
      return getAuthTokens(shouldForce, retries + 1)
    }
    logger.debug('getTokens', error)
    throw generateError(NoTokens)
  }
}

export async function getCurrentUser(): Promise<IAuthUser> {
  try {
    const user = await amplifyGetCurrentUser()
    if (!user) throw generateError(NoCurrentUser)
    const attributes = await fetchUserAttributes()
    logger.debug('User attributes', attributes)
    const response: IAuthUser = {
      username: user.username,
      userId: user.userId,
      attributes,
    }
    return response
  } catch (error) {
    // logger.error('getCurrentUser', error)
    throw generateError(NoCurrentUser)
  }
}

export async function signin(username: string, password?: string): Promise<IAuthUser | IAuthNextStep> {
  try {
    const amplifySigninInput: SignInInput = {
      username,
    }
    if (password) {
      amplifySigninInput.password = password
    } else {
      amplifySigninInput.options = {
        authFlowType: 'CUSTOM_WITHOUT_SRP',
      }
    }
    const signinResponse = await amplifySignIn(amplifySigninInput)

    switch (signinResponse.nextStep.signInStep) {
      case 'DONE':
        if (signinResponse.isSignedIn) {
          return getCurrentUser()
        }
        return signinResponse.nextStep

      case 'CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE':
        return {
          signInStep: 'CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE',
          additionalInfo: signinResponse.nextStep.additionalInfo,
          challengeName: 'CUSTOM_CHALLENGE',
          challengeParam: {
            ...signinResponse.nextStep.additionalInfo,
          },
        }

      case 'CONFIRM_SIGN_IN_WITH_TOTP_CODE':
        return {
          signInStep: 'CONFIRM_SIGN_IN_WITH_TOTP_CODE',
          challengeName: 'SOFTWARE_TOKEN_MFA',
        }

      case 'CONFIRM_SIGN_IN_WITH_SMS_CODE':
        return {
          signInStep: 'CONFIRM_SIGN_IN_WITH_SMS_CODE',
          challengeName: 'SMS_MFA',
        }

      case 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED':
        return {
          signInStep: 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED',
          challengeName: 'NEW_PASSWORD_REQUIRED',
        }

      default:
        return signinResponse.nextStep
    }
  } catch (error) {
    if (error.name === 'UserAlreadyAuthenticatedException') {
      return getCurrentUser()
    }
    logger.error('signin', error)
    throw error
  }
}

export async function confirmSigninWithCustomChallengeAnswer(challengeResponse: string, metadata?: { [key: string]: string }): Promise<IAuthUser> {
  const confirmSignInParams: ConfirmSignInInput = {
    challengeResponse,
  }
  if (metadata) {
    confirmSignInParams.options = {
      clientMetadata: metadata,
    }
  }
  await confirmSignIn(confirmSignInParams)
  return await getCurrentUser()
}

export async function signout(global = false): Promise<void> {
  // Global signout will invalidate refresh tokens, but the current access token will stay active for up to 15 minutes.
  await amplifySignOut({
    global,
  })
}

export async function initiateForgotPasswordFlow(username: string): Promise<void> {
  await resetPassword({
    username,
  })
}

export async function forgotPasswordSubmit(username: string, confirmationCode: string, newPassword: string): Promise<void> {
  await confirmResetPassword({
    username,
    confirmationCode,
    newPassword,
  })
}

export async function updatePassword(oldPassword: string, newPassword: string): Promise<void> {
  await amplifyUpdatePassword({
    oldPassword,
    newPassword,
  })
}

export async function getCredentials(): Promise<ICredentils> {
  const session = await fetchAuthSession()
  if (!session.credentials) throw generateError(NoCredentials)
  return session.credentials
}

export async function setupMfa(): Promise<string> {
  const output = await setUpTOTP()
  return output.sharedSecret
}

export async function updateMfaPreference(mfaType: 'SMS' | 'TOTP' | 'NOMFA'): Promise<void> {
  await amplifyUpdateMFAPreference({
    sms: mfaType === 'SMS' ? 'PREFERRED' : 'DISABLED',
    totp: mfaType === 'TOTP' ? 'PREFERRED' : 'DISABLED',
  })
}

export async function getPreferredMFA() {
  const { preferred } = await fetchMFAPreference()
  return preferred
}

export async function verifyTotpToken(code: string): Promise<void> {
  return await verifyTOTPSetup({ code })
}
