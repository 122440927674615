import React from 'react'
import { Alert } from 'antd'
import IntlMessages from '../../util/IntlMessages'
import { ApproversLevelEnum } from '@vacationtracker/shared/types/team'

interface ILeaveRequestAlertStatus {
  approverId?: string
  approverName?: string
  authUserId: string
  currentLeaveRequestStatus?: string
  leaveRequestStatus: string
  leaveRequestName?: string
  resend?: boolean
  isMultiLevelApproval?: boolean
  approvedLevel?: ApproversLevelEnum
}

export const LeaveRequestAlertStatus = ({ 
  approverId,
  approverName,
  authUserId,
  currentLeaveRequestStatus,
  leaveRequestStatus,
  leaveRequestName,
  resend,
  isMultiLevelApproval = false,
  approvedLevel,
}: ILeaveRequestAlertStatus): React.ReactElement => {
  
  return (<>
    {leaveRequestStatus === 'APPROVED' && approverId === authUserId && currentLeaveRequestStatus !== leaveRequestStatus && !isMultiLevelApproval &&
      <Alert
        message={<IntlMessages id="components.leaveRequestAlertStatus.leaveRequestApprovedByYouInfo" values={{ name : leaveRequestName }}/>}
        type="success"
        showIcon
      />
    }
    {leaveRequestStatus === 'OPEN' && isMultiLevelApproval && approvedLevel === ApproversLevelEnum.FIRST && approverName &&
      <Alert
        message={<IntlMessages id="components.leaveRequestAlertStatus.leaveRequestHasApprovedByFirstLevelYouInfo" values={{ approverName }} />}
        type="success"
        showIcon
      />
    }

    {leaveRequestStatus === 'APPROVED' && isMultiLevelApproval &&
      <Alert
        message={<IntlMessages id="components.leaveRequestAlertStatus.leaveRequestHasApprovedByYouSecondLevelInfo" values={{ name: leaveRequestName, approverName }} />}
        type="success"
        showIcon
      />
    }
    {leaveRequestStatus === 'APPROVED' && approverId !== authUserId && !isMultiLevelApproval &&
      <Alert
        message={<IntlMessages id="components.leaveRequestAlertStatus.leaveRequestApprovedInfo" values={{ approverName }}/>}
        type="success"
        showIcon
      />
    }
    {leaveRequestStatus === 'DENIED' && approverId === authUserId && currentLeaveRequestStatus !== leaveRequestStatus &&
      <Alert
        message={<IntlMessages id="components.leaveRequestAlertStatus.leaveRequestDeniedByYouInfo" values={{ name : leaveRequestName }}/>}
        type="warning"
        showIcon
      />
    }
    {leaveRequestStatus === 'DENIED' && approverId === authUserId && currentLeaveRequestStatus === leaveRequestStatus &&
      <Alert
        message={<IntlMessages id="components.leaveRequestAlertStatus.leaveRequestHasDeniedByYouInfo" />}
        type="warning"
        showIcon
      />
    }
    {leaveRequestStatus === 'DENIED' && approverId !== authUserId &&
      <Alert
        message={<IntlMessages id="components.leaveRequestAlertStatus.leaveRequestDeniedInfo" values={{ approverName }}/>}
        type="warning"
        showIcon
      />
    }
    {leaveRequestStatus === 'EXPIRED' && !resend &&
      <Alert
        message={<IntlMessages id="components.leaveRequestAlertStatus.leaveRequestExpiredInfo" />}
        type="warning"
        showIcon
      />
    }
    {leaveRequestStatus === 'EXPIRED' && resend &&
      <Alert
        message={<IntlMessages id="user.resending" />}
        type="info"
        showIcon
      />
    }
    {leaveRequestStatus === 'CANCELLED' &&
      <Alert
        message={<IntlMessages id="components.leaveRequestAlertStatus.leaveRequestCancelledInfo" />}
        type="warning"
        showIcon
      />
    }
  </>)
}
