/* eslint-disable @typescript-eslint/restrict-plus-operands */
import React, { useState, useContext, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { App, Alert, Descriptions, Typography, Input, Button } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import qs from 'qs'
import dayjs from 'dayjs'
import { useManualQuery } from 'graphql-hooks'

import Api from '@vacationtracker/shared/services/api'
import { getLeaveRequestById, getToilRequestById } from '../../../graphql/custom-queries'
import { isToilLeave } from '@vacationtracker/shared/functions/is-toil-leave-request'
import { lowerCase } from 'lodash'
import { formatTimeString } from '@vacationtracker/shared/functions/get-days-or-hours'
import { wait } from '@vacationtracker/shared/functions/wait'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { selectAuthUserSlice } from '../../../store/auth-user-slice'
import { selectAuthCompanySlice } from '../../../store/auth-company-slice'
import { selectLocaleSlice } from '../../../store/locale-slice'
import { notificationStore } from '../../../context/notificationsContext/store'
import { selectLeaveRequestActionEventSlice, setLeaveRequestActionEvent } from '../../../store/leave-request-action-event-slice'
import { track } from '../../../services/analytics/analytics'
import { convertHourFormats } from '@vacationtracker/shared/functions/convert-between-hour-formats'

import IntlMessages from '../../../util/IntlMessages'
import CircularProgress from '../../../components/circular-progress'
import { LeaveRequestAlertStatus } from '../../../components/leave-request-alert-status'
import FormattedDate from '@vacationtracker/shared/components/formatted-date'
import { DisplayLeaveDuration } from '@vacationtracker/shared/components/display-leave-in-days-and-hours'

import { IGetLeaveRequestByIdData, IGetLeaveRequestById, IGetToilRequestById, IGetToilRequestByIdData } from '../../../types/custom-queries'
import { LocaleEnum } from '@vacationtracker/shared/types/i18n'
import { HourFormatEnum } from '@vacationtracker/shared/types/user'
import { ApproversLevelEnum } from '@vacationtracker/shared/types/team'


const { Title, Paragraph } = Typography
const { TextArea } = Input

interface ILeaveRequestsPage {
  match: {
    params: {
      leaveRequestId: string
    }
  }
  location: {
    search: string
  }
  history: {
    push: Function
  }
}

const LeaveRequestsPage = ({ match, location, history }: ILeaveRequestsPage): React.ReactElement => {
  const { notification } = App.useApp()
  const now = new Date()
  const { locale } = useAppSelector(selectLocaleSlice)
  const { authUser } = useAppSelector(selectAuthUserSlice)
  const { authCompany } = useAppSelector(selectAuthCompanySlice)
  const { leaveRequestActionEvent } = useAppSelector(selectLeaveRequestActionEventSlice)
  const dispatch = useAppDispatch()
  const { actionNotifications, setActionNotifications } = useContext(notificationStore)
  const { formatMessage } = useIntl()

  const [leaveRequest, setLeaveRequest] = useState<IGetLeaveRequestById>()
  const [toilRequest, setToilRequest] = useState<IGetToilRequestById>()
  const [isLoading, setIsLoading] = useState(true)
  const [denyWithReason, setDenyWithReason] = useState(false)
  const [actionWrong, setActionWrong] = useState(false)
  const [resend, setResend] = useState<boolean>(false)
  const [leaveRequestIdWrong, setLeaveRequestIdWrong] = useState(false)
  const [inProgressLeaveRequestAction, setInProgressLeaveRequestAction] = useState(false)
  const [inProgressDenyWithReason, setInProgressDenyWithReason] = useState(false)
  const [denyReason, setDenyReason] = useState<string>()
  const [currentLeaveRequestStatus, setCurrentLeaveRequestStatus] = useState<string>()
  const [hourlyLeaveAccounting] = useState<boolean>(Boolean(authCompany?.hourlyLeaveAccounting))
  const [isMultiLevelApproval, setIsMultiLevelApproval] = useState<boolean>(false)
  const [approvedLevel, setApprovedLevel] = useState<ApproversLevelEnum | undefined>()
  const [leaveRequestApprovedLevel, setLeaveRequestApprovedLevel] = useState<ApproversLevelEnum | undefined>()

  const [getLeaveRequestByIdQuery] = useManualQuery<IGetLeaveRequestByIdData, { leaveRequestId: string }>(getLeaveRequestById)
  const [getToilRequestByIdQuery] = useManualQuery<IGetToilRequestByIdData, { leaveRequestId: string }>(getToilRequestById)

  useEffect(() => {
    const leaveId = match.params.leaveRequestId
    if (leaveId) {
      isToilLeave(leaveId) ? getToilRequest(leaveId) : getLeaveRequest(leaveId)
      dispatch(setLeaveRequestActionEvent(null))
    } else {
      setLeaveRequestIdWrong(true)
    }
  }, [match.params.leaveRequestId, actionNotifications, leaveRequestActionEvent])

  useEffect(() => {
    if (inProgressLeaveRequestAction) {
      setDenyWithReason(false)
      return
    }
    if (leaveRequest && !leaveRequest.autoApproved && leaveRequest.status === 'OPEN') {
      const action = qs.parse(location.search, { ignoreQueryPrefix: true }).action as string
      const actorId = qs.parse(location.search, { ignoreQueryPrefix: true }).actorId as string
      handleLeaveRequestAction(action, actorId)
      const approvedLevel = qs.parse(location.search, { ignoreQueryPrefix: true }).approvedLevel as ApproversLevelEnum
      setLeaveRequestApprovedLevel(approvedLevel)
      return
    }
    handleResendLeaveRequestAction(leaveRequest as IGetLeaveRequestById)
    if (leaveRequest && leaveRequest.status !== 'OPEN') {
      setDenyWithReason(false)
      setIsLoading(false)
    }
  }, [leaveRequest])

  useEffect(() => {
    if (inProgressLeaveRequestAction) {
      setDenyWithReason(false)
      return
    }
    if (toilRequest && !toilRequest.approver && toilRequest.status === 'OPEN') {
      const action = qs.parse(location.search, { ignoreQueryPrefix: true }).action as string
      const actorId = qs.parse(location.search, { ignoreQueryPrefix: true }).actorId as string
      handleLeaveRequestAction(action, actorId)
      return
    }
    handleResendLeaveRequestAction(toilRequest as IGetToilRequestById)
    if (toilRequest && toilRequest.status !== 'OPEN') {
      setDenyWithReason(false)
      setIsLoading(false)
    }
  }, [toilRequest])

  const handleResendLeaveRequestAction = async(leaveRequest: IGetLeaveRequestById | IGetToilRequestById) => {
    if (leaveRequest && leaveRequest.status === 'EXPIRED') {
      const action = qs.parse(location.search, { ignoreQueryPrefix: true }).action as string
      const isToilRequest = isToilLeave(leaveRequest.id)
      const additionalCondition = isToilRequest ? true : leaveRequest.startDate > now.toISOString()
      const type = isToilRequest ? 'TOIL' : 'LEAVE'
      if (action === 'resend' && leaveRequest.status === 'EXPIRED' && additionalCondition) {
        setResend(true)
        const data = {
          startDate: leaveRequest.startDate,
          endDate: leaveRequest.endDate,
          reason: leaveRequest.reason,
          isPartDay: leaveRequest.isPartDay,
          partDayStartHour: leaveRequest.isPartDay ? leaveRequest.partDayStartHour : undefined,
          partDayEndHour: leaveRequest.isPartDay ? leaveRequest.partDayEndHour : undefined,
          partDay: leaveRequest.isPartDay ? leaveRequest.partDay : undefined,
          leaveTypeId: leaveRequest.leaveType.id,
        }
        if (isToilRequest) {
          data['resentRequestId'] = leaveRequest.id
        } else {
          data['resentLeaveRequestId'] = leaveRequest.id
        }
        let response
        try {
          response = await Api.post(`/core/${lowerCase(type)}-request-validate`, {
            eventType: `${type}_REQUEST_CREATED`,
            eventGroup: `USER_${type}_REQUEST`,
            ...data,
            userId: leaveRequest.user.id,
          })

          response = await Api.post('/core/event', {
            eventType: `${type}_REQUEST_CREATED`,
            eventGroup: `USER_${type}_REQUEST`,
            ...data,
            userId: leaveRequest.user.id,
          })
          track(`${type}_REQUEST_RESENT`, {
            companyId: authCompany?.id,
            userId: leaveRequest.user.id,
            leaveRequestId: leaveRequest.id,
          })
          notification.open({
            key: response.correlationId,
            message: formatMessage({ id: isToilRequest ? 'components.toil.requestToilProgress' : 'requestLeave.inProgress' }),
            icon: (<LoadingOutlined />),
            duration: 0,
          })
          setActionNotifications([ ...actionNotifications, response.correlationId ])
          history.push('/app/dashboard')
        } catch (error) {
          if (error.response?.data?.error || error.response.data.message) {
            notification.error({
              message: formatMessage({ id: 'error.leaveSubmitError' }),
              description: formatMessage({ id: error.response.data.message || error.response?.data?.error }),
              duration: 0,
            })
          } else {
            const description = response?.correlationId ? formatMessage({ id: 'app.correlationIdError' }, { correlationId: response.correlationId }) : JSON.stringify(error)
            notification.error({
              message: formatMessage({ id: 'error.leaveSubmitError' }),
              description,
              duration: 0,
            })
          }
        }
      }
    }
  }

  const handleLeaveRequestAction = async (action: string, actorId?: string) => {
    if (action === 'approve' || action === 'deny') {
      setIsLoading(false)
      onUpdateLeaveRequest(leaveRequest ?? toilRequest, action === 'approve' ? true : false)
      return
    }
    if (action === 'deny-with-reason') {
      setIsLoading(false)
      setDenyWithReason(true)
      return
    }
    if (action === 'snooze' && (leaveRequest || toilRequest) && actorId === authUser.id) {
      setIsLoading(false)
      const body = { leaveRequestId: leaveRequest ? leaveRequest.id : toilRequest?.id }
      await Api.post('/core/snooze-reminders', body)
      notification.success({
        message: formatMessage({ id: 'bot.message.reminderSnoozed' }),
        description: formatMessage({ id: 'bot.message.reminderSnoozed' }),
        duration: 10,
      })
      return
    }

    setActionWrong(true)
    setIsLoading(false)
    await wait(7000)
    history.push('/app/dashboard')
    return await Promise.resolve()
  }

  const getLeaveRequest = async (leaveRequestId: string) => {
    const leaveRequestResponse = await getLeaveRequestByIdQuery({ variables: { leaveRequestId }})

    if (!leaveRequestResponse.data || leaveRequestResponse.error) throw leaveRequestResponse.error
    const leaveRequest = leaveRequestResponse.data.getLeaveRequest
    if (!leaveRequest) {
      setLeaveRequestIdWrong(true)
      setIsLoading(false)
      await wait(7000)
      history.push('/app/dashboard')
      return
    }

    const hasMultiLevelApproval = Boolean(leaveRequest.user.team?.approvers?.find(approver => approver.approverLevels.includes(ApproversLevelEnum.SECOND)))
    setIsMultiLevelApproval(hasMultiLevelApproval)
    if (hasMultiLevelApproval) {
      if(leaveRequest.approvedLevel) {
        setApprovedLevel(leaveRequest.approvedLevel)
      } else {
        setApprovedLevel(ApproversLevelEnum.FIRST)
      }
    }

    setCurrentLeaveRequestStatus(leaveRequest.status)
    const leaveDaysForUser = leaveRequest.user.leaveDays
    const leaveTypeId = leaveRequest.leaveType.id
    const currentYearIndex = leaveDaysForUser.findIndex(year => now.toISOString() > year.yearStart && now.toISOString() < year.yearEnd)
    const currentYearDays = leaveDaysForUser[currentYearIndex].leaveTypes.find(lt => lt.leaveTypeId === leaveTypeId)
    const nextYearDays = leaveDaysForUser[currentYearIndex + 1].leaveTypes.find(lt => lt.leaveTypeId === leaveTypeId)

    leaveRequest.user = {
      ...leaveRequest.user,
      currentYearDays,
      nextYearDays,
    }

    setLeaveRequest(leaveRequest)
  }

  const getToilRequest = async (leaveRequestId: string) => {
    const response = await getToilRequestByIdQuery({ variables: { leaveRequestId }})
    if (!response.data || response.error) throw response.error

    if (!response.data.getToilRequest) {
      setLeaveRequestIdWrong(true)
      setIsLoading(false)
      await wait(7000)
      history.push('/app/dashboard')
      return
    }

    setCurrentLeaveRequestStatus(response.data.getToilRequest.status)
    const leaveDaysForUser = response.data.getToilRequest.user.leaveDays
    const leaveTypeId = response.data.getToilRequest.leaveType.id
    const currentYearIndex = leaveDaysForUser.findIndex(year => now.toISOString() > year.yearStart && now.toISOString() < year.yearEnd)
    const currentYearDays = leaveDaysForUser[currentYearIndex].leaveTypes.find(lt => lt.leaveTypeId === leaveTypeId)
    const nextYearDays = leaveDaysForUser[currentYearIndex + 1].leaveTypes.find(lt => lt.leaveTypeId === leaveTypeId)

    response.data.getToilRequest.user = {
      ...response.data.getToilRequest.user,
      currentYearDays,
      nextYearDays,
    }

    setToilRequest(response.data.getToilRequest)
  }

  const onUpdateLeaveRequest = async (leave, leaveStatus, statusReason?: string) => {
    try {
      const isToil = isToilLeave(leave?.id as string)
      const leavePart = isToil ? 'TOIL' : 'LEAVE'
      const transPart = isToil ? 'components.toil' : 'user'

      let body
      if (isToil) {
        body = {
          eventType: leaveStatus ? `${leavePart}_REQUEST_APPROVED` : `${leavePart}_REQUEST_DENIED`,
          eventGroup: `USER_${leavePart}_REQUEST`,
          userId: leave.user.id,
          statusReason,
          toilRequestId: leave.id,
        }
      } else {
        body = {
          eventType: 'LEAVE_REQUEST_STATUS_CHANGED',
          leaveStatus: leaveStatus ? 'APPROVED' : 'DENIED',
          eventGroup: `USER_${leavePart}_REQUEST`,
          userId: leave.user.id,
          statusReason,
          leaveRequestId: leave.id,
        }
      }
      const response = await Api.post('/core/event', body)
      notification.open({
        key: response.correlationId,
        message: formatMessage({ id: leaveStatus ? 'app.updatedInProgress' : `${transPart}.denyInProgress` }),
        icon: (<LoadingOutlined />),
        duration: 0,
      })
      setInProgressLeaveRequestAction(true)
      setActionNotifications([ ...actionNotifications, response.correlationId ])
    } catch (error) {
      const errorMessage = typeof error === 'object' ? (
        error.error ? error.error : JSON.stringify(error, null, 2)
      ) : (
        typeof error === 'string' ? error : error.toString()
      )

      notification.error({
        message: formatMessage({ id: 'error.somethingWentWrong' }),
        description: <div>
          <Paragraph>{ formatMessage({ id: 'connect.platformErrorDescription1' }) }</Paragraph>
          <Paragraph keyboard copyable>{ errorMessage }</Paragraph>
          <Paragraph>{ formatMessage({ id: 'connect.platformErrorDescription2' }, { platform: 'Slack' }) }</Paragraph>
        </div>,
        duration: 0,
      })
    }
  }

  const handleDenyWithReason = () => {
    setInProgressDenyWithReason(true)
    onUpdateLeaveRequest(leaveRequest ?? toilRequest, false, denyReason)
  }

  const onChangeDenyReason = (e) => {
    setDenyReason(e.target.value)
  }

  const backToDashboard = () => {
    return history.push('/app/dashboard')
  }

  const columnsStartEndDate = (leaveRequest: IGetLeaveRequestById | IGetToilRequestById, locale: LocaleEnum) => {

    let formattedDate = <>
      <FormattedDate value={leaveRequest.startDate} format="MMMM Do YYYY." locale={locale} /> -
      <FormattedDate value={leaveRequest.endDate} format="MMMM Do YYYY." locale={locale} />
    </>
    if (dayjs(leaveRequest.startDate).format('YYYY-MM-DD') === dayjs(leaveRequest.endDate as string).format('YYYY-MM-DD') && leaveRequest.isPartDay) {
      const { value: startHour, minute: startMinute, amOrPm: startHourAmOrPm } = convertHourFormats(
        authUser.hourFormat as HourFormatEnum,
        leaveRequest.partDay ? leaveRequest.partDay.startHour : leaveRequest.partDayStartHour as number,
        leaveRequest.partDay ? leaveRequest.partDay.startMinute as number : 0
      )
      const { value: endHour, minute: endMinute, amOrPm: endHourAmOrPm } = convertHourFormats(
        authUser.hourFormat as HourFormatEnum,
        leaveRequest.partDay ? leaveRequest.partDay.endHour : leaveRequest.partDayEndHour as number,
        leaveRequest.partDay ? leaveRequest.partDay.endMinute as number : 0
      )
      const convertedStartHour = formatTimeString({hour: startHour, minute: startMinute, showZeroPadding: false, hourFormat: authUser.hourFormat, amOrPm: startHourAmOrPm})
      const convertedEndHour = formatTimeString({hour: endHour, minute: endMinute, showZeroPadding: false, hourFormat: authUser.hourFormat, amOrPm: endHourAmOrPm})
      formattedDate = <>
        <FormattedDate value={leaveRequest.startDate} format="MMMM Do YYYY." locale={locale} />
        ({convertedStartHour} - {convertedEndHour})
      </>
    }
    if (dayjs(leaveRequest.startDate).format('YYYY-MM-DD') === dayjs(leaveRequest.endDate as string).format('YYYY-MM-DD') && !leaveRequest.isPartDay) {
      formattedDate = <FormattedDate value={leaveRequest.startDate} format="MMMM Do YYYY." locale={locale} />
    }

    return formattedDate
  }

  return (
    <div className='main-content'>
      <div className="main-content-body">
        {isLoading ?
          <CircularProgress /> :
          <>
            {leaveRequestIdWrong &&
              <Alert
                message={<IntlMessages id="leaveRequests.wrongLeaveRequestId" />}
                type="error"
                showIcon
              />
            }
            {actionWrong &&
              <Alert
                message={<IntlMessages id="leaveRequests.wrongAction" />}
                type="error"
                showIcon
              />
            }
            {leaveRequest && !actionWrong && !leaveRequestIdWrong &&
              <>
                {!(leaveRequestApprovedLevel === ApproversLevelEnum.FIRST && leaveRequest.approvedLevel === ApproversLevelEnum.FIRST && leaveRequest.status === 'OPEN') &&
                  <LeaveRequestAlertStatus
                    approverId={leaveRequest?.approver?.id}
                    approverName={leaveRequest?.approver?.name}
                    authUserId={authUser?.id}
                    currentLeaveRequestStatus={currentLeaveRequestStatus}
                    leaveRequestStatus={leaveRequest.status}
                    leaveRequestName={leaveRequest?.user.name}
                    resend={resend}
                    isMultiLevelApproval={isMultiLevelApproval}
                    approvedLevel={approvedLevel}
                  />
                }
                <Title level={4} style={{ marginTop: 30, marginBottom: 30 }}><IntlMessages id="leaveRequests.requestSummary" /></Title>
                <Paragraph style={{ marginBottom: 20 }}>
                  <IntlMessages id="leaveRequests.leaveRequestInfoTitle" values={{
                    name: leaveRequest.user.name,
                    // eslint-disable-next-line
                    strong: (...chunks) => (
                      <strong>{chunks}</strong>
                    ),
                  }} />
                </Paragraph>
                <Descriptions layout="vertical" column={2}>
                  <Descriptions.Item
                    labelStyle={{ fontWeight: 600 }}
                    label={<IntlMessages id="app.leaveType" />}>
                    {leaveRequest.leaveType.name}
                  </Descriptions.Item>
                  <Descriptions.Item
                    labelStyle={{ fontWeight: 600 }}
                    label={<IntlMessages id="leaveRequests.dates" values={{ plural: leaveRequest.workingDays > 1 ? 's' : '' }} />}
                  >
                    {columnsStartEndDate(leaveRequest, locale.locale as LocaleEnum)}
                  </Descriptions.Item>
                  {leaveRequest.totalThisYear > 0 &&
                    <>
                      <Descriptions.Item
                        labelStyle={{ fontWeight: 600 }}
                        label={<IntlMessages id={hourlyLeaveAccounting ? 'leaveRequests.hoursRequestedForCurrentYear' : 'leaveRequests.daysRequestedForCurrentYear'} />}
                      >
                        <DisplayLeaveDuration
                          value={leaveRequest.totalThisYear}
                          daysList={leaveRequest.daysList}
                        />
                      </Descriptions.Item>
                      <Descriptions.Item
                        labelStyle={{ fontWeight: 600 }}
                        label={<IntlMessages id={hourlyLeaveAccounting ? 'leaveRequests.hoursRemainingForCurrentYear' : 'leaveRequests.daysRemainingForCurrentYear'} />}
                      >
                        {leaveRequest.user.currentYearDays?.hasUnlimitedDays
                          ? '∞'
                          : <DisplayLeaveDuration
                            value={ leaveRequest.user.currentYearDays?.currentDays ?? 0}
                            daysList={leaveRequest.daysList}
                          />
                        }
                      </Descriptions.Item>
                    </>
                  }
                  {leaveRequest.totalNextYear > 0 &&
                    <>
                      <Descriptions.Item
                        labelStyle={{ fontWeight: 600 }}
                        label={<IntlMessages id={hourlyLeaveAccounting ? 'leaveRequests.hoursRequestedForNextYear' : 'leaveRequests.daysRequestedForNextYear'} />}
                      >
                        <DisplayLeaveDuration
                          value={leaveRequest.totalNextYear}
                          daysList={leaveRequest.daysList}
                        />
                      </Descriptions.Item>
                      <Descriptions.Item
                        labelStyle={{ fontWeight: 600 }}
                        label={<IntlMessages id={hourlyLeaveAccounting ? 'leaveRequests.hoursRemainingForNextYear' : 'leaveRequests.daysRemainingForNextYear'} />}
                      >
                        {leaveRequest.user.nextYearDays?.hasUnlimitedDays
                          ? '∞'
                          : <DisplayLeaveDuration
                            value={ leaveRequest.user.nextYearDays?.currentDays ?? 0}
                            daysList={leaveRequest.daysList}
                          />
                        }
                      </Descriptions.Item>
                    </>
                  }
                  {leaveRequest.reason &&
                    <Descriptions.Item
                      labelStyle={{ fontWeight: 600 }}
                      span={2}
                      label={<IntlMessages id="app.reason" />}
                    >
                      {leaveRequest.reason}
                    </Descriptions.Item>
                  }
                  {denyWithReason &&
                    <>
                      <Descriptions.Item
                        labelStyle={{ fontWeight: 600 }}
                        span={2}
                        label={<IntlMessages id="leaveRequests.reasonForDenying" />}
                      >
                        <TextArea rows={4} onChange={onChangeDenyReason} disabled={inProgressDenyWithReason} />
                      </Descriptions.Item>
                      <Descriptions.Item
                        labelStyle={{ fontWeight: 600 }}
                        span={2}
                      >
                        <Button type="primary" danger loading={inProgressDenyWithReason} onClick={handleDenyWithReason} >
                          <IntlMessages id="app.denyWithReason" />
                        </Button>
                        <Button type="link" onClick={backToDashboard} disabled={inProgressDenyWithReason}>
                          <IntlMessages id="leaveRequests.cancelThisAction" />
                        </Button>
                      </Descriptions.Item>
                    </>
                  }
                </Descriptions>
              </>
            }
            {toilRequest && !actionWrong && !leaveRequestIdWrong &&
              <>
                <LeaveRequestAlertStatus
                  approverId={toilRequest?.approver?.id}
                  approverName={toilRequest?.approver?.name}
                  authUserId={authUser?.id}
                  currentLeaveRequestStatus={currentLeaveRequestStatus}
                  leaveRequestStatus={toilRequest.status}
                  leaveRequestName={toilRequest?.user.name}
                  resend={resend}
                />
                <Title level={4} style={{ marginTop: 30, marginBottom: 30 }}><IntlMessages id="leaveRequests.requestSummary" /></Title>
                <Paragraph style={{ marginBottom: 20 }}>
                  <IntlMessages id="leaveRequests.leaveRequestInfoTitleToil" values={{
                    name: toilRequest.user.name,
                    // eslint-disable-next-line
                    strong: (...chunks) => (
                      <strong>{chunks}</strong>
                    ),
                  }} />
                </Paragraph>
                <Descriptions layout="vertical" column={2}>
                  <Descriptions.Item
                    labelStyle={{ fontWeight: 600 }}
                    label={<IntlMessages id="app.leaveType" />}>
                    <IntlMessages id="app.toil" /> - {toilRequest.leaveType.name}
                  </Descriptions.Item>
                  <Descriptions.Item
                    labelStyle={{ fontWeight: 600 }}
                    label={<IntlMessages id="leaveRequests.dates" values={{ plural: toilRequest.workingDays > 1 ? 's' : '' }} />}
                  >
                    {columnsStartEndDate(toilRequest, locale.locale as LocaleEnum)}
                  </Descriptions.Item>
                  {toilRequest.reason &&
                    <Descriptions.Item
                      labelStyle={{ fontWeight: 600 }}
                      span={2}
                      label={<IntlMessages id="app.reason" />}
                    >
                      {toilRequest.reason}
                    </Descriptions.Item>
                  }
                  {denyWithReason &&
                    <>
                      <Descriptions.Item
                        labelStyle={{ fontWeight: 600 }}
                        span={2}
                        label={<IntlMessages id="leaveRequests.reasonForDenying" />}
                      >
                        <TextArea rows={4} onChange={onChangeDenyReason} disabled={inProgressDenyWithReason} />
                      </Descriptions.Item>
                      <Descriptions.Item
                        labelStyle={{ fontWeight: 600 }}
                        span={2}
                      >
                        <Button type="primary" danger loading={inProgressDenyWithReason} onClick={handleDenyWithReason} >
                          <IntlMessages id="app.denyWithReason" />
                        </Button>
                        <Button type="link" onClick={backToDashboard} disabled={inProgressDenyWithReason}>
                          <IntlMessages id="leaveRequests.cancelThisAction" />
                        </Button>
                      </Descriptions.Item>
                    </>
                  }
                </Descriptions>
              </>
            }
          </>
        }
      </div>
    </div>
  )
}

export default LeaveRequestsPage
