import React, { useContext, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { App, Button, Form, Input, Select, DatePicker, Row, Col, Divider, InputNumber } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import dayjs, { Dayjs } from 'dayjs'
import { useIntl } from 'react-intl'

import Api from '@vacationtracker/shared/services/api'
import { track } from '../../services/analytics/analytics'
import { notificationStore } from '../../context/notificationsContext/store'
import logger from '@vacationtracker/shared/functions/logger'
import { useManualQuery } from 'graphql-hooks'
import { getAutomationsById } from '../../graphql/custom-queries'
import IntlMessages from '../../util/IntlMessages'
import FilterAdvanced from '@vacationtracker/shared/components/filter-advanced'
import YesNoSwitch from '../yes-no-switch'
import CircularProgress from '../circular-progress'

import { FrontendUrls } from '../../types/urls'
import { IFilter } from '@vacationtracker/shared/types/filter'
import { AddonsStatus, AddonsStatusEnum } from '@vacationtracker/shared/types/addons'
import { ITeamShort } from '@vacationtracker/shared/types/team'
import { IGetTeamsShort } from '../../types/teams'
import { IGetLocationsShort } from '../../types/locations'
import { IGetLabelsShort } from '../../types/labels'
import { IGetAutomationsByIdData, ILeaveTypeListShort, ILeaveDurationLimitAutomation } from '../../types/custom-queries'
import { IAutomationLeaveDurationLimitCreateEvent, IAutomationLeaveDurationLimitForm } from '@vacationtracker/shared/types/automations'
import { QuotaUnits } from '@vacationtracker/shared/types/leave-policy'
import { ISelected } from '@vacationtracker/shared/components/filter-advanced/types'

const { RangePicker } = DatePicker

interface ILeaveDurationLimitForm {
  automationId?: string
  teams: IGetTeamsShort[] | ITeamShort[]
  locations: IGetLocationsShort[]
  leaveTypes: ILeaveTypeListShort[]
  labels: IGetLabelsShort[]
  shouldEnableLabels: boolean
  backToAddons: () => void
  amIAdmin: boolean
  hourlyLeaveAccounting: boolean
  status?: AddonsStatus
}

const LeaveDurationLimitForm = ({
  automationId,
  teams,
  locations,
  leaveTypes,
  labels,
  shouldEnableLabels,
  backToAddons,
  amIAdmin,
  hourlyLeaveAccounting,
  status,
}: ILeaveDurationLimitForm) => {
  const abortControllerRef = useRef<AbortController>(new AbortController())

  const history = useHistory()
  const { formatMessage } = useIntl()
  const [form] = Form.useForm()
  const { notification } = App.useApp()
  const { actionNotifications, setActionNotifications } = useContext(notificationStore)
  const [getAutomationsByIdQuery] = useManualQuery<IGetAutomationsByIdData, { id: string }>(getAutomationsById, {
    fetchOptionsOverrides: {
      signal: abortControllerRef.current?.signal,
    },
  })

  const [isLoading, setIsLoading] = useState(true)
  const [btnLoading, setBtnLoading] = useState(false)
  const [applyToAllUsers, setApplyToAllUsers] = useState<boolean>(false)
  const [units, setUnits] = useState<QuotaUnits>(hourlyLeaveAccounting ? 'hours' : 'days')
  const [filters, setFilters] = useState<IFilter>()
  const [initialFilterValues, setInitialFilterValues] = useState([{}])

  const [addon, setAddon] = useState<IAutomationLeaveDurationLimitForm>({
    automationId: '',
    name: '',
    period: undefined,
    recurring: true,
    minimumDuration: undefined,
    maximumDuration: undefined,
    units: hourlyLeaveAccounting ? 'hours' : 'days',
    autoRejectResponse: formatMessage({
      id: 'automations.leaveDurationLimit.autoRejectResponsePlaceholder',
    }),
    locationIds: [],
    teamIds: [],
    labelIds: [],
    leaveTypeIds: [],
  })

  const [formValues, setFormValues] = useState(addon)

  const handleFormChange = (changedValues: any, allValues: any) => {
    setFormValues(allValues)
  }

  useEffect(() => {
    abortControllerRef.current = new AbortController()

    return () => {
      abortControllerRef.current?.abort()
    }
  }, [])

  useEffect(() => {
    automationId ? getAutomationById(automationId) : setIsLoading(false)
  }, [automationId])

  const getAutomationById = async (id: string) => {
    try {
      const response = await getAutomationsByIdQuery({ variables: { id } })
      const automation = response.data?.getAutomation as ILeaveDurationLimitAutomation

      const existingAutomation = {
        automationId: automation.id,
        name: automation.name,
        period: [dayjs(automation.period[0]), dayjs(automation.period[1])] as [Dayjs, Dayjs],
        recurring: automation.recurring,
        minimumDuration: automation.minimumDuration,
        maximumDuration: automation.maximumDuration,
        units: automation.units,
        autoRejectResponse: automation.autoRejectResponse,
        locationIds: automation.locationIds,
        teamIds: automation.teamIds,
        labelIds: automation.labelIds,
        leaveTypeIds: automation.leaveTypeIds,
      }
      
      if (existingAutomation.locationIds?.length > 0 || existingAutomation.teamIds?.length > 0 || existingAutomation.labelIds?.length > 0) {
        setApplyToAllUsers(false)
        setInitialFilterValues(() => {
          const data: ISelected[] = []
          existingAutomation.locationIds?.length > 0 && data.push({ type: 'Locations', values: existingAutomation.locationIds })
          existingAutomation.teamIds?.length > 0 && data.push({ type: 'Departments', values: existingAutomation.teamIds })
          existingAutomation.labelIds?.length > 0 && data.push({ type: 'Labels', values: existingAutomation.labelIds })
          return data
        })
      } else {
        setApplyToAllUsers(true)
      }
      setUnits(existingAutomation.units)
      setAddon(existingAutomation)
      setFormValues(existingAutomation)
      setIsLoading(false)
    } catch (error) {
      console.error('Error fetching automation:', error)
    }
  }

  const onFinish = () => {
    if (status === AddonsStatusEnum.CANCELED) return
    form
      .validateFields()
      .then(async (values) => {
        setBtnLoading(true)
        values = { ...values, ...filters }
        values.period = [values.period[0]?.format('YYYY-MM-DD'), values.period[1]?.format('YYYY-MM-DD')]
        let response
        try {
          track(automationId ? 'LEAVE_DURATION_LIMIT_UPDATED' : 'LEAVE_DURATION_LIMIT_CREATED', values)
          if (!automationId) {
            response = await Api.post<Partial<IAutomationLeaveDurationLimitCreateEvent>>('/core/event', {
              eventType: 'LEAVE_DURATION_LIMIT_CREATED',
              eventGroup: 'AUTOMATION',
              ...values,
            })
          } else {
            response = await Api.post<Partial<IAutomationLeaveDurationLimitCreateEvent>>('/core/event', {
              eventType: 'LEAVE_DURATION_LIMIT_UPDATED',
              eventGroup: 'AUTOMATION',
              automationId,
              ...values,
              isActive: true,
            })
          }

          notification.open({
            key: response.correlationId,
            message: formatMessage({ id: automationId ? 'automations.leaveDurationLimit.updateInProgress' : 'automations.leaveDurationLimit.createInProgress' }),
            icon: (<LoadingOutlined />),
            duration: 0,
          })

          setActionNotifications([ ...actionNotifications, response.correlationId ])

          setTimeout(() => {
            history.push(`${FrontendUrls.addons}/leave-duration-limit/view`)
          }, 700)
        } catch (error) {
          setBtnLoading(false)
          if (error.response?.data?.error) {
            notification.error({
              message: formatMessage({ id: 'error.automations.automationSubmitError' }),
              description: formatMessage({ id: error.response.data.error }),
              duration: 0,
            })
          } else {
            let description
            let message = formatMessage({ id: 'error.automations.automationSubmitError' })
            if (response?.correlationId) {
              description = formatMessage({ id: 'error.automations.automationSubmitError' })
            } else if (error.message === 'Request failed with status code 400') {
              message = formatMessage({ id: 'error.automations.insufficientPrivileges.title' })
              description = formatMessage({ id: 'error.automations.insufficientPrivileges.description' })
            } else {
              description = error.message
            }

            notification.error({
              message,
              description,
              duration: 0,
            })
          }
        }
      })
      .catch(info => {
        logger.warning('Validate Failed:', info)
        setBtnLoading(false)
      })
  }

  return (
    <>
      {(isLoading && automationId) ?
        <CircularProgress /> :
        <>
          <Form
            form={form}
            layout="vertical"
            name="automationsForm"
            disabled={status === AddonsStatusEnum.CANCELED}
            onFinish={onFinish}
            className="automation-form"
            initialValues={addon}
            scrollToFirstError
            requiredMark="optional"
            labelWrap
            onValuesChange={handleFormChange}
          >
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col xxl={8} xl={8} lg={6} md={6} sm={24} xs={24}>
                <h2><IntlMessages id="automations.LEAVE_DURATION_LIMIT" /></h2>
                <p><IntlMessages id="automations.leaveDurationLimit.policyDescription" /></p>
                <p><IntlMessages id="automations.leaveDurationLimit.durationDescription" /></p>
                <p><IntlMessages id="automations.leaveDurationLimit.autoRejectDescription" /></p>
              </Col>
              <Col xxl={16} xl={16} lg={18} md={18} sm={24} xs={24}>
                <Form.Item
                  name="name"
                  label={<IntlMessages id="app.name" />}
                  rules={[
                    {
                      required: true,
                      message: <IntlMessages id="form.nameRequired" />,
                    },
                  ]}
                >
                  <Input
                    placeholder={formatMessage({ id: 'automationsForm.policy.name' })}
                    disabled={status === AddonsStatusEnum.CANCELED}
                  />
                </Form.Item>

                <Form.Item
                  name="period"
                  label={<IntlMessages id="automationsForm.period" />}
                  rules={[
                    {
                      required: true,
                      message: <IntlMessages id="automationsForm.periodRequired" />,
                    },
                  ]}
                >
                  <RangePicker
                    format="YYYY-MM-DD"
                    disabled={status === AddonsStatusEnum.CANCELED}
                  />
                </Form.Item>

                <YesNoSwitch
                  name="recurring"
                  label={<IntlMessages id="automationsForm.recurring" />}
                  required
                  disabled={status === AddonsStatusEnum.CANCELED}
                  tooltip={<IntlMessages id="automationsForm.recurringTooltip" />}
                />

                <Form.Item
                  name="minimumDuration"
                  label={<IntlMessages id="automationsForm.minimumLeaveDuration" values={{ units }} />}
                >
                  <InputNumber min={0} disabled={status === AddonsStatusEnum.CANCELED}/>
                </Form.Item>

                <Form.Item
                  name="maximumDuration"
                  label={<IntlMessages id="automationsForm.maximumLeaveDuration" values={{ units }} />}
                >
                  <InputNumber min={0} disabled={status === AddonsStatusEnum.CANCELED}/>
                </Form.Item>

                <Form.Item
                  name="autoRejectResponse"
                  label={<IntlMessages id="automations.autoRejectResponse" />}
                  rules={[
                    {
                      required: true,
                      message: <IntlMessages id="automationsForm.autoRejectResponseRequired" />,
                    },
                  ]}
                >
                  <Input.TextArea
                    disabled={status === AddonsStatusEnum.CANCELED}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Divider />
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col xxl={8} xl={8} lg={6} md={6} sm={24} xs={24}>
                <h2><IntlMessages id="app.applyTo" /></h2>
                <p><IntlMessages id="automations.filterDescription" /></p>
                <p><IntlMessages id="automations.leaveTypesDescription" /></p>
              </Col>
              <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                <YesNoSwitch
                  tooltip={<IntlMessages id="automationsForm.applyToAllUsersTooltip" />}
                  defaultValue={applyToAllUsers}
                  onChange={() => {setApplyToAllUsers(!applyToAllUsers)}}
                  label={<IntlMessages id="automationsForm.applyToAllUsers" />}
                  disabled={status === AddonsStatusEnum.CANCELED}
                />
                {!applyToAllUsers && amIAdmin &&
                  <>
                    <FilterAdvanced
                      isForm={true}
                      data={{
                        Locations: locations,
                        Departments: teams,
                        Labels: labels,
                      }}
                      onChangeFilter={(filters) => setFilters(filters)}
                      initialValues={initialFilterValues}
                      showLabels={shouldEnableLabels}
                      toolTipInfo="automationsForm.advancedFilter.tooltipInfo"
                      filterByWidth={125}
                      saveFilters={false}
                      page={automationId ? undefined : 'automationsForm'}
                    />
                  </>
                }
                {!amIAdmin &&
                  <Form.Item
                    label={<IntlMessages id="calendar.filterByTeam" />}
                    rules={[{ required: true, message: <IntlMessages id="form.inputRequired" /> }]}
                    name="teamIds"
                  >
                    <Select
                      mode="multiple"
                      allowClear
                      style={{ width: '100%' }}
                      placeholder={<IntlMessages id="app.pleaseSelect" />}
                    >
                      {teams.map((team) => (
                        <Select.Option value={team.id} key={team.id}>
                          {team.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                }
                <Form.Item
                  name="leaveTypeIds"
                  label={<IntlMessages id="user.leaveTypes" />}
                >
                  <Select
                    mode="multiple"
                    disabled={status === AddonsStatusEnum.CANCELED}
                  >
                    {leaveTypes.map(leaveType => (
                      <Select.Option key={leaveType.id} value={leaveType.id}>
                        {leaveType.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Form.Item name="units" hidden>
              <Input />
            </Form.Item>
            
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span={24}>
                <Form.Item style={{ textAlign: 'right' }}>
                  <Button onClick={backToAddons}>
                    <IntlMessages id="app.cancel" />
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={btnLoading}
                    disabled={status === AddonsStatusEnum.CANCELED}
                  >
                    <IntlMessages id="app.save" />
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Row>
            <Col xxl={8} xl={8} lg={6} md={6} sm={24} xs={24}>
            </Col>
            <Col xs={24} sm={24} md={16} lg={16} xl={12}>
              <div className='vt-primary-color' style={{ textAlign: 'center' }}>
                <IntlMessages id="automations.leaveDurationLimit.summary"
                  values={{
                    leaveTypes: leaveTypes.filter(lt => formValues.leaveTypeIds?.includes(lt.id))?.map(lt => lt.name)?.join(', ') || <IntlMessages id="app.all" />,
                    startDate: formValues.period?.[0]?.format('YYYY-MM-DD') || <IntlMessages id="app.notSet" />,
                    endDate: formValues.period?.[1]?.format('YYYY-MM-DD') || <IntlMessages id="app.notSet" />,  
                    allUsers: applyToAllUsers ? 2 : 1,
                  }} />
              </div>
            </Col>
          </Row>
        </>
      }
    </>
  )
}

export default LeaveDurationLimitForm