import React, { useState, useEffect, useContext } from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'

import { useManualQuery } from 'graphql-hooks'
import axios from 'axios'

import { App, Breadcrumb, Divider, Steps } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

import Api from '@vacationtracker/shared/services/api'
import * as logger from '../../../../services/logger'
import { ImportHolidaysStepEnum } from './types'
import { IGetLocationGeneral, IGetLocationLeaveTypesAllLeavePoliciesData } from '../../../../types/locations'
import { IGetHolidaysResponse } from '@vacationtracker/shared/types/holidays'

import { getLocationByIdLeaveTypesAllLeavePolicies } from '../../../../graphql/custom-queries'

import { ICountry, IState } from '@vacationtracker/shared/data/countries'
import IntlMessages from '../../../../util/IntlMessages'

import SelectCountryForm from './steps/select-country'
import SelectHolidaysForm from './steps/select-holidays'
import SummaryForm from './steps/summary'

import { useAppSelector } from '../../../../store/hooks'
import { selectLocaleSlice } from '../../../../store/locale-slice'
import { notificationStore } from '../../../../context/notificationsContext/store'
import { FrontendUrls } from '../../../../types/urls'
import { getAuthTokens } from '@vacationtracker/shared/services/auth'

interface IImportHolidaysPage {
  match: {
    params: {
      id: string
      year: string
    }
  }
}

const ImportHolidaysPage = ({ match }: IImportHolidaysPage): React.ReactElement => {
  const { formatMessage } = useIntl()
  const { notification } = App.useApp()
  const { actionNotifications, setActionNotifications} = useContext(notificationStore)
  const {locale} = useAppSelector(selectLocaleSlice) 
  const [currentStep, setCurrentStep] = useState(0)
  const [step, setStep] = useState(ImportHolidaysStepEnum.stepOne)
  const [location, setLocation] = useState<IGetLocationGeneral>()
  const [year, setYear] = useState(match.params.year as unknown as number)
  const [country, setCountry] = useState<ICountry>()
  const [state, setState] = useState<IState>()
  const [holidays, setHolidays] = useState<IGetHolidaysResponse[]>()

  const [ getLocationByIdLeaveTypesAllLeavePoliciesQuery ] = useManualQuery<IGetLocationLeaveTypesAllLeavePoliciesData, { id: string }>(getLocationByIdLeaveTypesAllLeavePolicies)

  useEffect(() => {
    fetchLocation(match.params.id)
  }, [match.params.id])

  const fetchLocation = async (id: string) => {
    try {
      const response = await getLocationByIdLeaveTypesAllLeavePoliciesQuery({ variables: { id }})
      if (!response.data || response.error) throw response.error
      setLocation(response.data.getLocation)
    } catch (err) { logger.error('error fetching location', err) }
  }

  const setSteps = (step: ImportHolidaysStepEnum, activeStep: number) => {
    setStep(step)
    setCurrentStep(activeStep)
  }

  const findHolidaysForCountry = async () => {
    const tokens = await getAuthTokens()
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/core/holidays`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${tokens?.idToken}`,
      },
      params: {
        year,
        countryIso: country?.iso,
        country: country?.name,
        stateIso: state?.iso,
        locale: locale.locale,
      },
    })
    //sometimes dates from date-holidays package(example Israel, Persian etc.)
    //are in format 2023-02-06 00:00:00 -0600 (religious holidays, lunar calendar or whatever)
    //so we just slice that suffix( -0600):
    response.data.map((holiday: IGetHolidaysResponse) => holiday.date = holiday.date.slice(0, 19))
    return response.data as IGetHolidaysResponse[]
  }

  const saveNewHolidays = async (autoImport) => {   
    const data = holidays?.map(holiday => ({
      date: holiday.valueForApi,
      isHalfDay: false,
      name: holiday.name,
    }))
    
    const response = await sendSaveNewHolidaysEvent(data)
    autoImport ? await sendAutoImportHolidaysEvent(data) : await sendAutoImportHolidaysEvent([])
    notification.open({
      key: response.correlationId,
      message: formatMessage({ id: 'location.holidays.updateInProgress' }),
      icon: (<LoadingOutlined />),
      duration: 0,
    })
    
    setActionNotifications([ ...actionNotifications, response.correlationId ])
  }

  const sendSaveNewHolidaysEvent = async (data) => {
    return await Api.post('/core/event', {
      eventType: 'LOCATION_YEAR_HOLIDAYS_CHANGED',
      eventGroup: 'LOCATION',
      locationId: match.params.id,
      holidays: data,
      year,
      creationType: 'IMPORTED',
    })
  }

  const sendAutoImportHolidaysEvent = async (data) => {
    return await Api.post('/core/event', {
      eventType: 'LOCATION_HOLIDAYS_AUTOIMPORT_CHANGED',
      eventGroup: 'LOCATION',
      locationId: match.params.id,
      holidays: data,
      year,
      country: country?.iso,
      stateIso: state?.iso,
    })
  }

  return <div className='main-content'>
    <div className="main-content-header">
      <div className="main-content-header-title">
        <span><IntlMessages id="app.location" />: {location?.name}</span>
      </div>
      <div className="main-content-header-breadcrumb">
        <Breadcrumb
          items={[
            {
              title: <Link to={FrontendUrls.dashboard}><IntlMessages id="sidebar.dashboard" /></Link>,
            },
            {
              title: <IntlMessages id='sidebar.settings' />,
            },
            {
              title: <Link to='/app/settings/locations'><IntlMessages id="app.locations" /></Link>,
            },
            {
              title: <Link to={`/app/settings/locations/${location?.id}/holidays`}>{location?.name}</Link>,
            },
            {
              title: <IntlMessages id='components.insertHolidaysForm.insertHolidays' />,
            },
          ]}
        />
      </div>
    </div>
    <div className="main-content-body">
      <div>
        <Steps
          className="import-holidays-steps"
          current={currentStep}
          style={{ paddingBottom: 35 }}
          items={[
            {
              title: <IntlMessages id='components.importHolidaysForm.selectCountry' />,
            },
            {
              title: <IntlMessages id='components.importHolidaysForm.selectHolidays' />,
            },
            {
              title: <IntlMessages id='components.modal.confirm' />,
            },
          ]}
        />
      </div>
      <Divider />
      <>
        {step === ImportHolidaysStepEnum.stepOne && <SelectCountryForm
          setSteps={setSteps}
          setYear={setYear}
          setCountry={setCountry}
          setState={setState}
          locationId={location?.id as string}
          defaultYear={match.params.year as unknown as number}
        />}
        {(step === ImportHolidaysStepEnum.stepTwo) && <SelectHolidaysForm
          year={year}
          country={country as ICountry}
          state={state as IState}
          setSteps={setSteps}
          findHolidaysForCountry={findHolidaysForCountry}
          setSelectedHolidays={setHolidays}
        />}
        {(step === ImportHolidaysStepEnum.stepThree) && <SummaryForm
          setSteps={setSteps}
          saveNewHolidays={saveNewHolidays}
          holidays={holidays as IGetHolidaysResponse[]}
          year={year}
          country={country as ICountry}
          state={state as IState}
          locationId={location?.id as string}
        />}
      </>
    </div>
  </div>
}

export default ImportHolidaysPage
