import React, { ReactElement } from 'react'
import { Link } from 'react-router-dom'

import IntlMessages from '../../util/IntlMessages'
import FormattedDate from '@vacationtracker/shared/components/formatted-date'

import { IFilter } from '@vacationtracker/shared/types/filter'
import { MaxUsersAwayTypeEnum } from '@vacationtracker/shared/types/automations'

export interface IMaximumUsersAwaySummaryInfo {
  filters: IFilter
  noOfUsers: number
  leaveTypes: string[]
  recurring: boolean
  fromDate: string
  toDate: string
  maxUsersAway: {
    type: MaxUsersAwayTypeEnum
    value: number
  }
  applyToAllUsers?: boolean
}

const MaximumUsersAwaySummary = ({
  filters,
  noOfUsers,
  recurring,
  fromDate,
  toDate,
  leaveTypes,
  maxUsersAway,
}: IMaximumUsersAwaySummaryInfo): ReactElement => {
  let linkTo = '/app/users?saveFilters=false'
  if (filters.locationIds?.length) {
    linkTo += `&locations=${filters.locationIds.join(',')}`
  }
  if (filters.teamIds?.length) {
    linkTo += `&departments=${filters.teamIds.join(',')}`
  }
  if (filters.labelIds?.length) {
    linkTo += `&labels=${filters.labelIds.join(',')}`
  }

  if (!fromDate) {
    return (<></>)
  }

  return (
    <div className='vt-primary-color' style={{ textAlign: 'center' }}>
      <IntlMessages id="components.maximumUsersAwaySummaryInfo.summary"
        values={{
          noOfUsers,
          multiUsers: (...chunks) => noOfUsers > 1 || noOfUsers === 0 || maxUsersAway.type === MaxUsersAwayTypeEnum.absolute ? <>{chunks}</> : '',
          link: (...chunks) => noOfUsers >= 1 ? <Link target="_blank" style={{ textDecoration: 'underline' }} to={linkTo}>{chunks}</Link> : '',
          leaveTypes: leaveTypes.join(', '),
          fromDate: recurring ? <FormattedDate value={fromDate} format="MMMM Do" /> : <FormattedDate value={fromDate} format="MMMM Do YYYY" />,
          toDate: recurring ? <FormattedDate value={toDate} format="MMMM Do" /> : <FormattedDate value={toDate} format="MMMM Do YYYY" />,
          endDate: (...chunks) => fromDate !== toDate ? <>{chunks}</> : '',
          recurring: (...chunks) => recurring ? <>{chunks}</> : '',
          maxValue: maxUsersAway.value,
          maxType: maxUsersAway.type === MaxUsersAwayTypeEnum.percentage ? '%' : '',
          isAbsolute: (...chunks) => maxUsersAway.type === MaxUsersAwayTypeEnum.absolute ? <>{chunks}</> : '',
        }} />
    </div>
  )
}

export default MaximumUsersAwaySummary