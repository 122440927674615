import React, { useState } from 'react'
import { useIntl } from 'react-intl'

import { getExtendedAddons } from '../common/addons'
import OpenApiDocumentation from '../../../components/open-api-documentation'

import { IPageParams } from '../../../types/data'
import { useAppSelector } from '../../../store/hooks'
import { selectFeatureFlagsSlice } from '../../../store/feature-flags-slice'

const AddonViewDocumentationPage = ({ match }: IPageParams): React.ReactElement => {
  const { formatMessage } = useIntl()
  const { featureFlags } = useAppSelector(selectFeatureFlagsSlice)
  
  const addonsEventTypes = getExtendedAddons(formatMessage, featureFlags)
  
  const [selectAddonType] = useState<string | null>(() => {
    if (match?.params?.addonType) {
      const addon = addonsEventTypes.find(addon => {
        return addon.url === match.params.addonType.replace(/_/g, '-')
      })
      return addon?.type as string
    }
    return null
  })
  
  return (
    <>
      {selectAddonType === 'OPEN_API' &&
        <OpenApiDocumentation />
      }
    </>
  )
}

export default AddonViewDocumentationPage
