import { IStripeTaxes } from '@vacationtracker/shared/types/stripe'

const test: IStripeTaxes = {
  'noTax': [
    'txr_1CLEl8CZGLYlCm3DFp2XH45d',
  ],
  'Alberta': [
    'txr_1F71CWCZGLYlCm3DkY819vy0',
  ],
  'British Columbia': [
    'txr_1F71CWCZGLYlCm3DkY819vy0',
    'txr_1JZdGDCZGLYlCm3DdtfUFiZP',
  ],
  'Manitoba': [
    'txr_1F71CWCZGLYlCm3DkY819vy0',
  ],
  'New Brunswick': [
    'txr_1F71Y7CZGLYlCm3D80NLapgv',
  ],
  'Newfoundland and Labrador': [
    'txr_1F71YgCZGLYlCm3Dyf2WBPwS',
  ],
  'Nova Scotia': [
    'txr_1F71ZLCZGLYlCm3D33I9LYSi',
  ],
  'Nunavut': [
    'txr_1F71CWCZGLYlCm3DkY819vy0',
  ],
  'Ontario': [
    'txr_1F71ZxCZGLYlCm3Dwteo7L4h',
  ],
  'Prince Edward Island': [
    'txr_1F71aRCZGLYlCm3DQb7Gzamu',
  ],
  'Quebec': [
    'txr_1F71CWCZGLYlCm3DkY819vy0',
    'txr_1F718LCZGLYlCm3D4ivQYQ0A',
  ],
  'Saskatchewan': [
    'txr_1F71CWCZGLYlCm3DkY819vy0',
    'txr_1QmCvcCZGLYlCm3DyunHQ3fd',
  ],
  'Yukon': [
    'txr_1F71CWCZGLYlCm3DkY819vy0',
  ],
}

const prod: IStripeTaxes = {
  'noTax': [
    'txr_1ChGb1CZGLYlCm3DyyisVF5W',
  ],
  'Alberta': [
    'txr_1Fc9okCZGLYlCm3DMF04nPn7',
  ],
  'British Columbia': [
    'txr_1Fc9okCZGLYlCm3DMF04nPn7',
    'txr_1JZdkYCZGLYlCm3DrR82AUbZ',
  ],
  'Manitoba': [
    'txr_1Fc9okCZGLYlCm3DMF04nPn7',
  ],
  'New Brunswick': [
    'txr_1Fc9oBCZGLYlCm3DluDzf3Tx',
  ],
  'Newfoundland and Labrador': [
    'txr_1Fc9npCZGLYlCm3DznkUGRvb',
  ],
  'Nova Scotia': [
    'txr_1Fc9nOCZGLYlCm3DWV35UO11',
  ],
  'Nunavut': [
    'txr_1Fc9okCZGLYlCm3DMF04nPn7',
  ],
  'Ontario': [
    'txr_1Fc9mvCZGLYlCm3DG9wiueaT',
  ],
  'Prince Edward Island': [
    'txr_1Fc9lZCZGLYlCm3DAmADTNVP',
  ],
  'Quebec': [
    'txr_1Fc9okCZGLYlCm3DMF04nPn7',
    'txr_1Fc9pDCZGLYlCm3DF0e30V5T',
  ],
  'Saskatchewan': [
    'txr_1Fc9okCZGLYlCm3DMF04nPn7',
    'txr_1QmCxHCZGLYlCm3Dwryqjm1F',
  ],
  'Yukon': [
    'txr_1Fc9okCZGLYlCm3DMF04nPn7',
  ],
}

export function getStripeTaxes(env: string): IStripeTaxes {
  if (env === 'prod') {
    return prod
  } else {
    return test
  }
}
